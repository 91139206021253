<template>
	<Card class="col-right-card min-height">
		<p slot="title">外修配件匹配管理</p>
		<Form :label-width="120">
			<FormItem class="FromItemnpt" label="外修商" prop="companyno"><Input v-model="search.ogname"  /></FormItem>
			<FormItem class="FromItemnpt" label="配件名称" prop="companyno"><Input v-model="search.cspname"  /></FormItem>
			<FormItem class="FromItemnpt" label="添加时间">
				<DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" v-model="timeshijian"></DatePicker>
			</FormItem>
			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>
		<Button class="marginbnt" @click="handleCreateModel" type="primary" v-show="button.add">新增</Button>

		<Button class="marginbnt" @click="handleEdit(1)" type="primary" v-show="button.delete">删除</Button>
		<!--<Button class="marginbnt" @click="handleEdit(2)" type="primary" v-show="button.enable">启用</Button>-->
		<!--<Button class="marginbnt" @click="handleEdit(3)" type="primary" v-show="button.disabled">禁用</Button>-->
		<!-- 
    <Button class="marginbnt"   v-show="button.edit" @click="handleGrade" type="primary">批量设置等级</Button> -->

		<Table
			height="500"
			border
			stripe
			:columns="canshu"
			:data="canshudata"
			@on-select="tableSelectionChange"
			@on-select-all="tableSelectionChange"
			@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
			<div style="height:30px;"></div>
		</div>
	
	
	
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as tools from '@/libs/tools';
import config from '@/config';
import cityView from '../mycomponents/cityView.vue';
import { getCustomer, getCustomerView, getCustomerAdd, getCustomerEdit, getCustomerDetail } from '@/api/backend';
import { getMemberLevelDrop } from '@/api/common';
import { getUserStatus, getWhether } from '@/api/dictionary';
	
		import * as Enum from '@/libs/enum'
		import {
		getorglist,
		getorhDrop,
		getOrgEdit,
		getorgcat,
            getSpareList,
            getorgcatadd,
            getOrgcatEdit,
			getpartsorglist
	} from '@/api/admin'

export default {
	components: {
		cityView
	},
	data() {
		return {
			handleData: {
				type: '',
				data: ''
			},
			
			partsdata:[],
			partsList:[],
            partscanshu:[
				{
					type: 'selection',
					width: 60,
					align: 'center',
					fixed: 'left',
					
				},
				{
					title: 'ID',
					key: 'id',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
				{
					title: '名称',
					key: 'cspname',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
				
			]
				
			,
			dataRules: {
				externalBusiness: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					}
				]
			},
			checkedIds: [],
            sparelist: [],	
            matchmodal:false,
			uploadHttp: '',
			access_token: '',
			defaultList: [],
			userInfoType: [],
			detailData: {},
			subData: {
                id: '',
				ogid:'',	  //机构id
				base_spare:'',//配件id
				base_spare_type:'',//1 钣金件  2机修件  3电器件  4玻璃件  5其他
                orgid_arr:'',
                base_arr:'',
			},
			hyse: false,
			types: '',
			search: {
					p: 1, // 当前页码
					t: 10, // 每页显示条数
					ogtype:Enum.ogtypeEnum.repair_business,
					kw:null,
					status:null,
					stime:null,
					etime:null,
					province:null,
					district:null,
					city:null,
					
			},
			timeshijian: [],
			button: {},
			userStatus: [],
			editTitle: '外修配件匹配新增',
			showEdit: false,
			showDetail: false,

			memberLevelDrop: [], // 会员等级
			totalCount: 10,
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			canshu: [
				{
					type: 'selection',
					width: 60,
					align: 'center',
					fixed: 'left'
				},
				{
					title: '外修商名称',
					key: 'name',
					align: 'center',
					minWidth: 140,
					fixed: 'left'
				},
				{
					title: '配件名称',
					key: 'cspname',
					align: 'center',
					minWidth: 140,
					fixed: 'left'
				},
				{
					title: '配件分类',
					key: 'base_spare_type',
					align: 'center',
					minWidth: 140,
					fixed: 'left'
				},
				{
					title: '添加时间',
					key: 'createtime',
					align: 'center',
					minWidth: 170
				},
				// {
				// 	title: '操作',
				// 	key: 'action',
				// 	width: 180,
				// 	align: 'center',
				// 	fixed: 'right',
				// 	render: (h, params) => {
				// 		return h('div', [
				// 			h(
				// 				'Button',
				// 				{
				// 					props: {
				// 						type: 'primary',
				// 						size: 'small',
				// 						disabled: !this.button.edit
				// 					},
				// 					style: {
				// 						marginRight: '5px'
				// 					},
				// 					on: {
				// 						click: () => {
				// 							this.handleUpdateModal(params.row.id);
				// 						}
				// 					}
				// 				},
				// 				'编辑'
				// 			),
				// 			// h(
				// 			// 	'Button',
				// 			// 	{
				// 			// 		props: {
				// 			// 			type: 'primary',
				// 			// 			size: 'small'
				// 			// 		},
				// 			// 		style: {
				// 			// 			marginRight: '5px'
				// 			// 		},
				// 			// 		on: {
				// 			// 			click: () => {
				// 			// 				this.handleDetailModal(params.row.id);
				// 			// 			}
				// 			// 		}
				// 			// 	},
				// 			// 	'查看'
				// 			// )
				// 		]);
				// 	}
				// }
			],
			canshudata: []
		};
	},
	methods: {
      
		getUserInfoType() {
			//
			getWhether().then(res => {
				this.userInfoType = res.data;
			});
		},
		tableSelectionChange(data2) {
			if (data2[0] != undefined) {
				this.checkedId = data2[0].id;
			}
			let ids = [];
			data2.map(function(item, key) {
				ids.push(item.id);
			});
			this.checkedIds = ids;
		},
		markmap() {
			//标注
			this.markmodel = true;
			if (this.subData.lng) {
				this.map.center.lng = this.subData.lng;
				this.map.center.lat = this.subData.lat;
			}
		},

		handleEdit(type) {
			if (this.checkedIds.length > 0) {
				let params = {};
				params.id = this.checkedIds;
				params.type = type;
				if (type == 1) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
						onOk: () => {
							this.sysEdit(params);
						},
						onCancel: () => {
							this.$Message.info('您取消了删除操作');
						}
					});
				} else {
					this.sysEdit(params);
				}
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},

	

		handleSubmit(type) {
			let params = {};
			params.id = this.checkedIds;
			params.type = type;
			params.data = this.handleData.levelData;
			this.sysEdit(params);
		},

		sysEdit(params) {
            getOrgcatEdit(params).then(res => {
				if (res.errCode == 0) {
					this.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					this.fzse = false;
					this.hyse = false;
					this.checkedIds = [];
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},

		
		
        handleCreateModel(){
			let query = { type:'add' };
			this.$router.push({
				name: 'extra_bussiness_parts',
				query: query
			});
		},
		// 日期
		checkDate(date) {
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		// 查询
		handleSearchAction() {
			this.search.p = 1;
			this.search.is_export = 0;
			this.handleSearch();
		},
	
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		// 列表
		handleSearch() {
			let params = this.search;

			getorgcat(params).then(res => {
				if (res.errCode == 0) {
					this.totalCount = parseInt(res.data.total);
					this.canshudata = res.data.list;
					this.button = res.data.button
				}
			});
		},
		reset() {
			this.$refs.subData.resetFields();
		},
		getDuration(date) {
			// how many days of this month
			let dt = new Date();
			var month = dt.getMonth();
			dt.setMonth(dt.getMonth() + 1);
			dt.setDate(0);
			return dt.getDate();
		},
		GetDate(is) {
			var now = new Date();
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			// var day = now.getDate();            　　//日
			if (is) {
				return year + '-' + month + '-' + is;
			} else {
				return year + '-' + month + '-1';
			}
		}
	},
	mounted() {
		switch (this.$route.query.type) {
			case 1:
				var mun = this.getDuration();

				this.search.stime = this.GetDate(false);
				this.search.etime = this.GetDate(false);
				this.timeshijian = [this.GetDate(false), this.GetDate(false)];
				break;
			case 2:
				break;
		}
		this.handleSearch();
		this.access_token = util.getToken();
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}

.map .search {
	margin-bottom: 65px;
}

.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
.FromItemnpt {
    width: 360px;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
}
</style>

<template>
	<Card class="col-right-card min-height">
		<p slot="title">报价时效</p>

		<div class="gray-nona"></div>

		<Table class="table_class" height="500" border stripe :columns="canshu" :data="canshudata"></Table>
		<div class="my-page"><Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" /></div>
	</Card>
</template>
<script>
export default {
	data() {
		return {
			id: '',
			type: '',
			pageTotal: 10,
			search: {
				// 筛选条件
				p: 1,
				t: 10,
				action_id: '62'
			},
			button: {},

			// 关于关联规格需要字段结束

			canshudata: [
				{
					id: '1',
					name: '钣金件',
					num: '4'
				},
				{
					id: '2',
					name: '机修件',
					num: '8'
				},
				{
					id: '3',
					name: '电器件',
					num: '12'
				},
				{
					id: '4',
					name: '玻璃件',
					num: '1'
				},
				{
					id: '5',
					name: '其他',
					num: '0'
				}
			],
			canshu: [
				{
					title: '品类名称',
					key: 'name',
					align: 'center',
					minWidth: 150
				},

				{
					title: '报价时效(小时)',
					align: 'center',
					minWidth: 150,
					render: (h, params) => {
						let vm = this;
						return h('div', [
							h('InputNumber', {
								props: {
									value: params.row.num > 0 ? parseInt(parseInt(params.row.num)) : 0
								},
								on: {
									'on-change': event => {
										vm.money = event;
										vm.canshudata[params.index].num = event;
									},
									'on-blur': () => {
										vm.editData(params.row.id, 1, params.row.num);
									}
								}
							})
						]);
					}
				},

				{
					title: '操作',
					align: 'center',
					minWidth: 150,

					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small'
									},
									on: {
										click: () => {
											this.editData(params.row.id, 2, '');
										}
									}
								},
								'保存'
							)
						]);
					}
				}
			]
		};
	},
	methods: {
		editData(id, type, data) {
			let params = { id: id, type: type, data: data };
			if (type == 2) {
				this.$Modal.confirm({
					title: '确认信息',
					content: '<p>你确定保存么?</p>',
					onOk: () => {
						// this.getVarietyEdit(params)
					},
					onCancel: () => {
						this.$Message.info('您取消了操作');
					}
				});
			} else {
				// this.getVarietyEdit(params)
			}
		},

		getVarietyEdit(params) {
			getVarietyEdit(params).then(res => {
				if (res.errCode == 0) {
					this.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},

		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},

		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		}
	},

	mounted() {
		this.handleSearch();
	},

	watch: {
		relevancyModal() {
			if (this.relevancyModal == false) {
				this.relevancyTitleData = '';
				this.relevancyTitle = [];
				this.relevancyNo_have = [];
				this.relevancyHave = [];
				this.id = '';
				this.type = '';
			}
		}
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.store_form_cls {
	width: 80%;
	display: inline-block;
	margin-right: 3%;
	white-space: nowrap;
}

.mar_rimport {
	margin-right: 2px !important;
	white-space: unset !important;

	input {
		width: 100%;
		margin-bottom: 1%;
	}

	button {
		float: right;
		position: relative;
	}
}

.mold_shux_cate {
	border: 1px solid #ddd;
	border-top: none;
	padding: 5px;
}

.cate_glcls {
	border: 1px solid #ddd;
	border-radius: 2px;

	.titcls {
		line-height: 44px;
		background: #f5f5f5;
		border-color: #ddd;
		border-bottom: 1px solid transparent;

		b {
			margin-left: 5px;
		}

		.floa_r {
			float: right;
		}
	}
}

.trancls {
	.ivu-transfer-list {
		width: 44%;
		height: 320px;
	}
}
</style>

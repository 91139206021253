<template>
    <div class="main" id="repair">

    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        banjin:[],
        jixiu:[],
        dianqi:[],
        boli:[],
        zongcheng:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getrepair()
    },
    getrepair(){
      var app = {};
      var chartDom = document.getElementById('repair');
      var myChart = echarts.init(chartDom, { width: 384, height: 120 });
      myChart.resize();
      var option;

      app.configParameters = {
        rotate: {
          min: -90,
          max: 200
        },

      };
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: false,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      };
      option = {
        grid: {
            top: 16,
          left: "1.5%",
          right: "3%",
          // width: "390",
          // bottom: "17%",
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['钣金件', '机修件', '电器件', '玻璃件','总成件'],
          width: "100%",
           padding: [0, 0, 0, 0],
          itemHeight: 6,
          itemWidth: 6,
          // 间距
          itemGap: 5,
          textStyle: {
            fontSize: 8,
          },

        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'left',
          top: 'center',

        },
        xAxis: [
          {
            type: 'category',
            //   隐藏刻度线
            axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                 fontSize:8,
                  fontFamily:'微软雅黑'
               
              },
            },
            // data: ['太平洋车险','平安车险','泰康车险','阳光保险','大地','人保车险','天安保险','中报'],
            data: this.data1.date,

          }
        ],
        yAxis: [
          {min:0,
            max:100,
            type: 'value',
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %' ,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:9
              },
            },
            //   隐藏刻度线
            axisTick: { show: false },
          }
        ],
        series: [
          {
            name: '钣金件',
            type: 'bar',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            // data: [20, 32,32, 11,31, 34, 39,20],
            data: this.data1.banjin,
            color:["#4A62D3"]
          },
          {
            name: '机修件',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#84CD7A"],
            // data: [22, 82, 91, 34, 90,20, 82, 91]
            data: this.data1.jixiu
          },
          {
            name: '电器件',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#FAC858"],
            // data: [50, 32, 21, 14, 19,22, 12, 91]
            data: this.data1.dianqi
          },
          {
            name: '玻璃件',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#EE6666"],
            // data: [8, 77, 11, 99, 40,20, 52, 51]
            data: this.data1.boli
          },
          {
            name: '总成件',
            type: 'bar',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#73C0DE"],
            // data: [98, 77, 11, 59, 40,20, 32, 71]
            data: this.data1.zongcheng
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getrepair()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

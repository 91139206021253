<template>
    <div class="main" id="companies">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Array
    },
  },
  data(){
    return{
      data1:[],
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getcompanies()
    },
    getcompanies(){

      var chartDom = document.getElementById('companies');
      var myChart = echarts.init(chartDom ,{});
          myChart.resize();
      var option;
      option = {
        title: {
          text: '合作保险公司数',
          left:"center",
          top:"10%",
          textStyle:{//标题内容的样式
            color:'#66FFFf',
            fontStyle:'normal',//主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight:"400",//可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily:" Microsoft YaHei",//主题文字字体，默认微软雅黑
            fontSize:12//主题文字字体大小，默认为18px

          },
        },
        
 global: false,
        tooltip: {
          trigger: 'item',
          position:'right'
        },
        visualMap: {
          show: false,
          min: 0,
          max: 0,
       
        },
       
        series: [
          {  
            name: '合作保险公司数',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            // data: [
            //   { value: 335, name: '南京市' },
            //   { value: 310, name: '常州市' },
            //   { value: 274, name: '杭州市' },
            //   { value: 235, name: '苏州市' },
            //   { value: 400, name: '上海市' }
            // ].sort(function (a, b) {
            //   return a.value - b.value;
            // }),
            data: this.data1,
            roseType: 'radius',
            label: {
              color:'#E56C68',
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },
                textStyle: {
                  fontSize: 8,
                  //显示文字的大小，
                  color: "#66FFFf",
                },
              },
            },
            labelLine: {
              lineStyle: {
                color:'#66FFFf',
              },
              smooth: 0.2,
              length:3,
              length2: 3,
               length3: 3

            },
    
            itemStyle: {
              color: '#E56C68',
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },

            // animationType: 'scale',
            // animationEasing: 'elasticOut',
            // animationDelay: function (idx) {
            //   return Math.random() * 200;
            // }
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getcompanies()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

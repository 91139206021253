import { get,post } from '@/libs/api.request'
import { getUserType } from '@/libs/util'


// 部门列表
export const getInsideOrg = (params) => {
  return post('common/inside-org',params)
}
/**
 * 地理区划
 */
export const getRegion = () => {
  const params = {
  }
  return get('dictionary/get-region', params)
}

//机构选择 - 弹窗形式
export const getSelectOrgList = (params) => {
  return post('common/select-org-list', params)
}

// 会员等级
export const getMemberLevelDrop = (params) => {
  return post('common/get-member-level-drop', params)
}


/**
 * 会员分组
 */
export const getMemberGroupDrop = (params) => {
  return get('common/member-group-drop', params)
}
/**
 * 获取省市区数据
 */
export const getCity = (pid) => {
  let params = {pid:pid}
  return get('common/select-area', params)
}
/**
 *  分类下拉
 */
export const getSelectCategory = (pid) => {
  let params = {pid:pid}
  return get('common/select-category', params)
}
/**
 * 上传图片
 * @returns {*}
 */
export const fileSave = (params) => {
  return post('common/file-save', params)
}
// 分类
export const getCategoryDrop = (params) => {
  return post('common/category-drop', params)
}
// 首字母
export const getSpell = (params) => {
  return post('common/get-spell', params)
}
export const getVarietyDrop = (params) => {
  return post('common/variety-drop', params)
}
export const getGoodsSpecsBrand = (params) => {
  return post('common/get-goods-specs-brand', params)
}
export const getCategoryAllDrop = (params) => {
  return post('common/category-all-drop', params)
}


export const getBrandDrop = (params) => {
  return post('common/brand-drop', params)
}



export const getPullSj = (params) => {
  return post('common/pull-sj', params)
}



export const getActivityGoodsPopup = (params) => {
  return post('common/activity-goods-popup', params)
}

export const getActivityCouponPopup = (params) => {
  return post('common/get-activity-coupon-popup', params)
}


export const getMerchantTopDepartmentDrop = (params) => {  // 门店组织结构  上级
  return post('common/get-merchant-top-department-drop', params)
}

export const getMerchantDepartmentDrop = (params) => {  // 门店用户--所属部门
  return post('common/get-merchant-department-drop', params)
}

export const getMerchantRoleDropDown = (params) => {  // 门店用户--所属角色
  return post('common/get-merchant-role-drop-down', params)
}

export const getCouponGoodsPopup = (params) => {
  return post('common/get-coupon-goods-popup', params)
}

/**
 * 修改密码
 * @param params
 * @returns {*}
 */
export const changepwd = (params) => {
  return post('common/changepwd', params)
}

// 会员列表
export const getMemberUserPopup = (params) => {
  return post('common/member-user-popup', params)
}

// 省市区汉化
export const getAreaName = (params) => {
  return post('common/get-area-name', params)
}


export const getOrgExpressPoupon = (params) => {
  return post('common/org-express-poupon', params)
}

export const getDitchfromTypeDrop = (params) => {
  return post('common/get-ditchfrom-type-drop', params)
}


export const getMerchantVipLevelDrop = (params) => {
  return post('common/get-merchant-vip-level-drop', params)
}

export const getMyProfile = (params) => {
  return post('common/my-profile', params)
}


export const getAddUserAddress = (params) => { // 新增/编辑用户收件地址
  return post('common/add-user-address', params)
}

export const getUserAddressEdit = (params) => { // 用户收件地址 -  删除/设置默认
  return post('common/user-address-edit', params)
}

export const getAddUserReceipt = (params) => { // 新增/编辑用户发票信息  
  return post('common/add-user-receipt', params)
}

export const getUserReceiptEdit = (params) => { // 用户发票 -  删除/设置默认
  return post('common/user-receipt-edit', params)
}


export const getOwnerPoup = (params) => {
  return post('common/owner-poup', params)
}

export const getStoreOwnerPoup = (params) => {
  return post('common/store-owner-poup', params)
}
export const getMytype = (params) => {
  return post('common/mytype', params)
}


<template>
	<Card class="col-right-card min-height">
		<p slot="title">回收配件管理</p>
		<Form :label-width="120">
			<FormItem class="FromItemnpt" label="配件名称"><Input clearable placeholder="请输入配件名称" v-model="search.sparename" /></FormItem>
			<FormItem class="FromItemnpt" label="报案号"><Input clearable placeholder="请输入报案号" v-model="search.reportno" /></FormItem>
      <FormItem class="FromItemnpt" label="报案尾号"><Input clearable placeholder="请输入报案尾号" v-model="search.reportno2" /></FormItem>
      <FormItem class="FromItemnpt" label="外修单号"><Input clearable placeholder="请输入外修单号" v-model="search.ordersn" /></FormItem>
      <FormItem class="FromItemnpt" label="回收状态">
        <Select v-model="search.recoverstatus" clearable>
          <Option v-for="(item, index) in recoverstatus" :value="index" :key="index">{{ item }}</Option>
        </Select>
      </FormItem>
      <FormItem class="FromItemnpt" label="发布时间">
				<DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" v-model="timeshijian"></DatePicker>
			</FormItem>
			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>

		<!-- 
    <Button class="marginbnt"   v-show="button.edit" @click="handleGrade" type="primary">批量设置等级</Button> -->

		<Table
			height="500"
			border
			stripe
			:columns="canshu"
			:data="canshudata"
			@on-select="tableSelectionChange"
			@on-select-all="tableSelectionChange"
			@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page :total="totalCount" :current="spage" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
			<div style="height:30px;"></div>
		</div>

		<Modal v-model="hyse" title="批量设置等级">
			<Form :label-width="120">
				<FormItem class="FromItemnpt" label="会员等级" required>
					<Select v-model="handleData.levelData">
						<Option v-for="item in memberLevelDrop" :value="item.id" :key="item.id">{{ item.name }}</Option>
					</Select>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="fzse = false">取消</Button>
				<Button type="primary" @click="handleSubmit('3')">保存</Button>
			</div>
		</Modal>
		<Modal v-model="ischoosebid" width="55%" title="选择中标外修商">
			<Table height="500" border stripe :columns="bidcanshu" :data="bidbuscanshudata"></Table>
			<div slot="footer"><Button @click="ischoosebid = false">取消</Button></div>
		</Modal>

		<Modal v-model="showcheckmodal" width="55%" title="核价">
			<Form :label-width="160" ref="checkdata" :model="checkdata" :rules="checkdataRules">
				<FormItem class="FromItemnpt" label="审核价格" prop="checkmoney" required>
					<Input type="number" v-model="checkdata.checkmoney" placeholder="请输入审核金额" style="width: 200px;" />
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="showcheckmodal = false">取消</Button>
				<Button type="primary" @click="submitCheckdata">确定</Button>
			</div>
		</Modal>
	</Card>
</template>
<script>
import { getToken } from '@/libs/util';
import config from '@/config';
import cityView from '../mycomponents/cityView.vue';

import { getMemberLevelDrop } from '@/api/common';
import { getUserStatus, getWhether, getRecoverstatus} from '@/api/dictionary';
import * as Enum from '@/libs/enum';
import { getorglist, getorhDrop, getOrgEdit, getorgdetail, getuserview, getorderitemlist, getitembusinesslist, bidorderitem, checkbid } from '@/api/admin';

export default {
	components: {
		cityView
	},
	data() {
		const validatecheckmoney = (rule, value, callback) => {
			if (this.checkdata.checkmoney < 0) {
				callback(new Error('请输入正整数'));
			} else {
				callback();
			}
		};

		return {
			handleData: {
				type: '',
				data: ''
			},
			showcheckmodal: false,
			checkdata: {},
			checkdataRules: {
				checkmoney: [{ validator: validatecheckmoney, trigger: 'blur' }]
			},
			dataRules: {
				// username: [{ required: true, message: '必填', trigger: 'blur' }],
				// password: [{ validator: validatePwd, trigger: 'blur' }],
				// confirmpwd: [{ validator: validatePassCheck, trigger: 'blur' }],
				// real_name: [{ required: true, message: '必填', trigger: 'blur' }],
				// mobile: [{ required: true, message: '必填', trigger: 'blur' }, { message: '格式有误', trigger: 'blur', pattern: /^1[34578]\d{9}$/ }],
				// sex: [{ required: true, message: '必选', trigger: 'change' }],
				// status: [{ required: true, message: '必选', trigger: 'change' }],
				// cityCheck: [{ validator: validateCityCheck, required: true, trigger: 'blur' }]
			},
			ischoosebid: false,
			checkedIds: [],
			bidbuscanshudata: [],
			uploadHttp: '',
			access_token: '',
			defaultList: [],
			userInfoType: [],
			detailData: {},
			subData: {
				uid: ''
			},
			hyse: false,
			types: '',
      spage:1,
      search: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				sparename: '',
				stime: '',
				etime: '',
				reportn:'',
				ordersn:'',
				type:Enum.itemstatus.recover,
        recoverstatus:'',
			},
      recoverstatus:[],
			timeshijian: [],
			button: {},
			userStatus: [],
			grade_tit: '',
			grade: false,
			gradeDetail: false,

			memberLevelDrop: [], // 会员等级
			totalCount: 10,
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			bidcanshu: [
				{ title: 'ID', key: 'id', align: 'center', minWidth: 100, fixed: 'left' },
				{ title: '外修商名称', key: 'ogname', align: 'center', minWidth: 130 },
				{ title: '状态', key: 'bstatus_str', align: 'center', minWidth: 100 },
				{ title: '报价金额', key: 'offermoney', align: 'center', minWidth: 80 },
				{
					title: '操作',
					key: 'action',
					width: 200,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						// console.log(params.row);
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'success',
										size: 'small',
										disabled: this.showbid(params.row.bstatus)
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.bidbusiness(params.row.id, params.row.order_item_id, params.row.bstatus);
										}
									}
								},
								'选择'
							)
						]);
					}
				}
			],
			canshu: [
				{ type: 'selection', width: 60, align: 'center', fixed: 'left' },
				{ title: 'ID', key: 'id', align: 'center', minWidth: 100, fixed: 'left' },
				{ title: '工单号', key: 'ordersn', align: 'center', minWidth: 150 },
				{ title: '配件名称', key: 'sparename', align: 'center', minWidth: 200 },
				{ title: '回收状态', key: 'status_recover_str', align: 'center', minWidth: 120 },
				{ title: '备注', key: 'remark', align: 'center', minWidth: 120 },
				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						// console.log(params.row);
						return h('div', [
							
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small'
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.handleDetailModal(params.row.id);
										}
									}
								},
								'详情'
							)
							
						]);
					}
				}
			],
			canshudata: []
		};
	},
	methods: {
		getUserInfoType() {
			//
			getWhether().then(res => {
				this.userInfoType = res.data;
			});
		},
    getrecoverstatus() {
      getRecoverstatus().then(res => {
        this.recoverstatus = res.data;
      });
    },
		showbid(bstatus) {
			if (bstatus == Enum.repair_bussiness_status.pending_bid) return false;
			else return true;
		},
		bidbusiness(id, itemid, bstatus) {
			var that = this;
			let pa = {
				order_item_id: itemid,
				bstatus: Enum.repair_bussiness_status.uncheck_quote,
				id: id
			};
			bidorderitem(pa).then(res => {
				console.log('bidorderitem', res);
				if (res.errCode == 0) {
					that.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					that.ischoosebid = false;
					that.handleSearch();
				}
			});
		},
		biditem(id, bstatus) {
			var that = this;
			let pa = {
				order_item_id: id,
				bstatus: bstatus
			};
			getitembusinesslist(pa).then(res => {
				console.log('getitembusinesslist', res);
				that.ischoosebid = true;
				that.bidbuscanshudata = res.data;
			});
		},
		tableSelectionChange(data2) {
			if (data2[0] != undefined) {
				this.checkedId = data2[0].id;
			}
			let ids = [];
			data2.map(function(item, key) {
				ids.push(item.id);
			});
			this.checkedIds = ids;
		},
		handleEdit(type) {
			if (this.checkedIds.length > 0) {
				let params = {};
				params.uid = this.checkedIds;
				params.type = type;
				if (type == 1) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
						onOk: () => {
							this.sysEdit(params);
						},
						onCancel: () => {
							this.$Message.info('您取消了删除操作');
						}
					});
				} else {
					this.sysEdit(params);
				}
			} else {
				this.$Modal.info({ title: '提示', content: '请至少选择一项' });
			}
		},

		handleGrade() {
			// 批量设置等级
			if (this.checkedIds.length > 0) {
				this.hyse = true;
			} else {
				this.$Modal.info({ title: '提示', content: '请至少选择一项' });
			}
		},

		handleSubmit(type) {
			let params = {};
			params.id = this.checkedIds;
			params.type = type;
			params.data = this.handleData.levelData;
			this.sysEdit(params);
		},

		sysEdit(params) {
			getCustomerEdit(params).then(res => {
				if (res.errCode == 0) {
					this.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					this.fzse = false;
					this.hyse = false;
					this.checkedIds = [];
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},

		selectFunc(cityObj) {
			this.subData.province = cityObj.provinceModel;
			this.subData.city = cityObj.cityModel;
			this.subData.district = cityObj.districtModel;
		},
		selectFunc1(cityObj) {
			this.search.province = cityObj.provinceModel;
			this.search.city = cityObj.cityModel;
			this.search.district = cityObj.districtModel;
		},

		getUserStatus() {
			getUserStatus().then(res => {
				this.userStatus = res.data;
			});
		},
		getMemberLevelDrop() {
			let params = { type: '1' };
			getMemberLevelDrop(params).then(res => {
				this.memberLevelDrop = res.data;
			});
		},

		// 日期
		checkDate(date) {
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		// 查询
		handleSearchAction() {
			this.search.p = 1;
			this.search.is_export = 0;
			this.handleSearch();
		},
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		// 列表
		handleSearch() {
			let params = this.search;
			getorderitemlist(params).then(res => {
				if (res.errCode == 0) {
					this.totalCount = parseInt(res.data.total);
					this.canshudata = res.data.list;
					this.button = res.data.button;
				}
			});
		},
		reset() {
			this.$refs.subData.resetFields();
		},
		// 新增
		handleCreateModel() {
			this.reset();
			this.subData = {};
			this.subData.real_name = '';
			this.subData.sex = '0';
			this.subData.id_card = '';
			this.subData.interesting = '';
			this.subData.wechat = '';
			this.subData.email = '';
			this.subData.birthday = '';
			this.subData.status = '';
			this.subData.vip_level = '';
			this.subData.address = '';
			this.subData.remark = '';
			this.subData.status = '1';
			this.$refs.cityView.viewdata.provinceModel = '';
			this.$refs.cityView.viewdata.cityModel = '';
			this.$refs.cityView.viewdata.districtModel = '';
			this.grade = true;
			this.grade_tit = '会员新增';
		},

		handleUpdateModal(data) {
			this.viewData(data);
			this.grade_tit = '会员修改';
			this.grade = true;
		},

		handleDetailModal(id) {
      let query = this.search;
      query.id = id;
      query.type = 'recover';
      query.bsstatus = '';

			this.$router.push({
				name: 'item_details',
				query: query
			});
		},
		checkitme(val) {
			this.showcheckmodal = true;

			this.checkdata = val;
			this.checkdata.checkmoney = val.bidmoney;
		},
		viewData(uid) {
			getCustomerView({ id: uid }).then(res => {
				this.subData = res.data;
				let that = this;
				console.log('res', res);
				// setTimeout(function () {
				//   that.$refs.cityView.viewdata.provinceModel = res.data.province
				//   that.$refs.cityView.viewdata.cityModel = res.data.city
				//   that.$refs.cityView.viewdata.districtModel = res.data.district
				// }, 150)
			});
		},
		submitCheckdata() {
			// 保存
			var that = this;
			this.$refs.checkdata.validate(valid => {
				console.log('valid', valid);
				if (valid) {
					let params = {
						bstatus: Enum.repair_bussiness_status.pending_pick,
						id: that.checkdata.id,
						checkmoney: that.checkdata.checkmoney
					};
					checkbid(params).then(res => {
						console.log('checkbid', res);
						if (res.errCode == 0) {
							that.showcheckmodal = false;
							that.handleSearch();
							that.$Modal.success({ title: '提示', content: '审核成功' });
						} else {
							that.$Modal.error({ title: '提示', content: res.errMsg });
						}
					});
				}
			});
		},
		submitForm() {
			// 保存
			this.$refs.subData.validate(valid => {
				if (valid) {
					let params = this.subData;
					getCustomerAdd(params).then(res => {
						if (res.errCode == 0) {
							this.grade = false;
							this.handleSearch();
							this.reset();
							this.$Modal.success({ title: '提示', content: '提交成功' });
						} else {
							this.$Modal.error({ title: '提示', content: res.errMsg });
						}
					});
				}
			});
		},
		handleError(data1, data2, data3) {
			// error file fileList
			this.$Modal.error({ title: '提示', content: '上传失败' });
		},
		handleFormatError(data1, data2, data3) {
			// file fileList
			this.$Modal.error({ title: '提示', content: '文件格式不正确' });
		},
		handleMaxSize(data1, data2) {
			// file fileList
			this.$Modal.error({ title: '提示', content: '文件太大' });
		},
		handleSuccess(data1, data2, data3) {
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.avatar = data1.data[0].pic; // 图片上传地址
				this.subData.url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: res.errMsg
				});
			}
		},
		getDuration(date) {
			// how many days of this month
			let dt = new Date();
			var month = dt.getMonth();
			dt.setMonth(dt.getMonth() + 1);
			dt.setDate(0);
			return dt.getDate();
		},
		GetDate(is) {
			var now = new Date();
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			// var day = now.getDate();            　　//日
			if (is) {
				return year + '-' + month + '-' + is;
			} else {
				return year + '-' + month + '-1';
			}
		}
	},
	mounted() {
		switch (this.$route.query.type) {
			case 1:
				var mun = this.getDuration();
				this.search.status = '1';
				this.search.stime = this.GetDate(false);
				this.search.etime = this.GetDate(false);
				this.timeshijian = [this.GetDate(false), this.GetDate(false)];
				break;
			case 2:
				break;
		}
    this.search.p = this.$route.query.p;
    this.spage = this.$route.query.p;
    this.search.t = this.$route.query.t;
    this.search.sparename = this.$route.query.sparename;
    this.search.stime = this.$route.query.stime;
    this.search.etime = this.$route.query.etime;
    this.search.reportn = this.$route.query.reportn;
    this.search.ordersn = this.$route.query.ordersn;
    this.search.recoverstatus = this.$route.query.recoverstatus;
		this.getUserStatus();
		this.getMemberLevelDrop();
		this.getUserInfoType();
    this.getrecoverstatus();
		this.handleSearch();
		this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro;
		this.access_token = getToken();
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}
</style>

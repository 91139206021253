<template>
  <Card class="col-right-card min-height">
    <p slot="title">短信通知</p>
    <Form :label-width="120">
      <FormItem class="FromItemnpt" label="关键词">
        <Input v-model="search.kw" clearable placeholder="请输入模板名称"/>
      </FormItem>
      <FormItem class="FromItemnpt" label="添加时间：" prop="username">
        <DatePicker type="daterange" :options="dateRange" @on-change="checkDate"
                    style="width: 190px;"></DatePicker>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
    </Form>
    <div class="gray-nona"></div>
    <Button type="primary" v-show="button.add" @click="handleCreateModel">添加</Button>
    <Modal v-model="modal1" :title="modatit">
      <Form :label-width="120" ref="subData" :model="subData" :rules='dataRules'>
        <FormItem class="FromItemnpt" style="width:90%;" label="模板名称" prop="name">
          <Input v-model="subData.name" :disabled="edit"/>
        </FormItem>
        <FormItem class="FromItemnpt" label="模板类型：" prop="t_type">
          <Select v-model="subData.t_type" :disabled="edit">
            <Option v-for="item in noticeTemplateType" :value="item.id" :key="item.id"> {{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="FromItemnpt" label="通知人类型：" prop="u_type">
          <Select v-model="subData.u_type" :disabled="edit">
            <Option v-for="item in notifierType" :value="item.id" :key="item.id"> {{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="FromItemnpt" style="width:90%;" label="是否启用">
          <i-Switch size="large" v-model="subData.sms_open">
            <span slot="open">已开</span>
            <span slot="close">已关</span>
          </i-Switch>
        </FormItem>
        <FormItem class="FromItemnpt" style="width:90%;" label="示例" prop="sms">
          <Input
            v-model="subData.sms"
            :rows="4"
            type="textarea"
            placeholder="请输入示例内容"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="modal1 = false">取消</Button>
        <Button type="primary" @click="submitForm">保存</Button>
      </div>
    </Modal>
    <Table height="500" class="table_class" border stripe :columns="columns" :data="columnsdata"></Table>
    <div class="my-page">
      <Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage"
            @on-page-size-change='handlePageSize'/>
    </div>
  </Card>
</template>

<script>
  import { getNoticeTemplate, getNoticeTemplateView, getNoticeTemplateAdd } from '@/api/backend'
  import { getNoticeTemplateType, getNotifierType } from '@/api/dictionary'

  export default {
    data () {
      return {
        dataRules: {
          name: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
          t_type: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          u_type: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          sms: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
        },
        modal1: false,
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          action_id:'21'
        },
        subData: {
          id: '',
          name: '',
          sms_open: true,
          t_type: '',
          u_type: '',
          sms: '',
        },
        button:{},
        edit: false,
        notifierType: [],
        noticeTemplateType: [],
        pageTotal: 10,
        columnsdata: [],
        modatit: '',
        columns: [
          { title: '模板名称', key: 'name', align: 'center' },
          { title: '通知人类型', key: 'u_type_str', align: 'center' },
          { title: '模板类型', key: 't_type_str', align: 'center' },
          { title: '创建时间', key: 'createtime', align: 'center' },
          {
            title: '短信模板',
            key: 'moname',
            width: 130,
            align: 'center',
            render: (h, params) => {
              let style = this.button.edit?'block':'none'
              let vm = this
              return h('div', [
                h('a',
                  {
                    style: {
                      color: '#ff9900',
                      display: 'block'
                    },
                    on:{
                      click: () => {
                        this.handleEditType(params.row.id,)
                      }

                    }
                  },
                  params.row.sms_open_str
                ),
                h('a',
                  {
                    style: {
                      display: vm.button.edit ? 'show' : style,
                    },
                    on: {
                      click: () => {
                        this.subData.id = params.row.id
                        this.handleUpdateModal()
                      }
                    }
                  },
                  '编辑模板'
                )
              ])
            }
          },],
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        },
      }
    },
    methods: {
      handleEditType(id) {
        console.log('修改短信状态')
      },
      reset () {
        this.$refs.subData.resetFields()
      },
      handleCreateModel () {
        this.reset()
        this.subData.id = ''
        this.subData.name = ''
        this.subData.sms_open = true
        this.subData.t_type = ''
        this.subData.u_type = ''
        this.subData.sms = ''
        this.modatit = '新增短信模板'
        this.edit = false
        this.modal1 = true
      },
      handleUpdateModal () {
        this.reset()
        this.edit = true
        this.modatit = '编辑短信模板'
        this.handleview()
        this.modal1 = true
      },
      handleview () {
        let params = { id: this.subData.id }
        getNoticeTemplateView(params).then(res => {
          this.subData = res.data
        })
      },

      getNoticeTemplateType () {
        getNoticeTemplateType().then(res => {
          this.noticeTemplateType = res.data
        })
      },
      getNotifierType () {
        getNotifierType().then(res => {
          this.notifierType = res.data
        })
      },

      checkDate (date) { // 操作时间
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      handleSearchAction () {
        this.search.p = 1
        this.handleSearch()
      },
      handlePage (value) {
        this.search.p = value
        this.handleSearch()
      },
      handlePageSize (value) {
        this.search.t = value
        this.handleSearch()
      },

      // 列表
      handleSearch () {
        this.search.action_id = 49
        let params = this.search
        getNoticeTemplate(params).then(res => {
          this.pageTotal = parseInt(res.data.total)
          this.columnsdata = res.data.list
          this.button = res.data.button
        })
      },

      submitForm () { // 保存
        this.$refs.subData.validate((valid) => {
          if (valid) {
            let params = this.subData
            getNoticeTemplateAdd(params).then(res => {
              if (res.errCode == 0) {
                this.modal1 = false
                this.handleSearch()
                this.$Modal.success({ title: '提示', content: res.errMsg })
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },
    },
    mounted () {
      this.handleSearch()
      this.getNoticeTemplateType()
      this.getNotifierType()
    }
  }

</script>

<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";

  .storebrand_form_doal {
    width: 70%;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
  }
</style>

import axios from 'axios'
import store from '@/store'
import {
	getToken,
	setToken,
	setTokenUser
} from '@/libs/util'
import router from '@/router'
// import { Spin } from 'iview'
const addErrorLog = errorInfo => {
	// const { statusText, status, request: { responseURL } } = errorInfo
	// let info = {
	//   type: 'ajax',
	//   code: status,
	//   mes: statusText,
	//   url: responseURL
	// }
	// if (!responseURL.includes('save_error_locontent-typegger')) store.dispatch('addErrorLog', info)
}

class HttpRequest {
	constructor(baseUrl = baseURL) {
		this.baseUrl = baseUrl
		this.queue = {}
	}
	getInsideConfig() {
		const token = getToken()
		if (token) {
			const config = {
				baseURL: this.baseUrl,
				headers: {
					'Authorization': 'Bearer ' + token
				}
			}
			return config
		} else {
			const config = {
				baseURL: this.baseUrl,
				headers: {
					//
				}
			}
			return config
		}

	}
	destroy(url) {
		delete this.queue[url]
		if (!Object.keys(this.queue).length) {
			// Spin.hide()
		}
	}
	interceptors(instance, url) {
		// 请求拦截
		instance.interceptors.request.use(config => {
			// 添加全局的loading...
			if (!Object.keys(this.queue).length) {
				// Spin.show() // 不建议开启，因为界面不友好
			}
			this.queue[url] = true
			return config
		}, error => {
			return Promise.reject(error)
		})
		// 响应拦截
		instance.interceptors.response.use(res => {
			this.destroy(url)
			const {
				data,
				status
			} = res
			return data
		}, error => {
			this.destroy(url)
			// let errorInfo = error.response
			// if (!errorInfo) {
			//   const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
			//   // errorInfo = {
			//   //   statusText,
			//   //   status,
			//   //   request: { responseURL: config.url }
			//   // }
			// }
			// addErrorLog(errorInfo)
			return Promise.reject(error)
		})
	}
	request(options) {

		// if (URL.HTTP_URL_FRESHTOKEN != options.url) {
		//   lastOptions = options
		// }
		if (options.method === 'post') {
			if (options.data) {
				let params = JSON.parse(JSON.stringify(options.data))
				params.agent = 4
				params.loginIdent = 1
				options.data = params

			} else {
				let params = {}
				params.agent = 4
				params.loginIdent = 1
				options.data = params
			}

		} else {
			if (options.params) {
				let params = JSON.parse(JSON.stringify(options.params))
				params.agent = 4
				params.loginIdent = 1
				options.params = params
			} else {
				let params = {}
				params.agent = 4
				params.loginIdent = 1
				options.params = params
			}

		}
		let lastOptions = {}
		lastOptions = options
		const instance = axios.create()
		options = Object.assign(this.getInsideConfig(), options)
		this.interceptors(instance, options.url)
		let that = this
		return new Promise(function(resolve, reject) {
			let _promise = instance(options)
			that.responseHandler(lastOptions, _promise, resolve, reject)
		})
	}
	responseHandler(options, promiser, resolve, reject) {
		promiser.then(res => {
			// console.log(res)
			if (res.errCode === 1022) { // token过期，请重新登陆
				setToken('')
				router.push({
					name: 'login'
				})
			} else if (res.errCode === 0) { // 请求成功，返回数据
				resolve(res)
			} else {
				// console.log('异常-请联系开发人员')
				// reject(res)
				resolve(res)
			}
		})
	}
}
export default HttpRequest

<template>
  <div class="myApp">
    <Row class="my-row">
      <div class="col-rightN">
        <Card class="col-right-card">
          <p slot="title">用户信息</p>
          <Form :label-width="120">
            <div class="FromItemdiv">
              <FormItem class="FromItemnpt" label="用户账号：">
                <Input clearable v-model="topForm.u_name"/>
              </FormItem>
              <FormItem class="FromItemnpt" label="真实姓名：">
                <Input clearable v-model="topForm.real_name"/>
              </FormItem>
              <FormItem class="FromItemnpt" label="状态：">
                <Select v-model="topForm.status" clearable>
                  <Option v-for="(item,key) in orgstatus" :value="key" :key="item"> {{ item }}</Option>
                </Select>
              </FormItem>
            <!--   <FormItem class="FromItemnpt" label="用户类型：">
                <Select v-model="topForm.type" clearable>
                  <Option v-for="(item,key) in allAddUserType" :value="item.id" :key="item.id"> {{ item.name }}</Option>
                </Select>
              </FormItem> -->


              <Button type="primary" @click="handleSearch">查询</Button>
            </div>
          </Form>
          <div class="gray-nona"></div>
          <div class="table-bnt">
            <Button type="primary" @click="handleAdd" v-show="button.add">新增</Button>
            <Button type="primary" @click="handleUpdate" v-show="button.edit">修改</Button>
            <Button type="primary" @click="handleOpen" v-show="button.enable">启用</Button>
            <Button type="primary" @click="handleClose" v-show="button.disabled">禁用</Button>
            <Button type="primary" @click="handleDel" v-show="button.delete">删除</Button>
          </div>

          <Table stripe border height="520" ref="selection" :columns="columns4" :data="data1"
                 @on-selection-change="tableSelectionChange"></Table>
          <div class="my-page">
            <Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage"
                  @on-page-size-change='handlePageSize'/>
          </div>
          <userform ref="userform" :initOption="userformInitOption" v-on:resultMethod="resultMethod"></userform>
        </Card>
      </div>
      w
    </Row>
  </div>
</template>

<script>
  import { getUser } from '@/libs/util'
  import userform from '@/view/sys/userform'
  import { getuserList, sysAdminEdit } from '@/api/backend'
  import {getUserStatus ,getAllAddUserType} from '@/api/dictionary'

  export default {
    components: {
      userform,
    },
    name: 'account',
    data () {
      return {
        roleData:[],
        allAddUserType:[],
        topForm: {
          u_name: '',
          real_name: '',
          inarea: '',
          daareaid: 0,
          p: 1,
          t: 10,
          action_id: '455'
        },
        select3: 'day',
        value: '',
        button: {},
        pageTotal: 10,
        pageNum: 1,
        pageSize: 10,
        modal1: false,
        chooseItem: false,
        user_name: '',
        searchValue: '',
        selectRow: [],
        orgstatus: [],
        checkedIds: [],
        columns4: [
          {
            type: 'selection',
            width: 60,
            align: 'center',fixed:'left'
          },
          {
            title: '用户账号',
            minWidth: 150,
            align: 'center',
            key: 'user_name',fixed:'left'
          },
          {
            title: '真实姓名',
            minWidth: 130,
            align: 'center',
            key: 'real_name'
          },
          {
            title: '所属区域',
            minWidth: 120,
            align: 'center',
            key: 'daarea_name'
          },
          {
            title: '角色',
            minWidth: 120,
            align: 'center',
            key: 'role_name'
          },
          {
            title: '性别',
            minWidth: 120,
            align: 'center',
            key: 'sex_str'
          },
          {
            title: '手机号',
            minWidth: 120,
            align: 'center',
            key: 'mobile'
          },
          /*{
            title: '用户类型',
            minWidth: 120,
            align: 'center',
            key: 'type_str'
          },*/
          {
            title: '状态',
            minWidth: 100,
            align: 'center',
            key: 'status_str'
          },
          {
            title: '添加时间',
            minWidth: 170,
            align: 'center',
            key: 'createtime'
          }
        ],
        data1: [],
        userformInitOption: {
          title: '',
          action: '',
          showModal: false,
          id: ''
        },
        editInitOption: {
          title: '',
          action: '',
          showModal: false,
          id: ''
        },
        chooseorgInitOption: {
          title: '',
          action: '',
          showModal: false,
          id: ''
        }
      }
    },
    methods: {
      
      getAllAddUserType () {
        getAllAddUserType().then(res => {
          this.allAddUserType = res.data
        })
      },


      handleAdd () {
        this.userformInitOption.title = '新增用户'
        this.userformInitOption.action = 'add'
        this.userformInitOption.showModal = true
        this.userformInitOption.id = ''
        this.userformInitOption.is_agent = this.roleData.is_agent
        this.$refs.userform.reset()
      },
      handleUpdate () {
        if (this.selectRow.length === 1) {
          this.userformInitOption.title = '修改用户信息'
          this.userformInitOption.action = 'update'
          this.userformInitOption.showModal = true
          this.userformInitOption.id = this.selectRow[0].id
          this.userformInitOption.is_agent = this.roleData.is_agent
          this.$refs.userform.reset()
          this.$refs.userform.getInfo()
        } else {
          this.$Modal.info({ title: '提示', content: '请选择一项' })
        }
      },
      tableSelectionChange (selection) {
        this.selectRow = selection
        let ids = []
        selection.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },
      handlePage (value) {
        this.pageNum = value
        this.getuserList()
      },
      handlePageSize (value) {
        this.pageSize = value
        this.getuserList()
      },
      handleDel () {
        this.vliadChooseItem()
        if (this.chooseItem) {
          this.$Modal.confirm({
            title: '提示',
            content: '<p>确定要删除该用户吗？</p>',
            onOk: () => {
              this.sysAdminEdit(1)
            },
            onCancel: () => {
            }
          })
        }
      },
      handleOpen () {
        this.vliadChooseItem()
        if (this.chooseItem) {
          this.$Modal.confirm({
            title: '提示',
            content: '<p>确定要启用该用户吗？</p>',
            onOk: () => {
              this.sysAdminEdit(2)
            },
            onCancel: () => {
            }
          })
        }
      },
      handleClose () {
        this.vliadChooseItem()
        if (this.chooseItem) {
          this.$Modal.confirm({
            title: '提示',
            content: '<p>确定要禁用该用户吗？</p>',
            onOk: () => {
              this.sysAdminEdit(3)
            },
            onCancel: () => {
            }
          })
        }
      },
      sysAdminEdit (type) {
        const uid = this.checkedIds
        sysAdminEdit(uid, type).then(res => {
          if (res.errCode === 0) {
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
            this.selectRow = []
            this.getuserList()
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      },
      vliadChooseItem () {
        this.chooseItem = false
        if (this.checkedIds.length == 0) {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        } else {
          this.chooseItem = true
        }
      },
      remove (index) {
        this.data1.splice(index, 1)
      },
      resultMethod () {
        this.userformInitOption.showModal = false
        this.pageNum = 1
        this.getuserList()
      },
      getuserList () {
        this.topForm.t = this.pageSize
        this.topForm.p = this.pageNum
        this.topForm.type = 1
        
        this.topForm.action_id = 43
        getuserList(this.topForm).then(res => {
          if (res.errCode === 0) {
            this.data1 = res.data.list
            this.pageTotal = res.data.total
            this.button = res.data.button
          }
        })
      },
      getOrgData (item) {
        this.chooseorgInitOption.showModal = false
        this.subForm.ogname = item.name
        this.subForm.ogid = item.id
        this.getDepartment(this.subForm.ogid)
      },

      getAddOrgStatusList () {
        getUserStatus().then(res => {
          this.orgstatus = res.data
        })
      },
      handleSearch () {
        this.pageNum = 1
        this.getuserList()
      }
    },
    mounted () {
      this.roleData = getUser()
      this.getuserList()
      this.getAddOrgStatusList()
      this.getAllAddUserType()
    }
  }
</script>
<style lang="less">
  @import '../style2.less';
</style>

<!--业务管理-物损配件管理-重选外修商-->
<template>
    <Modal v-model="showchoosemodal2" width="55%" title="重新选择外修商">
        <Form :label-width="100">
            <FormItem class="FromItemnpt" label="物损名称" required>
                <Input type="text" v-model="partData.partsTypeName" placeholder="请输入物损名称" />
            </FormItem>
            <FormItem class="FromItemnpt" label="物损地址" required>
                <Input type="text" v-model="partData.repair_address" placeholder="请输入物损地址" />
            </FormItem>
            <Table @on-selection-change="handleSelect" height="500" border stripe :columns="rechoosecloumn"
                :data="partData.business_data"></Table>
        </Form>
        <div slot="footer">
            <Button @click="showchoosemodal2 = false">取消</Button>
            <Button type="primary" @click="submititemdata">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { getitemDetail, addorderitem } from '@/api/admin';
import * as Enum from '@/libs/enum';

export default {
    props: ['showchoosemodal', 'itemId'],
    data() {
        return {
            ordertype: '',
            selectData: [],
            rechoosecloumn: [
                { title: "", align: "center", type: "selection", width: 50 },
                { title: 'ID', key: 'ogid', align: 'center', minWidth: 100, },
                { title: '外修商名称', key: 'ogname', align: 'center', minWidth: 130 },
                { title: '状态', key: 'bstatus_str', align: 'center', minWidth: 100 },
                { title: '报价金额', key: 'offermoney', align: 'center', minWidth: 80 },
                { title: '备注', key: 'remark', align: 'center', minWidth: 80 }
            ],
            partData: {
                ordertype: '',
                remark: '',
                offermoney: '',
                type: 1,
                givetypetime: null,
                givetypetimeid: null,
                largeTypeName: null,
                partsTypeId: null,
                base_spare_type: null,
                partsTypeName: null,
                business_data: [],
                partsimgurlstr: null,
                partsimglist: [],
                index: -1
            },
        }
    },
    methods: {
        handleSelect(data) {
            this.selectData = data;
        },
        submititemdata() {
            var that = this;
            if (!this.partData.partsTypeName) {
                this.$Modal.info({ title: '提示', content: '请配件名称缺失' });
                return false;
            }
            let org_data = [];
            this.selectData.forEach((item) => {
                org_data.push({
                    ogid: item.ogid
                });
            });
            if (org_data.length == 0) {
                this.$Modal.info({ title: '提示', content: '请至少选择一家外修商' });
                return false;
            }

            var ordertype;
            if (that.ordertype == Enum.ordertype.recover) {
                //如果只有回收配件
                ordertype = Enum.ordertype.all; //大订单改变为外修和回修都包含
            } else {
                ordertype = that.ordertype;
            }
            let parms = {
                type: Enum.itemstatus.wusun,
                order_id: that.partData.order_id,
                sparename: that.partData.partsTypeName,
                base_spare_id: that.partData.partsTypeId,
                base_spare_type: that.partData.base_spare_type,
                givetype: that.partData.givetypetimeid,
                ordertype: that.partData.ordertype,
                pic: that.partData.partsimgurlstr,
                remark: that.partData.remark,
                bstatus: that.order_status,
                offermoney: that.partData.offermoney,
                business_data: org_data
            };

            addorderitem(parms).then(res => {
                if (res.errCode == 0) {
                    this.$Notice.success({
                        title: '提示',
                        desc: '操作成功'
                    });
                    this.showchoosemodal2 = false
                } else {
                    this.$Notice.error({
                        title: '提示',
                        desc: res.errMsg
                    });
                }
            });
        },
        getitemDetail(id) {
            let params = {};
            params.id = id;
            var that = this
            getitemDetail(params).then(res => {
                that.partData.business_data = [];
                that.partData.partsTypeName = res.data.sparename
                console.log('that.partData.partsTypeName', that.partData.partsTypeName);
                that.partData.partsTypeId = res.data.base_spare_id
                that.partData.base_spare_type = res.data.base_spare_type
                that.partData.givetypetimeid = res.data.givetype
                that.partData.givetypetime = res.data.givetype + '天'
                that.partData.partsimgurlstr = res.data.per_pic
                that.partData.remark = res.data.remark
                that.partData.offermoney = res.data.offermoney
                that.partData.order_id = res.data.order_id
                that.partData.repair_address = res.data.repair_address
                that.order_status = 2
                that.ordertype = res.data.ordertype;

                for (var i = 0; i < res.data.order_business_list.length; i++) {
                    var temp = {
                        ogid: '',
                        ogname: '',
                        address: '',
                        distance: '',
                        offermoney: '',
                        remark: '',
                        bstatus_str: '',
                        ischecked: true,

                    }

                    temp.ogname = res.data.order_business_list[i].ogname
                    temp.offermoney = res.data.order_business_list[i].offermoney
                    temp.remark = res.data.order_business_list[i].remark
                    temp.bstatus_str = res.data.order_business_list[i].bstatus_str
                    temp.ogid = res.data.order_business_list[i].ogid
                    console.log(temp)
                    that.partData.business_data.push(temp)
                }
            });
        },
    },
    computed: {
        showchoosemodal2: {
            get: function () {
                if (this.showchoosemodal) {
                    this.getitemDetail(this.itemId)
                }
                return this.showchoosemodal
            },
            set: function (val) {
                this.$emit('son', val)
            }
        }
    },
}
</script>
<template>
  <Card class="col-right-card min-height">
  <div slot="title" class="cradflex">
    <p>文章分类</p>
  	<Button type="primary"  v-show="button.add" @click="submitForm">保存</Button>
  </div>  
    <Table class="table_class" height="500" border stripe :columns="canshu" :data="canshudata"></Table>
  </Card>
</template>
<script>
  import {
    getHotNewsType,
    getHotNewsTypeAdd,
  } from '@/api/backend'

  export default {
    data () {
      return {
        search:{},
        button:{},
        canshudata: [
          {
            data_name:'',
            data_value:'',
            sort:'',
          }
        ],
        canshu: [
          {
            title: '分类名称',
            key: 'data_name',
            align: 'center',
            render: (h, params) => {
              let vm = this

              return h('div', [
                h('Input', {
                  props: {
                    value: params.row.data_name ? params.row.data_name : '',
                    disabled: !vm.button.edit
                  },
                  on: {
                    'input' : (event) => {
                      params.row.data_name = event
                      vm.canshudata[params.index] = params.row
                    }
                  }
                })
              ])
            }
          },
          {
            title: 'ID',
            key: 'data_value',
            align: 'center',
            render: (h, params) => {
              let vm = this
              return h('div', [
                h('Input', {
                  props: {
                    value: params.row.data_value ? params.row.data_value : '',
                    disabled: true
                  },
                })
              ])
            }
          },
          {
            title: '序号',
            key: 'sort',
            align: 'center',
            minWidth: 80,
            render: (h, params) => {
              let vm = this
              return h('InputNumber',
                {
                  props: {
                    value: params.row.sort ? parseInt(params.row.sort) : 0,
                    disabled: !vm.button.edit
                  },
                  on: {
                    'on-change': (event) => {
                      params.row.sort = event
                      vm.canshudata[params.index] = params.row
                    },
                  },
                }
              )
            }
          },

          {
            title: '操作',
            align: 'center',
            width: '200',
            render: (h, params) => {
              return h('div', [
                h(
                  'Button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small',
                      disabled: !this.button.edit
                    },
                    style: {
                      // marginTop: "5px",
                    },
                    on: {
                      click: () => {
                        this.getRowAdd()
                      }
                    }
                  },
                  '新增'
                ),
                h(
                  'Button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small',
                      disabled: !this.button.delete
                    },
                    on: {
                      click: () => {
                        this.getRowDel(params.row)
                      }
                    }
                  },
                  '删除'
                )
              ])
            }
          }
        ],
      }
    }
    ,
    methods: {
      handleSearch () {
        this.search.action_id = '248'
        let params = this.search
        getHotNewsType(params).then(res => {
          if (res.errCode === 0) {
            if( res.data.list.length > 0) {
              this.canshudata = res.data.list
            } 
            this.button = res.data.button
          }
        })
      },

      getRowDel(e) {
        this.canshudata.splice(e._index, 1)
        if(this.canshudata.length == 0) {
          this.getRowAdd()
        }
      },


      getRowAdd() {
        this.canshudata.push({
          id:'',
          sort:0,
          data_value:'',
          data_name:'',
        })
      },

      submitForm() {
        let params = {}
        let data = []
        this.canshudata.forEach(item => {
            if(item.data_name) {
              data.push(item)
            }
          })
        if(data.length > 0) {
           params.data = JSON.stringify(data)
           let vm = this
           getHotNewsTypeAdd(params).then(res => {
            if(res.errCode == 0) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功'
              })
              setTimeout(function () {
                vm.handleSearch()
              }, 1000) 
            } else {
              this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
            }
           })

        } else {
          this.$Notice.error({
              title: '提示',
              desc: '请添加选项'
            })
        }
      },
    },

    mounted () {
      this.handleSearch()
    },
  }
</script>

<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
  	.cradflex{display: flex;justify-content: space-between;align-items: center;}
  .store_form_cls {
    width: 80%;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
  }

  .mar_rimport {
    margin-right: 2px !important;
    white-space: unset !important;

    Input {
      width: 100%;
      margin-bottom: 1%;
    }

    Button {
      float: right;
      position: relative;
    }
  }

  .mold_shux_cate {
    border: 1px solid #ddd;
    border-top: none;
    padding: 5px;
  }

  .cate_glcls {
    border: 1px solid #ddd;
    border-radius: 2px;

    .titcls {
      line-height: 44px;
      background: #f5f5f5;
      border-color: #ddd;
      border-bottom: 1px solid transparent;

      b {
        margin-left: 5px;
      }

      .floa_r {
        float: right;
      }
    }
  }

  .trancls {
    .ivu-transfer-list {
      width: 44%;
      height: 320px;
    }
  }
</style>

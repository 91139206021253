import { get, post } from '@/libs/api.request'
import { getUserType } from '@/libs/util'

// 客户列表
export const getCustomer = (params) => {
  return post('backend/customer', params)
}

export const getCustomerView = (params) => {
  return post('backend/customer-view', params)
}
export const getCustomerAdd = (params) => {
  return post('backend/customer-add', params)
}

export const getCustomerEdit = (params) => {
  return post('backend/customer-edit', params)
}

// 新增门店
export const getMerchantAdd = (params) => {
  return post('backend/merchant-add', params)
}
// 门店详情
export const getMerchantView = (params) => {
  return post('backend/merchant-view', params)
}

/**
 * 门店列表
 */
export const getMerchant = (params) => {
  return post('backend/merchant', params)
}


// 门店客户详情
export const getErpUserView = (params) => {
  return post('backend/erp-user-view', params)
}


// 设置门店删除/设置等级
export const getMerchantEdit = (params) => {
  return post('backend/merchant-edit', params)
}



// 门店客户列表
export const getErpUser = (params) => {
  return post('backend/erp-user', params)
}


// 修改状态会员
export const getErpUserEdit = (params) => {
  return post('backend/erp-user-edit', params)
}

/**
 * 会员等级列表
 */
export const getMemberLevel = (params) => {
  return post('backend/member-level', params)
}



// 门店用户新增
export const getErpUserAdd = (params) => {
  return post('backend/erp-user-add', params)
}
// 新增/编辑会员
export const getUserAccountAdd = (params) => {
  return post('backend/user-account-add', params)
}
/**
 * 会员等级新增/编辑
 */
export const getMemberLevelAdd = (params) => {
  return post('backend/member-level-add', params)
}

/**
 * 会员管理列表
 */
export const getOrgMember = (params) => {
  return post('backend/org-member', params)
}
/**
 * 会员管理详情
 */
export const getMemberView = (params) => {
  return post('backend/member-view', params)
}
/**
 * 会员管理新增/编辑
 */
export const getOrgMemberAdd = (params) => {
  return post('backend/org-member-add', params)
}
/**
 * 会员分组列表
 */
export const getMemberGroup = (params) => {
  return post('backend/member-group', params)
}

// 会员管理 -  删除
// type=>操作类型（1删除  2批量设置分组  3批量设置等级）
export const getMemberEdit = (params) => {
  return post('backend/member-edit', params)
}



/**
 * 会员管理新增/编辑
 */
export const getOrgMemberGroupAdd = (params) => {
  return post('backend/org-member-group-add', params)
}
/**
 * 代理商清单
 */
export const getOrgAgent = (params) => {
  return post('backend/org-agent', params)
}
/**
 * 代理商新增
 */
export const getOrgAgentAdd = (params) => {
  return post('backend/org-agent-add', params)
}
// 会员等级 -  删除
export const getMemberLevelEdit = (params) => {
  return post('backend/member-level-edit', params)
}


/**
 * 代理商设置等级/删除
 */
export const getOrgEdit = (params) => {
  return post('backend/org-edit', params)
}
/**
 * 代理商机构 - 详情
 */
export const getOrgAgentView = (params) => {
  return post('backend/org-agent-view', params)
}


/**
 * 代理设置 - 详情
 */
export const getOrgAgentSetView = (params) => {
  return post('backend/org-agent-set-view', params)
}


/**
 * 代理设置 - 新增编辑
 */
export const getOrgAgentSetAdd = (params) => {
  return post('backend/org-agent-set-add', params)
}
/**
 * 代理设置 - 修改状态（（（（现用的）
 */
export const getOrgAgentEdit = (params) => {
  return post('backend/org-agent-edit', params)
}


/**
 * 门店列表 
 */
export const getMerchantPopup = (params) => {
  return post('common/get-merchant-popup', params)
}


/**
 * 代理商列表 
 */
export const getAgentPoup = (params) => {
  return post('common/agent-poup', params)
  // return post('common/select-org-list', params)
}




/**
 * 采购机构列表  采购商清单
 */
export const getOrgPurchase = (params) => {
  return post('backend/org-purchase', params)
}




//后台管理 - 导航栏目树
export const getMenuList = (params) => {
  return post('common/get-menu-tree', params)
}

//后台管理 - 导航栏目列表
export const getSysMenu = (params) => {
  return post('backend/sys-menu', params)
}

// 后台管理    菜单新增、编辑
export const getSysMenuAdd = (params) => {
  return post('backend/sys-menu-add', params)
}


// 后台管理    菜单详情
export const getSysMenuView = (params) => {
  return post('backend/sys-menu-view', params)
}

// 后台管理    菜单管理 - 状态冻结/启用/删除
export const getSysMenuEdit = (params) => {
  return post('backend/sys-menu-edit', params)
}

export const getOrgRole = (params) => {
  return post('backend/org-role', params)

}


export const getOrgRoleView = (params) => {
  return post('backend/org-role-view', params)

}


// 后台管理 - 角色管理 -新增/编辑
export const getOrgRoleAdd = (params) => {
  return post('backend/org-role-add', params)
}

// 后台管理 - 角色管理 - 状态冻结 启用  删除
export const getOrgRoleEdit = (params) => {
  return post('backend/org-role-edit', params)
}

/**
 * 供货配件商分配 - 详情
 */
export const getBxBusinessView = (params) => {
  return post('backend/bx-business-view', params)
}

/**
 * 供货配件商分配 - 新增/编辑
 */
export const getBxBusinessAdd = (params) => {
  return post('backend/bx-business-add', params)
}

// 用户角色权限管理
export const getSysRoleMenuAdd = (params) => {
  return post('backend/sys-admin-role-menu-add',params)
}
// 用户树
export const getRoleTree = (params) => {
  return post('common/get-role-tree',params)
}
/**
 * 获取角色菜单权限管理树
 */
export const getRolesMenu = (params) => {
  return post('backend/sys-admin-role-menu', params)
}

/**
 * 获取角色菜单权限管理树保存
 */
export const getMenuEdit = (params) => {
  return post('backend/sys-admin-role-menu', params)
}

/**
 * 用户列表
 * @param params
 * @returns {*|never|AxiosPromise<any>|ClientRequest|ClientHttp2Stream}
 */
export const getuserList = (params) => {
  return post('backend/user-account', params)
}

export const sysAdminEdit = (uid, type) => {
  let  params = {uid:uid,type:type}

  return post('backend/user-account-edit', params)
}

// 用户角色权限管理
export const getRoleDropDown = (params) => {
  return post('common/get-role-drop-down',params)
}

/**
 * 用户新增/编辑
 * @param params
 * @returns {*|never|AxiosPromise<any>|ClientRequest|ClientHttp2Stream}
 */
export const addUserAccount = (params) => {
  return post('backend/user-account-add',params)
}



/**
 * 用户详情
 * @param uid uid
 * @returns {*}
 */
export const getUserView = (uid) => {
  let params = {uid:uid}
  return get('backend/user-account-view', params)
}

//分类设置列表  -  分类结构树
export const getCategoryTree = (params) => {
  return post('common/get-category-tree', params)
}

//分类设置列表  -  分类结构树
export const getOrgAgentStream = (params) => {
  return post('backend/org-agent-stream', params)
}


/**
 * 分类设置列表
 * @param kw=>关键词（支持名称） || stime=>添加开始时间 || etime=>添加结束时间
 * @param pid=>上级分类id || status=>状态
 *
 * @return id=>id || shortname=>分类名称 || fullname=>分类全称 || pic=>分类图片
 * @return ratio=>分类折扣比列 || goods_num=>分类下的商品数
 * @return sortcode=>排序 || first_spell=>首字母 || createtime=>添加时间
 * @return parent_name=>上级分类名称 || ishot_str=>是否热门 || status_str=>状态
 */

export const getCategory = (params) => {
  return post('backend/category', params)
}

/**
 * 商品品牌列表
 * @param kw=>关键词(支持名称) || stime=>添加开始时间 || etime=>添加结束时间 || status=>状态
 *
 * @return id=>id || name=>名称 || status=>状态(bool类型) || status_str=>状态中文
 * @return createtime=>添加时间 || pic=>品牌logo || sortcode=>排序
 */
export const getBrand = (params) => {
  return post('backend/brand', params)
}
/**
 * 品牌 -  删除/状态/排序操作
 * @param id=>id（字符串单个id） || type=>操作类型（1删除 2修改排序 3状态开启关闭）
 * @param  data=>状态值 type为3传递bool
 *
 * @return
 */
export const getBrandEdit = (params) => {
  return post('backend/brand-edit', params)
}





/**
 * 商品品牌 - 详情
 * @param id=>id
 *
 * @return id=>id || name=>名称 || status=>状态(bool类型) || status_str=>状态中文
 * @return ishot=>是否热门 || ishot_str=>是否热门中文 || first_spell=>首字母
 * @return sortcode=>排序 || pic_url=>品牌图片全路径 || pic=>品牌图片半路径
 * @return describe=>品牌描述
 */
export const getBrandView = (params) => {
  return post('backend/brand-view', params)
}

export const getBrandAdd = (params) => {
  return post('backend/brand-add', params)
}


export const getOrgAdd = (params) => {
  return post('backend/org-add', params)
}




export const getOrgView = (params) => {
  return post('backend/org-view', params)
}


/**
 * 分类设置 - 详情
 * @param id=>id
 *
 * @return id=>id || shortname=>分类名称 || fullname=>分类全称 || pic=>分类图片
 * @return ratio=>分类折扣比列
 * @return sortcode=>排序 || first_spell=>分类首字母 || createtime=>添加时间
 * @return pid_menu_name=>上级分类名称 || ishot_str=>是否热门 || status_str=>状态
 */
export const getCategoryView = (params) => {
  return post('backend/category-view', params)
}


/**
 * 分类设置 - 新增编辑
 * @param id=>id || parentId=>上级分类id || shortname=>分类名称 || fullname=>分类全称
 * @param vid=>品类id
 * @param first_spell=>首字母 || sortcode=>排序
 * @param ishot=>是否热门 || status=>状态(bool类型) || ratio=>分类折扣
 *
 * @see {"parentId":"0","shortname":"测试一级","sortcode":"300","ishot":"2","status":true}
 *
 * @return
 */
export const getCategoryAdd = (params) => {
  return post('backend/category-add', params)
}


/**
 * 分类设置 -  删除
 * @param id=>id） || type=>操作类型（1删除  2状态启用  3状态隐藏 4取消删除）
 *
 * @return
 */
export const getCategoryEdit = (params) => {
  return post('backend/category-edit', params)
}




/**
 * 商品品类列表
 * @param kw=>关键词(支持名称) || stime=>添加开始时间 || etime=>添加结束时间 || status=>状态
 *
 * @return id=>id || name=>名称 || status=>状态(bool类型) || sortcode=>排序
 * @return createtime=>添加时间
 */

export const getVariety = (params) => {
  return post('backend/variety', params)
}



/**
 * 商品品类 - 新增编辑
 * @param id=>id || name=>品类名称 || status=>状态(bool类型) || sortcode=>排序
 * @param specs=>关联规格(id组成一维数组形式) ["1","2","3"] ||
 * @param brand=>关联品牌(id组成一维数组形式) ["1","2","3"] ||
 * @param category=>关联分类(id组成一维数组形式) ["1","2","3"] ||
 * @param attribute=>商品属性[{"id"=>属性id,"name"=>属性名称,"type"=>输入类型,"sortcode"=>排序,"data"=>属性值}]
 *
 * @see {"name":"测试刀具","sortcode":"300","status":true,"specs":["1","2","3"],"attribute":[{"id":"0","name":"材质","type":"2","sortcode":"255","data":[{"id":"0","name":"金刚"},{"id":"0","name":"生铁"}]},{"id":"0","name":"直径","type":"1","sortcode":"255"}]}
 *
 * @return
 */
export const getVarietyAdd = (params) => {
  return post('backend/variety-add', params)
}
// 关联规格详情
export const getVarietySpecsPopup = (params) => {
  return post('backend/variety-specs-popup', params)
}

// 关联品牌
export const getVarietyBrandPopup = (params) => {
  return post('backend/variety-brand-popup', params)
}

// 关联品牌
export const getVarietyBrandSpecsCateEdit = (params) => {
  return post('backend/variety-brand-specs-cate-edit', params)
}
// 属性列表
export const getVarietyAttributePopup = (params) => {
  return post('backend/variety-attribute-popup', params)
}

// 删除属性
export const getVarietyEdit = (params) => {
  return post('backend/variety-edit', params)
}

// 添加属性
export const getVarietyAttributeAdd = (params) => {
  return post('backend/variety-attribute-add', params)
}

// 关联分类列表
export const getVarietyCategoryPopup = (params) => {
  return post('backend/variety-category-popup', params)
}

// 商品品类详情
export const getVarietyView = (params) => {
  return post('backend/variety-view', params)
}


//  商品列表
export const getGoods = (params) => {
  return post('backend/goods', params)
}


//  商品模板列表
export const getGoodstem = (params) => {
  return post('backend/goodstem', params)
}


//  商品详情
export const getGoodsView = (params) => {
  return post('backend/goods-view', params)
}
//  商品新增/编辑
export const getGoodsAdd = (params) => {
  return post('backend/goods-add', params)
}



//  商品详情
export const getGoodstemView = (params) => {
  return post('backend/goodstem-view', params)
}
//  商品新增/编辑
export const getGoodstemAdd = (params) => {
  return post('backend/goodstem-add', params)
}

//  商品列编辑
export const getGoodsEdit = (params) => {
  return post('backend/goods-edit', params)
}
//  商品规格
export const getVarietySpecs = (params) => {
  return post('backend/variety-specs', params)
}

//  规格编辑
export const getVarietySpecsEdit = (params) => {
  return post('backend/variety-specs-edit', params)
}
//  //  规格新增
export const getVarietySpecsAdd = (params) => {
  return post('backend/variety-specs-add', params)
}
// 规格详情
export const getVarietySpecsView = (params) => {
  return post('backend/variety-specs-view', params)
}

// 优惠券列表
export const getCoupon = (params) => {
  return post('backend/coupon', params)
}
// 优惠券设置
export const getCouponEdit = (params) => {
  return post('backend/coupon-edit', params)
}

// 优惠券新增
export const getCouponAdd = (params) => {
  return post('backend/coupon-add', params)
}

// 优惠券详情
export const getCouponView = (params) => {
  return post('backend/coupon-view', params)
}
// 部分商品列表   ---优惠券详情
export const getCouponViewGoods = (params) => {
  return post('backend/coupon-view-goods', params)
}

// 优惠券记录
export const getCouponUseLog = (params) => {
  return post('backend/coupon-use-log', params)
}

// 订单管理列表
export const getOrder = (params) => {
  return post('backend/order', params)
}

// 订单管理详情
export const getOrderView = (params) => {
  return post('backend/order-view', params)
}
// 活动列表
export const getActivityManage = (params) => {
  return post('backend/activity-manage', params)
}

// 促销活动 活动管理 -  删除/上线/下线/作废操作

export const getActivityManageEdit = (params) => {
  return post('backend/activity-manage-edit', params)
}

// 促销活动 活动详情
export const getActivityManageView = (params) => {
  return post('backend/activity-manage-view', params)
}

// 促销活动 新增编辑
export const getActivityManageAdd = (params) => {
  return post('backend/activity-manage-add', params)
}


// 支付方式详情.
export const getPaymentMethodView = (params) => {
  return post('backend/payment-method-view', params)
}
// 支付方式编辑
export const getPaymentMethodAdd = (params) => {
  return post('backend/payment-method-add', params)
}

// 支付设置详情.
export const getPaymentSettingsView = (params) => {
  return post('backend/payment-settings-view', params)
}

// 支付设置编辑
export const getPaymentSettingsAdd = (params) => {
  return post('backend/payment-settings-add', params)
}

// 交易设置 详情
export const getTransactionSettingsView = (params) => {
  return post('backend/transaction-settings-view', params)
}
// 交易设置 编辑
export const getTransactionSettingsAdd = (params) => {
  return post('backend/transaction-settings-add', params)
}

// 提现设置 - 详情
export const getCashOfferSettingsView = (params) => {
  return post('backend/cash-offer-settings-view', params)
}

// 提现设置 - 编辑
export const getCashOfferSettingsAdd = (params) => {
  return post('backend/cash-offer-settings-add', params)
}
// 短信通知
export const getNoticeTemplate = (params) => {
  return post('backend/notice-template', params)
}
// 短信通知 详情
export const getNoticeTemplateView = (params) => {
  return post('backend/notice-template-view', params)
}
// 短信通知 - 新增编辑
export const getNoticeTemplateAdd = (params) => {
  return post('backend/notice-template-add', params)
}

// 会员等级折扣设置 - 新增编辑
export const getMemberLevelRatioAdd = (params) => {
  return post('backend/member-level-ratio-add', params)
}

// 成长值管理 - 详情
export const getGrowthValueView = (params) => {
  return post('backend/growth-value-view', params)
}

// 成长值管理 - 新增编辑
export const getGrowthValueAdd = (params) => {
  return post('backend/growth-value-add', params)
}

// 物流列表
export const getOrgLogistics = (params) => {
  return post('backend/org-logistics', params)
}

export const getOrgExpress = (params) => {
  return post('backend/org-express', params)
}

export const getOrgExpressView = (params) => {
  return post('backend/org-express-view', params)
}

export const getOrgExpressAdd = (params) => {
  return post('backend/org-express-add', params)
}

export const getOrgExpressEdit = (params) => {
  return post('backend/org-express-edit', params)
}

// 交易记录列表
export const gettransaction = (params) => {
  return post('backend/transaction', params)
}









// 商品评价
export const getOrderGoodsComment = (params) => {
  return post('backend/order-goods-comment', params)
}
//  商品评价 -  删除/设置优质评论/批量回复评论
export const getOrderGoodsCommentEdit = (params) => {
  return post('backend/order-goods-comment-edit', params)
}

// 趋势分析
export const getTrendAnalysis = (params) => {
  return post('backend/trend-analysis', params)
}

// 订单统计
export const getOrderStatistics = (params) => {
  return post('backend/order-statistics', params)
}

// 机构清单
export const getOrgDetailedList = (params) => {
  return post('backend/org-detailed-list', params)
}


export const getOrgOpenEdit = (params) => {
  return post('backend/org-open-edit', params)
}
// 会员分组 - 新增编辑
export const getMemberGroupAdd = (params) => {
  return post('backend/member-group-add', params)
}

// 会员分组 - 详情
export const getMemberGroupView = (params) => {
  return post('backend/member-group-view', params)
}

// 余额提现
export const getCashWithdrawal = (params) => {
  return post('backend/cash-withdrawal', params)
}

// 余额提现 -  删除/状态操作
export const getCashWithdrawalEdit = (params) => {
  return post('backend/cash-withdrawal-edit', params)
}
// 余额变动
export const getBalanceChange = (params) => {
  return post('backend/balance-change', params)
}
// 积分流水
export const getIntegralFlow = (params) => {
  return post('backend/integral-flow', params)
}
//佣金提现流水
export const getCommissionWithdrawalFlow = (params) => {
  return post('backend/commission-withdrawal-flow', params)
}
// 佣金提现流水 -  删除/状态操作
export const getCommissionWithdrawalFlowEdit = (params) => {
  return post('backend/commission-withdrawal-flow-edit', params)
}
// 佣金流水
export const getCommissionFlow = (params) => {
  return post('backend/commission-flow', params)
}

// 会员分析
export const getMemberAnalysis = (params) => {
  return post('backend/member-analysis', params)
}

// 会员分析 - 会员消费TOP排名 table
export const getMemberAnalysisTopRank = (params) => {
  return post('backend/member-analysis-top-rank', params)
}

// 地区分析 - echarts地图数据
export const getEchartsMapAnalysis = (params) => {
  return post('backend/echarts-map-analysis', params)
}

// 地区分析 - echarts地图数据
export const getRegionalAnalysis = (params) => {
  return post('backend/regional-analysis', params)
}

// 统计商品销售
export const getGoodsSales = (params) => {
  return post('backend/goods-sales', params)
}

// 售后
export const getAfterOrder = (params) => {
  return post('backend/after-order', params)
}
// 首页
export const getHomeStatistics = (params) => {
  return post('backend/home-statistics', params)
}



export const getEditOrderStatus = (params) => {
  return post('backend/edit-order-status', params)
}

export const getEditOrderStoreStatus = (params) => {
  return post('backend/edit-order-store-status', params)
}





export const getFahuoOrderView = (params) => {
  return post('backend/fahuo-order-view', params)
}

export const getOrderStoreList = (params) => { // 订货管理列表
  return post('backend/order-store-list', params)
}

export const getOrderStoreView = (params) => { // 订货详情
  return post('backend/order-store-view', params)
}











// 广告管理相关开始
export const getAd = (params) => {
  return post('backend/ad', params)
}
export const getAdAdd = (params) => {
  return post('backend/ad-add', params)
}
export const getAdEdit = (params) => {
  return post('backend/ad-edit', params)
}

export const getAdView = (params) => {
  return post('backend/ad-view', params)
}

// 广告管理相关结束




export const getMerchantDepartment = (params) => { // 门店组织结构列表
  return post('backend/merchant-department', params)
}

export const getMerchantDepartmentView = (params) => { // 门店组织结构详情
  return post('backend/merchant-department-view', params)
}

export const getMerchantDepartmentEdit = (params) => { // 门店组织结构 -  删除
  return post('backend/merchant-department-edit', params)
}

export const getMerchantDepartmentAdd = (params) => { // 门店组织结构 - 新增编辑
  return post('backend/merchant-department-add', params)
}


export const getMerchantAccount = (params) => { // 门店用户账号列表 
  return post('backend/merchant-account', params)
}

export const getMerchantAccountView = (params) => { // 门店用户账号详情
  return post('backend/merchant-account-view', params)
}

export const getMerchantAccountAdd = (params) => { // 门店用户账号 - 新增编辑
  return post('backend/merchant-account-add', params)
}

export const getMerchantAccountEdit = (params) => { // 门店用户账号 -  删除
  return post('backend/merchant-account-edit', params)
}


export const getMerchantRole = (params) => { //门店角色管理
  return post('backend/merchant-role', params)
}


export const getMerchantRoleView = (params) => { //门店角色管理 -- 详情
  return post('backend/merchant-role-view', params)
}


export const getMerchantRoleAdd = (params) => { //门店角色管理--新增编辑
  return post('backend/merchant-role-add', params)
}


export const getMerchantRoleEdit = (params) => { //门店角色管理列表操作
  return post('backend/merchant-role-edit', params)
}

/** 
 * 门店权限设置  门店角色/门店用户
 * @param type=>1角色权限 2用户权限 || merchant_id=>门店id
 * 
 * @return 
 */
export const getMerchantRoleUserNav = (params) => { 
  return post('backend/merchant-role-user-nav', params)
}

/** 
 * 门店权限设置  菜单数据
 * @param type=>1角色权限 2用户权限 || merchant_id=>门店id
 * @param id=>角色id/用户id
 * 
 * @return 
 */

export const getMerchantRoleMenu = (params) => { 
  return post('backend/merchant-role-menu', params)
}





export const getMerchantRoleUser = (params) => { // 门店角色管理-所含人员
  return post('backend/merchant-role-user', params)
}

/** 
* 门店权限设置  门店角色/门店用户 - 编辑权限 - 保存
* @param type=>1角色权限 2用户权限 || merchant_id=>门店id
* @param save_menu=>保存菜单权限数据 || id=>角色id/用户id
* @return 
*/

export const getMerchantRoleMenuAdd = (params) => { 
  return post('backend/merchant-role-menu-add', params)
}


export const getThUserBack = (params) => {  //用户反馈
  return post('backend/th-user-back', params)
}

export const getThUserBackView = (params) => {  //用户反馈
  return post('backend/th-user-back-view', params)
}
export const getSubmitUserBackResult = (params) => {  //用户反馈
  return post('backend/submit-user-back-result', params)
}

export const getAccountWithdrawLog = (params) => {  
  return post('backend/account-withdraw-log', params)
}
export const getCheckApply = (params) => {  
  return post('backend/check-apply', params)
}

export const getScoreDetail = (params) => {  
  return post('backend/score-detail', params)
}
export const getOrderZz = (params) => {
    return post('backend/order-zz', params)
}

export const editOrderStore = (params) => {  
  return post('backend/edit-order-store-status', params)
}


export const getFahuoOrderStoreView = (params) => {  
  return post('backend/fahuo-order-store-view', params)
}


export const getMerchantVipLevel = (params) => {  
  return post('backend/merchant-vip-level', params)
}

export const getMerchantVipLevelView = (params) => {  
  return post('backend/merchant-vip-level-view', params)
}

export const getMerchantVipLevelAdd = (params) => {  
  return post('backend/merchant-vip-level-add', params)
}


export const getMerchantVipLevelEdit = (params) => {  
  return post('backend/merchant-vip-level-edit', params)
}

export const getOwnerRegAgreementView = () => {  
  return post('backend/owner-reg-agreement-view')
}

export const getOwnerRegAgreementAdd = (params) => {  
  return post('backend/owner-reg-agreement-add',params)
}


export const getStoreRegAgreementView = () => {  
  return post('backend/store-reg-agreement-view')
}

export const getStoreRegAgreementAdd = (params) => {  
  return post('backend/store-reg-agreement-add',params)
}


export const getLoginLog = (params) => {  
  return post('backend/login-log',params)
}


export const getSmsCode = (params) => {  
  return post('backend/sms-code',params)
}

export const getSmsQueue = (params) => {   // 短信发送记录
  return post('backend/sms-queue',params)
}


export const getAccountBank = (params) => {   // 用户银行卡
  return post('backend/account-bank',params)
}


export const getAccountBankView = (params) => {   // 用户银行卡详情
  return post('backend/account-bank-view',params)
}

export const getBindingBank = (params) => {   // 新增用户银行卡
  return post('backend/binding-bank',params)
}

export const getAccountBankEdit = (params) => {   // 操作用户银行卡
  return post('backend/account-bank-edit',params)
}

export const getUserAddress = (params) => {   // 用户地址
  return post('backend/user-address',params)
}

export const getUserAddressView = (params) => {   // 地址详情
  return post('backend/user-address-view',params)
}

export const getUserReceipt = (params) => {   // 用户发票
  return post('backend/user-receipt',params)
}
export const getUserReceiptView = (params) => {   // 发票详情
  return post('backend/user-receipt-view',params)
}

export const getInviteRecord = (params) => {   // 邀请记录
  return post('backend/invite-record',params)
}

export const getUserCar = (params) => {   // 用户爱车
  return post('backend/user-car',params)
}

export const getUserCarView = (params) => {   // 爱车详情
  return post('backend/user-car-view',params)
}

export const getUserCarEdit = (params) => {   // 用户爱车爱车 -  删除/设置默认
  return post('backend/user-car-edit',params)
}


export const getAddUserCar = (params) => {   // 添加用户爱车爱车
  return post('backend/add-user-car',params)
}


export const getHotNewsAdd = (params) => {   // 热点头条资讯 - 新增编辑
  return post('backend/hot-news-add',params)
}

export const getHotNewsView = (params) => {   // 热点头条资讯 -详情
  return post('backend/hot-news-view',params)
}

export const getHotNews = (params) => {   // 热点头条资讯 -列表
  return post('backend/hot-news',params)
}


export const getHotNewsEdit = (params) => {
  return post('backend/hot-news-edit', params)
}

export const getHotNewsType = (params) => {
  return post('backend/hot-news-type', params)
}

export const getHotNewsTypeAdd = (params) => {
  return post('backend/hot-news-type-add', params)
}

export const getMaintenancePackage = (params) => {
  return post('backend/maintenance-package', params)
}


export const getMaintenancePackageView = (params) => {
  return post('backend/maintenance-package-view', params)
}

export const getMaintenancePackageAdd = (params) => {
  return post('backend/maintenance-package-add', params)
}

export const getMaintenancePackageEdit = (params) => {
  return post('backend/maintenance-package-edit', params)
}

export const getGoodsEvaluate = (params) => {
  return post('backend/goods-evaluate', params)
}


export const getOrderGoodsEvaluateEdit = (params) => {
  return post('backend/order-goods-evaluate-edit', params)
}


export const getStoreEvaluate = (params) => {
  return post('backend/store-evaluate', params)
}


export const getGoodsSearch = (params) => {
  return post('backend/goods-search', params)
}


export const getGoodsSearchAdd = (params) => {
  return post('backend/goods-search-add', params)
}


export const getGoodsSearchEdit = (params) => {
  return post('backend/goods-search-edit', params)
}


export const getModelAccessorieRelation = (params) => {
  return post('backend/model-accessorie-relation', params)
}

export const getModelAccessorieRelationView = (params) => {
  return post('backend/model-accessorie-relation-view', params)
}

export const getModelAccessorieRelationAdd = (params) => {
  return post('backend/model-accessorie-relation-add', params)
}


export const getModelAccessorieRelationEdit = (params) => {
  return post('backend/model-accessorie-relation-edit', params)
}
export const getStoreInviteRecord = (params) => {
  return post('backend/store-invite-record', params)
}
export const getOwnerInviteRecord = (params) => {
  return post('backend/owner-invite-record', params)
}


export const getSetCompanyInfo = (params) => {
  return post('test/set-company-info', params)
}



export const getSendVerificationCode = (params) => {
  return post('test/send-verification-code', params)
}

export const getBindPhone = (params) => {
  return post('test/bind-phone', params)
}

export const getSignContractUrl = (params) => {
  return post('test/get-sign-contract-url', params)
}


export const getMyScore = (params) => {
  return post('backend/my-score', params)
}


export const getPostMoney = (params) => {
  return post('backend/post-money', params)
}



export const getNewsGrab = (params) => {
  return post('backend/news-grab', params)
}



//维保订单竞价
export const getOrderMaintaincompare = (params) => {
  return post('backend/order-maintain-compare', params)
}
export const getOrderMaintainView = (params) => {
  return post('backend/order-maintain-compare-view', params)
}

// 维保订单
export const getOrderMaintain = (params) => {
  return post('backend/order-maintain', params)
}
export const getOrderMaintaiView = (params) => {
  return post('backend/order-maintain-view', params)
}

//维保单位
export const getOrderMaintaincompany = (params) => {
  return post('backend/order-maintain-company', params)
}
export const getOrderMaintaincomView = (params) => {
  return post('backend/order-maintain-company-view', params)
}
//维保推送
export const getOrderMaintainapi = (params) => {
  return post('backend/order-maintain-api', params)
}
export const getOrderMaintainapiView = (params) => { 
  return post('backend/order-maintain-api-view', params)
}

export const getOrderMaintainapiAdd = (params) => { 
  return post('backend/order-maintain-api-add', params)
}

export const getOrderMaintainapedit = (params) => {
  return post('backend/order-maintain-api-edit', params)
}


export const getCustomerDetail = (params) => {
  return post('backend/customer-detail', params)
}

export const getWarninglist = (params) => {
  return post('backend/warning-agent', params)
}


export const getStocklist = (params) => {
  return post('backend/agent-stock-list', params)
}


export const getMerchantlist = (params) => {
  return post('backend/warning-merchant', params)
}
export const getGoodslist = (params) => {
  return post('backend/goods', params)
}

export const getGoodsedit = (params) => {
  return post('backend/warning-edit', params)
}

export const getBalanceBdong = (params) => {
  return post('backend/balance-change', params)
}

export const getWarChange = (params) => {
  return post('backend/warning-change', params)
}

export const getShoppingOwners = (params) => {
  return post('backend/shopping-owners', params)
}

export const getShoppingStore = (params) => {
  return post('backend/shopping-store', params)
}

export const getCardlegal = (params) => {
  return post('test/apply-bind-bank-cardlegal', params)
}
export const getCardAgent = (params) => {
  return post('test/apply-bind-bank-cardlegal-agent', params)
}

export const getaddMer = (params) => {
  return post('backend/order-store-add-mer', params)
}
export const getaddMeragent = (params) => {
  return post('backend/order-store-add-agent', params)
}





<template>
	<div class="myApp">
		<Row class="my-row">
			<div class="col-rightN">
				<Card class="col-right-card">
					<p slot="title">评价管理</p>
					 <Form :label-width="120">
      <FormItem class="FromItemnpt" label="工单号"
        ><Input clearable placeholder="工单号" v-model="search.ordersn"
      /></FormItem>
    
     
      <FormItem class="FromItemnpt" label="评价时间">
        <DatePicker
          style="width:180px;"
          type="daterange"
          :options="dateRange"
          @on-change="checkDate"
          v-model="timeshijian"
        ></DatePicker>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
    </Form>



					<div class="gray-nona"></div>
					<Button class="marginbnt"   v-show="button.add" @click="handleCreateModel" type="primary">新增</Button>
					<Button class="marginbnt"   v-show="button.delete" @click="handleEdit(1)" type="primary">删除</Button>
					<Table
						stripe
						height="500"
						style="margin-bottom: 7px;"
						border
						:columns="columns"
						:data="data1"
						@on-select="tableSelectionChange"
						@on-select-all="tableSelectionChange"
						@on-select-cancel="tableSelectionChange"
					></Table>

					<div class="my-page"><Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" /></div>




					<Modal v-model="showEdit" width="55%" :title="editTitle">
						<Form
								:label-width="120"
								ref="subData"
								:model="subData"
								:rules="dataRules">

							<FormItem class="FromItemnpt" label="工单号" prop="ordersn">
								<Input v-model="subData.ordersn" @blur.native.capture="onChange"/></FormItem>


							<FormItem class="FromItemnpt" label="评价对象" prop="order_business_id">
								<Select v-model="subData.order_business_id">
									<Option
											v-for="(item, index) in peijianarr"
											:value="item.bid_order_business_id"
											:key="item.bid_order_business_id"
									>{{ item.name }}</Option
									>
								</Select>
							</FormItem>

							<FormItem class="FromItemnpt" label="评分" prop="score">
								<Select v-model="subData.score">
									<Option
											v-for="(item, index) in scorearr"
											:value="index"
											:key="index"
									>{{ item }}</Option
									>
								</Select>
							</FormItem>

							<FormItem label="评价内容" prop="content"
							><Input type="textarea" v-model="subData.content"
							/></FormItem>

						</Form>
						<div slot="footer">
							<Button @click="showEdit = false">取消</Button>
							<Button type="primary" @click="submitForm">保存</Button>
						</div>
					</Modal>





				</Card>
			</div>
		</Row>
	</div>
</template>

<script>
import config from '@/config';
import { getToken } from '@/libs/util';
// import { getOrgView, getOrgEdit, getInsideAdd, getInsideList } from '@/api/user';
// import { getOryStatus } from '@/api/common';
import { getevaluatelist,getStar,getOrderDropDown,addOrderEvaluate,getEvaluateEdit } from "@/api/admin";
export default {
	name: 'account',
	data() {
		return {
            dataRules: {
                ordersn: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                order_business_id: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                score: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],

            },

			button: {},
			checkedIds: [],
			titleData: '', // 标题
			newModel: false,
			orgstatus: [], // 机构状态
			search: {// 筛选条件
				p: 1,
				t: 10,
                ordersn: '',
                stime: "",
                etime: "",
			},
			subData: {
                order_business_id: '',
                order_id: '',
                score: '',
                content: '',
                ordersn: '',
			},
			pageTotal: 10,
			modal1: false,
			columns: [
				// 表头
                { type: 'selection', width: 60, align: 'center' ,fixed:'left'},
                { title: 'ID', key: 'id', align: 'center' ,minWidth:100,fixed:'left'},
				{ title: '外修商', align: 'center', key: 'ogname', minWidth: 120 },
				{ title: '工单号', align: 'center', key: 'ordersn', minWidth: 140 },
				{ title: '星级', key: 'score', align: 'center', minWidth: 100 },
				{ title: '评价人', align: 'center', key: 'createname', minWidth: 140 },
				{ title: '评价时间', align: 'center', key: 'createtime', minWidth: 140 },
				{ title: '备注', align: 'center', key: 'content', minWidth: 150 }
			],
			data1: [],
            dateRange: {
                shortcuts: [
                    {
                        text: '今日',
                        value () {
                            const end = new Date()
                            const start = new Date()
                            return [start, end]
                        }
                    },
                    {
                        text: '本周',
                        value () {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            return [start, end]
                        }
                    },
                    {
                        text: '本月',
                        value () {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            return [start, end]
                        }
                    },
                    {
                        text: '本季度',
                        value () {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                            return [start, end]
                        }
                    }
                ]
            },
            timeshijian:[],
            editTitle: '',
            showEdit: false,
            peijianarr: [],
            scorearr: [],
		};
	},
	methods: {
        handleEdit (type) {
            alert(this.checkedIds);
            if (this.checkedIds.length > 0) {
                let params = {}
                params.id = this.checkedIds
                params.type = type
                if (type == 1) {
                    this.$Modal.confirm({
                        title: '确认信息',
                        content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
                        onOk: () => {

                            this.sysEdit(params)
                        },
                        onCancel: () => {
                            this.$Message.info('您取消了删除操作')
                        }
                    })
                } else {

                    this.sysEdit(params)
                }

            } else {
                this.$Modal.info({ title: '提示', content: '请至少选择一项' })
            }
        },
        sysEdit (params) {
            getEvaluateEdit(params).then(res => {
                if (res.errCode == 0) {
                    this.$Notice.success({
                        title: '提示',
                        desc: '操作成功'
                    })
                    this.fzse = false
                    this.hyse = false
                    this.checkedIds = []
                    this.handleSearch()
                } else {
                    this.$Notice.error({
                        title: '提示',
                        desc: res.errMsg
                    })
                }
            })
        },
        onChange() {
            let params = {};
            params.ordersn = this.subData.ordersn;
            getOrderDropDown(params).then((res) => {
                // console.log(res.data[0].order_id);
                this.peijianarr = res.data;
                if (res.data[0].order_id) {
                    this.subData.order_id = res.data[0].order_id;
                }
            });
        },
        getStar() {
            getStar().then((res) => {
                this.scorearr = res.data;
            });
        },
        submitForm () {   // 保存
            this.$refs.subData.validate((valid) => {
                if (valid) {
                    let params = this.subData;
                    // console.log(params);
                    addOrderEvaluate(params).then(res => {
                        if (res.errCode == 0) {
                            this.showEdit = false
                            this.handleSearch()
                            this.reset()
                            this.$Modal.success({ title: '提示', content: '提交成功' })
                        } else {
                            this.$Modal.error({ title: '提示', content: res.errMsg })
                        }
                    })
                }
            })
        },
		checkDate(date) {
			// 操作时间
			this.search.stime = date[0];
			this.search.etime = date[1];
		},


        handleCreateModel () {
            //this.reset()
            this.subData = {}
            this.subData.order_business_id = '';
            this.subData.ident = 1;
            this.peijianarr= [];
            this.showEdit = true;
            this.editTitle = '留言新增';
        },

        tableSelectionChange (data2) {
            if (data2[0] != undefined) {
                this.checkedId = data2[0].id
            }
            let ids = []
            data2.map(function (item, key) {
                ids.push(item.id)
            })
            this.checkedIds = ids
        },

		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},

		// 机构列表
		handleSearch() {
			this.search.action_id = 425;
			let params = this.search;
			 params.action_id='425';
			getevaluatelist(params).then((res) => {
			       
			        if (res.errCode == 0) {
			          this.totalCount = parseInt(res.data.total);
			          this.data1 = res.data.list;
			          this.button = res.data.button;
			        }
			      });
			
		},

	
		handleSearchAction() {
			this.search.p = 1;
			this.handleSearch();
		},

		// handleUpdateModal() {
		// 	this.reset();
		// 	this.titleData = '修改部门信息';
		// 	if (this.checkedIds.length == 1) {
		// 		this.handleorgview();
		// 		this.newModel = true;
		// 	} else {
		// 		this.$Modal.info({ title: '提示', content: '请选择一项' });
		// 	}
		// },

		// 机构详情
		// handleorgview() {
		// 	let id = this.checkedId;
		// 	getOrgView(id)
		// 		.then(res => {
		// 			this.subData = res.data;
		// 			this.subData.org_type = String(res.data.org_type);
		// 			this.chooseProvince();
		// 			this.chooseCity();
		// 			this.imgurl = res.data.img;
		// 			this.imgurlUrl = res.data.img_url;
		// 		})
		// 		.catch(err => {
		// 			console.log(err);
		// 		});
		// },

		// 新增，编辑
		// getAdminRoleAdd() {
		// 	this.$refs.subData.validate(valid => {
		// 		if (valid) {
		// 			let params = this.subData;
		// 			let permiose = getInsideAdd(params);
		// 			permiose
		// 				.then(res => {
		// 					if (res.errCode == 0) {
		// 						if (this.subData.ogid) {
		// 							this.checkedIds = [];
		// 						}
		// 						this.newModel = false;
		// 						this.handleSearch();
		// 						this.reset();
		// 						this.$Modal.success({ title: '提示', content: '提交成功' });
		// 					} else {
		// 						this.$Modal.error({ title: '提示', content: res.errMsg });
		// 					}
		// 				})
		// 				.catch(err => {
		// 					console.log(err);
		// 				});
		// 		}
		// 	});
		// },
		// 启用
		// handleStatusSuccess() {
		// 	if (this.checkedIds.length > 0) {
		// 		this.$Modal.confirm({
		// 			title: '确认信息',
		// 			content: '<p>你确定要将选中的信息设置为启用状态么？?</p>',
		// 			onOk: () => {
		// 				this.sysAdminEdit(2);
		// 			},
		// 			onCancel: () => {
		// 				this.$Message.info('您取消了启用操作');
		// 			}
		// 		});
		// 	} else {
		// 		this.$Modal.info({ title: '提示', content: '请至少选择一项' });
		// 	}
		// },

		// sysAdminEdit(type) {
		// 	let params = {};
		// 	params.ogid = this.checkedIds;
		// 	params.type = type;
        //
		// 	getOrgEdit(params).then(res => {
		// 		if (res.errCode === 0) {
		// 			this.checkedIds = [];
		// 			this.$Notice.success({
		// 				title: '提示',
		// 				desc: res.errMsg
		// 			});
		// 			this.handleSearch();
		// 		} else {
		// 			this.$Notice.error({
		// 				title: '提示',
		// 				desc: res.errMsg
		// 			});
		// 		}
		// 	});
		// },
		// 禁用
		// handleStatusFail() {
		// 	if (this.checkedIds.length > 0) {
		// 		this.$Modal.confirm({
		// 			title: '确认信息',
		// 			content: '<p>你确定要将选中的信息设置为禁用状态么？?</p>',
		// 			onOk: () => {
		// 				this.sysAdminEdit(3);
		// 			},
		// 			onCancel: () => {
		// 				this.$Message.info('您取消了禁用操作');
		// 			}
		// 		});
		// 	} else {
		// 		this.$Modal.info({ title: '提示', content: '请至少选择一项' });
		// 	}
		// },
		// 删除
		// handleDelete() {
		// 	if (this.checkedIds.length > 0) {
		// 		this.$Modal.confirm({
		// 			title: '确认信息',
		// 			content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
		// 			onOk: () => {
		// 				this.sysAdminEdit(1);
		// 			},
		// 			onCancel: () => {
		// 				this.$Message.info('您取消了删除操作');
		// 			}
		// 		});
		// 	} else {
		// 		this.$Modal.info({ title: '提示', content: '请至少选择一项' });
		// 	}
		// },
		reset() {
			this.$refs.subData.resetFields();
		}
	},
	mounted() {
		this.handleSearch();
        this.getStar();
		
	}
};
</script>
<style lang="less">
@import '../style2.less';

.bxnpt {
	width: 25%;
	margin-left: 2%;
	float: left;
}
</style>

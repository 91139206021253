import * as  axios from '@/libs/api.request'

//机构管理-列表
export const getorglist = (params) => {
  return axios.post('backend/org-list', params)
};
// 定损员-列表15911
export const getDingSunYuanlist = (params) => {
  return axios.post('backend/user-list', params)
};
//机构管理-列表
export const getinfo = (params) => {
  return axios.post('backend/get-user-info', params)
};
export const addmessage = (params) => {
  return axios.post('backend/add-order-business-message', params)
};
export const changepwd = (params) =>{
	return axios.post('backend/changepwd',params);
};
//机构管理-列表
export const orderitemview = (params) => {
  return axios.post('backend/orderitem-view', params)
};
export const wusunorderlist = (params) => {
  return axios.post('backend/wu-sun-order-list', params)
};
export const wusunorderview = (params) => {
  return axios.post('backend/wu-sun-order-view', params)
};
export const addorgareas = (params) => {
  return axios.post('backend/org-areas-add', params)
};
export const addUserAreaCatws = (params) => {
  return axios.post('backend/user-area-cat-ws-add', params)
};

export const adduserarearec = (params) => {
  return axios.post('backend/user-area-cat-rec-add', params)
};


//外修分类增加
export const addUserAreaCatwx = (params) => {
  return axios.post('backend/user-area-cat-wx-add', params)
};

export const selectarea = (params) => {
  return axios.post('backend/select-area', params)
};
export const selectarearec = (params) => {
  return axios.post('backend/select-area-rec', params)
};


export const queryarea = (params) => {
  return axios.post('backend/query-area', params)
};

export const queryorgarea = (params) => {
    return axios.post('backend/query-org-area', params)
};

export const coverarea = (params) => {
  return axios.post('backend/cover-area', params)
};

export const addcoverarea = (params) => {
  return axios.post('backend/org-cover-add', params)
};
export const getitemDetail = (params) => {
  return axios.post('backend/order-item-detail', params)
};

export const addorderitem = (params) => {
  return axios.post('backend/add-order-item', params)
};



export const usercoverarea = (params) => {
  return axios.post('backend/user-cover-area', params)
};

export const addusercoverarea = (params) => {
  return axios.post('backend/org-cover-add-user', params)
};

//机构管理-详情
export const getorgdetail = (params) => {
  return axios.post('backend/org-view', params)
};
//机构管理-机构下拉
export const getorhDrop = (params) => {
  return axios.post('backend/org-all-drop', params)
};
//机构管理-新增编辑
export const addorg = (params) => {
  return axios.post('backend/org-add', params)
};
//机构管理-操作 type=>[1=>删除,2=>启用,3=>禁用]
export const getOrgEdit = (params) => {
    return axios.post('backend/org-edit', params)
};
//用户管理-列表
export const getuserlist = (params) => {
  return axios.post('backend/user-list', params)
};
//用户区域项目管理-列表
export const getUserAreaCatlist= (params) => {
  return axios.post('backend/user-area-cat-list', params)
};

//用户保险区域项目管理-列表
export const getBxAreaCatlist= (params) => {
    return axios.post('backend/bx-area-cat-list', params)
};

//用户区域项目管理-列表
export const getUserAreaReclist= (params) => {
  return axios.post('backend/user-area-rec-list', params)
};

//用户区域项目管理-列表
export const getUserAreaWsCatlist= (params) => {
  return axios.post('backend/user-area-ws-cat-list', params)
};




//用户管理-新增编辑
export const getUserAdd= (params) => {
    return axios.post('backend/user-add', params)
}
//用户管理-操作 type=>[1=>删除,2=>启用,3=>冻结]
export const getUserEdit = (params) => {
    return axios.post('backend/user-edit', params)
};
//用户管理-详情
export const getuserview = (params) => {
  return axios.post('backend/user-view', params)
};
//外修配件匹配
export const getorgcat = (params) => {
  return axios.post('backend/orgcat-list', params)
};
//外修配件匹配-配件下拉
export const getSpareList = (params) => {
    return axios.post('app/base-spare-list', params)
};
//-岗位管理--详情
export const positionview = (params) => {
  return axios.post('backend/position-view', params)
};
//-部门管理--详情
export const departmentview = (params) => {
  return axios.post('backend/department-view', params)
};

//外修配件匹配-新增编辑
export const getorgcatadd = (params) => {
  return axios.post('backend/orgcat-add', params)
};
//外修配件匹配-删除
export const getOrgcatEdit = (params) => {
    return axios.post('backend/orgcat-edit', params)
};
//-获取外修工单列表
export const getitembusinesslist = (params) => {
    return axios.post('backend/order-business-list', params)
};
//-工单配件管理-中标
export const bidorderitem = (params) => {
    return axios.post('backend/order-business-zhong-biao', params)
};
//-工单配件管理-核价
export const checkbid = (params) => {
    return axios.post('backend/order-business-shen-he', params)
};

//获取配件信息列表
export const getpartsorglist= (params) =>  {

	return axios.post('backend/base-spare-list', params);
};
//获取配件信息列表2-协议
export const getpartsorglist2= (params) =>  {
	return axios.post('backend/base-spare-list2', params);
};

//获取物损分类列表，用户是否已分配
export const getWscatTree= (params) =>  {

    return axios.post('backend/wscat-tree', params);
};
//获取物损分类列表，仅查询分类
export const getQueryWscatTree= (params) =>  {

    return axios.post('backend/query-wscat-tree', params);
};

//获取外修分类列表，仅查询分类
export const getQueryWxcatTree= (params) =>  {

    return axios.post('backend/query-wxcat-tree', params);
};


//业务管理-外修配件分配-批量添加
export const addwscat= (params) =>  {

	return axios.post('backend/org-ws-cats-add', params);
};


//业务管理-物损分类分配-批量添加
export const addorgcat= (params) =>  {

    return axios.post('backend/org-cats-add', params);
};
//上级岗位下拉
export const getPositionDrop= (params) =>  {

	return axios.post('backend/position-down', params);
};
//上级部门下拉
export const getDepartmentDrop= (params) =>  {

	return axios.post('backend/department-down', params);
};
//新增部门
export const addDepartment= (params) =>  {

	return axios.post('backend/department-add', params);
};
//新增岗位
export const addPosition= (params) =>  {

	return axios.post('backend/position-add', params);
};
//岗位列表
export const getstationlist= (params) =>  {

	return axios.post('backend/position-list', params);
};
//外修商/回收商区域
export const getorgarea = (params) => {
  return axios.post('backend/orgarea-list', params)
};
//外修商/回收商区域-新增编辑
export const getorgareaadd = (params) => {
  return axios.post('backend/orgarea-add', params)
};
//外修商/回收商区域-删除
export const getorgareaedit = (params) => {
    return axios.post('backend/orgarea-edit', params)
};




//订单管理-列表
export const getorderlist = (params) => {
  return axios.post('backend/order-list', params)
};
//订单管理-
export const getOrderView = (params) => {
    return axios.post('backend/order-view', params)
};


//中标开标-列表
export const getZhongbiaoList = (params) => {
    return axios.post('backend/zhongbiao-list', params)
};
export const agreementList = (params) => {
  return axios.post('backend/agreement-list', params)
};
export const agreementAll = (params) => {
  return axios.post('backend/agreement-all', params)
};
//开标协议-新增编辑
export const agreementAdd = (params) => {
  return axios.post('backend/agreement-add', params)
};
//开标协议--详情
export const agreementView = (params) => {
  return axios.post('backend/agreement-view', params)
};
//开标协议-删除
export const agreementDel = (params) => {
  return axios.post('backend/agreement-del', params)
};
//中标开标-新增编辑
export const getZhongbiaoAdd = (params) => {
    return axios.post('backend/zhongbiao-add', params)
};
//中标开标-删除
export const getZhongbiaoEdit = (params) => {
    return axios.post('backend/zhongbiao-edit', params)
};
//中标开标--详情
export const getZhongbiaoView = (params) => {
    return axios.post('backend/zhongbiao-view', params)
};
//业务管理-留言管理-列表
export const getMessageList= (params) => {
    return axios.post('backend/message-list', params)
};
//业务管理-留言管理-新增-配件下拉
export const getOrderDropDown= (params) => {
    return axios.post('backend/get-order-item-list-by-order', params)
};
//业务管理-留言管理-新增
export const addOrderBusinessmessage= (params) => {
    return axios.post('backend/add-order-business-message', params)
};
//业务管理-留言管理-删除
export const getMessageEdit= (params) => {
    return axios.post('backend/message-edit', params)
};

//业务管理-评价管理-列表
export const getevaluatelist= (params) => {
    return axios.post('backend/evaluate-list', params)
};
//业务管理-评价管理-新增-评价星级
export const getStar= (params) => {
    return axios.post('dictionary/get-star', params)
};
//业务管理-评价管理-新增
export const addOrderEvaluate= (params) => {
    return axios.post('backend/add-order-evaluate', params)
};
//业务管理-评价管理-删除
export const getEvaluateEdit= (params) => {
    return axios.post('backend/evaluate-edit', params)
};


//系统区域管理-列表
export const getreglist = (params) => {
  return axios.post('backend/region-list', params)
};
//系统区域管理-新增编辑
export const getreglistadd = (params) => {
  return axios.post('backend/region-add', params)
};
//系统区域管理-上级选择下拉
export const getRegionDrop = (params) => {
    return axios.post('backend/region-drop', params)
};
//系统区域管理-省市区层级
export const getAreaLevel = (params) => {
    return axios.post('dictionary/get-area-level', params)
};







export const additembus = (params) => {
  return axios.post('backend/additembus', params)
};
export const editOrderRegion = (params) => {
  return axios.post('backend/edit-order-region', params)
};



//外修配件管理
export const getorderitemlist = (params) => {
  return axios.post('backend/orderitem-list', params)
};

// 用户树
export const getRoleTree = (params) => {
    return axios.post('common/get-role-tree',params)
}
//隐私权政策
export const getOwnerPrivacy = () => {
    return axios.post('backend/owner-privacy')
}
//用户服务协议
export const getOwnerServeAgreement = () => {
    return axios.post('backend/owner-serve-agreement')
}
//隐私权政策新增
export const getOwnerPrivacyAdd = (params) => {
    return axios.post('backend/owner-privacy-add',params)
}
//用户服务协议新增
export const getOwnerServeAgreementAdd = (params) => {
    return axios.post('backend/owner-serve-agreement-add',params)
}
//系统设置-全局设置-客服电话
export const getServiceView = () => {
    return axios.post('backend/service-view')
}
//系统设置-全局设置-客服电话-修改
export const getServiceChange = (params) => {
    return axios.post('backend/service-change',params)
}
//系统管理-部门管理-列表
export const getDepartmentList = (params) => {
    return axios.post('backend/department-list',params)
}
//系统管理-部门管理-删除
export const getDepartmentEdit = (params) => {
    return axios.post('backend/department-edit',params)
}
//系统管理-岗位管理-删除
export const getPositionEdit = (params) => {
    return axios.post('backend/position-edit',params)
}

//统计中心-订单统计
export const getOrderStatistics = (params) => {
    return axios.post('backend/order-statistics',params)
}
// 15911
//统计中心-分类报表
export const getOrderTypeStatistics = (params) => {
    return axios.post('backend/order-type-statistics',params)
}
//统计中心-抢单统计
export const getGrabbingOrder = (params) => {
    return axios.post('backend/grabbing-order-statistics',params)
}
//统计中心-抢单统计
export const getPrescription = (params) => {
    return axios.post('backend/prescription-statistics',params)
}
//统计中心-抢单统计
export const getComprehensive = (params) => {
    return axios.post('backend/comprehensive-statistics',params)
}
// 用户角色权限管理
export const getRoleDropDown = (params) => {
    return axios.post('common/get-role-drop-down',params)
}
// 首页
export const getHomeStatistics = (params) => {
    return axios.post('backend/index', params)
}
//外修配件管理-确认关闭工单
export const getOrderBusinessClose = (params) => {
    return axios.post('backend/order-business-close', params)
}
//统计中心-外修清单-列表
export const getQingdanList= (params) => {
    return axios.post('backend/qingdan-list', params)
}
//统计中心-物损清单-列表
export const wusunList= (params) => {
  return axios.post('backend/wusun-list', params)
}
//统计中心-定损报表-列表
export const getDingsunList= (params) => {
    return axios.post('backend/dingsun-list', params)
}
//统计中心-外修报表-列表
export const getWaixiuList= (params) => {
    return axios.post('backend/waixiu-list', params)
}
//统计中心-综合报表-列表
export const getZongheList= (params) => {
    return axios.post('backend/zonghe-list', params)
}
//客户管理-维修厂指派-新增
export const getOrgAppointAdd = (params) => {
    return axios.post('backend/org-appoint-add', params)
};
//客户管理-维修厂指派-列表
export const getOrgAppointList = (params) => {
    return axios.post('backend/org-appoint-list', params)
};
//系统管理-维修厂指派-删除
export const getOrgAppointEdit = (params) => {
    return axios.post('backend/org-appoint-edit',params)
}
//客户管理-物损一询商-新增
export const getOrgAppointWsAdd = (params) => {
    return axios.post('backend/org-appoint-ws-add', params)
};
//客户管理-物损一询商-列表
export const getOrgAppointWsList = (params) => {
    return axios.post('backend/org-appoint-ws-list', params)
};
//系统管理-物损一询商-删除
export const getOrgAppointWsEdit = (params) => {
    return axios.post('backend/org-appoint-ws-edit',params)
}
export const waixiujiansunfenxi = (params) => {
    return axios.post('backend/list-three', params)
};
export const waixiuchenggongfenxi = (params) => {
    return axios.post('backend/list-two', params)
};
export const jiashicang = (params) => {
    return axios.post('backend/list-one', params)
};
export const zongheZoushi = (params) => {
    return axios.post('backend/list-four', params)
};
export const orderbusinessdickerprocess = (params) => {
    return axios.post('backend/order-business-dicker-process', params)
}

//获取外修分类列表，仅查询分类
export const getQueryOrgcatTree= (params) =>  {
    return axios.post('backend/query-orgcat-tree', params);
};

//外修-调价审核
export const waixiuCheck = (params) => {
  return axios.post('backend/order-business-tj-shen-he', params)
}
//外修-回退定损员
export const waixiutuihui = (params) => {
  return axios.post('backend/waixiu-tui-hui', params)
}
//外修-核价处展示中标商
export const zhongbiaoinfo = (params) => {
  return axios.post('backend/zb-info', params)
}

export const addUserBxAreaCat = (params) => {
    return axios.post('backend/add-user-bx-area-cat', params)
}

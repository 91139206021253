<template>
	<div class="side-menu-wrapper">
		<slot></slot>
		<!-- <p class="routetit">工业巴士</p> -->


		<Menu ref="menu" v-show="!collapsed" :active-name="activeName" :open-names="openedNames" :accordion="accordion"
		 :theme="theme" width="auto" @on-select="handleSelect">
			<template v-for="item in menuList">
				<template v-if="item.children ">
					<!-- 159 -->
					<SideMenuItem   :key="`menu-${item.name}`" :parent-item="item"></SideMenuItem>
				
				</template>
			</template>
		</Menu>


		<div class="menu-collapsed" v-show="collapsed" :list="menuList">
			<template v-for="item in menuList">
				<CollapsedMenu v-if="item.children && item.children.length > 1" @on-click="handleSelect" hide-title :hideTitle='showhideTitle'
				 :root-icon-size="rootIconSize" :icon-size="iconSize" :theme="theme" :parent-item="item" :key="`drop-menu-${item.name}`"></CollapsedMenu>
				<Tooltip transfer v-else :content="showTitle(item.children && item.children[0] ? item.children[0] : item)"
				 placement="right" :key="`drop-menu-${item.name}`">
					<a @click="handleSelect(getNameOrHref(item, true))" class="drop-menu-a" :style="{textAlign: 'center'}">
						<CommonIcon :size="rootIconSize" :color="textColor" :type="item.icon || (item.children && item.children[0].icon)" /></a>
				</Tooltip>
			</template>
		</div>
	</div>
</template>
<script>
	import {
		getUnion
	} from '@/libs/tools'
	import mixin from './mixin'

	export default {
		name: 'SideMenu',
		mixins: [mixin],
		components: {

			SideMenuItem: () => {
				return import('./side-menu-item.vue');
			},
			CollapsedMenu: () => {
				return import('./collapsed-menu.vue');
			},

		},
		props: {
			menuList: {
				type: Array,
				default () {
					return []
				}
			},
			collapsed: {
				type: Boolean
			},
			theme: {
				type: String,
				default: 'dark'
			},
			rootIconSize: {
				type: Number,
				default: 20
			},
			iconSize: {
				type: Number,
				default: 16
			},
			accordion: Boolean,
			activeName: {
				type: String,
				default: ''
			},
			openNames: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				openedNames: [],
				showhideTitle:false
			}
		},
		methods: {
			handleSelect(name) {
				// console.log('handleSelect',name);
				this.$emit('on-select', name)
			},
			getOpenedNamesByActiveName(name) {
				return this.$route.matched.map(item => item.name).filter(item => item !== name)
			},
			updateOpenName(name) {
				if (name === this.$config.homeName) this.openedNames = []
				else this.openedNames = this.getOpenedNamesByActiveName(name)
			}
		},
		computed: {
			textColor() {
				return this.theme === 'dark' ? '#fff' : '#495060'
			}
		},
		watch: {
			activeName(name) {
				if (this.accordion) this.openedNames = this.getOpenedNamesByActiveName(name)
				else this.openedNames = getUnion(this.openedNames, this.getOpenedNamesByActiveName(name))
			},
			openNames(newNames) {
				this.openedNames = newNames
			},
			openedNames() {
				this.$nextTick(() => {
					this.$refs.menu.updateOpened()
				})
			}
		},
		mounted() {
			this.openedNames = getUnion(this.openedNames, this.getOpenedNamesByActiveName(name))
		}
	}
</script>
<style lang="less">
	@import './side-menu.less';
</style>

<template>
    <div class="main" id="damage">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        price:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getdamage()
    },
    getdamage(){

      var chartDom = document.getElementById('damage');
      var myChart = echarts.init(chartDom,{width:530,height:128});
            myChart.resize()
      var option;

      option = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          top:7,
          left: '1.5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // data: ['前保险杠','大灯框架','倒车灯','外门把手','后备箱盖','后侧尾板','前桥','后桥'],
            data: this.data1.date,
            axisTick: { show: false,
              alignWithLabel: true

            },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:8
              },
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
          }
        ],
        series: [
          {
            name: '金额',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(80,80,255)'
                },
                {
                  offset: 1,
                  color: 'rgb(215,215,255)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            // data: [140, 232, 101, 264, 90, 300, 250,210]
            data: this.data1.price
          }

        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getdamage()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

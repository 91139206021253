<template>
	<Card class="col-right-card min-height">
		<p slot="title">外修单管理</p>
		<Form :label-width="120">
			<!--<FormItem class="FromItemnpt" label="状态">-->
				<!--<RadioGroup v-model="button1" type="button">-->
					<!--<Radio label="全部"></Radio>-->
					<!--<Radio label="审核"></Radio>-->
					<!--<Radio label="待审"></Radio>-->
				<!--</RadioGroup>-->
			<!--</FormItem>-->
			<FormItem class="FromItemnpt" label="报案号"><Input clearable placeholder="请输入报案号" v-model="search.reportno" /></FormItem>
			<FormItem class="FromItemnpt" label="车牌号"><Input clearable placeholder="请输入车牌号" v-model="search.carno" /></FormItem>

			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>
		<!--<Button type="primary" @click="clickdel">详情</Button>-->

		<Table
			height="500"
			border
			stripe
			:columns="canshu"
			:data="canshudata"
			@on-select="tableSelectionChange"
			@on-select-all="tableSelectionChange"
			@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
			<div style="height:30px;"></div>
		</div>

		<Modal v-model="showregionmodal" width="55%" title="区域匹配">
			  <cityView
            ref="cityView"
            style="display: inline-block;"
            @selectFunc="selectFunc"
          ></cityView>
			<div slot="footer">
				<Button @click="showregionmodal= false">取消</Button>
				<Button type="primary" @click="submitCheckdata">确定</Button>
			</div>
		</Modal>
	
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as tools from '@/libs/tools';
import config from '@/config';
import cityView from '../mycomponents/cityView.vue';
import { getMemberLevelDrop } from '@/api/common';
import { getUserStatus, getWhether } from '@/api/dictionary';
		import * as Enum from '@/libs/enum';
		import {
		getorderlist,editOrderRegion

	} from '@/api/admin';

export default {
	components: {
		cityView
	},
	// computed: {
	//   ...mapGetters([
	//     'userStatus'
	//   ]),

	//   },
	data() {
		const validateCityCheck = (rule, value, callback) => {
			if (this.subData.province == "" || this.subData.city == "" || this.subData.district == "") {
				callback(new Error('请选择省市区'));
			} else {
				callback();
			}
		};
		const validatePwd = (rule, value, callback) => {
			callback();
		};
		const validatePassCheck = (rule, value, callback) => {
			if (this.subData.password !== '') {
				if (value == '') {
					callback(new Error('请再次输入密码'));
				} else if (value == this.subData.password) {
					callback();
				} else {
					console.log(this.subData.password);
					callback(new Error('两个输入密码不匹配！'));
				}
			} else {
				callback();
			}
		};
		return {
			handleData: {
				type: '',
				data: ''
			},
			showregionmodal:false,
			orderid:0,

			button1: '全部',

			map: {
				keyword: '',
				center: {
					lng: 117.1195,
					lat: 36.655164
				},
				zoom: 15,
				show: true,
				dragging: true
			},
			markmodel: false,
			lat: 0,
			lng: 0,

			dataRules: {
				username: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					}
				],
				password: [
					{
						validator: validatePwd,
						trigger: 'blur'
					}
				],
				sex: [
					{
						required: true,
						message: '必选',
						trigger: 'change'
					}
				],
				status: [
					{
						required: true,
						message: '必选',
						trigger: 'change'
					}
				],
				email: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					}
				],
				mobile: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					},
					{
						message: '格式有误',
						trigger: 'blur',
						pattern: /^1[34578]\d{9}$/
					}
				],

				cityCheck: [
					{
						validator: validateCityCheck,
						required: true,
						trigger: 'blur'
					}
				]
			},
			checkedIds: [],

			uploadHttp: '',
			access_token: '',
			defaultList: [],
			userInfoType: [],
			detailData: {},
			subData: {
				// id: '',
				// username: '', //姓名
				// email: '',
				// mobile: '',
				// referralCode: '', //推荐码
				// workNumber: '', //工号
				// organization: '', //所属机构
				// qq: '',
				// sex: '0',
				// status: '1',
				// password: '',
				// remark: '', //用户备注
				// province: '',
				// city: '',
				// district: ''
			},
			hyse: false,
			types: '',
			search: {
					p: 1, // 当前页码
					t: 10, // 每页显示条数
                search:null,
                carno:null,
                yctype:1,
                loss_type:1,//1  配件损失  2物损
               // ordertype://1  外修  2 回收 3 俩者都有
			},
			timeshijian: [],
			button: {},
			userStatus: [],
			editTitle: '回收单新增',
			showEdit: false,
			showDetail: false,
			organizationList: [],

			memberLevelDrop: [], // 会员等级
			totalCount: 10,
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			canshu: [
				{ title: '报案号', key: 'reportno', align: 'center', minWidth: 100, fixed: 'left' },
				{ title: '保险公司', key: 'baoxianname', align: 'center', minWidth: 100, fixed: 'left' },
				{ title: '外修单号', key: 'ordersn', align: 'center', minWidth: 130 },
				{ title: '车牌号', key: 'carno', align: 'center', minWidth: 80 },
				{ title: 'VIN', key: 'vin', align: 'center', minWidth: 100 },
				{ title: '车品牌', key: 'brand_name', align: 'center', minWidth: 80 },
				{ title: '车型名称', key: 'model_name', align: 'center', minWidth: 100 },
				{ title: '修理厂名称', key: 'repair_ogname', align: 'center', minWidth: 100 },

				{ title: '修理厂省', key: 'repair_province_name', align: 'center', minWidth: 100 },
				{ title: '修理厂市', key: 'repair_city_name', align: 'center', minWidth: 100 },
				{ title: '修理厂区', key: 'repair_district_name', align: 'center', minWidth: 100 },
				{ title: '接待人', key: 'repair_contact', align: 'center', minWidth: 100 },
				{ title: '接待人电话', key: 'repair_contact_phone', align: 'center', minWidth: 100 },
				{ title: '创建时间', key: 'createtime', align: 'center', minWidth: 100 },



				{
					title: '操作',
					key: 'action',
					width: 300,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small'
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.handleDetailModal(params.row.id);
										}
									}
								},
								'详情'
							),
							h(
								'Button',
								{
									props: {
										type: 'success',
										size: 'small',
									
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.regionpipei(params.row.id);
										}
									}
								},
								'匹配区域'
							),
							
						]);
					}
				}
			],
			canshudata: []
		};
	},
	methods: {
		getUserInfoType() {
			//
			getWhether().then(res => {
				this.userInfoType = res.data;
			});
		},
		tableSelectionChange(data2) {
			if (data2[0] != undefined) {
				this.checkedId = data2[0].id;
			}
			let ids = [];
			data2.map(function(item, key) {
				ids.push(item.id);
			});
			this.checkedIds = ids;
		},
		markmap() {
			//标注
			this.markmodel = true;
			if (this.subData.lng) {
				this.map.center.lng = this.subData.lng;
				this.map.center.lat = this.subData.lat;
			}
		},
		handleEdit(type) {
			if (this.checkedIds.length > 0) {
				let params = {};
				params.uid = this.checkedIds;
				params.type = type;
				if (type == 1) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
						onOk: () => {
							this.sysEdit(params);
						},
						onCancel: () => {
							this.$Message.info('您取消了删除操作');
						}
					});
				} else {
					this.sysEdit(params);
				}
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},

		regionpipei (id) {
 		 this.orderid=id;
       
         this.showregionmodal=true;
          
      },

      submitCheckdata(){

      	let params = {};
		params.orderid = this.orderid;
		params.belong_province_id =this.subData.province;
		params.belong_city_id =this.subData.city;
		params.belong_district_id =this.subData.district;



      	editOrderRegion(params).then(res => {
                if (res.errCode == 0) {
                    this.$Notice.success({
                        title: '提示',
                        desc: '操作成功'
                    });
                     this.showregionmodal=false;
                 
                } else {
                    this.$Notice.error({
                        title: '提示',
                        desc: res.errMsg
                    });
                }
            });

      },

		handleGrade() {
			// 批量设置等级
			if (this.checkedIds.length > 0) {
				this.hyse = true;
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},
		clickdel() {
			this.showDetail = true;
			
			// let query = { id: params.row.id }
			this.$router.push({
                      name: 'order_details',
                      
                    })
		},
		handleSubmit(type) {
			let params = {};
			params.id = this.checkedIds;
			params.type = type;
			params.data = this.handleData.levelData;
			this.sysEdit(params);
		},

		sysEdit(params) {
			getCustomerEdit(params).then(res => {
				if (res.errCode == 0) {
					this.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					this.fzse = false;
					this.hyse = false;
					this.checkedIds = [];
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},

		selectFunc(cityObj) {
			this.subData.province = cityObj.provinceModel;
			this.subData.city = cityObj.cityModel;
			this.subData.district = cityObj.districtModel;
			console.log('this.subData---------',this.subData);
		},
		selectFunc1(cityObj) {
			this.search.province = cityObj.provinceModel;
			this.search.city = cityObj.cityModel;
			this.search.district = cityObj.districtModel;
		},

		getUserStatus() {
			// console.log('result',this.userStatus );
			getUserStatus().then(res => {
				this.userStatus = res.data;
			});
		},
		confirmlnglat() {
			this.subData.lng = this.lng;
			this.subData.lat = this.lat;
			this.$Notice.success({
				title: '提示',
				desc: '选择成功'
			});
			this.markmodel = false;
		},

		getMemberLevelDrop() {
			let params = {
				type: '1'
			};
			getMemberLevelDrop(params).then(res => {
				this.memberLevelDrop = res.data;
			});
		},

		// 日期
		checkDate(date) {
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		// 查询
		handleSearchAction() {
			this.search.p = 1;
			this.search.is_export = 0;
			this.handleSearch();
		},
		handler({ BMap, map }) {
			let me = this;
			console.log(BMap, map);
			// 鼠标缩放

			map.enableScrollWheelZoom(true);
			// 点击事件获取经纬度
			map.addEventListener('click', function(e) {
				me.subData.lng = e.point.lng;
				me.subData.lat = e.point.lat;
				me.lng = e.point.lng;
				me.lat = e.point.lat;
				console.log(e.point.lng, e.point.lat);
			});
		},
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		// 列表
		handleSearch() {
			let params = this.search;
			// getMember(params).then(res => {
			getorderlist(params).then(res => {
				if (res.errCode == 0) {
					this.totalCount = parseInt(res.data.total);
					this.canshudata = res.data.list;
					this.button = res.data.button
				}
			});
		},
		reset() {
			this.$refs.subData.resetFields();
		},
		// 新增
		handleCreateModel() {
			this.reset();
			this.subData = {};
			this.$refs.cityView.viewdata.provinceModel = '';
			this.$refs.cityView.viewdata.cityModel = '';
			this.$refs.cityView.viewdata.districtModel = '';
			this.showEdit = true;
			this.editTitle = '回收单新增';
		},

		handleUpdateModal(data) {
			this.viewData(data);
			this.editTitle = '回收单修改';
			this.showEdit = true;
		},

		handleDetailModal(id) {
            if (id) {
                let query = { id: id ,type:1}//type  1  外修单   2回收单
                this.$router.push({
                    name: 'order_details',
                    query: query
                })
            }
		},

		viewData(uid) {
			getCustomerView({
				uid: uid
			}).then(res => {
				this.subData = res.data;
				let that = this;
				setTimeout(function() {
					that.$refs.cityView.viewdata.provinceModel = res.data.province;
					that.$refs.cityView.viewdata.cityModel = res.data.city;
					that.$refs.cityView.viewdata.districtModel = res.data.district;
				}, 150);
			});
		},

		submitForm() {
			// 保存
			this.$refs.subData.validate(valid => {
				console.log('valid',valid);
				if (valid) {
					let params = this.subData;
					getCustomerAdd(params).then(res => {
						if (res.errCode == 0) {
							this.showEdit = false;
							this.handleSearch();
							this.reset();
							this.$Modal.success({
								title: '提示',
								content: '提交成功'
							});
						} else {
							this.$Modal.error({
								title: '提示',
								content: res.errMsg
							});
						}
					});
				}
			});
		},
		handleError(data1, data2, data3) {
			// error file fileList
			this.$Modal.error({
				title: '提示',
				content: '上传失败'
			});
		},
		handleFormatError(data1, data2, data3) {
			// file fileList
			this.$Modal.error({
				title: '提示',
				content: '文件格式不正确'
			});
		},
		handleMaxSize(data1, data2) {
			// file fileList
			this.$Modal.error({
				title: '提示',
				content: '文件太大'
			});
		},
		handleSuccess(data1, data2, data3) {
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.avatar = data1.data[0].pic; // 图片上传地址
				this.subData.url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: res.errMsg
				});
			}
		},
		getDuration(date) {
			// how many days of this month
			let dt = new Date();
			var month = dt.getMonth();
			dt.setMonth(dt.getMonth() + 1);
			dt.setDate(0);
			return dt.getDate();
		},
		GetDate(is) {
			var now = new Date();
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			// var day = now.getDate();            　　//日
			if (is) {
				return year + '-' + month + '-' + is;
			} else {
				return year + '-' + month + '-1';
			}
		}
	},
	mounted() {
		switch (this.$route.query.type) {
			case 1:
				var mun = this.getDuration();
				this.search.status = '1';
				this.search.stime = this.GetDate(false);
				this.search.etime = this.GetDate(false);
				this.timeshijian = [this.GetDate(false), this.GetDate(false)];
				break;
			case 2:
				break;
		}
		this.getUserStatus();
		this.getMemberLevelDrop();
		this.getUserInfoType();
		this.handleSearch();
		this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro;
		this.access_token = util.getToken();
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}

.map .search {
	margin-bottom: 65px;
}

.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
</style>

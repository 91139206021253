<template>
	<Card class="col-right-card min-height">
		<p slot="title">外修商机构区域管理</p>
		<Form :label-width="120">
			<FormItem class="FromItemnpt" label="外修商" prop="externalerr"><Input v-model="search.ogname"  /></FormItem>
			<FormItem class="FromItemnpt" label="保险公司" prop="insurance"><Input v-model="search.bxname"  /></FormItem>
			<FormItem class="FromItemnpt" label="添加时间">
				<DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" v-model="timeshijian"></DatePicker>
			</FormItem>
			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>
		<Button class="marginbnt" @click="handleCreateModel" type="primary" v-show="button.add">新增</Button>

		<Button class="marginbnt" @click="handleEdit(1)" type="primary" v-show="button.delete">删除</Button>
	

		<Table
			height="500"
			border
			stripe
			:columns="canshu"
			:data="canshudata"
			@on-select="tableSelectionChange"
			@on-select-all="tableSelectionChange"
			@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
			<div style="height:30px;"></div>
		</div>

		 <Modal v-model="showEdit" width="40%" :title="editTitle">
			<Form :label-width="120" ref="subData" :model="subData" :rules="dataRules">
				<Row>
					<FormItem class="FromItemnpt  org-width" label="外修商机构" prop="repair_name">
						<Input v-model="subData.repair_name" :readonly=true>
						  <Icon type="ios-search" slot="suffix" @click="repair_org"/>
						<a style="margin-left:5px;" @click="clear">清除</a>
						</Input>
					</FormItem>
				</Row>
				<Row>
					<FormItem class="FromItemnpt  org-width" label="保险机构" prop="in_name">
						<Input v-model="subData.in_name" :readonly=true>
						<Icon type="ios-search" slot="suffix" @click="insurance_org"/>
						<a style="margin-left:5px;" @click="cleartwo">清除</a>
						</Input>
					</FormItem>
				</Row>
				<!--<Row>-->
					<!--<FormItem class="FromItemnpt" label="类型" prop="typename">-->
						<!--<Select v-model="subData.type">-->
							<!--<Option v-for="(item, index) in userStatus" :value="index" :key="index">{{ item }}</Option>-->
						<!--</Select>-->
					<!--</FormItem>-->
				<!--</Row>-->
				<Row>
					<FormItem class="FromItemnpt dizhi_width" label="地区" prop="cityCheck">
						<cityView ref="cityView" style="display: inline-block;" @selectFunc="selectFunc"></cityView>
					</FormItem>
				</Row>

			</Form>
			
			<div slot="footer">
				<Button @click="showEdit = false">取消</Button>
				<Button type="primary" @click="submitForm">保存</Button>
			</div>
		</Modal>





		<insurance_org
				ref="insurance_org"
				:initOption="chooseorgInitOption"
				@getorgdata="getChooseGY">
		</insurance_org>



	</Card>
</template>
<script>

    import insurance_org from '@/view/my-components/ditanorg/insurance_org';
    // import cityView from '../my-components/ditanorg/cityView.vue';
    import cityView from '../mycomponents/cityView.vue';

import * as util from '@/libs/util';
import * as tools from '@/libs/tools';
import config from '@/config';


// import { getCustomer, getCustomerView, getCustomerAdd, getCustomerEdit, getCustomerDetail } from '@/api/backend';
// import { getMemberLevelDrop } from '@/api/common';
// import { getOrgAreaType, getWhether } from '@/api/dictionary';
		import * as Enum from '@/libs/enum';
		import {
		// getorglist,
		getorhDrop,
		// getOrgEdit,
		// getorgcat,
		getorgarea,
		// getorderlist,
		// getreglist,
		getorgareaadd, getorgareaedit

	} from '@/api/admin';

export default {
	components: {
		cityView,
        insurance_org,
	},
	data() {
        const validateCityCheck = (rule, value, callback) => {
            if (this.subData.bx_province_id == undefined || this.subData.bx_city_id == undefined ) {
                callback(new Error('请选择省市区'));
            } else {
                callback();
            }
        };
		return {
			handleData: {
				type: '',
				data: ''
			},

			dataRules: {
                cityCheck: [
                    {
                        validator: validateCityCheck,
                        required: true,
                        trigger: 'blur'
                    }
                ],
                repair_name: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur'
                    }
                ],
                in_name: [
                    {
                        required: true,
                        message: '必填',
                        trigger: 'blur'
                    }
                ],
			},
			checkedIds: [],

			uploadHttp: '',
			access_token: '',
			defaultList: [],
			userInfoType: [],
			detailData: {},
			subData: {
				bx_ogid:'', //保险机构id
				bus_ogid:'',//外修商机构id
				bx_province_id:'',//开通区域省id
				bx_city_id:'',	  //开通区域地级市id
                bx_district_id:'',
				type:'',	//2外修  4回收  5物损
				ogtype:Enum.ogtypeEnum.repair_business,	//1 维修厂 2外修商  3 保险公司 4回收商 5物损
                repair_name: '',
                in_name: '',
			},
			hyse: false,
			types: '',
            chooseorgInitOption: {
                title: '',
                showModal: false,
            },
			search: {
					p: 1, // 当前页码
					t: 10, // 每页显示条数
					ogtype:Enum.ogtypeEnum.repair_business,
					kw:null,
                bxname:null,
                ogname:null,
			},
			timeshijian: [],
			button: {},
			// userStatus: [],
			editTitle: '外修商机构区域新增',
			showEdit: false,
			showDetail: false,

			memberLevelDrop: [], // 会员等级
			totalCount: 10,
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			canshu: [
				{
					type: 'selection',
					width: 60,
					align: 'center',
					fixed: 'left'
				},
				{
					title: '保险公司',
					key: 'bxname',
					align: 'center',
					minWidth: 140,
					fixed: 'left'
				},

				{
					title: '外修商',
					key: 'ogname',
					align: 'center',
					minWidth: 60,
					fixed: 'left'
				},
				{
					title: '外修商省份',
					key: 'bx_province_name',
					align: 'center',
					minWidth: 60,
					fixed: 'left'
				},

				{
					title: '外修商市',
					key: 'bx_city_name',
					align: 'center',
					minWidth: 60,
                    fixed: 'left'
				},
                {
                    title: '外修商区',
                    key: 'bx_district_name',
                    align: 'center',
                    minWidth: 60,
                    fixed: 'left'
                },
				{
					title: '添加时间',
					key: 'createtime',
					align: 'center',
					minWidth: 100,
                    fixed: 'left'
				},
				{
					title: '操作',
					key: 'action',
					width: 1,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
										disabled: !this.button.edit
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.handleUpdateModal(params.row.id);
										}
									}
								},
								'编辑'
							),
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small'
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.handleDetailModal(params.row.id);
										}
									}
								},
								'查看'
							)
						]);
					}
				}
			],
			canshudata: []
		};
	},
	methods: {
        clear() {
            this.subData.bus_ogid = ''
            this.subData.repair_name = ''
        },
        cleartwo() {
            this.subData.bx_ogid = ''
            this.subData.in_name = ''
        },
        repair_org () {
            this.chooseorgInitOption.title = '选择外修商';
            this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.repair_business;
            this.chooseorgInitOption.showModal = true;
            this.$refs.insurance_org.handleSearch()
        },
        insurance_org () {
            this.chooseorgInitOption.title = '选择保险公司';
            this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
            this.chooseorgInitOption.showModal = true;
            this.$refs.insurance_org.handleSearch()
        },
        getChooseGY (item) {
            // console.log(item);
            this.chooseorgInitOption.showModal = false

            if (item.ogtype == 2){
                this.subData.bus_ogid = item.id;
                this.subData.repair_name = item.name;
            }else if (item.ogtype == 3) {
                this.subData.bx_ogid = item.id;
                this.subData.in_name = item.name;
            }
        },
        //机构下拉
        // getinsuranceorg() {
        //     let params = {
        //         ogtype: Enum.ogtypeEnum.repair_business
        //     };
        //     getorhDrop(params).then(res => {
        //         this.orgdata = res.data;
        //     });
        //     let bxparams = {
        //         ogtype: Enum.ogtypeEnum.insurance_company
        //     };
        //     getorhDrop(bxparams).then(res => {
        //         this.bxorgdata = res.data;
        //     });
        // },
        changeparent(value, selectedData){
            // console.log(selectedData,value);return;
            this.subData.bus_ogid=selectedData[selectedData.length-1].value;
        },
        bxchangeparent(value, selectedData){
            // console.log(selectedData,value);return;
            this.subData.bx_ogid=selectedData[selectedData.length-1].value;
        },
		// getUserInfoType() {
		// 	//
		// 	getWhether().then(res => {
		// 		this.userInfoType = res.data;
		// 	});
		// },
		tableSelectionChange(data2) {
			if (data2[0] != undefined) {
				this.checkedId = data2[0].id;
			}
			let ids = [];
			data2.map(function(item, key) {
				ids.push(item.id);
			});
			this.checkedIds = ids;
		},
		markmap() {
			//标注
			this.markmodel = true;
			if (this.subData.lng) {
				this.map.center.lng = this.subData.lng;
				this.map.center.lat = this.subData.lat;
			}
		},
		handleEdit(type) {
			if (this.checkedIds.length > 0) {
				let params = {};
				params.id = this.checkedIds;
				params.type = type;
				if (type == 1) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
						onOk: () => {
							this.sysEdit(params);
						},
						onCancel: () => {
							this.$Message.info('您取消了删除操作');
						}
					});
				} else {
					this.sysEdit(params);
				}
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},

		handleGrade() {
			// 批量设置等级
			if (this.checkedIds.length > 0) {
				this.hyse = true;
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},

		handleSubmit(type) {
			let params = {};
			params.id = this.checkedIds;
			params.type = type;
			params.data = this.handleData.levelData;
			this.sysEdit(params);
		},

		sysEdit(params) {
            getorgareaedit(params).then(res => {
				if (res.errCode == 0) {
					this.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					this.fzse = false;
					this.hyse = false;
					this.checkedIds = [];
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},

		selectFunc(cityObj) {
			this.subData.bx_province_id = cityObj.provinceModel;
			this.subData.bx_city_id = cityObj.cityModel;
            this.subData.bx_district_id = cityObj.districtModel;
		},

		// getUserStatus() {
         //    getOrgAreaType().then(res => {
		// 		this.userStatus = res.data;
		// 	});
		// },
		confirmlnglat() {
			this.subData.lng = this.lng;
			this.subData.lat = this.lat;
			this.$Notice.success({
				title: '提示',
				desc: '选择成功'
			});
			this.markmodel = false;
		},

		// getMemberLevelDrop() {
		// 	let params = {
		// 		type: '1'
		// 	};
		// 	getMemberLevelDrop(params).then(res => {
		// 		this.memberLevelDrop = res.data;
		// 	});
		// },

		// 日期
		checkDate(date) {
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		// 查询
		handleSearchAction() {
			this.search.p = 1;
			this.search.is_export = 0;
			this.handleSearch();
		},
		handler({ BMap, map }) {
			let me = this;
			// console.log(BMap, map);
			// 鼠标缩放

			map.enableScrollWheelZoom(true);
			// 点击事件获取经纬度
			map.addEventListener('click', function(e) {
				me.subData.lng = e.point.lng;
				me.subData.lat = e.point.lat;
				me.lng = e.point.lng;
				me.lat = e.point.lat;
				// console.log(e.point.lng, e.point.lat);
			});
		},
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		// 列表
		handleSearch() {
			let params = this.search;
			// getMember(params).then(res => {
			getorgarea(params).then(res => {
				if (res.errCode == 0) {
					this.totalCount = parseInt(res.data.total);
					this.canshudata = res.data.list;
					this.button = res.data.button
				}
			});
		},
		reset() {
			this.$refs.subData.resetFields();
		},
		// 新增
		handleCreateModel() {
			this.reset();
			// this.subData = {};
            this.subData.bus_ogid = '';
            this.subData.repair_name = '';
            this.subData.bx_ogid = '';
            this.subData.in_name = '';
            this.$refs.cityView.viewdata.provinceModel = '';
            this.$refs.cityView.viewdata.cityModel = '';
            this.$refs.cityView.viewdata.districtModel = '';
			this.showEdit = true;
			this.editTitle = '外修商机构区域新增';
		},

		handleUpdateModal(data) {
			this.viewData(data);
			this.editTitle = '外修商机构区域修改';
			this.showEdit = true;
		},

		handleDetailModal(data) {
			getorgarea({
				uid: data
			}).then(res => {
				this.detailData = res.data;
				this.showDetail = true;
			});
		},

		viewData(uid) {
			getorgarea({
				uid: uid
			}).then(res => {
				this.subData = res.data;
			});
		},

		submitForm() {
			// 保存
            var that=this;
			this.$refs.subData.validate(valid => {
				if (valid) {
                    // that.subData.ogtype=2;
					let params = this.subData;
					getorgareaadd(params).then(res => {
						if (res.errCode == 0) {
							this.showEdit = false;
							this.handleSearch();
							this.reset();
							this.$Modal.success({
								title: '提示',
								content: '提交成功'
							});
						} else {
							this.$Modal.error({
								title: '提示',
								content: res.errMsg
							});
						}
					});
				}
			});
		},
		handleError(data1, data2, data3) {
			// error file fileList
			this.$Modal.error({
				title: '提示',
				content: '上传失败'
			});
		},
		handleFormatError(data1, data2, data3) {
			// file fileList
			this.$Modal.error({
				title: '提示',
				content: '文件格式不正确'
			});
		},
		handleMaxSize(data1, data2) {
			// file fileList
			this.$Modal.error({
				title: '提示',
				content: '文件太大'
			});
		},
		handleSuccess(data1, data2, data3) {
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.avatar = data1.data[0].pic; // 图片上传地址
				this.subData.url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: res.errMsg
				});
			}
		},
		getDuration(date) {
			// how many days of this month
			let dt = new Date();
			var month = dt.getMonth();
			dt.setMonth(dt.getMonth() + 1);
			dt.setDate(0);
			return dt.getDate();
		},
		GetDate(is) {
			var now = new Date();
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			// var day = now.getDate();            　　//日
			if (is) {
				return year + '-' + month + '-' + is;
			} else {
				return year + '-' + month + '-1';
			}
		}
	},
	mounted() {
		switch (this.$route.query.type) {
			case 1:
				var mun = this.getDuration();

				this.search.stime = this.GetDate(false);
				this.search.etime = this.GetDate(false);
				this.timeshijian = [this.GetDate(false), this.GetDate(false)];
				break;
			case 2:
				break;
		}
        // this.getinsuranceorg();//获取机构下拉
		// this.getUserStatus();
		// this.getMemberLevelDrop();
		// this.getUserInfoType();
		this.handleSearch();
		this.access_token = util.getToken();
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}

.map .search {
	margin-bottom: 65px;
}

.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
.FromItemnpt {
    width: 360px;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
}
</style>

<template>
  <Card class="col-right-card min-height">
    <!-- <div class="list"> -->
    <p slot="title">外修清单</p>
    <Form :label-width="120">
      <!--<FormItem class="FromItemnpt"><Input clearable value='2020-01-07'/></FormItem>-->
      <FormItem class="FromItemnpt" label="订单发起时间">
        <DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" ></DatePicker>
      </FormItem>

      <FormItem class="FromItemnpt" label="保险公司">
        <Input v-model="search.baoxianname" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="insurance_org" />
        </Input>
        <a style="margin-left: 5px" @click="clearBaoxian">清除</a>
      </FormItem>
      <FormItem class="FromItemnpt" label="报案号">
        <Input
          v-model="search.reportno"
          placeholder="请输入报案号"
        ></Input>
      </FormItem>
      <FormItem class="FromItemnpt" label="第三方单位名称：">
        <Input v-model="search.repair_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="repair_org" />
        </Input>
        <a style="margin-left: 5px" @click="clear">清除</a>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
      <Button type="primary" :loading="exportLoading" @click="exportExcel" >导出</Button>
    </Form>
    <Table
      height="500"
      border
      stripe
      :columns="columsQingdan"
      :data="dataQingdan"
      @on-select="tableSelectionChange"
      @on-select-all="tableSelectionChange"
      @on-select-cancel="tableSelectionChange"
    ></Table>
    <!-- </div> -->

    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height: 30px"></div>
    </div>

    <insurance_org
      ref="insurance_org"
      :initOption="chooseorgInitOption"
      @getorgdata="getChooseGY"
    >
    </insurance_org>
  </Card>
</template>

<script>
import excel from '@/libs/excel'
import insurance_org from "@/view/my-components/ditanorg/insurance_org";
import * as Enum from "@/libs/enum";
import { getQingdanList } from '@/api/admin';
// import config from "@/config";
// import * as util from "@/libs/util";
export default {
  name: "",
  components: {
    insurance_org,
  },
  data() {
    return {
      search: {
        p: 1,
        t: 10,
        bx_ogid: '',
        baoxianname: '',
        reportno: '',
        ogid: '',
        repair_name: '',
      },
      totalCount: 10,
      dataQingdan: [],
      columsQingdan: [
        {title: "订单发起时间", key: "createtime_str", align: "center", minWidth: 170,},
        {title: "保险公司", key: "baoxianname", align: "center", minWidth: 100,},
        { title: "归属地区", key: "belong_city_id_str", align: "center", minWidth: 100 },
        {title: "定损员名称", key: "createname", align: "center", minWidth: 110,},
        { title: "报案号", key: "reportno", align: "center", minWidth: 100 },
        { title: "车牌号", key: "carno", align: "center", minWidth: 100 },
        { title: "车辆品牌", key: "brand_name", align: "center", minWidth: 100 },
        { title: "车型", key: "model_name", align: "center", minWidth: 120 },
        {title: "配件单号", key: "ordersn", align: "center", minWidth: 150,},
        {title: "取件修理厂", key: "repair_ogname", align: "center", minWidth: 170,},
        { title: "接待人", key: "repair_contact", align: "center", minWidth: 80 },
        { title: "配件编码", key: "sparecode", align: "center", minWidth: 130 },
        { title: "配件名称", key: "sparename", align: "center", minWidth: 130 },
        { title: "配件分类", key: "base_spare_type_str", align: "center", minWidth: 100 },
        { title: "配件原值", key: "offermoney", align: "center", minWidth: 100 },
        {title: "外修商机构名称", key: "ogname", align: "center", minWidth: 150,},
        {title: "外修状态", key: "bstatus_str", align: "center", minWidth: 100,},
        { title: "整单状态", key: "obstatus_str", align: "center", minWidth: 100 },
        { title: "报价金额", key: "bjmoney", align: "center", minWidth: 100 },
        { title: "核价金额", key: "checkmoney", align: "center", minWidth: 100 },
        { title: "中标状态", key: "zbstatus", align: "center", minWidth: 100 },
        { title: "开标时间", key: "bidtime_str", align: "center", minWidth: 170 },
        {title: "外修商报价时间", key: "offertime_str", align: "center", minWidth: 170,},
        {title: "外修商取件时间", key: "qujtime_str", align: "center", minWidth: 170,},
        {title: "外修商还件时间", key: "hjtime_str", align: "center", minWidth: 170,},
        {title: "修复成功时间", key: "xiftime_str", align: "center", minWidth: 170,},
        {title: "订单类型", key: "quotetype_str", align: "center", minWidth: 170,},
        {title: "是否自动审核", key: "ishejia_str", align: "center", minWidth: 170,},
        {title: "审核人", key: "hejia_str", align: "center", minWidth: 170,},
        {title: "调价审核人", key: "tiaojiaren", align: "center", minWidth: 170,},
        {title: "报价时效", key: "offertime_ageing", align: "center", minWidth: 170,},
        {title: "开标时效", key: "bidtime_ageing", align: "center", minWidth: 170,},
        {title: "维修时效", key: "returntime_ageing", align: "center", minWidth: 170,}
      ],
      chooseorgInitOption: {
        title: "",
        showModal: false,
      },
      dateRange: {
        shortcuts: [
          {
            text: '今日',
            value() {
              const end = new Date();
              const start = new Date();
              return [start, end];
            }
          },
          {
            text: '本周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '本月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '本季度',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      exportLoading: false,
    };
  },
  methods: {
    clear() {
      this.search.ogid = "";
      this.search.repair_name = "";
    },
    clearBaoxian() {
      this.search.bx_ogid = "";
      this.search.baoxianname = "";
    },
    exportExcel () {
      this.exportLoading = true



      let params = JSON.parse(JSON.stringify(this.search));
      params.t = 20000;//导出最大条数
      getQingdanList(params).then((res) => {
        if (res.errCode == 0) {

          if (res.data.list.length) {
            const params = {
              title: ['订单发起时间', '保险公司', '归属地区', '定损员名称', '报案号', '车牌号', '车辆品牌', '车型', '配件单号', '取件修理厂',
                '接待人', '配件编码', '配件名称', '配件分类', '配件原值', '外修商机构名称', '外修商处理状态', '整单状态', '报价金额', '核价金额','中标状态',
                '开标时间', '外修商报价时间', '外修商取件时间', '外修商还件时间', '修复成功时间','订单类型','是否自动审核','审核人','调价审核人','报价时效','开标时效','维修时效'],
              key: ['createtime_str', 'baoxianname', 'belong_city_id_str', 'createname', 'reportno', 'carno', 'brand_name', 'model_name', 'ordersn',
                'repair_ogname', 'repair_contact', 'sparecode', 'sparename', 'base_spare_type_str', 'offermoney', 'ogname', 'bstatus_str',
                'obstatus_str', 'bjmoney', 'checkmoney', 'zbstatus','bidtime_str', 'offertime_str', 'qujtime_str', 'hjtime_str', 'xiftime_str','quotetype_str','ishejia_str','hejia_str','tiaojiaren','offertime_ageing','bidtime_ageing','returntime_ageing'],
              data: res.data.list,
              autoWidth: true,
              filename: '外修清单列表'
            }
            excel.export_array_to_excel(params)
            this.exportLoading = false
          } else {
            this.$Message.info('表格数据不能为空！')
            this.exportLoading = false
          }



        }
      });

    },
    // 日期
    checkDate(date) {
      this.search.stime = date[0];
      this.search.etime = date[1];
    },
    tableSelectionChange(data2) {
      if (data2[0] != undefined) {
        this.checkedId = data2[0].id;
      }
      let ids = [];
      data2.map(function(item, key) {
        ids.push(item.id);
      });
      this.checkedIds = ids;
    },
    //选择机构输入框
    repair_org() {
      this.chooseorgInitOption.title = "选择外修商";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.repair_business;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    //选择机构
    // getChooseGY(item) {
    //   // console.log(item);
    //   this.chooseorgInitOption.showModal = false;
    //
    //   this.search.bx_ogid = item.id;
    //   this.search.baoxianname = item.name;
    // },

    getChooseGY(item) {

      this.chooseorgInitOption.showModal = false;

      if (item.ogtype == 2) {
        this.search.ogid = item.id;
        this.search.repair_name = item.name;
      } else if (item.ogtype == 3) {
        this.search.bx_ogid = item.id;
        this.search.baoxianname = item.name;
      }
    },

    // 分页
    handlePage(value) {
      this.search.p = value;
      this.handleSearch();
    },
    handlePageSize(value) {
      this.search.t = value;
      this.handleSearch();
    },
    // 列表
    handleSearch() {
      let params = this.search;
      getQingdanList(params).then((res) => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.dataQingdan = res.data.list;
          // this.button = res.data.button
        }
      });
    },
    // 查询
    handleSearchAction() {
      this.search.p = 1;
      this.search.is_export = 0;
      this.handleSearch();
    },
  },
  mounted() {
    this.handleSearch();
  }
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.dizhiselecls {
  width: 342px;
  margin-right: 3px;
}

.map {
  width: 100%;
  height: 400px;
}

.map .search {
  margin-bottom: 65px;
}

.userfnpt {
  width: 300px;
  margin-right: 3%;
  display: inline-block;
}
</style>

<template>

  <div class="home_class"  v-if="!this.roleData.is_agent">

    <Card class="margin_t" :bordered="false" dis-hover>
      <p class="card_titp">运营情况</p>
      <div class="card_padd">

        <div class="yuny_ca color_red">
          <!-- 路由跳转   -->
          <img class="echar_imgcls" src="../../assets/images/echartslogo/6.png" alt="">
          <div  @click="routertoname('/shop/shop_order_manage',1)">
            <span>合计</span>
            <p>{{subData.total.count_total}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',2)">
            <span>待完善工单</span>
            <p>{{subData.total.count_dws}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',3)">
            <span>已审核工单</span>
            <p>{{subData.total.count_ysh}}</p>
          </div>
        </div>


        <div class="yuny_ca echar_margin_ri color_fdd">
          <!-- 路由跳转   -->
          <img class="echar_imgcls" src="../../assets/images/echartslogo/9.png" alt="">
          <div  @click="routertoname('/shop/shop_order_manage',1)">
            <span>外修单合计</span>
            <p>{{subData.wx.count_total}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',2)">
            <span>外修单待完善工单</span>
            <p>{{subData.wx.count_dws}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',3)">
            <span>外修单已审核工单</span>
            <p>{{subData.wx.count_ysh}}</p>
          </div>
        </div>





        <div class="yuny_ca color_fdd" >
          <img class="echar_imgcls" src="../../assets/images/echartslogo/9.png" alt="">
          <div  @click="routertoname('/shop/shop_order_manage',1)">
            <span>回收单合计</span>
            <p>{{subData.hs.count_total}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',2)">
            <span>回收单待完善工单</span>
            <p>{{subData.hs.count_dws}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',3)">
            <span>回收单已审核工单</span>
            <p>{{subData.hs.count_ysh}}</p>
          </div>
        </div>



        <div class="yuny_ca echar_margin_ri color_fdd">
          <!-- 路由跳转   -->
          <img class="echar_imgcls" src="../../assets/images/echartslogo/9.png" alt="">
          <div  @click="routertoname('/shop/shop_order_manage',1)">
            <span>物损单合计</span>
            <p>{{subData.ws.count_total}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',2)">
            <span>物损单待完善工单</span>
            <p>{{subData.ws.count_dws}}</p>
          </div>
          <div  @click="routertoname('/shop/shop_order_manage',3)">
            <span>物损单已审核工单</span>
            <p>{{subData.ws.count_ysh}}</p>
          </div>
        </div>






      </div>
    </Card>



    <!--<div class="flex_container">-->
      <!--<Card class="card_le_ri_inblock  margin_t" :bordered="false" dis-hover>-->
        <!--<p class="card_titp">工单概况</p>-->
        <!--<div class="card_padd">-->
          <!--<div id="chart_exampleshouye" class="dingdanzk" style="width:100%;height:500px;"></div>-->
        <!--</div>-->
      <!--</Card>-->
    <!--</div>-->



  </div>

 <!--<div class="home_class"  v-else>-->
 <!---->
    <!--<Card class="margin_t" :bordered="false" dis-hover>-->
      <!--<p class="card_titp">运营情况</p>-->
      <!--<div class="card_padd">-->
        <!--<div class="yuny_ca echar_margin_ri color_red">-->
          <!--<img class="echar_imgcls" src="../../assets/images/echartslogo/6.png" alt="">-->
          <!--<div>-->
            <!--<span>订货待发货</span>-->

            <!--<p>{{subData.operation_situation.shipped_order_store}}</p>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>订货待收货</span>-->
            <!--<p>{{subData.operation_situation.shou_order_store}}</p>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>订货合计</span> -->
            <!--<p>本月：{{subData.operation_situation.order_store_inventory.curr}}</p>-->
            <!--<span>上月：{{subData.operation_situation.order_store_inventory.prev}}</span>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="yuny_ca color_red">-->
          <!--<img class="echar_imgcls" src="../../assets/images/echartslogo/7.png" alt="">-->
          <!--<div>-->
            <!--<span>区域营业额</span>-->
            <!--<p>今日：{{subData.operation_situation.today_turnover.today}}</p>-->
            <!--<span>昨日：{{subData.operation_situation.today_turnover.yesterday}}</span>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>区域支付订单</span>-->
            <!--<p>今日：{{subData.operation_situation.today_pay.today}}</p>-->
            <!--<span>昨日支付订单：{{subData.operation_situation.today_pay.yesterday}}</span>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>区域支付人数</span>-->
            <!--<p>今日：{{subData.operation_situation.pay_user.today}}</p>-->
            <!--<span>昨日支付人数：{{subData.operation_situation.pay_user.yesterday}}</span>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="yuny_ca echar_margin_ri color_ger">-->
          <!--<img class="echar_imgcls" src="../../assets/images/echartslogo/10.png" alt="">-->
          <!--<div>-->
            <!--<span>区域新增会员</span>-->
            <!--<p>今日：{{subData.operation_situation.new_member.today}}</p>-->
            <!--<span>昨日：{{subData.operation_situation.new_member.yesterday}}</span>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>区域今日活跃用户</span>-->
            <!--<p>{{subData.operation_situation.active_users.today}}</p>&lt;!&ndash; 今日：-->
         <!--&lt;!&ndash;    <span>昨日：{{subData.operation_situation.active_users.yesterday}}</span> &ndash;&gt;-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>区域总会员数</span>-->
            <!--<p>{{subData.operation_situation.total_membership}}</p>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="yuny_ca color_ger">-->
          <!--<img class="echar_imgcls" src="../../assets/images/echartslogo/11.png" alt="">-->
          <!--<div>-->
            <!--<span>出售中商品</span>-->
            <!--<p>{{subData.operation_situation.goods_on_sale}}</p>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>仓库中商品</span>-->
            <!--<p>{{subData.operation_situation.warehouse_early_warning}}</p>-->
          <!--</div>-->
          <!--<div>-->
            <!--<span>商品预警</span>-->
            <!--<p>{{subData.operation_situation.goods_early_warning}}</p>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</Card>-->
    <!--<div class="flex_container">-->
      <!--<Card class="card_le_ri_inblock  margin_t" :bordered="false" dis-hover>-->
        <!--<p class="card_titp">订单概况</p>-->
        <!--<div class="card_padd">-->
          <!--<div id="chart_exampleshouye" class="dingdanzk" style="width:100%;height:500px;"></div>-->
        <!--</div>-->
      <!--</Card>-->
    <!--</div>-->
  <!--</div>-->




</template>

<script>

  import { getUser } from '@/libs/util'
  import { getHomeStatistics } from '@/api/admin'
  // import echarts from 'echarts'
  // import chinas from './echartsditu'
  import echarts from 'echarts'

  export default {
    data () {
      return {
        roleData:[],
        isdaili:true,
        subData:{

        },
      }
    },
    methods: {
      routertoname(router,type){
        // console.log(router,type)
        // this.$router.push({
        //   path:router,
        //   query:{
        //     type:type
        //   }
        // })
      },
      handleSearch () {
        let params = this.search
        getHomeStatistics(params).then(res => {
            // console.log(res.data);
          if (res.errCode == 0) {
            this.subData = res.data

          }
        })
      },

      // daryuan () {
      //   const myChartyuan = echarts.init(
      //     document.getElementById('chart_exampleshouye')
      //   )
      //   const option = {
      //     title: {
      //       text: '近七日自运营订单走势',
      //       textStyle: {
      //         fontWeight: 400,
      //         fontSize: 15,
      //       },
      //       left: 'center',
      //       top: '20'
      //     },
      //     tooltip: {
      //       trigger: 'axis',
      //       position: function (p) { //其中p为当前鼠标的位置
      //         return [p[0] + 10, p[1] - 10]
      //       },
      //       extraCssText: 'width:160px;'
      //     },
      //     legend: this.subData.order_trend.legend,
      //     grid: {
      //       left: '3%',
      //       right: '4%',
      //       bottom: '3%',
      //       containLabel: true
      //     },
      //     toolbox: {
      //       feature: {
      //         saveAsImage: {}
      //       }
      //     },
      //     xAxis: this.subData.order_trend.xAxis,
      //     yAxis: {
      //       type: 'value'
      //     },
      //     series:this.subData.order_trend.series,
      //    /* series: [
      //       {
      //         name: '订单量',
      //         type: 'line',
      //         stack: '总量',
      //         data: [120, 132, 101, 134, 90, 230, 210]
      //       },
      //       {
      //         name: '付款订单',
      //         type: 'line',
      //         stack: '总量',
      //         data: [220, 182, 191, 234, 290, 330, 310]
      //       },
      //       {
      //         name: '售后订单',
      //         type: 'line',
      //         stack: '总量',
      //         data: [150, 232, 201, 154, 190, 330, 410]
      //       }
      //     ]*/
      //   }
      //   myChartyuan.setOption(option)
      // }
    },
    mounted () {
      this.handleSearch()
      this.roleData = getUser()
    }
  }
</script>
<style lang="less">
  @import "./login.less";

  .home_class {
    overflow: hidden;

    .card_titp {
      font-weight: 700;
    }

    .flex_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .margin_ri {
        margin-right: 1%;
      }

      .card_le_ri_inblock {
        width: 100%;
        display: inline-block;
      }
    }

    //所有盒子上边距
    .margin_t {
      margin-top: 1%;
    }

    //所有盒子最外成的div
    .card_padd {
      padding: 15px 5px 5px 5px;
    }

    //快捷入口
    .kuaijie {
      display: inline-block;
      width: 18%;
      padding: 8px;
      border-radius: 4px;
      // border:1px solid red;
      line-height: 45px;
      margin: 0 10px 10px 0;

      .kuaijie_imgcls {
        width: 60px;
        height: 60px;
        // border-radius: 10px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    .kuaijie:hover {
      background: #f5f8fe;
      box-shadow: 0 0 1px #f5f8fe;
    }
  }

  //运营情况
  .echar_margin_ri {
    margin-right: 1%;
    margin-bottom: 10px;
  }

  .color_red {
    div {
      p {
        color: #ff5b60;
      }
    }
  }

  .color_fdd {
    div {
      p {
        color: #4087fd;
      }
    }
  }

  .color_ger {
    div {
      p {
        color: #3db471;
      }
    }
  }

  .yuny_ca {
    padding: 20px 0 20px 60px;
    display: inline-block;
    background: #f5f8fe;
    width: 49.5%;
    position: relative;
    /*cursor: pointer;*/
    .echar_imgcls {
      float: left;
      width: 60px;
      height: 60px;
      border-radius: 40px;
      position: absolute;
      left: 2px;
    }

    div {
      display: inline-block;
      padding-left: 10px;
      width: 30%;
      vertical-align: text-top;
      font-size: 12px;
      height: 63px;

      span {
        color: #999;
        display: block;
      }

      p {
        position: relative;
        // color: #ff5b60;
        font-weight: 700;
        font-size: 18px;
        /*cursor: pointer;*/
        display: inline-block;
      }

      p:before {
        content: " ";
        // width: 1px;
        height: 30px;
        border: 1px solid #ddd;
        position: absolute;
        left: -5px;
        top: -5px;
      }
    }
  }

  //弹性盒子 左右布局
  .flex-containerecharts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  //转化概况
  .zhuancls {
    position: relative;

    .flexdiv_left {
      width: 100%;

      ul {
        width: 78%;
        display: block;
        height: 95px;

        li {
          display: inline-block;
          padding: 27px;

          p {
            color: orangered;
            text-align: center;
          }
        }
      }
    }

    .flexdiv_left ul:nth-child(1) {
      background: #f2f6fd;
    }

    .flexdiv_left ul:nth-child(2) {
      background: #eff8f0;
      margin-top: 7px;
    }

    .flexdiv_left ul:nth-child(3) {
      background: #fdf7ed;
      margin-top: 8px;
    }

    .back_img {
      background: url("../../assets/images/left-chart.png") no-repeat;
      position: absolute;
      right: 20px;
      top: 0;
      width: 180px;
      height: 300px;
    }

    .lvclscol {
      position: absolute;

      p {
        color: orangered;
        text-align: center;
      }
    }

    .flexdiv_right {
      // position: relative;
      .item_lv1 {
        top: 90px;
        left: 123px;
      }

      .item_lv2 {
        top: 190px;
        left: 110px;
      }

      .item_lv3 {
        top: 270px;
        left: 90px;
      }
    }
  }

  .dingdanzk div:nth-child(1) {
    width: 100% !important;

    canvas {
      width: 100% !important;
    }
  }
</style>

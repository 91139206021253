<template>
  <div>
    <Card class="box-card oder_dingcls">
      <p slot="title">{{titledesc}}配件详情</p>
      <Form :label-width="120">
        <Row>
          <Col span="8">
            <FormItem label="报案号">
              <Input disabled v-model="orderdel.reportno" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="配件单号">
              <Input disabled v-model="orderdel.ordersn +'-'+subData.itemno"/>
            </FormItem>
          </Col>
          <Col span="8"
            ><FormItem label="车辆VIN"
              ><Input disabled v-model="orderdel.vin" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="4">
            <FormItem label="询价模式"><Input disabled v-model="orderdel.quotetype_str" /></FormItem>
          </Col>
          <Col span="4">
              <FormItem label="车辆品牌"><Input disabled v-model="orderdel.brand_name" /></FormItem>
          </Col>
          <Col span="8">
              <FormItem label="车型"><Input disabled v-model="orderdel.model_name" /></FormItem>
          </Col>
          <Col span="8">
              <FormItem label="车牌号"><Input disabled v-model="orderdel.carno" /></FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
              <FormItem label="定损员名称"><Input disabled v-model="orderdel.createname" /></FormItem>
          </Col>
          <Col span="8">
              <FormItem label="定损员电话"><Input disabled v-model="orderdel.createphone" /></FormItem>
          </Col>
          <Col span="8">
              <FormItem label="保险公司"><Input disabled v-model="orderdel.baoxianname" /></FormItem>
          </Col>
        </Row>
		<Row>
    <Col span="8">
      <FormItem label="所在地区"><Input disabled v-model="orderdel.carno_pro" /></FormItem>
    </Col>
      <Col span="8">
              <FormItem label="修理厂"><Input disabled v-model="orderdel.repair_ogname" /></FormItem>
      </Col>
      <Col span="8">
              <FormItem label="接待人"><Input disabled v-model="orderdel.repair_contact" /></FormItem>
      </Col>

		</Row>
		<Row>
            <Col span="8">
              <FormItem label="接待人电话"><Input disabled v-model="orderdel.repair_contact_phone" /></FormItem>
            </Col>
            <Col span="8">
              <FormItem label="配件分类"><Input disabled v-model="subData.base_spare_type_name" /></FormItem>
            </Col>
            <Col span="8">
              <FormItem label="配件名称"><Input disabled v-model="subData.sparename" /></FormItem>
            </Col>
            <!--<Col span="8">-->
              <!--<FormItem label="铭牌照片">-->
                  <!--<Button icon="md-image" @click='seevin(orderdel.a_driver_license)'>查看图片</Button>-->
              <!--</FormItem>-->
            <!--</Col>-->
            <Col span="8">
                <FormItem label="配件照片"><Button icon="md-image" @click='seepartimg(subData.pic)'>查看图片</Button>
                </FormItem>
            </Col>
            <Col span="8">
                <FormItem label="取件照片">
                    <Button icon="md-image" @click='seequjianimg(subData.qujian_pic.pic)'>{{showimgtitle(subData.qujian_pic.pic)}}</Button>
                </FormItem>
            </Col>
            <Col span="8">
                <FormItem label="还件照片">
                    <Button icon="md-image" @click='seehuanjianimg(subData.huanjian_pic.pic)'>{{showimgtitle(subData.huanjian_pic.pic)}}</Button>
                </FormItem>
            </Col>

		</Row>

      </Form>
    </Card>
    <Card>
      <Form :label-width="120">
        <FormItem label="损失概述">
          <Input disabled v-model="orderdel.remark" type="textarea" />
        </FormItem>
      </Form>
    </Card>
    <Card>
      <Form :label-width="120" v-if="subData.modify_price_status>0">
        <FormItem label="申请调价信息">
          <Input disabled :value="'外修商申请调价 调价金额'+subData.modifymoney+'元 调价原因：'+subData.modify_reason" type="textarea" />
          <viewer :images="showimages" style="display: inline-block;" >
            <img v-for="image in subData.tiaoj_pic.pic" width="180" height="180" :src="image"  />
          </viewer>
        </FormItem>
      </Form>
    </Card>
   <Card>
      <Table border stripe :columns="canshu" :data="subData.order_business_list"></Table>
    </Card>

    <Card >
      <p slot="title">操作日志</p>
      <Timeline>
        <TimelineItem v-for="item in subData.order_log">
          <p style="font-size: 18px" class="time">{{ item.createname }}</p>
          <p style="font-size: 18px" class="content">{{ item.content }}</p>
          <p style="font-size: 18px" class="content">{{ item.createtime }}</p>
        </TimelineItem>
      </Timeline>
    </Card>
    <Card>
      <Row>
        <Col :span="8" offset="8">
            <Button class="marginbnt" type="primary" @click="routefun">返回</Button>
            <Button class="marginbnt" type="success" :disabled="showbid"  @click="clickshowbid">中标</Button>
            <Button class="marginbnt" type="warning" :disabled="checkitme"  @click="clickcheckitme">审核</Button>
            <Button class="marginbnt" :disabled="disableChoose" @click="chooseBusiness">重选外修商</Button>
        </Col>
      </Row>
    </Card>
<div>
   <Modal v-model="showimg" width="40%" :title="editTitle"  style='text-align: center;'>
  <viewer :images="showimages" style="display: inline-block;" >
               <img v-for="(src,index) in showimages" :src="src" :key="index" style="width: 180px;height: 180px;" >
   </viewer>
    <div slot="footer">
      <Button @click="showimg = false">关闭</Button>
    </div>
   </Modal>

</div>
      <Modal v-model="ischoosebid" width="55%" title="选择中标外修商">
          <Table height="500" border stripe :columns="bidcanshu" :data="bidbuscanshudata"></Table>
          <div slot="footer"><Button @click="ischoosebid = false">取消</Button></div>
      </Modal>

      <Modal v-model="showcheckmodal" width="55%" title="核价">
          <Form :label-width="160" ref="checkdata" :model="checkdata" :rules="checkdataRules">
              <FormItem class="FromItemnpt" label="审核价格" prop="checkmoney" required>
                  <Input type="number" v-model="checkdata.checkmoney" placeholder="请输入审核金额" style='width: 200px;' />
              </FormItem>
              <Table height="200" :columns="columns1" :data="data2"></Table>
              <FormItem class="FromItemnpt" label="审核备注" style="margin-top:10px">
                  <textarea style="width:400px" v-model="checkdata.reason" placeholder="执行退回定损员操作时，请输入审核备注"></textarea>
              </FormItem>
          </Form>
          <div slot="footer">
              <Button @click="showcheckmodal = false">取消</Button>
              <Button @click="backOrder">退回定损员</Button>
              <Button type="primary" @click="submitCheckdata">确定</Button>
          </div>
      </Modal>
      <Modal v-model="showcheckordermodal" width="55%" title="核单">
        <Form :label-width="160" ref="checkdata" :model="checkdata" :rules="checkdataRules">
          <FormItem class="FromItemnpt" label="外修金额" prop="checkmoney" required>
            <Input type="number" v-model="checkdata.busoffermoney"   style='width: 200px;' />
          </FormItem>
          <div>
            <FormItem class="FromItemnpt" label="审核备注" style="margin-top:10px">
              <textarea style="width:400px" v-model="checkdata.reason" placeholder="请输入审核备注"></textarea>
            </FormItem>
          </div>
        </Form>
        <div slot="footer">
          <Button @click="showcheckordermodal = false">取消</Button>
          <Button @click="backOrder">退回定损员</Button>
          <Button type="primary" @click="submitCheckdata(2)">通过</Button>
        </div>
      </Modal>
      <Modal v-model="showEdit" width="40%" :title="yjTitle">
        <Form :label-width="70" ref="subData">
          <FormItem class="FromItemnpt" label="期望金额">
            <Input clearable placeholder="请输入期望金额" v-model="tjMoney"/>
          </FormItem>
          <Row>
            <FormItem class="FromItemnpt org-width" style="width:90%" label="审核备注">
              <textarea style="width:100%" v-model="yjRemark"></textarea>
            </FormItem>
          </Row>
        </Form>
        <div slot="footer">
          <Button @click="showEdit = false">取消</Button>
          <Button type="primary" @click="dicker2">保存</Button>
        </div>
      </Modal>
      <WxBusiness :showchoosemodal="showChoose" :itemId="$route.query.id" @son="son"></WxBusiness>
  </div>
</template>
<script>
import { orderitemview,getitembusinesslist,bidorderitem,checkbid,waixiuCheck,waixiutuihui,zhongbiaoinfo } from "@/api/admin";
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
import WxBusiness from '@/components/business/WxBusiness.vue'
export default {
  components:{
    WxBusiness
  },
  data() {
      const validatecheckmoney = (rule, value, callback) => {
          if (this.checkdata.checkmoney < 0) {
              callback(new Error('请输入正整数'));
          } else {
              callback();
          }
      };
    return {
      columns1: [
				{
					title: 'ID',
					key: 'id'
				},
        {
					title: '操作员',
					key: 'bjname'
				},
				{
					title: '机构名称',
					key: 'orgname'
				},
				{
					title: '报价金额',
					key: 'offermoney'
				},
				{
					title: '修复概率',
					key: 'probability'
				},
				{
					title: '报价备注',
					key: 'remark'
				}
			],
			data2: [],
      //调价
      yjTitle:'',
      yjId: 0,
      yjRemark:'',
      yjType:1,
      showEdit: false,
      tjMoney: '',

      disableChoose: false,
      showChoose: false,
      searchdata:{
        p: 1, // 当前页码
        t: 10, // 每页显示条数
        sparename: '',
        stime: '',
        etime: '',
        reportn:'',
        ordersn:'',
        bsstatus:'',
        recoverstatus:''
      },
      backurl:'',
      showbid:false,
      checkitme:false,
      id: "",
      grade: false,
      showimg:false,
      editTitle:'',
      showimages: [],
      orderdel:[],
      canshu: [
    {
      title: "ID",
      key: "id",
      align: "center",
      minWidth: 100,
      fixed: "left",
    },
    {
      title: "机构名称",
      key: "ogname",
      align: "center",
      minWidth: 100,
      fixed: "left",
    },
		{
      title: "状态",
      key: "status",
      align: "center",
      minWidth: 100,
		},
    {
      title: "报价备注",
      key: "remark",
      align: "center",
      minWidth: 100,
    },
    {
      title: "修复概率",
      key: "probability",
      align: "center",
      minWidth: 100,
    },
    { title: "报价金额", key: "offermoney", align: "center", minWidth: 130 },
		{
			title: "核价金额",
			key: "showmodifymoney",
			align: "center",
			minWidth: 100,
		},
    {
      title: '操作',
      key: 'action',
      width: 200,
      align: 'center',
      fixed: 'right',
      render: (h, params) => {
        // console.log(params.row);
        return h('div', [
          h(
            'Button',
            {
              props: {
                type: 'success',
                size: 'small',
                disabled: params.row.modify_price_status == 1 ? false : true
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.dicker(params.row.order_item_id,params.row.modifymoney, 2);
                }
              }
            },
            '调价通过'
          ),
          h(
            'Button',
            {
              props: {
                type: 'warning',
                size: 'small',
                disabled: params.row.modify_price_status == 1 ? false : true
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.dicker(params.row.order_item_id,params.row.modifymoney, 3);
                }
              }
            },
            '调价不通过'
          )
        ])
      }
    }
      ],
    itemdel:[],
      subData: {
        id: "",
        caseno: "", //报案号
        vin: "", //vin
        brand_name: "", //车品牌
        model_name: "", //车型
        carno: "", //车牌号
        createname: "", //定损员
        createphone: "", //定损员电话
        remark: "", //损失概述
        a_driver_license: "", //铭牌照片地址
        item_data: [
          {
            id: "",
            type_str: "",
            sparename: "11111",
            status_recover_str: "",
            orgialPhotoUrl: "原始照片url",
            takePhotoUrl: "取件url",
            returnPhotoUrl: "还件rul",
          },
        ],
      },
        titledesc:'',
        order_item_id: '',
        bstatus: '',
        ischoosebid: false,
        bidcanshu: [
            { title: 'ID', key: 'id', align: 'center', minWidth: 100, fixed: 'left' },
            { title: '外修商名称', key: 'ogname', align: 'center', minWidth: 130 },
            { title: '状态', key: 'bstatus_str', align: 'center', minWidth: 100 },
            { title: '报价金额', key: 'offermoney', align: 'center', minWidth: 80 },
            {
                title: '操作',
                key: 'action',
                width: 200,
                align: 'center',
                fixed: 'right',
                render: (h, params) => {
                    // console.log(params.row);
                    return h('div', [
                        h(
                            'Button',
                            {
                                props: {
                                    type: 'success',
                                    size: 'small',
                                    disabled: this.showbidselect(params.row.bstatus)
                                },
                                style: {
                                    marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                        this.bidbusiness(params.row.id, params.row.order_item_id, params.row.bstatus);
                                    }
                                }
                            },
                            '选择'
                        )
                    ]);
                }
            }
        ],
        bidbuscanshudata: [],
        showcheckmodal:false,//审核弹窗
        showcheckordermodal:false,//审核弹窗
        checkdata:{},//审核弹窗
        checkdataRules:{
            checkmoney: [
                { validator: validatecheckmoney, trigger: 'blur' }
            ],
        },
        checkmoney: '',
    };
  },
  methods: {
    //退回定损员
		backOrder() {
			// 保存
      if (!this.checkdata.reason) {
        this.$Modal.error({ title: '提示', content: '请输入审核备注' });
        return
      }
			var that=this
			waixiutuihui({id:this.order_item_id,reason:this.checkdata.reason}).then(res => {
				if (res.errCode == 0) {
					that.showcheckmodal = false;
          that.showcheckordermodal = false;
					that.viewData()
					that.$Modal.success({ title: '提示', content: '退回成功' });
				} else {
					that.$Modal.error({ title: '提示', content: res.errMsg });
				}
			});
		},
      //中标
      clickshowbid() {
          var that = this;
          let pa = {
              order_item_id: this.order_item_id,
              bstatus: this.bstatus
          };
          getitembusinesslist(pa).then(res => {
              that.ischoosebid = true;
              that.bidbuscanshudata = res.data;
          });
      },
      //弹窗-选择
      showbidselect(bstatus) {
          if (bstatus == Enum.repair_bussiness_status.pending_bid) return false;
          else return true;
      },
      bidbusiness(id, itemid, bstatus) {
          var that = this;
          let pa = {
              order_item_id: itemid,
              bstatus: Enum.repair_bussiness_status.uncheck_quote,
              id: id
          };
          bidorderitem(pa).then(res => {
              if (res.errCode == 0) {
                  that.$Notice.success({
                      title: '提示',
                      desc: '操作成功'
                  });
                  that.ischoosebid = false;
                  // this.showbid=true;
                  this.viewData();
              }
          });
      },
      //审核按钮
      clickcheckitme(){
          if(this.bstatus == Enum.repair_bussiness_status.uncheck_order){
            this.showcheckordermodal=true
            this.checkdata.busoffermoney=this.busoffermoney
          }else{
            this.showcheckmodal=true
            this.checkdata.checkmoney=this.checkmoney
            this.getZbInfo(this.subData.bid_order_business_id)
          }
      },
      //获取中标商信息
      getZbInfo(id){
        this.data2 = []
        let params = {};
        params.id = id;
        zhongbiaoinfo(params).then(res=>{
          this.data2.push(res.data)
        })
      },
      chooseBusiness(){
        this.showChoose = true
      },
      //审核保存
      submitCheckdata(type=1) {
          // alert(this.order_item_id);

          var that=this
          this.$refs.checkdata.validate(valid => {
              // console.log('valid',valid);
              if (valid) {
                  let params = {
                      bstatus:Enum.repair_bussiness_status.pending_pick,
                      id:this.order_item_id,
                      checkmoney:that.checkdata.checkmoney,
                      reason:that.checkdata.reason
                  };
                  if (type==2) {
                    params.checkmoney = that.checkdata.busoffermoney
                  }
                  checkbid(params).then(res => {
                      if (res.errCode == 0) {
                          that.showcheckmodal = false;
                          that.showcheckordermodal = false;
                          // that.handleSearch();
                          this.viewData();
                          that.$Modal.success({ title: '提示', content: '审核成功' });
                          // this.checkitme=true;

                      } else {
                          that.$Modal.error({ title: '提示', content: res.errMsg });
                      }
                  });
              }
          });
      },
      routefun () {
          this.$router.push({
              name: this.backurl,
              query:this.searchdata
          })
      },
      showimgtitle(val){
        if(tools.isVisible(val)){
          return '查看照片'
        }else{
          return '无'
        }
      },
    getSendOut() {
      let query = { id: this.id, type: "order" };
      this.$router.push({
        name: "order_goods_fahuo_details",
        query: query,
      });
    },

   seevin(val){
      if(val){
        this.showimg=true;
        this.editTitle='铭牌照片';
        let arr1=[];
        arr1[0]=this.orderdel.a_driver_license;
        this.showimages=arr1
      }
   },
   toimgarr(val){
    if(tools.isVisible(val)){
      this.showimg=true;
      let arr1=[];
      arr1=val;
      this.showimages=arr1
    }
   },
   seepartimg(val){
      this.editTitle='配件照片';
      this.toimgarr(val);
   },
   seequjianimg(val){
    this.editTitle='取件照片';
    this.toimgarr(val);
   },
   seehuanjianimg(val){
    this.editTitle='还件照片';
    this.toimgarr(val)
   },
    viewData() {
      // alert( this.id );
      let params = { id: this.id };
      orderitemview(params).then((res) => {
        if (res.errCode == 0) {
        let resdata= res.data;
            if (resdata.bstatus == Enum.repair_bussiness_status.pending_bid){
                this.showbid=false;
            } else {
                this.showbid=true;
            }

            if (((resdata.bstatus == Enum.repair_bussiness_status.uncheck_quote )||(resdata.bstatus == Enum.repair_bussiness_status.uncheck_order)) && (resdata.roleType == 1)){
                this.checkitme=false;
            } else {
                this.checkitme=true;
            }

            this.disableChoose = (resdata.bstatus == Enum.repair_bussiness_status.unable_fix || resdata.bstatus == Enum.repair_bussiness_status.unable_pick || resdata.bstatus == Enum.repair_bussiness_status.timeout_quote || resdata.bstatus == Enum.repair_bussiness_status.pick_before_unrepair || resdata.bstatus == Enum.repair_bussiness_status.pick_after_unrepair) ? false : true
            this.order_item_id=resdata.id;//中标按钮
            this.bstatus=resdata.bstatus;//中标按钮
            this.checkmoney=resdata.bidmoney;//审核按钮
            this.subData=resdata;
            this.orderdel=resdata.order_detail;
            this.orderdel.remark = '工单备注：' + (this.orderdel.remark ? this.orderdel.remark : '无') + '\r\n配件备注：'+(resdata.remark ? resdata.remark : '无')
            this.busoffermoney=resdata.busoffermoney;//审核按钮

          resdata.order_business_list.forEach((u)=>{
              if(this.type=='extra'){
                u.status=u.bstatus_str;
              }else if(this.type=='recover'){
                u.status=u.status_recover_str;
              }
            })
        }
      });
    },
    son(data) {
      this.showChoose = data
    },
    //调价审核
    dicker(id,money,type){
      this.showEdit = true
      this.yjId = id
      this.yjType = type
      this.tjMoney = money
      if(type == 2){
        this.yjTitle = '调价审核通过'
      }else{
        this.yjTitle = '调价审核不通过'
      }
    },
    dicker2(){
      let pa = {
        id: this.yjId,
        modifymoney: this.tjMoney,
        bxremark:this.yjRemark,
        pricetype: this.yjType
      };

      waixiuCheck(pa).then(res => {
        if (res.errCode == 0) {
          this.$Message.info('审核完成');
          this.showEdit = false
          this.viewData()
        } else {
          this.$Message.error(res.errMsg);
        }
      });
    },
  },
  mounted() {
    // console.log('mounted',this.$route.query);
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.searchdata.p = this.$route.query.p;
    this.searchdata.t = this.$route.query.t;
    this.searchdata.sparename = this.$route.query.sparename;
    this.searchdata.stime = this.$route.query.stime;
    this.searchdata.etime = this.$route.query.etime;
    this.searchdata.reportn = this.$route.query.reportn;
    this.searchdata.ordersn = this.$route.query.ordersn;
    this.searchdata.reportno2 = this.$route.query.reportno2;
    this.searchdata.bsstatus = this.$route.query.bsstatus;
    this.searchdata.recoverstatus = this.$route.query.recoverstatus;
    if(this.type=='extra'){
      this.titledesc='外修'
      this.backurl = 'extra_fitting_manage'
    }else if(this.type=='recover'){
      this.titledesc='回收'
      this.backurl = 'recover_fitting_manage'

    }
    this.viewData();
  },
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";
.casenoItemnpt {
  width: 500px;
  display: inline-block;
  margin-right: 3%;
  white-space: nowrap;
}
// ------------------------
  .wytoubu {
    margin: 10px 0;
    background-color: #f5f5f5;
    border: 1px solid #e8e8e8;
    text-align: center;
  }

  .wytoubu th {
    padding: 10px 0;
    font-weight: 400;
    font-size: 12px;
  }

  /*th宽度*/
  .widthdu1 {
    width: 21%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu2 {
    width: 4%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu3 {
    width: 15%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu6 {
    width: 15%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu4 {
    width: 9%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu5 {
    width: 9%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu7 {
    width: 4%;
    line-height: 16px;
    font-size: 12px;
  }

  .zhuneitongpad .widthdu1,
  .widthdu2,
  .widthdu3,
  .widthdu4,
  .widthdu5,
  .widthdu6,
  .widthdu7 {
    padding: 10px 5px 10px 5px;
  }

  .widthdu1 .lefts {
    width: 80px;
    display: inline-block;
  }

  .widthdu1 .lefts img {
    width: 80px;
    height: 80px;
  }

  .widthdu1 .rights {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    margin: 6px 0 0 5px;
  }

  .widthdu1 .rights p {
    width: 80%;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .hoverred:hover {
    color: red;
    cursor: pointer;
  }

  .widthdu1 .rights span {
    color: #9e9e9e;
  }

  .xianbor {
    border-left: 1px solid #daf3ff;
  }

  /* 公用 */
  .bought-table-mod__table___3u4gN {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    color: #3c3c3c;
    table-layout: fixed;
  }

  /* 全选一行样式 */
  .shaixuan {
    height: 30px;
    margin: 10px 0;
    margin-top: 60px;
  }

  .shaixuan span {
    margin-right: 30px;
  }

  .shaixuan .anniutz {
    padding: 4px;
  }

  .shaixuan .florigh {
    float: right;
  }

  .bordertbal {
    border: 1px solid #daf3ff;
    margin: 10px 0;
  }

  /* teconttbody */
  .teconttbody {
    background: #eaf8ff;
  }

  .tecont td {
    border: none;
  }

  .tecont .padd {
    padding: 10px 0px;
  }

  .padd b {
    margin-right: 10px;
    margin-left: 3px;
  }

  .teconttbody .widthdu4 {
    text-align: center;
  }

  .centes {
    text-align: center;
  }

  .centes p {
    text-align: center;
    line-height: 20px;
  }

  .centes span {
    color: #9e9e9e;
    display: block;
    margin-top: 5px;
  }

  .centes .reds {
    color: red;
  }

  .nptss {
    padding: 5px;
    width: 237px;
  }

  .shctr2 .bnt {
    width: 60px;
    padding: 5px 2px;
    margin-left: -4px;
  }

  .shctr2 {
    float: right;
    font-size: 12px;
    width: 300px;
    color: #6b6464;
    position: relative;
    top: 8px;
    z-index: 3;
  }

  /* .shctl{position:relative;} */
  .el-tabs__content {
    overflow: visible;
  }

  .tempspan {
    /*表格里面的字*/
    width: 240px;
    display: inline-block;
  }

  .fenyedi {
    height: 40px;
  }

  .widthdu2 p {
    text-align: center;
    height: 64px;
    line-height: 64px;
  }

  .widthdu7 p {
    text-align: center;
    height: 64px;
    line-height: 64px;
  }

  .yanse {
    color: rgb(37, 147, 252);
  }

  .widthdu_div_margin {
    //   margin-bottom: 10px;
  }

  .widthdu_div_margin span:nth-child(3) {
    color: #74bcff;
    margin-left: 5px;
  }
</style>

<template>
  <div class="myApp">
    <Row class="my-row">
      <div class="col-rightN">
        <Card class="col-right-card">
          <p slot="title">登录日志</p>
          <Form :label-width="80">
            <div>
           
              <FormItem class="FromItemnpt" label="登录时间：" prop="username">
                <DatePicker
                  style="width:180px;"
                  type="daterange"
                  :options="dateRange"
                  @on-change="checkDate"
                ></DatePicker>
              </FormItem>
              <Button type="primary" @click="handleSearchAction">查询</Button>
            </div>
          </Form>
          <div class="gray-nona"></div>
          <Table stripe border
                 height="520"
                 :columns="columns"
                 :data="data1"
          ></Table>

          <div class="my-page">
            <Page
              :total="totalCount"
              :current="search.p"
              @on-change="handleChangePage"
              :page-size="parseInt(search.t)"
            ></Page>
            <span class="pageSelect">
              每页显示
              <Select
                class="ivu-select-selection-1"
                v-model="search.t"
                @on-change="handleChangePageLimit"
                :label="search.t"
              >
                <Option value="10">10</Option>
                <Option value="20" v-show="totalCount > 10">20</Option>
                <Option value="30" v-show="totalCount > 20">30</Option>
                <Option value="40" v-show="totalCount > 30">40</Option>
                <Option value="50" v-show="totalCount > 40">50</Option>
              </Select>
            </span>
          </div>
        </Card>
      </div>
    </Row>
  </div>
</template>


<script>
  import {getLoginLog} from '@/api/backend'
  export default {
    data () {
      return {
        totalCount: 10, // 总条数
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          action_id: '215'
        },
        data1: [],
       
        columns: [
          // 表头
          { title: '用户名', align: 'center', key: 'user_name', minWidth: 100 },
          { title: '姓名', align: 'center', key: 'real_name', minWidth: 100 },
          { title: '用户类型', align: 'center', key: 'user_type', minWidth: 130 },
          { title: '性别', align: 'center', key: 'sex', minWidth: 100 },
          { title: '成功登录', align: 'center', key: 'status', minWidth: 100 },
          { title: 'IP区域', align: 'center', key: 'ip_area', minWidth: 150 },
          { title: 'IP地址', align: 'center', key: 'ip', minWidth: 150 },
          { title: '登录端', align: 'center', key: 'device_type', minWidth: 150 },
          { title: '登录时间', align: 'center', key: 'createtime', minWidth: 150 }
        ],
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        }
      }
    },
    methods: {

      checkDate (date) {
        // 操作时间
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      handleSearch () {
        let params = this.search
        getLoginLog(params).then(res => {
          this.totalCount = parseInt(res.data.total)
          this.data1 = res.data.list
        })
      },

      handleSearchAction () {
        this.search.p = 1
        this.handleSearch()
      },
      handleChangePage (data1) {
        // 切换页码
        this.search.p = data1
        this.handleSearch()
      },
      handleChangePageLimit () {
        // 切换每页显示条数
        this.search.p = 1
        this.handleSearch()
      },
    },
    mounted () {
      this.handleSearch()
    },
    watch: {}
  }
</script>


<style lang="less">
  @import "../style2.less";
</style>


import * as  axios from '@/libs/api.request';
// 配件详情
export const orderitemview = (params) => {
  return axios.post('backend/orderitem-view-forzl', params)
};
// 审核
export const checkbid = (params) => {
    return axios.post('backend/order-business-shen-he-forzl', params)
};
//外修-核价处展示中标商
export const zhongbiaoinfo = (params) => {
  return axios.post('backend/zb-info-forzl', params)
};
//外修配件管理
export const getorderitemlist = (params) => {
  return axios.post('backend/orderitem-list-forzl', params)
};
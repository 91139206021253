<template>
	<Card class="col-right-card min-height">
		<p slot="title">{{pagetitle}}批量物损区域项目匹配{{areatype==1?"增加":"取消"}}
		</p>
		<Form  ref="ogdata" :model="ogdata" :rules="dataRules">
			<Row class='og_row'>
				<FormItem class="FromItemnpt  org-width" :label="pagetitle" prop="real_name" >	
					<Input disabled v-model="ogdata.real_name"   />	
				</FormItem>	
				 <!-- v-show="showorg" -->
				<FormItem class="FromItemnpt  org-width insurancediv" label="保险公司">	
					<Input v-model="insurancedata.name" :readonly=true>
					  <Icon type="ios-search" slot="suffix" @click="chooseGy_org"/>
					</Input>	
				</FormItem>

				<FormItem class="FromItemnpt" label="询价模式">
			        <Select v-model="curmodetype" clearable>
			          <Option
			            v-for="(item, index) in modetype"
			            :value="index"
			            :key="index"
			            >{{ item }}</Option
			          >
			        </Select>
      			</FormItem>
				<Button type="primary" @click="save">保存</Button>
			</Row>
				
		
		</Form>
		<Modal v-model="slemodal" title="选择保险公司" width="900">
			<Form :label-width="80">
				<FormItem class="FromItemnpt" label="名称">
					<Input v-model="search.kw" clearable />
					</FormItem>
				<Button type="primary" @click="handleSearch">搜索</Button>
			
			</Form>
	
	  
	 <Table
	 	height="500"
	 	border
	 	stripe
	 	:columns="canshu"
	 	:data="canshudata"
	 
	 ></Table>
	 
	 <div class="my-page">
	 	<Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
	 	<div style="height:30px;"></div>
	 </div>
	  
	
			<div slot="footer"></div>
		</Modal>


		<div class="tree_view" v-if="orgareadata.length>0">
				<Card>
					<div slot="title" class="title_p">
					
					</div>
					<div class="p_item">
					<Tree :data="orgareadata" show-checkbox  @on-check-change='checkchang'  multiple></Tree>
					</div>
				</Card>

			<div v-for="pitem in partsList" class="p_item">
				<Card>
					<div slot="title" class="title_p">
					
					</div>
					<div class="tree_select">
						<Tree :data="pitem.ptreedata" show-checkbox multiple @on-check-change='checkchang'></Tree>
						</div>
				</Card>
			</div>



		</div>




		<div></div>
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
import config from '@/config';
import { getorglist, getorhDrop, getOrgEdit, getorgdetail, addorg, getQueryWscatTree ,addorgcat,queryarea,addUserAreaCatws} from '@/api/admin';
import { getWsModeType } from "@/api/dictionary";


export default {
	data() {
		return {
			areatype:1,
			ogdata: {},
			partsList: [],
			canshudata:[],
			slemodal:false,
			totalCount:0,
			curmodetype:0,
			type:null,
			titlespec:null,
			search: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			},
			modetype:[],
			pagetitle:'外修商',
			orgareadata:[],
			canshu: [
				
				{
					title: '机构编号',
					key: 'code',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
				{
					title: '机构名称',
					key: 'name',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
			
				{
					title: '主营',
					key: 'main_camp',
					align: 'center',
					minWidth: 100
				},
				{
					title: '所在省市区',
					key: 'area_name',
					align: 'center',
					minWidth: 150
				},
				{
					title: '所在地址',
					key: 'address',
					align: 'center',
					minWidth: 100
				},
			
			
				{
					title: '状态',
					key: 'status_str',
					align: 'center',
					minWidth: 100
				},
				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
									
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.handldselectModal(params.row);
										}
									}
								},
								'选择'
							)
						]);
					}
				}
			],
			dataRules: {
				real_name: [
					{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}
				]
			},
			ptreedata: [],
			isEdit:false,
			insurancedata:[],
			insuranid:0,
			showorg:1,
			matchmodal: true
		};
	},
	methods: {
		
		
		getUser(){
				console.log('getUser',util.getUser());
				let curuser=util.getUser();
				if(curuser.ogid==0){

					console.log('curuser.ogid',curuser.ogid);
					this.showorg=1;
				}else{
					// this.insuranid=curuser.ogid;
					// this.showorg=0;
					// if(this.insuranid)
					// this.handmatcharea();
				}
		},

	    getWsModeType() {
	      getWsModeType().then((res) => {
	        this.modetype = res.data;
	      });
	    },

		save(){
			var that = this;
			
			this.$refs.ogdata.validate(valid => {
					

				if (valid) {
					let add_acts=[];
					let pmdata=that.orgareadata[0].children;

                    // console.log(pmdata);

					pmdata.forEach((pitem,$one) => {
						let tdata=pitem.children;

						tdata.forEach((titem,$two) => {

                            let three=titem.children;

                            three.forEach(threetitem => {


                                if(threetitem.checked){

                                    // alert(pmdata[$one].bx_district_name);

                                    let pare={


                                        bx_province_id:pmdata[$one].bx_district_id,
                                        bx_province_name:pmdata[$one].bx_district_name,

                                        bx_city_id:tdata[$two].bx_district_id,
                                        bx_city_name:tdata[$two].bx_district_name,

                                        bx_district_id:threetitem.bx_district_id,
                                        bx_district_name:threetitem.bx_district_name,

                                    }
                                    add_acts.push(pare)
                                }
							})

						})
					})

					let add_actstype=[];
					that.partsList.forEach(pitem => {
						let tdata=pitem.ptreedata[0].children;
						tdata.forEach(titem => {
							if(titem.checked){
								let pare={
			
									spare_type:titem.id,
								}
								add_actstype.push(pare)
							}
						})
					})


					let typefor=2;
					if(that.type=='extra'){
						typefor=2;
					}else if(that.type=='recover'){
						typefor=4;
					}else if(that.type=='wusun'){
						typefor=5;
					}
					let params = {
						add_acts:add_acts,
						add_actstype:add_actstype,
						bx_ogid:that.insuranid,
						uid:that.ogdata.id,
						modetype:that.curmodetype,
						type:typefor,//2外修  4回收  5物损
						areatype:this.areatype
					};


					// console.log('addorgareas params',params);


					addUserAreaCatws(params).then(res => {
						// console.log('addorgcat',res);
						if (res.errCode == 0) {

							that.$Modal.success({
								title: '提示',
								content: '保存成功'
							});
							this.$router.go(-1)
						} else {
							that.$Modal.error({
								title: '提示',
								content: res.errMsg
							});
						}
					});
				}
			});
		},
		checkchang(arr,val){
			// console.log('111',arr,val);
			if(val.checked){
				this.a
			}else{
				
			}
		},
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		
		handleSearch(){
			let params = this.search;
		getorglist(params).then(res => {
			if (res.errCode == 0) {
				this.totalCount = parseInt(res.data.total);
				this.canshudata = res.data.list;
			}
		});	
		},
		chooseGy_org(){
			this.slemodal=true
			this.handleSearch();
		  
		},
		handldselectModal(val){
			this.slemodal=false;
			console.log('handldselectModal',val);
			this.insurancedata=val;
			this.insuranid=val.id;
			
			this.matcharea(),
			this.matchparts();
		},

		handmatcharea(){
		
		
			let params={
				bx_ogid:this.insuranid,
				uid:this.ogdata.id,
			}
			this.matcharea(params)
		},

		 calleArr(array){
            array.forEach((item, index) => {
                if (item.child) {


                    item.checked = item.status === 1 ? 1 : 0
                    item.title = item.name
                    item.children = item.child

					  item.bx_district_id  = item.id
					  item.bx_district_name = item.name
										 
                    this.calleArr(item.child) //自己调用自己



                } else {
					  item.bx_district_id  = item.id
					  item.bx_district_name = item.name
                    item.checked = item.status === 1 ? 1 : 0
                    item.title = item.name
                 }
           })
   			return array
        },


        matchparts() {
			this.matchmodal = true;
			const msg = this.$Message.loading({
				content: '加载中...',
				dutration: 0
			});

			getQueryWscatTree().then(res => {
				console.log('getpartsorglist', res);
				if (res.errCode === 0) {
					this.partsList = res.data.list;
					this.partsList.forEach(pitem => {
						// debugger
						pitem.ptreedata=[];
						let p={
							title:'全部',
							expand: true,
							children:[],
						};
						pitem.data.forEach(item1 => {
						
							
							let ch = false;
							if (item1.status == 0) {
								ch = false;
							} else {
								ch = true;
							}
							let cl = {
								checked: ch,
								id: item1.id,
								title: item1.name,
							
							};

							p.children.push(cl);
						});
						console.log('-----p---------',p);
						pitem.ptreedata.push(p)
					});
					
					setTimeout(msg, 0);
				}
			});
		},

		matcharea() {


			this.matchmodal = true;
			const msg = this.$Message.loading({
				content: '加载中...',
				dutration: 0
			});

			queryarea().then(res => {
         if (res.errCode === 0) {
          let resdata = res.data;
          // resdata[0].child[0].child[0].status = 1
          // console.log(res.data)
           this.orgareadata = [{
            title:'全部',
            expand: true,
            children: this.calleArr(resdata),
           }]
          //  console.log(this.orgareadata)
          setTimeout(msg, 0);
         }
   });

		}
	
	},
	mounted() {
			let querydata=this.$route.query;
			this.isEdit=true;
			this.areatype=this.$route.query.areatype;
			this.type=this.$route.query.type;
			this.ogdata = querydata.ogdata;
			this.pagetitle=querydata.title;
			this.getWsModeType();
		
			this.getUser();
			// this.matchparts(this.ogdata);
		
		
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';
.insurancediv{
	margin-left: 60px;
}
.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}
.og_row{
	button{
		margin-left: 100px;
	}
}
.map .search {
	margin-bottom: 65px;
}
.org-width {
	width: 320px !important;
}
.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
.right_title {
	line-height: 20px;
	font-size: 16px;
	color: #17233d;
}
.tree_view {
	width: 100%;
	display: flex;
	.p_item {
		width: 20%;
		.title_p{
			color: #f57d22;
		}
		
		.tree_select{
			overflow: scroll;
			height: 600px;
		}
	}
}
</style>

<template>
  <Card class="col-right-card min-height">
    <p slot="title">留言管理</p>
    <Form :label-width="120">
      <FormItem class="FromItemnpt" label="工单号">
        <Input clearable placeholder="请输工单号" v-model="search.ordersn"/>
      </FormItem>
      <FormItem class="FromItemnpt" label="添加时间">
        <DatePicker
          style="width:180px;"
          type="daterange"
          :options="dateRange"
          @on-change="checkDate"
          v-model="timeshijian"
        ></DatePicker>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
    </Form>
    <div class="gray-nona"></div>
    <Button class="marginbnt"   v-show="button.add" @click="handleCreateModel" type="primary">新增</Button>
    
    <Button class="marginbnt"   v-show="button.delete" @click="handleEdit(1)" type="primary">删除</Button>
    <!--<Button class="marginbnt"   v-show="button.enable" @click="handleEdit(2)" type="primary">启用</Button>-->
    <!--<Button class="marginbnt"   v-show="button.disabled" @click="handleEdit(3)" type="primary">禁用</Button>-->
<!-- 
    <Button class="marginbnt"   v-show="button.edit" @click="handleGrade" type="primary">批量设置等级</Button> -->

    <Table height="500" border stripe :columns="canshu" :data="canshudata"
           @on-select="tableSelectionChange"
           @on-select-all="tableSelectionChange"
           @on-select-cancel="tableSelectionChange"></Table>

    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height:30px;"></div>
    </div>


	<Modal v-model="showMsgreturn" width="55%" title="回复留言">
			<Form :label-width="160" ref="msgdata" :model="msgdata" :rules="msgrules">
			
				<FormItem class="FromItemnpt" label="留言内容：" prop="content1">
				<Input v-model="msgdata.content" clearable />
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="showMsgreturn = false">取消</Button>
				<Button type="primary" @click="huifu">确定</Button>
			</div>
		</Modal>



      <Modal v-model="showEdit" width="55%" :title="editTitle">
          <Form
                  :label-width="120"
                  ref="subData"
                  :model="subData"
                  :rules="dataRules">

              <FormItem class="FromItemnpt" label="工单号" prop="ordersn">
                  <Input v-model="subData.ordersn" @blur.native.capture="onChange"/></FormItem>

              <FormItem class="FromItemnpt" label="工单配件" prop="order_business_id">
                  <Select v-model="subData.order_business_id">
                      <Option
                              v-for="(item, index) in peijianarr"
                              :value="item.bid_order_business_id"
                              :key="item.bid_order_business_id"
                      >{{ item.sparename }}</Option
                      >
                  </Select>
              </FormItem>

              <FormItem label="留言内容" prop="content"
              ><Input type="textarea" v-model="subData.content"
              /></FormItem>

          </Form>
          <div slot="footer">
              <Button @click="showEdit = false">取消</Button>
              <Button type="primary" @click="submitForm">保存</Button>
          </div>
      </Modal>

 




  </Card>
</template>
<script>

  import { getToken } from '@/libs/util'
  import config from '@/config'
  import cityView from '../mycomponents/cityView.vue'
   import * as tools from '@/libs/tools';
  import { getMessageList,addmessage,getOrderDropDown,addOrderBusinessmessage,getMessageEdit } from '@/api/admin';

  // import { getCustomer, getCustomerView, getCustomerAdd, getCustomerEdit ,getCustomerDetail} from '@/api/backend'
  // import { getMemberLevelDrop } from '@/api/common'
  // import { getUserStatus,getWhether } from '@/api/dictionary'
  export default {
    components: {
      cityView,
    },
    data () {

      return {


        handleData: {
          type: '',
          data: ''
        },
       
    
        dataRules: {
            ordersn: [
            { required: true, message: '必填', trigger: 'blur' }
          ],
            order_business_id: [
                { required: true, message: '必填', trigger: 'blur' }
            ],
            content: [
                { required: true, message: '必填', trigger: 'blur' }
            ],


        },
		msgrules:{
            content1: [
		       { required: true, message: '必填', trigger: 'blur' }
			],
			
		},
		msgdata:{
			content:''
		},
        checkedIds: [],
        showMsgreturn:false,
        uploadHttp: '',
        access_token: '',
        defaultList: [],
        userInfoType: [],
        detailData:{},
        subData: {
            ident: 1,
            content: '',
            ordersn: '',
            order_business_id: '',
            order_id: '',
    },
        hyse: false,
        types: '',
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
            ordersn: '',
        },
        timeshijian:[],
        button: {},
          peijianarr: [],

          editTitle: '',
          showEdit: false,

        memberLevelDrop: [], // 会员等级
        totalCount: 10,
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        },
		mdata:null,
        canshu: [
          { type: 'selection', width: 60, align: 'center' ,fixed:'left'},
          { title: 'ID', key: 'id', align: 'center' ,minWidth:100,fixed:'left'},
          { title: '工单号', key: 'ordersn', align: 'center'  ,minWidth:130},
          { title: '提交单位', key: 'ogname', align: 'center'  ,minWidth:100},
          { title: '提交时间', key: 'createtime', align: 'center' ,minWidth:80},
          { title: '留言内容', key: 'content', align: 'center' ,minWidth:80},
          { title: '提交人', key: 'createname', align: 'center',minWidth:100},
          { title: '订单状态', key: 'order_status_str', align: 'center' ,minWidth:100},
          {
            title: '操作',
            key: 'action',
            width: 180,
            align: 'center',
            fixed:'right',
            render: (h, params) => {
              return h('div', [
                h(
                  'Button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small',
                      disabled:!this.button.edit
                    },
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        this.handleUpdateModal(params.row)
                      }
                    }
                  },
                  '回复'
                )
              ])
            }
          }
        ],
        canshudata: [],
      }
    },
    methods: {
        onChange() {
            let params = {};
            params.ordersn = this.subData.ordersn;
            getOrderDropDown(params).then((res) => {
                // console.log(res.data[0].order_id);
                this.peijianarr = res.data;
                if (res.data[0].order_id) {
                    this.subData.order_id = res.data[0].order_id;
                }
            });
        },
        getUserInfoType () { // 
            getWhether().then(res => {
              this.userInfoType = res.data
            })
          },
      tableSelectionChange (data2) {
        if (data2[0] != undefined) {
          this.checkedId = data2[0].id
        }
        let ids = []
        data2.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },
      handleEdit (type) {
        if (this.checkedIds.length > 0) {
          let params = {}
          params.id = this.checkedIds
          params.type = type
          if (type == 1) {
            this.$Modal.confirm({
              title: '确认信息',
              content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
              onOk: () => {
                
                this.sysEdit(params)
              },
              onCancel: () => {
                this.$Message.info('您取消了删除操作')
              }
            })
          } else {

            this.sysEdit(params)
          }
          
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },
      huifu(){
		  var that=this;
		  // console.log('huifu',this.msgdata);
	  		that.$refs.msgdata.validate(valid => {
				let params={
					ident: 1, //1定损员2外修商
					order_id: that.mdata.order_id,
					order_business_id: that.mdata.order_business_id,
					order_status: that.mdata.order_status,
					content: that.msgdata.content
				}

				// console.log(params);
				addmessage(params).then(res => {
					 if(res.errCode == 0){
						 that.msgdata.content=null
						 that.mdata=null
						 that.showMsgreturn=false
						 this.handleSearch()
						 that.$Modal.info({ title: '提示', content: '发送成功' })
					 }else{
						 	 that.$Modal.info({ title: '提示', content: '发送失败' })
					 }




				})
			})
	  
	  },


      handleGrade () {// 批量设置等级
        if (this.checkedIds.length > 0) {
          this.hyse = true
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },

      handleSubmit (type) {
        let params = {}
        params.id = this.checkedIds
        params.type = type
        params.data = this.handleData.levelData
        this.sysEdit(params)
      },

      sysEdit (params) {
          getMessageEdit(params).then(res => {
          if (res.errCode == 0) {
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
            this.fzse = false
            this.hyse = false
            this.checkedIds = []
            this.handleSearch()
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      },
     
      selectFunc (cityObj) {
        this.subData.province = cityObj.provinceModel
        this.subData.city = cityObj.cityModel
        this.subData.district = cityObj.districtModel
      },
      selectFunc1 (cityObj) {
        this.search.province = cityObj.provinceModel
        this.search.city = cityObj.cityModel
        this.search.district = cityObj.districtModel
      },

      // getUserStatus () {
      //   getUserStatus().then(res => {
      //     this.userStatus = res.data
      //   })
      // },
      getMemberLevelDrop () {
        let params = { type: '1' }
        getMemberLevelDrop(params).then(res => {
          this.memberLevelDrop = res.data
        })
      },
 
      
      // 日期
      checkDate (date) {
        this.search.stime = date[0]
        this.search.etime = date[1]
      },
      // 查询
      handleSearchAction () {
        this.search.p = 1
        this.search.is_export = 0
        this.handleSearch()
      },
      // 分页
      handlePage (value) {
        this.search.p = value
        this.handleSearch()
      },
      handlePageSize (value) {
        this.search.t = value
        this.handleSearch()
      },
      // 列表
      handleSearch () {
          let params = this.search
          params.action_id='424';
          getMessageList(params).then(res => {
          if (res.errCode == 0) {
            this.totalCount = parseInt(res.data.total)
            this.canshudata = res.data.list
            this.button = res.data.button
          }
        })
      },
      reset () {
        this.$refs.subData.resetFields()
      },
      // 新增
      handleCreateModel () {
        //this.reset()
        this.subData = {}
          this.subData.order_business_id = '';
          this.subData.ident = 1;
          this.peijianarr= [];
        this.showEdit = true;
        this.editTitle = '留言新增';


      },

      handleUpdateModal (data) {
          this.msgdata.content='';
        this.mdata=data;
        this.showMsgreturn = true;
      },

      // handleDetailModal(data) {
      //   getCustomerDetail({uid:data}).then(res => {
      //     this.detailData = res.data
      //     this.gradeDetail = true 
      //   })
      // },



      // viewData (uid) {
      //   getCustomerView({uid:uid}).then(res => {
      //     this.subData = res.data
      //     let that = this
      //     setTimeout(function () {
      //       that.$refs.cityView.viewdata.provinceModel = res.data.province
      //       that.$refs.cityView.viewdata.cityModel = res.data.city
      //       that.$refs.cityView.viewdata.districtModel = res.data.district
      //     }, 150)
      //   })
      // },

      submitForm () {   // 保存
        this.$refs.subData.validate((valid) => {
          if (valid) {
            let params = this.subData;
              // console.log(params);
              addOrderBusinessmessage(params).then(res => {
              if (res.errCode == 0) {
                this.showEdit = false
                this.handleSearch()
                this.reset()
                this.$Modal.success({ title: '提示', content: '提交成功' })
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },
      handleError (data1, data2, data3) { // error file fileList
        this.$Modal.error({ title: '提示', content: '上传失败' })
      },
      handleFormatError (data1, data2, data3) { // file fileList
        this.$Modal.error({ title: '提示', content: '文件格式不正确' })
      },
      handleMaxSize (data1, data2) { // file fileList
        this.$Modal.error({ title: '提示', content: '文件太大' })
      },
      handleSuccess (data1, data2, data3) { // response file fileList    图标
        if (data1.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '上传成功'
          })
          this.defaultList = []
          this.subData.avatar = data1.data[0].pic // 图片上传地址
          this.subData.url = data1.data[0].url // 图片域名地址
        } else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      },
      getDuration (date) {    
      // how many days of this month   
       let dt = new Date()    
       var month = dt.getMonth()  
       dt.setMonth(dt.getMonth() + 1)   
       dt.setDate(0);  
       return dt.getDate()  
     },
      GetDate(is){
        var now = new Date();
        var year = now.getFullYear();      　　 //年
        var month = now.getMonth() + 1;     //月
        // var day = now.getDate();            　　//日
        if(is){
          return year +'-'+ month +'-' + is;
        }else{
          return year +'-'+ month +'-1';
        }
      }
     
    },
    mounted () {
      switch(this.$route.query.type){
        case 1:
              var mun = this.getDuration();
              this.search.status = '1'
              this.search.stime = this.GetDate(false)
              this.search.etime = this.GetDate(false)
              this.timeshijian = [this.GetDate(false) , this.GetDate(false)]
              break;
        case 2:
              
              break;
      }
      // this.getUserStatus()
      // this.getMemberLevelDrop()
      // this.getUserInfoType()
      this.handleSearch()
      this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro
      this.access_token = getToken()
    }
  }
</script>


<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";

  .dizhiselecls {
    width: 342px;
    margin-right: 3px;
  }
</style>

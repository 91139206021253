<template>
  <div class="myApp">
    <Row class="my-row">
      <div class="col-rightN">
        <Card class="col-right-card">
          <p slot="title">客户反馈信息</p>
          <Form :label-width="80">
            <div>
              <FormItem class="FromItemnpt" label="状态">
                <Select v-model="search.status" clearable>
                  <Option v-for="(item,index) in userBackStatus" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
              <FormItem class="FromItemnpt" label="添加时间：" prop="username">
                <DatePicker
                  style="width:180px;"
                  type="daterange"
                  :options="dateRange"
                  @on-change="checkDate"
                ></DatePicker>
              </FormItem>
              <Button type="primary" @click="handleSearchAction">查询</Button>
            </div>
          </Form>
          <div class="gray-nona"></div>
          <div class="tablebnt">
            <Button type="primary" @click="handleUpdateModal" v-show="button.edit">修改</Button>
            <Modal v-model="newModel" :title="titleData" width="600" draggable>
              <div>
                <Form :label-width="100" ref="subData" :model="subData" :rules="rules">
                  <FormItem label="客户姓名">
                    <Input v-model="subData.createname" readonly/>
                  </FormItem>
                  <FormItem label="反馈时间">
                    <Input v-model="subData.createtime" readonly/>
                  </FormItem>
                  <FormItem label="反馈内容">
                    <Input type="textarea" v-model="subData.content" readonly/>
                  </FormItem>

                  <FormItem label="处理状态">
                    <Input v-model="subData.status_str" readonly/>
                  </FormItem>
                   <!-- <FormItem label="处理人">
                    <Input v-model="subData.processname"/>
                  </FormItem>
                  <FormItem label="处理时间">
                    <Input v-model="subData.processtime"/>
                  </FormItem> -->
                  <FormItem label="处理意见" prop="proc_result">
                    <Input type="textarea" v-model="subData.proc_result"/>
                  </FormItem>
                </Form>
              </div>
              <div slot="footer">
                <Button @click="newModel = false">取消</Button>
                <Button type="primary" v-show="subData.button.dispose" @click="getSubmitForm">保存</Button>
              </div>
            </Modal>
          </div>
          <Table stripe border
                 height="520"
                 :columns="columns"
                 :data="data1"
                 @on-select="handleCheckedOrDeleteTable"
                 @on-select-all="handleCheckedOrDeleteTable"
                 @on-select-cancel="handleCheckedOrDeleteTable"
          ></Table>

          <div class="my-page">
            <Page
              :total="totalCount"
              :current="search.p"
              @on-change="handleChangePage"
              :page-size="parseInt(search.t)"
            ></Page>
            <span class="pageSelect">
              每页显示
              <Select
                class="ivu-select-selection-1"
                v-model="search.t"
                @on-change="handleChangePageLimit"
                :label="search.t"
              >
                <Option value="10">10</Option>
                <Option value="20" v-show="totalCount > 10">20</Option>
                <Option value="30" v-show="totalCount > 20">30</Option>
                <Option value="40" v-show="totalCount > 30">40</Option>
                <Option value="50" v-show="totalCount > 40">50</Option>
              </Select>
            </span>
          </div>
        </Card>
      </div>
    </Row>
  </div>
</template>


<script>
  import { getThUserBack ,getThUserBackView ,getSubmitUserBackResult} from '@/api/backend'
  import { getUserBackStatus} from '@/api/dictionary'
  export default {
    data () {
      return {
        userBackStatus:[],
        titleData: '',
        rules: {
          proc_result: [{ required: true, message: '必填', trigger: 'blur' }],
        },

        newModel: false, // 新增模态框显示状态
        totalCount: 10, // 总条数
        button: {},
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          status: 0,
          action_id: '165'
        },
        data1: [],
        subData: {
          createname:'',
          createtime:'',
          content:'',
          status:'',
          proc_result:'',
          processname:'',
          processtime:'',
          button:{dispose:false},
        },
        columns: [
          // 表头
          { type: 'selection', align: 'center', width: 60 },
          { title: '客户姓名', align: 'center', key: 'createname', minWidth: 100 },
          { title: '反馈时间', align: 'center', key: 'createtime', minWidth: 130 },
          { title: '反馈内容', align: 'center', key: 'content', minWidth: 100 },
          { title: '处理状态', align: 'center', key: 'status_str', minWidth: 100 },
          { title: '处理意见', align: 'center', key: 'proc_result', minWidth: 100 },
          { title: '处理人', align: 'center', key: 'processname', minWidth: 150 },
          { title: '处理时间', align: 'center', key: 'processtime', minWidth: 150 }
        ],
        checkedIds: [],
        checkedId: '',
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        }
      }
    },
    methods: {
      // 反馈内容开始
      getUserBackStatus () {
        getUserBackStatus().then(res => {
          this.userBackStatus = res.data
        })
      },

      handleChangePage (data1) {
        // 切换页码
        this.search.p = data1
        this.handleSearch()
      },
      handleChangePageLimit () {
        // 切换每页显示条数
        this.search.p = 1
        this.handleSearch()
      },

      // 角色列表
      handleSearch () {
        let params = this.search
        getThUserBack(params).then(res => {
          this.totalCount = parseInt(res.data.total)
          this.data1 = res.data.list
          this.button = res.data.button
        })
      },

      handleSearchAction () {
        this.search.p = 1
        this.handleSearch()
      },


      handleUpdateModal () {
        this.reset()
        if (this.checkedIds.length == 1) {
          this.titleData = '处理反馈信息'
          this.handleView()
          this.newModel = true
        } else {
          this.$Modal.info({ title: '提示', content: '请选择一项' })
        }
      },

      handleView () {
        let params = {}
        params.id = this.checkedId
        getThUserBackView(params).then(res => {
          this.subData = res.data
        })
      },

      checkDate (date) {
        // 操作时间
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      handleCheckedOrDeleteTable (data1) {
        if (data1[0]) {
          this.checkedId = data1[0].id
        }
        let ids = []
        data1.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },

      reset () {
        this.$refs.subData.resetFields()
      },


      getSubmitForm () {
        this.$refs.subData.validate(valid => {
          if (valid) {
            let params = {}
            params.id = this.subData.id
            params.proc_result = this.subData.proc_result
            getSubmitUserBackResult(params).then(res => {
              if (res.errCode == 0) {
                if (this.subData.id) {
                  this.checkedIds = []
                }
                this.newModel = false
                this.handleSearch()
                this.$Modal.success({ title: '提示', content: res.errMsg })
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },

      // 反馈内容结束
    },
    mounted () {
      this.getUserBackStatus()
      this.handleSearch()
    },
  }
</script>


<style lang="less">
  @import "../style2.less";
</style>


<template>
    <Modal v-model="initOption.showModal" :title="initOption.title" width="50%" footer-hide>
      <Form :label-width="120">
        <FormItem class="FromItemnpt" label="关键词">
          <Input v-model="topForm.kw"/>
          <Button style="margin-left:5px;" type="primary" @click="handleSearchAction">搜索</Button>
        </FormItem>
      </Form>
      <div class="gray-nona"></div>
      <div>
        <Table
          stripe
          border
          height="500"
          :columns="gy_canshu"
          :data="gy_canshudata"
        ></Table>
      </div>
      <div class="my-page">
        <Page
          :total="pageTotal"
          show-elevator
          show-sizer
          show-total
          @on-change="handlePage"
          @on-page-size-change="handlePageSize"
        />
      </div>
    </Modal>
</template>
<script>
  import { getorglist } from '@/api/admin';
  // import * as Enum from '@/libs/enum';
export default {
  props: {
    initOption: {
      title: {
        type: String,
        default: '选择机构'
      },
      action: {
        type: String,
        default: 'add'
      },
      showModal: {
        type: Boolean
      },
      id: {
        type: String
      },
        ogtype: {
            type: String
        },
    }
  },
  data () {
    return {
      pageTotal: 10,
      topForm: {
        ogtype: '',
        p:1,
        t:10,
        kw:'',
      },

      gy_canshudata: [],
        gy_canshu: [
          { title: '机构编号', key: 'code', align: 'center', minWidth: 100 },
          { title: '机构名称', key: 'name', align: 'center', minWidth: 100 },
          { title: '联系人', key: 'manager', align: 'center', minWidth: 100 },
          { title: '联系电话', key: 'managerphone', align: 'center', minWidth: 100 },
          { title: '所在省市区', key: 'area_name', align: 'center', minWidth: 130 },
          {
            title: '操作',
            width: '100',
            align: 'center',
            fixed: 'right',
            render: (h, params) => {
              let vm = this
              return h('div', [
                h(
                  'Button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small'
                    },
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        this.chooseItem(params.row)
                      }
                    }
                  },

                  '选择'
                )
              ])
            }
          },
        ],
    }
  },
  methods: {
    chooseItem (val) {
        // console.log(val);
      this.$emit('getorgdata', val)
    },
    handleSearchAction() {
      this.topForm.p = 1
      this.handleSearch()
    },
    handlePage (value) {
      this.topForm.p = value
      this.handleSearch()
    },
    handlePageSize (value) {
      // this.pageSize = value
      this.topForm.t = value
      this.handleSearch()
    },

    // 列表
    handleSearch () {

      let params = this.topForm;
        params.ogtype=this.initOption.ogtype;
        getorglist(params).then(res => {
        if (res.errCode === 0) {
          this.pageTotal = parseInt(res.data.total)
          this.gy_canshudata = res.data.list
        }
      })
    }
  },
    mounted () {
        this.topForm.kw = ''
    },
    watch:{
        'initOption.showModal' () {
            if (!this.initOption.showModal) {
                this.topForm.kw = ''
            }
        }
    }
}
</script>
<style>
  .test{}
  .test .ivu-modal-content{height: 630px;}
  .test .ivu-modal-footer{margin-top: 25px;}

</style>

<template>
    <div class="main" id="increase">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        waixiudan_money:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getincrease()
    },
    getincrease(){
      let that = this
      var chartDom = document.getElementById('increase');
      var myChart = echarts.init(chartDom,{
        width:'100%',
        height:60
      });
      
    myChart.resize();
      var option;

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        title: {
          left:"center",
          top:"10%",
          text: '专修金额增长曲线',
          textStyle: {
            color: "#66FFFF",//Y轴内容文字颜色
            fontSize:12,
            fontWeight:"400",
            fontFamily:"MicrosoftYaHei"

          },

        },
        grid: {
            top: 40,
          left: "-1%",
          // right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月'],
            data: this.data1.date,
            axisTick: { show: true,
              alignWithLabel: true

            },
            boundaryGap:true,
            //  刻度
            axisTick: { show: true,length:3 , alignWithLabel:true,},
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "white",//Y轴内容文字颜色
                fontSize:8,

              },
            },
            splitLine:{
              show:true,
              lineStyle: {
                color: "#2C7DAD",
              },
            },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: true,
              lineStyle: {
                color: "#2C7DAD",
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return that.data1.date2[that.data1.date.indexOf(params.value)]
                }
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine:{
              show:false
            },
            axisTick: { show: false,length:3 },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: true,
              lineStyle: {
                color: "#2C7DAD",
              },
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              margin: 20,//刻度标签与轴线之间的距离。
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "white",//Y轴内容文字颜色
                fontSize:8,
                align: 'left',

                margin:[0,20,0,0],

              },
            },
          }
        ],
        visualMap: {
          type:'piecewise',
          show: false,
          dimension: 1,
          visualMap:0,
          pieces: [
            {
              lte: 149,
              color: '#0B5EFB'
            },
            {
              gt: 150,
              color: '#01F0EE'
            }
          ]
        },
        series: [
          {

            name: '外修金额',
            type: 'line',
            stack: 'Total',
            smooth: true,
            /* lineStyle: {
              width: 5,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: '#1C00FF'
                }
              ]),

            },
            emphasis: {
              focus: 'series'
            }, */
            // data: [140, 232, 101, 204, 90, 240, 250,44,75,205,88,240]
            data: this.data1.waixiudan_money
          },
          {

            name: '物损金额',
            type: 'line',
            stack: 'Total2',
            smooth: true,
            /* lineStyle: {
              width: 5,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: '#1C00FF'
                }
              ]),

            },
            emphasis: {
              focus: 'series'
            }, */
            // data: [140, 232, 101, 204, 90, 240, 250,44,75,205,88,240]
            data: this.data1.wusun_money
            }

        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getincrease()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

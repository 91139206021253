<template>
    <div class="page">
        <Card class="box-card oder_dingcls">
            <p slot="title">{{ titledesc }}配件详情</p>
            <Form :label-width="120">
                <Row>
                    <Col span="8">
                    <FormItem label="报案号">
                        <Input disabled v-model="orderdel.reportno" />
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="配件单号">
                        <Input disabled v-model="orderdel.ordersn + '-' + subData.itemno" />
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="车辆VIN"><Input disabled v-model="orderdel.vin" />
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="4">
                    <FormItem label="询价模式"><Input disabled v-model="orderdel.quotetype_str" /></FormItem>
                    </Col>
                    <Col span="4">
                    <FormItem label="车辆品牌"><Input disabled v-model="orderdel.brand_name" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="车型"><Input disabled v-model="orderdel.model_name" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="车牌号"><Input disabled v-model="orderdel.carno" /></FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="定损员名称"><Input disabled v-model="orderdel.createname" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="定损员电话"><Input disabled v-model="orderdel.createphone" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="保险公司"><Input disabled v-model="orderdel.baoxianname" /></FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="所在地区"><Input disabled v-model="orderdel.carno_pro" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="修理厂"><Input disabled v-model="orderdel.repair_ogname" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="接待人"><Input disabled v-model="orderdel.repair_contact" /></FormItem>
                    </Col>

                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="接待人电话"><Input disabled v-model="orderdel.repair_contact_phone" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="配件分类"><Input disabled v-model="subData.base_spare_type_name" /></FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="配件名称"><Input disabled v-model="subData.sparename" /></FormItem>
                    </Col>
                    <!--<Col span="8">-->
                    <!--<FormItem label="铭牌照片">-->
                    <!--<Button icon="md-image" @click='seevin(orderdel.a_driver_license)'>查看图片</Button>-->
                    <!--</FormItem>-->
                    <!--</Col>-->
                    <Col span="8">
                    <FormItem label="配件照片"><Button icon="md-image" @click='seepartimg(subData.pic)'>查看图片</Button>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="取件照片">
                        <Button icon="md-image" @click='seequjianimg(subData.qujian_pic.pic)'>{{
                            showimgtitle(subData.qujian_pic.pic) }}</Button>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="还件照片">
                        <Button icon="md-image" @click='seehuanjianimg(subData.huanjian_pic.pic)'>{{
                            showimgtitle(subData.huanjian_pic.pic) }}</Button>
                    </FormItem>
                    </Col>

                </Row>

            </Form>
        </Card>
        <Card>
            <Form :label-width="120">
                <FormItem label="损失概述">
                    <Input disabled v-model="orderdel.remark" type="textarea" />
                </FormItem>
            </Form>
        </Card>
        <Card>
            <Form :label-width="120" v-if="subData.modify_price_status > 0">
                <FormItem label="申请调价信息">
                    <Input disabled :value="'外修商申请调价 调价金额' + subData.modifymoney + '元 调价原因：' + subData.modify_reason"
                        type="textarea" />
                    <viewer :images="showimages" style="display: inline-block;">
                        <img v-for="image in subData.tiaoj_pic.pic" width="180" height="180" :src="image" />
                    </viewer>
                </FormItem>
            </Form>
        </Card>
        <Card>
            <Table border stripe :columns="canshu" :data="subData.order_business_list"></Table>
        </Card>

        <Card>
            <p slot="title">操作日志</p>
            <Timeline>
                <TimelineItem v-for="item in subData.order_log">
                    <p style="font-size: 16px" class="time">{{ item.createname }}</p>
                    <p style="font-size: 16px" class="content">{{ item.content }}</p>
                    <p style="font-size: 16px" class="content">{{ item.createtime }}</p>
                </TimelineItem>
            </Timeline>
        </Card>
        <Card>
            <Row class="btn-group">
                <Button class="marginbnt" type="primary" @click="routefun">返回</Button>
                <Button class="marginbnt" type="warning"
                    v-if="this.externalDatas.type == 1 && !this.subData.checkmoney && this.subData.bstatus !== 8"
                    @click="clickcheckitme">审核</Button>
            </Row>
        </Card>
        <div>
            <Modal v-model="showimg" width="40%" :title="editTitle" style='text-align: center;'>
                <viewer :images="showimages" style="display: inline-block;">
                    <img v-for="(src, index) in showimages" :src="src" :key="index" style="width: 180px;height: 180px;">
                </viewer>
                <div slot="footer">
                    <Button @click="showimg = false">关闭</Button>
                </div>
            </Modal>

        </div>


        <Modal v-model="showcheckmodal" width="800px" title="核价">
            <Form :label-width="160" ref="checkdata" :model="checkdata" :rules="checkdataRules">
                <FormItem class="FromItemnpt" label="审核价格" prop="checkmoney" required>
                    <Input type="number" v-model="checkdata.checkmoney" placeholder="请输入审核金额" style='width: 200px;' />
                </FormItem>
                <Table height="200" :columns="columns1" :data="data2"></Table>
                <FormItem class="FromItemnpt" label="审核备注" style="margin-top:10px">
                    <textarea style="width:400px" v-model="checkdata.reason"
                        placeholder="执行退回定损员操作时，请输入审核备注"></textarea>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="showcheckmodal = false">取消</Button>
                <Button type="primary" @click="submitCheckdata">确定</Button>
            </div>
        </Modal>
        <Modal v-model="showcheckordermodal" width="800px" title="核单">
            <Form :label-width="160" ref="checkdata" :model="checkdata" :rules="checkdataRules">
                <FormItem class="FromItemnpt" label="外修金额" prop="checkmoney" required>
                    <Input type="number" v-model="checkdata.busoffermoney" style='width: 200px;' />
                </FormItem>
                <div>
                    <FormItem class="FromItemnpt" label="审核备注" style="margin-top:10px">
                        <textarea style="width:400px" v-model="checkdata.reason" placeholder="请输入审核备注"></textarea>
                    </FormItem>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="showcheckordermodal = false">取消</Button>
                <Button type="primary" @click="submitCheckdata(2)">通过</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { orderitemview, checkbid, zhongbiaoinfo } from "@/api/login-free-api.js";
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
export default {
    components: {
    },
    data() {
        const validatecheckmoney = (rule, value, callback) => {
            if (this.checkdata.checkmoney < 0) {
                callback(new Error('请输入正整数'));
            } else {
                callback();
            }
        };
        return {
            columns1: [
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '操作员',
                    key: 'bjname'
                },
                {
                    title: '机构名称',
                    key: 'orgname'
                },
                {
                    title: '报价金额',
                    key: 'offermoney'
                },
                {
                    title: '修复概率',
                    key: 'probability'
                },
                {
                    title: '报价备注',
                    key: 'remark'
                }
            ],
            data2: [],
            searchdata: {
                p: 1, // 当前页码
                t: 10, // 每页显示条数
                sparename: '',
                stime: '',
                etime: '',
                reportn: '',
                ordersn: '',
                bsstatus: '',
                recoverstatus: ''
            },
            type: 'extra',
            backurl: 'extra_fitting_manage',
            checkitme: false,
            id: "",
            grade: false,
            showimg: false,
            editTitle: '',
            showimages: [],
            orderdel: [],
            canshu: [
                {
                    title: "ID",
                    key: "id",
                    align: "center",
                    minWidth: 100,
                    fixed: "left",
                },
                {
                    title: "机构名称",
                    key: "ogname",
                    align: "center",
                    minWidth: 100,
                    fixed: "left",
                },
                {
                    title: "状态",
                    key: "status",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "报价备注",
                    key: "remark",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "修复概率",
                    key: "probability",
                    align: "center",
                    minWidth: 100,
                },
                { title: "报价金额", key: "offermoney", align: "center", minWidth: 130 },
                {
                    title: "核价金额",
                    key: "showmodifymoney",
                    align: "center",
                    minWidth: 100,
                },
            ],
            subData: {
                id: "",
                caseno: "", //报案号
                vin: "", //vin
                brand_name: "", //车品牌
                model_name: "", //车型
                carno: "", //车牌号
                createname: "", //定损员
                createphone: "", //定损员电话
                remark: "", //损失概述
                a_driver_license: "", //铭牌照片地址
                item_data: [
                    {
                        id: "",
                        type_str: "",
                        sparename: "11111",
                        status_recover_str: "",
                        orgialPhotoUrl: "原始照片url",
                        takePhotoUrl: "取件url",
                        returnPhotoUrl: "还件rul",
                    },
                ],
            },
            titledesc: '外修',
            order_item_id: '',
            bstatus: '',
            bidbuscanshudata: [],
            showcheckmodal: false,//审核弹窗
            showcheckordermodal: false,//审核弹窗
            checkdata: {},//审核弹窗
            checkdataRules: {
                checkmoney: [
                    { validator: validatecheckmoney, trigger: 'blur' }
                ],
            },
            checkmoney: '',
            externalDatas: {}
        };
    },
    methods: {
        //弹窗-选择
        showbidselect(bstatus) {
            if (bstatus == Enum.repair_bussiness_status.pending_bid) return false;
            else return true;
        },

        //审核按钮
        clickcheckitme() {
            if (this.bstatus == Enum.repair_bussiness_status.uncheck_order) { // 核单
                this.showcheckordermodal = true
                this.checkdata.busoffermoney = this.busoffermoney
            } else { // 核价
                this.showcheckmodal = true
                this.checkdata.checkmoney = this.checkmoney
                this.getZbInfo(this.subData.bid_order_business_id)
            }
        },
        //获取中标商信息
        getZbInfo(id) {
            this.data2 = []
            let params = {};
            params.id = id;
            zhongbiaoinfo(params).then(res => {
                this.data2.push(res.data)
            })
        },
        //审核保存
        submitCheckdata(type = 1) {
            var that = this
            this.$refs.checkdata.validate(valid => {
                if (valid) {
                    let params = {
                        // bstatus: Enum.repair_bussiness_status.pending_pick,
                        bstatus: this.subData.bstatus,
                        id: this.order_item_id,
                        checkmoney: that.checkdata.checkmoney,
                        reason: that.checkdata.reason,
                        checkuser: this.externalDatas.checkuser,
                        checkname: this.externalDatas.checkname,
                        ogname: this.externalDatas.ogname,
                        type: this.externalDatas.type,
                    };
                    if (type == 2) {
                        params.checkmoney = that.checkdata.busoffermoney
                    }
                    checkbid(params).then(res => {
                        if (res.errCode == 0) {
                            that.showcheckmodal = false;
                            that.showcheckordermodal = false;
                            // that.handleSearch();
                            this.viewData();
                            that.$Modal.success({ title: '提示', content: '审核成功' });

                        } else {
                            that.$Modal.error({ title: '提示', content: res.errMsg });
                        }
                    });
                }
            });
        },
        routefun() {
            let query = {
                ...this.externalDatas
            };
            delete query.id;
            this.$router.push({
                path: '/bussiness/accessory',
                query: query,
            });
        },
        showimgtitle(val) {
            if (tools.isVisible(val)) {
                return '查看照片'
            } else {
                return '无'
            }
        },
        getSendOut() {
            let query = { id: this.id, type: "order" };
            this.$router.push({
                name: "order_goods_fahuo_details",
                query: query,
            });
        },

        seevin(val) {
            if (val) {
                this.showimg = true;
                this.editTitle = '铭牌照片';
                let arr1 = [];
                arr1[0] = this.orderdel.a_driver_license;
                this.showimages = arr1
            }
        },
        toimgarr(val) {
            if (tools.isVisible(val)) {
                this.showimg = true;
                let arr1 = [];
                arr1 = val;
                this.showimages = arr1
            }
        },
        seepartimg(val) {
            this.editTitle = '配件照片';
            this.toimgarr(val);
        },
        seequjianimg(val) {
            this.editTitle = '取件照片';
            this.toimgarr(val);
        },
        seehuanjianimg(val) {
            this.editTitle = '还件照片';
            this.toimgarr(val)
        },
        viewData() {
            // alert( this.id );
            let params = { id: this.id };
            orderitemview(params).then((res) => {
                if (res.errCode == 0) {
                    let resdata = res.data;


                    this.order_item_id = resdata.id;//中标按钮
                    this.bstatus = resdata.bstatus;//中标按钮
                    this.checkmoney = resdata.bidmoney;//审核按钮
                    this.subData = resdata;
                    this.orderdel = resdata.order_detail;
                    this.orderdel.remark = '工单备注：' + (this.orderdel.remark ? this.orderdel.remark : '无') + '\r\n配件备注：' + (resdata.remark ? resdata.remark : '无')
                    this.busoffermoney = resdata.busoffermoney;//审核按钮

                    resdata.order_business_list.forEach((u) => {
                        u.status = u.bstatus_str;
                    })
                }
            });
        },
    },
    mounted() {
        // console.log('mounted',this.$route.query);
        this.id = this.$route.query.id;
        this.externalDatas = this.$route.query || {}; // type, // 1显示审核按钮 2不显示审核按钮
        this.viewData();
    },
};
</script>

<style scoped lang="less">
@import "../style2.less";
@import "../gongye.less";

.page {
    height: 100%;
    overflow: auto;
    padding: 10px;
}

.btn-group {
    margin: 0 auto;
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.casenoItemnpt {
    width: 500px;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
}

// ------------------------
.wytoubu {
    margin: 10px 0;
    background-color: #f5f5f5;
    border: 1px solid #e8e8e8;
    text-align: center;
}

.wytoubu th {
    padding: 10px 0;
    font-weight: 400;
    font-size: 12px;
}

/*th宽度*/
.widthdu1 {
    width: 21%;
    line-height: 16px;
    font-size: 12px;
}

.widthdu2 {
    width: 4%;
    line-height: 16px;
    font-size: 12px;
}

.widthdu3 {
    width: 15%;
    line-height: 16px;
    font-size: 12px;
}

.widthdu6 {
    width: 15%;
    line-height: 16px;
    font-size: 12px;
}

.widthdu4 {
    width: 9%;
    line-height: 16px;
    font-size: 12px;
}

.widthdu5 {
    width: 9%;
    line-height: 16px;
    font-size: 12px;
}

.widthdu7 {
    width: 4%;
    line-height: 16px;
    font-size: 12px;
}

.zhuneitongpad .widthdu1,
.widthdu2,
.widthdu3,
.widthdu4,
.widthdu5,
.widthdu6,
.widthdu7 {
    padding: 10px 5px 10px 5px;
}

.widthdu1 .lefts {
    width: 80px;
    display: inline-block;
}

.widthdu1 .lefts img {
    width: 80px;
    height: 80px;
}

.widthdu1 .rights {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    margin: 6px 0 0 5px;
}

.widthdu1 .rights p {
    width: 80%;
    line-height: 16px;
    margin-bottom: 20px;
}

.hoverred:hover {
    color: red;
    cursor: pointer;
}

.widthdu1 .rights span {
    color: #9e9e9e;
}

.xianbor {
    border-left: 1px solid #daf3ff;
}

/* 公用 */
.bought-table-mod__table___3u4gN {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    color: #3c3c3c;
    table-layout: fixed;
}

/* 全选一行样式 */
.shaixuan {
    height: 30px;
    margin: 10px 0;
    margin-top: 60px;
}

.shaixuan span {
    margin-right: 30px;
}

.shaixuan .anniutz {
    padding: 4px;
}

.shaixuan .florigh {
    float: right;
}

.bordertbal {
    border: 1px solid #daf3ff;
    margin: 10px 0;
}

/* teconttbody */
.teconttbody {
    background: #eaf8ff;
}

.tecont td {
    border: none;
}

.tecont .padd {
    padding: 10px 0px;
}

.padd b {
    margin-right: 10px;
    margin-left: 3px;
}

.teconttbody .widthdu4 {
    text-align: center;
}

.centes {
    text-align: center;
}

.centes p {
    text-align: center;
    line-height: 20px;
}

.centes span {
    color: #9e9e9e;
    display: block;
    margin-top: 5px;
}

.centes .reds {
    color: red;
}

.nptss {
    padding: 5px;
    width: 237px;
}

.shctr2 .bnt {
    width: 60px;
    padding: 5px 2px;
    margin-left: -4px;
}

.shctr2 {
    float: right;
    font-size: 12px;
    width: 300px;
    color: #6b6464;
    position: relative;
    top: 8px;
    z-index: 3;
}

/* .shctl{position:relative;} */
.el-tabs__content {
    overflow: visible;
}

.tempspan {
    /*表格里面的字*/
    width: 240px;
    display: inline-block;
}

.fenyedi {
    height: 40px;
}

.widthdu2 p {
    text-align: center;
    height: 64px;
    line-height: 64px;
}

.widthdu7 p {
    text-align: center;
    height: 64px;
    line-height: 64px;
}

.yanse {
    color: rgb(37, 147, 252);
}

.widthdu_div_margin {
    //   margin-bottom: 10px;
}

.widthdu_div_margin span:nth-child(3) {
    color: #74bcff;
    margin-left: 5px;
}
</style>
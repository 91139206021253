<template>
    <div class="main" id="regional">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        banjin:[],
        jixiu:[],
        dianqi:[],
        boli:[],
        zongcheng:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getregional()
    },
    getregional(){
      var app = {};
      var chartDom = document.getElementById('regional');
      var myChart = echarts.init(chartDom,{width:380,height:125});
            myChart.resize()
      var option;

      app.configParameters = {
        rotate: {
          min: -90,
          max: 200
        },

      };
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: false,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      };
      option = {
        grid: {
          top:26,
          left: '1.5%',
          right: "0%",
          bottom: "17%",
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['钣金件', '机修件', '电器件', '玻璃件','总成件'],
          padding:[3, 0, 0 ,0],
          itemHeight:8,
          itemWidth:8,
// 间距
          itemGap:10,
          textStyle: {

            fontSize: 8,
            // padding: [0, 0, 0, 10]
          },

        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'left',
          top: 'center',

        },
        xAxis: [
          {
            type: 'category',
            //   隐藏刻度线
            axisTick: { show: false },
            // data: ['太平洋车险','平安车险','泰康车险','阳光保险','大地','人保车险','天安保险','中报'],
            data: this.data1.date,
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:9
              },
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisTick: { show: false },
            axisLabel: {

              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:9
              },
            }


          }

        ],
        series: [
          {
            name: '钣金件',
            type: 'bar',
            barGap: 0,
            stack: 'x',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            // data: [10, 20,32, 31,21, 24, 10,20],
            data: this.data1.banjin,
            color:["#5050FF"]
          },
          {
            name: '机修件',
            type: 'bar',
            stack: 'x',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#7454E0"],
            // data: [10, 20,32, 31,21, 24, 10,20],
            data: this.data1.jixiu,
          },
          {
            name: '电器件',
            type: 'bar',
            stack: 'x',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#9857C2"],
            // data: [10, 20,32, 31,21, 24, 10,20],
            data: this.data1.dianqi,
          },
          {
            name: '玻璃件',
            type: 'bar',
            stack: 'x',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#AA59B2"],
            // data: [10, 20,32, 31,21, 24, 10,20],
            data: this.data1.boli,
          },
          {
            name: '总成件',
            type: 'bar',
            stack: 'x',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#CE5C94"],
            // data: [10, 20,32, 31,21, 24, 10,20],
            data: this.data1.zongcheng,
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getregional()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

<template>
  <div>
    <Card class="col-right-card min-height">
      <p slot="title">新增广告</p>
      <Form :label-width="120" ref="subData" :model="subData" :rules='dataRules'>

        <FormItem class="FromItemnpt dizhi_width" label="广告位选择" prop="position_id">
          <Select v-model="subData.position_id">
            <Option v-for="(item,index) in adPosition" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt dizhi_width" label="广告名称"  prop="name">
          <Input v-model="subData.name"/>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt dizhi_width" label="广告链接" >
          <Input v-model="subData.link_url"/>
        </FormItem>
        <br/>

        <FormItem class="storebrand_form_doal" label="图片信息"  prop="path_url">
          <viewer style="display: inline-block;" :image="subData.path_url" v-if="subData.path_url">
            <div class="yingimgs">
              <img :src="subData.path_url" style="width: 60px;height: 60px;"
                   class="upload-image-view"/>
            </div>
          </viewer>
          <Upload
            ref="upload"
            :default-file-list="defaultList"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :format="['jpg','jpeg','png','gif']"
            :max-size="1024"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :headers="{Authorization: 'Bearer ' + this.access_token}"
            :data="{type: 'other'}"
            type="drag"
            :action=this.uploadHttp
            style="display: inline-block;width:58px;">
            <div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
          <p class="colors">建议400*200，支持JPG/GIF/PNG，上传信息不能超过2M</p>
        </FormItem>
        <br/>


        <FormItem class="FromItemnpt dizhi_width" label="数据类型" prop="data_type">
          <Select v-model="subData.data_type">
            <Option v-for="(item,index) in adDataType" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
          <p class="colors">纯展示：文章详情，活动：活动详情，商品：商品详情。</p>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt dizhi_width" label="对应数据ID"><!--   prop="data_id" -->
          <Input v-model="subData.data_id"/>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt dizhi_width" label="媒体类型"  prop="media_type">
          <Select v-model="subData.media_type">
            <Option v-for="(item,index) in adMediaType" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <br/>


        <FormItem class="FromItemnpt dizhi_width" label="有效期限"  prop="valid_time">
          <DatePicker style="width:100%;" type="daterange" :options="dateRange" @on-change="checkDate"></DatePicker>
        </FormItem> 
        <br/>


        <FormItem class="FromItemnpt dizhi_width" label="优先级排序">
          <Input v-model="subData.sortcode"/>
        </FormItem>
        <br/>

        <FormItem class="FromItemnpt" label="状态">
          <i-Switch v-model="subData.status">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-Switch>
          <p class="colors">开启后，该广告为显示。关闭则隐藏。</p>
        </FormItem>
         <br/>
      </Form>
    </Card>
    <Card class="col-right-card">
      <div class="bnt_center">
        <Button type="primary" @click="submitForm">保存</Button>
      </div>
    </Card>
  </div>
</template>

<script>
  import { getAdAdd } from '@/api/backend'
  import {getStatus,getAdPosition,getAdDataType,getAdMediaType } from '@/api/dictionary'
  import { getToken } from '@/libs/util'
  import config from '@/config'

  export default {
    data () {
      const validateTime = (rule, value, callback) => {
        if (!this.subData.stime || !this.subData.etime) {
          callback(new Error('必填'))
        } else {
          callback()
        }
      }
      return {
        dataRules: {
          position_id: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          name: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
          // link_url: [
          //   { required: true, message: '必填', trigger: 'blur' },
          // ],
          data_type: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          media_type: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          data_id: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
          path_url: [
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          valid_time: [
            {validator: validateTime, required: true, trigger: 'blur'},
          ],

        },
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        },

        adPosition:[], // 广告位类型
        adDataType:[] ,// 广告位数据类型
        adMediaType:[] ,// 广告位媒体类型
        statusList:[] ,// 广告位媒体类型
        subData: {
          id: '',
          position_id: '1',
          name: '',
          link_url: '',
          path: '', // 图片
          path_url: '', // 图片
          data_type: '', 
          data_id: '', 
          media_type: '', 
          stime: '', 
          etime: '', 
          sortcode: '255', 
          status: true, 
        },
        uploadList: '',
        uploadHttp: '',
        access_token: '',
        defaultList: [],
      }
    },
    methods: {
      checkDate (date) { // 操作时间
        this.subData.stime = date[0]
        this.subData.etime = date[1]
      },

      getAdPosition() {
        getAdPosition().then(res => {
          this.adPosition = res.data
        })
      },
      getAdDataType() {
        getAdDataType().then(res => {
          this.adDataType = res.data
        })
      },  

      getAdMediaType() {
        getAdMediaType().then(res => {
          this.adMediaType = res.data
        })
      },
      getStatus() {
        getStatus().then(res => {
          this.statusList = res.data
        })
      },
      handleSuccess (res, file) {
        if (res.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '上传成功'
          })
          this.subData.path = res.data[0].pic // 图片上传地址
          this.subData.path_url = res.data[0].url // 图片域名地址
        }else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      },
      handleError (file) {
        this.$Modal.error({ title: '提示', content: '上传失败' })
      },
      handleFormatError (file) {
        this.$Modal.error({ title: '提示', content: '文件格式不正确' })
      },

      handleMaxSize (file) {
        this.$Modal.error({ title: '提示', content: '文件太大' })
      },

      handleBeforeUpload () {
        const check = this.uploadList.length < 2
        if (!check) {
          this.$Modal.error({ title: '提示', content: '最多可以上传一张照片。' })
        }
        return check
      },

  


      submitForm () { // 保存
        this.$refs.subData.validate(valid => {
          if (valid) {
            let params = this.subData
            getAdAdd(params).then(res => {
              if (res.errCode == 0) {
                this.$Modal.success({ title: '提示', content: res.errMsg })
                this.viewData()
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },
    },
    mounted () {
      this.access_token = getToken()
      this.uploadList = this.$refs.upload.fileList
      this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro
      this.getAdPosition()
      this.getAdDataType()
      this.getAdMediaType()
      this.getStatus()

    }
  }
</script>


<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
</style>

<template>
	<Card class="col-right-card min-height">
		<p slot="title">外修区域项目匹配</p>
    <Form  ref="ogdata" :rules="dataRules">
			<Row class='og_row'>
				<FormItem class="FromItemnpt  org-width" :label="pagetitle" prop="real_name" >	
					<Input disabled v-model="ogdata.real_name"  />
				</FormItem>
        <FormItem class="FromItemnpt insurancediv" label="询价模式">
          <Select v-model="curmodetype">
            <Option
                v-for="(item, index) in modetype"
                :value="index"
                :key="index"
            >{{ item }}
            </Option>
          </Select>
        </FormItem>
        <div @click="chooseGy_org" style="width:20%">
          <FormItem class="FromItemnpt  org-width " label="保险公司">
            <Input v-model="insurancedata.name" :readonly=true />
<!--            <Icon type="ios-search" @click="chooseGy_org"/>-->
          </FormItem>

        </div>
        <div v-if="curmodetype ==3">
          <FormItem class="FromItemnpt  org-width" label="随机询价权重" prop="weight" >
            <Input  v-model="ogdata.weight" />
          </FormItem>
        </div>
        <Button type="primary" @click="handldselectModal">查询</Button>
        <Button type="primary" @click="save">保存</Button>
			</Row>
		</Form>
		<Modal v-model="slemodal" title="选择保险公司" width="900">
      <Form :label-width="80" >
          <FormItem class="FromItemnpt" label="名称">
            <Input v-model="search.kw" clearable />
          </FormItem>
        <Button type="primary" @click="handleSearch">搜索</Button>
      </Form>
      <Table height="500" border stripe :columns="canshu" :data="canshudata"></Table>
      <div class="my-page">
        <Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
        <div style="height:30px;"></div>
      </div>
      <div slot="footer"></div>
		</Modal>
		<div class="tree_view" v-if="orgareadata.length>0">
				<Card>
					<div slot="title" class="title_p"><Button type="warning">地区</Button></div>
					<div class="p_item">
            <Tree :data="orgareadata" show-checkbox  @on-check-change='checkchang'  multiple></Tree>
					</div>
				</Card>
        <div v-for="pitem in partsList" class="p_item">
          <Card>
            <div slot="title" class="title_p">
              <Button type="warning">{{pitem.name}}</Button>
            </div>
            <div class="tree_select">
              <Tree :data="pitem.ptreedata" show-checkbox multiple @on-check-change='checkchang'></Tree>
            </div>
          </Card>
        </div>
		</div>
    <div></div>
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';
import { getorglist,getQueryOrgcatTree,queryorgarea,addUserBxAreaCat} from '@/api/admin';
export default {
	data() {
		return {
			areatype:1,
			ogdata: {},
			partsList: [],
			canshudata:[],
			slemodal:false,
			totalCount:0,
			curmodetype:0,
			type:null,
			titlespec:null,
			search: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			},
			modetype:['请选择','单件询价','整单询价','随机询价'],
			pagetitle:'外修商',
			orgareadata:[],
			canshu: [
				{
					title: '机构编号',
					key: 'code',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
				{
					title: '机构名称',
					key: 'name',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
				{
					title: '主营',
					key: 'main_camp',
					align: 'center',
					minWidth: 100
				},
				{
					title: '所在省市区',
					key: 'area_name',
					align: 'center',
					minWidth: 150
				},
				{
					title: '所在地址',
					key: 'address',
					align: 'center',
					minWidth: 100
				},
				{
					title: '状态',
					key: 'status_str',
					align: 'center',
					minWidth: 100
				},
				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
                      this.slemodal=false;
                      this.insurancedata=params.row;
                      this.insuranid=params.row.id;
										}
									}
								},
								'选择'
							)
						]);
					}
				}
			],
			dataRules: {
				real_name: []
			},
			ptreedata: [],
			isEdit:false,
			insurancedata:[],
			insuranid:0,
			showorg:1,
			matchmodal: true
		};
	},
	methods: {
		getUser(){
				let curuser=util.getUser();
				if(curuser.ogid==0){
					this.showorg=1;
				}
		},
		save(){
			var that = this;
			this.$refs.ogdata.validate(valid => {
				if (valid) {
					let add_acts=[];
					let pmdata=that.orgareadata[0].children;
					pmdata.forEach((pitem,$one) => {
						let tdata=pitem.children;
						tdata.forEach((titem,$two) => {
                let three=titem.children;
                three.forEach(threetitem => {
                    if(threetitem.checked){
                        let pare={
                            bx_province_id:pmdata[$one].bx_district_id,
                            bx_province_name:pmdata[$one].bx_district_name,
                            bx_city_id:tdata[$two].bx_district_id,
                            bx_city_name:tdata[$two].bx_district_name,
                            bx_district_id:threetitem.bx_district_id,
                            bx_district_name:threetitem.bx_district_name,
                        }
                        add_acts.push(pare)
                    }
                })
						})
					})
					let add_actstype=[];
					that.partsList.forEach(pitem => {
						let tdata=pitem.ptreedata[0].children;
						tdata.forEach(titem => {
							if(titem.checked){
								let pare={
									spare_type:titem.id,
                  base_spare_type:titem.csptyoe
								}
                add_actstype.push(pare)
							}
						})
					})
					let params = {
						add_acts:add_acts,
						add_actstype:add_actstype,
						bx_ogid:that.insuranid,
						userid:that.ogdata.id,
            ogid:that.ogdata.ogid,
						modetype:that.curmodetype,
            weight:that.ogdata.weight
					};
					addUserBxAreaCat(params).then(res => {
						if (res.errCode == 0) {
							that.$Modal.success({
								title: '提示',
								content: '保存成功'
							});
							this.$router.go(-1)
						} else {
							that.$Modal.error({
								title: '提示',
								content: res.errMsg
							});
						}
					});
				}
			});
		},
		checkchang(arr,val){
			if(val.checked){
				this.a
			}
		},
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		handleSearch(){
			let params = this.search;
      getorglist(params).then(res => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.canshudata = res.data.list;
        }
      });
		},
		chooseGy_org(){
      if(this.curmodetype == 0){
        this.$Modal.error({
          title: '提示',
          content: '请选择询价模式'
        });
        return false;
      }
			this.slemodal=true
			this.handleSearch();
    },
		handldselectModal(){
      this.matcharea();
      this.matchparts();
		},
		handmatcharea(){
			let params={
				bx_ogid:this.insuranid,
				uid:this.ogdata.id,
			}
			this.matcharea(params)
		},
    calleArr(array){
            array.forEach((item, index) => {
                if (item.child) {
                    item.checked = item.status === 1 ? 1 : 0
                    item.title = item.name
                    item.children = item.child
                    item.bx_district_id  = item.id
                    item.bx_district_name = item.name
                    this.calleArr(item.child) //自己调用自己
                } else {
                    item.bx_district_id  = item.id
                    item.bx_district_name = item.name
                    item.checked = item.status === 1 ? 1 : 0
                    item.title = item.name
                 }
           })
          return array
    },
    matchparts() {
        this.matchmodal = true;
        const msg = this.$Message.loading({
          content: '加载中...',
          dutration: 0
        });
        getQueryOrgcatTree({'ogid':this.ogdata.ogid,'userid':this.ogdata.id,'bx_ogid':this.insuranid,'modetype':this.curmodetype}).then(res => {
				if (res.errCode === 0) {
          this.partsList = res.data.list;
          this.partsList.forEach(pitem => {
            // debugger
            pitem.ptreedata=[];
            let p={
              title:'全部',
              expand: true,
              children:[],
            };
            if(pitem.data){
              pitem.data.forEach(item1 => {
                let ch = false;
                if (item1.status == 0) {
                  ch = false;
                } else {
                  ch = true;
                }
                let cl = {
                  checked: ch,
                  id: item1.id,
                  title: item1.cspname,
                  csptyoe: item1.csptyoe
                };
                p.children.push(cl);
              });
            }
            pitem.ptreedata.push(p)
          });
          setTimeout(msg, 0);
				}
			});
		},
		matcharea() {
			this.matchmodal = true;
			const msg = this.$Message.loading({
				content: '加载中...',
				dutration: 0
			});

			queryorgarea({'ogid':this.ogdata.ogid,'type':2,'userid':this.ogdata.id,'bx_ogid':this.insuranid,'modetype':this.curmodetype}).then(res => {
         if (res.errCode === 0) {
          let resdata = res.data.data;
           this.orgareadata = [{
            title:'全部',
            expand: true,
            children: this.calleArr(resdata),
           }]
           this.ogdata.weight = res.data.weight;
           setTimeout(msg, 0);
         }
      });
		}
	},
	mounted() {
			let querydata=this.$route.query;
			this.isEdit=true;
			this.areatype=this.$route.query.areatype;
			this.type=this.$route.query.type;
			this.ogdata = {
        'id':this.$route.query.id,
        'ogid':this.$route.query.ogid,
        'real_name':this.$route.query.real_name
      }
			this.pagetitle=querydata.title;
			this.getUser();
	}
};
</script>
<style lang="less">
@import '../style2.less';
@import '../gongye.less';
.insurancediv{
	margin-left: 60px;
}
.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}
.map {
	width: 100%;
	height: 400px;
}
.og_row{
	button{
		margin-left: 100px;
	}
}
.map .search {
	margin-bottom: 65px;
}
.org-width {
	width: 230px !important;
}
.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
.right_title {
	line-height: 20px;
	font-size: 16px;
	color: #17233d;
}
.tree_view {
	width: 100%;
	display: flex;
	.p_item {
		width: 20%;
		.title_p{
			color: #f57d22;
		}
		.tree_select{
			overflow: scroll;
			height: 600px;
		}
	}
}
</style>

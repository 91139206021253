export const ogtypeEnum =  {
	//1 维修厂 2外修商  3 保险公司 4回收商 5物损
   repair_company:1,
   repair_business:2,
   recovery_business:4,
   insurance_company:3,

     org_thing:5,
    //1管理员  2保险公司 3外修商 4回收商 5物损
    user_admin:1,
    user_insurance:2,
    user_consult:3,
    user_recycle:4,
    user_thing:5,
}
//1 待完善 2待报价   3待核价  4待分配  5待取件 6修复中 7已还件 8已关闭 9修复成功 10已结算  10已选择其他外修商   12待开标  13无法修复  14 无法取件'
export const repair_bussiness_status = { //
	unfinish: 1, //"待完善
	pending_quote: 2, //待报价
	uncheck_quote: 3, //待核价
	unassign: 4, //待分配
	pending_pick: 5, //待取件
	repairing: 6, //修复中
	returned: 7, //已还件
	closed: 8, //已关闭
	repair_success: 9, //修复成功
	Settled: 10, //已结算
	choose_other  : 11, //已选择其他外修商
	pending_bid: 12, //待开标
	unable_fix: 13, //无法修复
	unable_pick: 14, //无法取件
	close_audit: 16, //关闭待审核
	timeout_quote:15,//超时未报价
	pick_before_unrepair:17,//取件时无法修
	pick_after_unrepair:18,//取件后无法修
	dicker:19,//转议价
	undicker:20,//议价失败
	dicker_success:21,//议价成功
	uncheck_order:23,//待核价

};
export const ordertype = { //1  外修  2 回收 3 俩者都有
	extra: 1, 
	recover: 2, 
	all:3

};

export const itemstatus = { //1  外修  2 回收
	extra: 1, 
	recover: 2, 
	wusun:3,
};
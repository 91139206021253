<template>
  <Card class="col-right-card min-height">
    <p slot="title">综合报表</p>
    <Form :label-width="120">
      <!--<FormItem class="FromItemnpt"><Input clearable value='2020-01-07'/></FormItem>-->


      <FormItem class="FromItemnpt" label="定损机构">
        <Input v-model="search.createogid_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="insurance_org" />
        </Input>
      </FormItem>
      <FormItem class="FromItemnpt" label="第三方名称">
        <Input v-model="search.ogid_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="repair_org" />
        </Input>
      </FormItem>

      <Button type="primary" @click="handleSearchAction">查询</Button>
      <Button type="primary" :loading="exportLoading" @click="exportExcel" >导出</Button>
<!--      <Button type="primary" @click="exportAction">导出</Button>-->
    </Form>
    <!-- <div class="zongHeState"> -->
      <Table
        height="500"
        border
        stripe
        :columns="columsZongHe"
        :data="dataQingdan"
        @on-select="tableSelectionChange"
        @on-select-all="tableSelectionChange"
        @on-select-cancel="tableSelectionChange"
      ></Table>

      <div class="my-page">
        <Page
          :total="totalCount"
          show-elevator
          show-sizer
          show-total
          @on-change="handlePage"
          @on-page-size-change="handlePageSize"
        />
        <div style="height: 30px"></div>
      </div>
      <insurance_org
      ref="insurance_org"
      :initOption="chooseorgInitOption"
      @getorgdata="getChooseGY"
    >
    </insurance_org>
    <!-- </div> -->
  </Card>
</template>

<script>
import excel from '@/libs/excel'
import insurance_org from "@/view/my-components/ditanorg/insurance_org";
import * as Enum from "@/libs/enum";
import {getWaixiuList, getZongheList} from "@api/admin";
export default {
  name: "",
  components:{
    insurance_org
  },
  data() {
    return {
      search: {
        p: 1, // 当前页码
        t: 10, // 每页显示条数
        createogid_name: "",
        createogid: "",
        ogid_name: "",
        ogid: "",
      },
      chooseorgInitOption: {
        title: "",
        showModal: false,
      },
      //综合报表
      columsZongHe: [
        {
          title: "定损机构",
          key: "createogid_str",
          align: "center",
          minWidth: 130,
        },
        {
          title: "第三方名称",
          key: "ogid_str",
          align: "center",
          minWidth: 170,
        },
        { title: "配件总数", key: "count", align: "center", minWidth: 100 },
        { title: "报价数量", key: "baojia", align: "center", minWidth: 100 },
        {
          title: "报价无法修复",
          key: "baojianotxiu",
          align: "center",
          minWidth: 130,
        },
        {
          title: "应标总数量",
          key: "yingbiao",
          align: "center",
          minWidth: 120,
        },
        { title: "超时数量", key: "chaoshi", align: "center", minWidth: 100 },
        {
          title: "应标率(%)",
          key: "yingbiaolv",
          align: "center",
          minWidth: 120,
        },
        {
          title: "超时占比(%)",
          key: "chaoshizhanbi",
          align: "center",
          minWidth: 130,
        },
        {
          title: "应标时效",
          key: "yingbiaoshixiao",
          align: "center",
          minWidth: 100,
        },
        { title: "平均应标时效", key: "pingjunyingbiaoshixiao", align: "center", minWidth: 130 },
        { title: "中标数量", key: "zhongbiao", align: "center", minWidth: 100 },
        {
          title: "中标率(%)",
          key: "zhongbiaolv",
          align: "center",
          minWidth: 120,
        },
        { title: "配件原值", key: "offermoney_sum", align: "center", minWidth: 100 },
        { title: "中标金额", key: "bidmoney_sum", align: "center", minWidth: 100 },
        { title: "审核金额", key: "checkmoney_sum", align: "center", minWidth: 100 },
        { title: "减损金额", key: "jiansunmoney", align: "center", minWidth: 100 },
        { title: "减损率", key: "jiansunLv", align: "center", minWidth: 100 },
        {
          title: "外修费偏差金额",
          key: "waixiupiancha",
          align: "center",
          minWidth: 140,
        },
        {
          title: "外修费偏差率",
          key: "pianchaLv",
          align: "center",
          minWidth: 130,
        },
        {
          title: "取件时无法修",
          key: "beforenotxiu",
          align: "center",
          minWidth: 130,
        },
        { title: "取件后无法修", key: "laternotxiu", align: "center", minWidth: 130 },
        {
          title: "配件维修成功数",
          key: "succeed",
          align: "center",
          minWidth: 150,
        },
        {
          title: "中标维修成功率",
          key: "weixiuchenggonglv",
          align: "center",
          minWidth: 150,
        },
        {
          title: "配件维修占比(%)",
          key: "weixiuzhanbi",
          align: "center",
          minWidth: 170,
        },
        {
          title: "配件维修失败数",
          key: "laternotxiu",
          align: "center",
          minWidth: 150,
        },
        {
          title: "配件维修失败占比(%)",
          key: "weixiushibai",
          align: "center",
          minWidth: 180,
        },
        {
          title: "维修时效(小时)",
          key: "dpj_total",
          align: "center",
          minWidth: 130,
        },
        {
          title: "平均维修时效(小时)",
          key: "dpj_total",
          align: "center",
          minWidth: 160,
        },
      ],
      dataZongHe: [],
      dataQingdan: [],
      totalCount: 10,
      exportLoading: false,
    };
  },
  methods:{
    exportExcel () {
      this.exportLoading = true

      let params = JSON.parse(JSON.stringify(this.search));
      params.t = 20000;//导出最大条数
      getZongheList(params).then((res) => {
        if (res.errCode == 0) {

          if (res.data.list.length) {
            const params = {
              title: ['定损机构', '第三方名称', '配件总数', '报价数量', '报价无法修复', '应标总数量', '超时数量', '应标率（%）',
                '超时占比(%)', '应标时效', '平均应标时效', '中标数量','中标率（%）', '配件原值', '中标金额', '审核金额', '减损金额',
                '减损率', '外修费偏差金额', '外修费偏差率', '取件时无法修', '取件后无法修', '配件维修成功数', '中标维修成功率',
                '配件维修占比(%)' , '配件维修失败数', '配件维修失败占比(%)', '维修时效（小时）', '平均维修时效（小时）'],
              key: ['createogid_str', 'ogid_str', 'count', 'baojia', 'baojianotxiu', 'yingbiao', 'chaoshi', 'yingbiaolv',
                'chaoshizhanbi', 'yingbiaoshixiao', 'pingjunyingbiaoshixiao', 'zhongbiao','zhongbiaolv', 'offermoney_sum',
                'bidmoney_sum', 'checkmoney_sum', 'jiansunmoney', 'jiansunLv', 'waixiupiancha', 'pianchaLv', 'beforenotxiu' ,
                'laternotxiu' , 'succeed', 'weixiuchenggonglv' , 'weixiuzhanbi', 'laternotxiu', 'weixiushibai', 'dpj_total', 'dpj_total' ],
              data: res.data.list,
              autoWidth: true,
              filename: '综合报表'
            }
            excel.export_array_to_excel(params)
            this.exportLoading = false
          } else {
            this.$Message.info('表格数据不能为空！')
             this.exportLoading = false
          }

        }
      });
    },
    tableSelectionChange(data2) {
      if (data2[0] != undefined) {
        this.checkedId = data2[0].id;
      }
      let ids = [];
      data2.map(function(item, key) {
        ids.push(item.id);
      });
      this.checkedIds = ids;
    },
    // 分页
    handlePage(value) {
      this.search.p = value;
      this.handleSearch();
    },
    handlePageSize(value) {
      this.search.t = value;
      this.handleSearch();
    },
    exportAction() {
      // console.log("导出");
    },
    repair_org() {
      this.chooseorgInitOption.title = "选择外修商";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.repair_business;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    //选择机构
    getChooseGY(item) {
      // console.log(item);
      this.chooseorgInitOption.showModal = false;

      if (item.ogtype == 2) {
        this.search.ogid = item.id;
        this.search.ogid_name = item.name;
      } else if (item.ogtype == 3) {
        this.search.createogid = item.id;
        this.search.createogid_name = item.name;
      }
    },
    // 列表
    handleSearch() {
      let params = this.search;
      getZongheList(params).then((res) => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.dataQingdan = res.data.list;
          // this.button = res.data.button
        }
      });
    },
    // 查询
    handleSearchAction() {
      this.search.p = 1;
      this.search.is_export = 0;
      this.handleSearch();
    },
  },
  mounted(){
    this.handleSearch();
  }
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.dizhiselecls {
  width: 342px;
  margin-right: 3px;
}

.map {
  width: 100%;
  height: 400px;
}

.map .search {
  margin-bottom: 65px;
}

.userfnpt {
  width: 300px;
  margin-right: 3%;
  display: inline-block;
}
</style>

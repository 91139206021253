<template>
  <Card class="col-right-card min-height">
    <div slot="title" style="display: flex;justify-content: space-between;align-items: center;">
      <p>用户服务协议</p>
    <Button type="primary" @click="handleCreateModel">添加</Button>
    </div>
      <editor style="margin-top: 10px;" v-model="subData.details"  :uploadtype="uploadtype"/>
  </Card>
</template>

<script>
  import editor from '../../components/editor/index.js'
  import {  } from '@/api/backend'
  import { getOwnerServeAgreement,getOwnerServeAgreementAdd } from '@/api/admin';
  export default {
    components: {
      editor,
    },
    data () {
      return {
        uploadtype:'activity',
        subData: {
          details: '',
        },
   
      }
    },
    methods: {
      handleCreateModel() {
        let params = {}
        params.data = this.subData.details
          getOwnerServeAgreementAdd(params).then(res => {
          if(res.errCode == 0) {
            this.$Modal.success({ title: '提示', content: res.errMsg })
            let vm = this
            setTimeout(function () {
              vm.viewData()
            }, 1500) 
          } else {
            this.$Modal.error({ title: '提示', content: res.errMsg })
          } 
        })
      },
      viewData() {
          getOwnerServeAgreement().then(res => {
          if (res.errCode == 0) {
            this.subData.details = res.data
          }
        })
      }
    },
    mounted () {
      this.viewData()
    }
  }

</script>

<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";

  .storebrand_form_doal {
    width: 70%;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
  }
</style>

<template>
  <div class="myApp">
    <Row class="my-row">
      <div class="col-rightN">
        <Card class="col-right-card">
          <p slot="title">平台会员等级管理</p>
          <Form :label-width="80">
            <div>
              <FormItem class="FromItemnpt" label="等级名称：">
                <Input clearable v-model="search.kw" placeholder="请输入等级名称"></Input>
              </FormItem>
              <FormItem class="FromItemnpt" label="添加时间：" prop="username">
                <DatePicker
                  style="width:180px;"
                  type="daterange"
                  :options="dateRange"
                  @on-change="checkDate"
                ></DatePicker>
              </FormItem>
              <Button type="primary" @click="handleSearchAction">查询</Button>
            </div>
          </Form>
          <div class="gray-nona"></div>
          <div class="tablebnt">
            <Button type="primary" @click="handleCreateModel" v-show="button.add">新增</Button>
            <Button type="primary" @click="handleDelete" v-show="button.delete">删除</Button>
            
            <Modal v-model="newModel" :title="titleData" width="600" draggable>
              <div>
                <Form :label-width="100" ref="subData" :model="subData" :rules="rules">
                  <FormItem class="FromItemnpt" label="会员等级名称" prop="name">
                    <Input v-model="subData.name"/>
                  </FormItem>
                  <FormItem class="storclass_form_doal" label="是否默认">
                    <i-Switch v-model="subData.is_default">
                      <span slot="open">是</span>
                      <span slot="close">否</span>
                    </i-Switch>
                    <p class="colors">开启后，用户在商城里才会看到该商品分类。关闭则隐藏分类。</p>
                  </FormItem>
                  <FormItem class="FromItemnpt" label="满足交易额" prop="money">
                    <InputNumber v-model="subData.money" />
                  </FormItem>
                  <FormItem class="FromItemnpt" label="满足交易次数" prop="trade_num">
                    <InputNumber v-model="subData.trade_num"/>
                  </FormItem>
                </Form>
              </div>
              <div slot="footer">
                <Button @click="newModel = false">取消</Button>
                <Button type="primary" @click="getSubmitForm">保存</Button>
              </div>
            </Modal>
          </div>
          <Table stripe border
                 height="520"
                 :columns="columns"
                 :data="data1"
                 @on-select="handleCheckedOrDeleteTable"
                 @on-select-all="handleCheckedOrDeleteTable"
                 @on-select-cancel="handleCheckedOrDeleteTable"
          ></Table>

          <div class="my-page">
            <Page
              :total="totalCount"
              :current="search.p"
              @on-change="handleChangePage"
              :page-size="parseInt(search.t)"
            ></Page>
            <span class="pageSelect">
              每页显示
              <Select
                class="ivu-select-selection-1"
                v-model="search.t"
                @on-change="handleChangePageLimit"
                :label="search.t"
              >
                <Option value="10">10</Option>
                <Option value="20" v-show="totalCount > 10">20</Option>
                <Option value="30" v-show="totalCount > 20">30</Option>
                <Option value="40" v-show="totalCount > 30">40</Option>
                <Option value="50" v-show="totalCount > 40">50</Option>
              </Select>
            </span>
          </div>
        </Card>
      </div>
    </Row>
  </div>
</template>
<script>
  import {getMemberLevel, getMemberLevelAdd,getMemberLevelEdit} from '@/api/backend'
  export default {
    data () {
      const validataMoney = (rule, value, callback) => {
        if (!value) {
          console.log(1)
          callback(new Error('必填'))
        } else {
          callback()
        }
      }
      const validataTradeNum = (rule, value, callback) => {
        if (!value) {
          callback(new Error('必填'))
        } else {
          callback()
        }
      }
      return {
        titleData: '',
        rules: {
          name: [{ required: true, message: '必填', trigger: 'blur' }],
          trade_num: [
            {required: true, validator: validataTradeNum, trigger: 'blur'}
          ],
          money: [
            {required: true, validator: validataMoney, trigger: 'blur'}
          ],
        },
        newModel: false, // 新增模态框显示状态
        totalCount: 10, // 总条数
        button: {},
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          type: '1',
          action_id: '142'
        },
        data1: [],
        subData: {
          name: '',
          id: '',
          type:'1',
          money:null,
          trade_num:null,
          is_default:false,
        },
        columns: [
          // 表头
          { type: 'selection', align: 'center', width: 60 },
          { title: '等级名称', align: 'center', key: 'name', minWidth: 100 },
          { title: '会员人数', align: 'center', key: 'u_num', minWidth: 130 },
          { title: '满足交易额', align: 'center', key: 'money', minWidth: 120 },
          { title: '满足交易次数', align: 'center', key: 'trade_num', minWidth: 120 },
          { title: '默认', align: 'center', key: 'is_default_str', minWidth: 100 },


          { title: '添加时间', align: 'center', key: 'createtime', minWidth: 160 },
          {
            title: '操作',
            key: 'action',
            width: 180,
            fixed:'right',
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small',
                    disabled:!this.button.edit
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.handleUpdateModal(params.row)
                    }
                  }
                }, '编辑'),
              ])
            }
          }
        ],
        checkedIds: [],
        checkedId: '',
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        }
      }
    },
    methods: {
      checkDate (date) {
        // 操作时间
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      handleSearch () {
        let params = this.search
        getMemberLevel(params).then(res => {
          this.totalCount = parseInt(res.data.total)
          this.data1 = res.data.list
          this.button = res.data.button
        })
      },

      getSubmitForm () {
        this.$refs.subData.validate(valid => {
          if (valid) {
            let params = this.subData
            getMemberLevelAdd(params).then(res => {
              if (res.errCode == 0) {
                if (this.subData.id) {
                  this.checkedIds = []
                }
                this.newModel = false
                this.$Modal.success({ title: '提示', content: res.errMsg })
                setTimeout(() => {
                    this.handleSearch()
                }, 100);
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },

      handleSearchAction () {
        this.search.p = 1
        this.handleSearch()
      },
      handleCreateModel () {
        this.reset()
        this.subData.id = ''
        this.subData.type = '1'
        this.subData.money = null
        this.subData.trade_num = null
        this.subData.is_default = false
        this.titleData = '平台会员等级新增'
        this.newModel = true
      },
      handleUpdateModal (data) {
        this.reset()
        this.subData.id = data.id
        this.subData.name = data.name
        this.subData.is_default = data.is_default
        this.subData.type = '1'
        this.subData.money = parseFloat(parseFloat(data.money).toFixed(2))
        this.subData.trade_num = parseFloat(parseFloat(data.trade_num).toFixed(2))
        this.titleData = '平台会员等级修改'
        this.newModel = true
      },
      handleCheckedOrDeleteTable (data1) {
        if (data1[0]) {
          this.checkedId = data1[0].id
        }
        let ids = []
        data1.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },

      handleChangePage (data1) {
        // 切换页码
        this.search.p = data1
        this.handleSearch()
      },
      handleChangePageLimit () {
        // 切换每页显示条数
        this.search.p = 1
        this.handleSearch()
      },

      getMemberLevelEdit (params) {
        getMemberLevelEdit(params).then(res => {
          if (res.errCode == 0) {
            this.checkedIds = []
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
            setTimeout(() => {
              this.handleSearch()
            }, 100);
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      },


      handleDelete () {
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '1'
              this.getMemberLevelEdit(params)
            },
            onCancel: () => {
              this.$Message.info("您取消了删除操作");
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },
      reset () {
        this.$refs.subData.resetFields()
      },
    },
    mounted () {
      this.handleSearch()
    },
    watch: {}
  }
</script>


<style lang="less">
  @import "../style2.less";
</style>


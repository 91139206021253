import * as  axios from '@/libs/api.request'

export const login = ({
	userName,
	password
}) => {
	const data = {
		uname: userName,
		upwd: password
	}
	return axios.post('common/login', data)

}

export const editpwdaq = ({
							  account,
							  oldpwd,
							  pwd,
								pwd2
					  }) => {
	const data = {
		account: account,
		oldpwd: oldpwd,
		pwd:pwd,
		pwd2:pwd2
	}
	return axios.post('common/changepwdaq', data)
}


/**
 * 用户列表
 * @param params
 * @returns {*|never|AxiosPromise<any>|ClientRequest|ClientHttp2Stream}
 */
export const getuserList = (params) => {

	return axios.post('backend/user-account', params)
}

// 用户角色权限管理
export const getRoleDropDown = (params) => {
	return axios.post('backend/role-drop-down', params)
}

export const sysAdminEdit = (uid, type) => {

	var params = {
		uid: uid,
		type: type
	}
	return axios.post('backend/user-account-edit', params)

}

export const getSysAdminRole = (params) => {
	return axios.post('sys-admin-role', params)
}
// 后台管理 - 角色管理 -新增/编辑
export const getSysAdminRoleAdd = (params) => {

	return axios.post('sys-admin-role-add', params)
}

// 后台管理 - 角色管理 - 状态冻结 启用  删除
export const getSysAdminRoleEdit = (params) => {


	return axios.post('backend/sys-admin-role-edit', params)
}


/**
 * 用户新增/编辑
 * @param params
 * @returns {*|never|AxiosPromise<any>|ClientRequest|ClientHttp2Stream}
 */
export const addUserAccount = (params) => {

	return axios.post('user-account-add', params)
}
/**
 * 用户详情
 * @param uid uid
 * @returns {*}
 */
export const getUserView = (uid) => {
	const params = {
		uid: uid
	}
	return axios.get('backend/user-account-view', params)
}

export const getChangephone = (params) => {
	return axios.post(
		'common/changephone',
		 params
	)
}
export const getAdmin = (params) => {
	return axios.post(
		'backend/sys-admin',
		params
	)
}

export const getUserInfo = (token) => {
	return axios.get(
		'axios.get_info',
		{
			token
		},

	)
}

export const logout = (token) => {
	return axios.post(
		'logout',

	)
}



export const getOrgMemberLevel = (params) => {

	return axios.post(
		'backend/org-member-level',
		params
	)
}

<template>
  <Card class="col-right-card min-height">
    <!-- <div class="dingSunState"> -->
    <p slot="title">定损外修报表</p>
    <Form :label-width="120">
      <FormItem class="FromItemnpt" label="定损机构">
        <Input v-model="search.createogid_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="insurance_org" />
        </Input>
        <a style="margin-left: 5px" @click="clearBaoxian">清除</a>
      </FormItem>
      <FormItem class="FromItemnpt" label="定损员">
        <Input v-model="search.createuser_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="insurance_ding" />
        </Input>
        <a style="margin-left: 5px" @click="clear">清除</a>
      </FormItem>
      <FormItem class="FromItemnpt" label="创建时间">
				<DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" v-model="timeshijian"></DatePicker>
			</FormItem>
      <FormItem class="FromItemnpt" label="分类">
        <Select v-model="search.base_spare_type" clearable>
          <Option
            v-for="(item, index) in spareType"
            :value="index"
            :key="index"
            >{{ item }}</Option
          >
        </Select>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
      <Button type="primary" :loading="exportLoading" @click="exportExcel" >导出</Button>
    </Form>
    <Table
      height="500"
      border
      stripe
      :columns="columsDingSun"
      :data="dataQingdan"
      @on-select="tableSelectionChange"
      @on-select-all="tableSelectionChange"
      @on-select-cancel="tableSelectionChange"
    ></Table>
    <!-- </div> -->

    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height: 30px"></div>
    </div>
    <insurance_org
      ref="insurance_org"
      :initOption="chooseorgInitOption"
      @getorgdata="getChooseGY"
    >
    </insurance_org>
    <insurance_ding
      ref="insurance_ding"
      :initOption="chooseorgInitOptionDing"
      @getorgdata="getChooseGYDing"
    >
    </insurance_ding>
  </Card>
</template>

<script>
import excel from '@/libs/excel'
import insurance_org from "@/view/my-components/ditanorg/insurance_org";
import insurance_ding from "@/view/my-components/ditanorg/insurance_ding";

import * as Enum from "@/libs/enum";
import {getComprehensive, getDingsunList} from '@/api/admin';
import { getItemType, getSparetypetwo } from "@/api/dictionary";

export default {
  name: "",
  components: {
    insurance_org,
    insurance_ding,
  },
  data() {
    return {
      search: {
        p: 1, // 当前页码
        t: 10, // 每页显示条数
        createogid_name: "",
        createogid: "",
        createuser_name: "",
        createuser: "",
      },
      chooseorgInitOption: {
        title: "",
        showModal: false,
      },
      chooseorgInitOptionDing: {
        title: "",
        showModal: false,
      },
      itemType: [],
      spareType: [],
      dataQingdan: [],
      totalCount: 10,
      //定损外修报表
      columsDingSun: [
        {title: "定损机构", key: "createogid_str", align: "center", minWidth: 130,},
        {title: "定损员", key: "createuser_str", align: "center", minWidth: 100,},
        { title: "分类", key: "base_spare_type_str", align: "center", minWidth: 80 },
        { title: "配件总数", key: "count", align: "center", minWidth: 100 },
        { title: "配件原值(全部)", key: "offermoney_all", align: "center", minWidth: 130 },
        { title: "报价数量", key: "baojia", align: "center", minWidth: 100 },
        { title: "配件原值", key: "baojia_yuanzhi", align: "center", minWidth: 100 },
        { title: "报价金额", key: "baojia_money", align: "center", minWidth: 100 },
        {title: "报价无法修复", key: "baojia_notxiu", align: "center", minWidth: 130,},
        {title: "配件原值", key: "baojia_notxiu_yuanzhi", align: "center", minWidth: 100,},
        { title: "超时未报价", key: "chaoshi", align: "center", minWidth: 100 },
        { title: "配件原值", key: "chaoshi_yuanzhi", align: "center", minWidth: 100 },
        {title: "待核价", key: "daihejia", align: "center", minWidth: 120,},
        {title: "配件原值", key: "daihejia_yuanzhi", align: "center", minWidth: 120,},
        {title: "报价金额", key: "daihejia_money", align: "center", minWidth: 120,},
        { title: "已退回", key: "yituihui", align: "center", minWidth: 100 },
        { title: "配件原值", key: "yituihui_yuanzhi", align: "center", minWidth: 100 },
        { title: "维修金额", key: "yituihui_money", align: "center", minWidth: 100 },
        {title: "待取件", key: "daiqvjian", align: "center", minWidth: 130,},
        {title: "配件原值", key: "daiqvjian_yuanzhi", align: "center", minWidth: 130,},
        {title: "维修金额", key: "daiqvjian_weixiu", align: "center", minWidth: 130,},
        {title: "核价金额", key: "daiqvjian_checkmoney", align: "center", minWidth: 130,},
        { title: "修复中", key: "xiufuzhong", align: "center", minWidth: 120 },
        { title: "配件原值", key: "xiufuzhong_yuanzhi", align: "center", minWidth: 120 },
        { title: "维修金额", key: "xiufuzhong_weixiu", align: "center", minWidth: 120 },
        { title: "核价金额", key: "xiufuzhong_checkmoney", align: "center", minWidth: 120 },
        {title: "已还件", key: "yihuanjian", align: "center", minWidth: 120,},
        {title: "配件原值", key: "yihuanjian_yuanzhi", align: "center", minWidth: 120,},
        {title: "维修金额", key: "yihuanjian_weixiu", align: "center", minWidth: 120,},
        {title: "核价金额", key: "yihuanjian_checkmoney", align: "center", minWidth: 120,},
        {title: "修复成功", key: "succeed", align: "center", minWidth: 100,},
        {title: "配件原值", key: "succeed_yuanzhi", align: "center", minWidth: 100,},
        {title: "维修金额", key: "succeed_weixiu", align: "center", minWidth: 100,},
        {title: "核价金额", key: "succeed_checkmoney", align: "center", minWidth: 100,},
        {title: "无法取件", key: "notqvjian", align: "center", minWidth: 100,},
        {title: "配件原值", key: "notqvjian_yuanzhi", align: "center", minWidth: 100,},
        {title: "维修金额", key: "notqvjian_weixiu", align: "center", minWidth: 100,},
        {title: "核价金额", key: "notqvjian_checkmoney", align: "center", minWidth: 100,},
        {title: "取件时无法修", key: "beforenotxiu", align: "center", minWidth: 130,},
        {title: "配件原值", key: "beforenotxiu_yuanzhi", align: "center", minWidth: 100,},
        {title: "维修金额", key: "beforenotxiu_weixiu", align: "center", minWidth: 100,},
        {title: "核价金额", key: "beforenotxiu_checkmoney", align: "center", minWidth: 100,},
        { title: "取件后无法修", key: "laternotxiu", align: "center", minWidth: 130 },
        {title: "配件原值", key: "laternotxiu_yuanzhi", align: "center", minWidth: 100,},
        {title: "维修金额", key: "laternotxiu_weixiu", align: "center", minWidth: 100,},
        {title: "核价金额", key: "laternotxiu_checkmoney", align: "center", minWidth: 100,},
        { title: "关闭待审核", key: "closedaishenhe", align: "center", minWidth: 120 },
        {title: "配件原值", key: "closedaishenhe_yuanzhi", align: "center", minWidth: 100,},
        {title: "已关闭", key: "yiguanbi", align: "center", minWidth: 100,},
        {title: "配件原值", key: "yiguanbi_yuanzhi", align: "center", minWidth: 100,},
        {title: "有效单数", key: "yxds", align: "center", minWidth: 100,},
        {title: "订单有效率", key: "ddyxl", align: "center", minWidth: 100,},
        {title: "有效减损配件原值", key: "jiansun_peijian_yuanzhi", align: "center", minWidth: 160,},
        {title: "有效减损外修金额", key: "jiansun_waixiu_money", align: "center", minWidth: 160,},
        {title: "有效减损核价金额", key: "jiansun_hejia_money", align: "center", minWidth: 160,},
        {title: "有效减损核价偏差", key: "piancha", align: "center", minWidth: 160,},
        {title: "有效减损金额(按报价)", key: "jiansun_money_baojia", align: "center", minWidth: 180,},
        {title: "有效减损金额(按核价)", key: "jiansun_money_hejia", align: "center", minWidth: 180,},
        { title: "有效订单减损率", key: "jiansunLv", align: "center", minWidth: 150 },
        { title: "费用占比", key: "fyzb", align: "center", minWidth: 100 },
        { title: "超时占比", key: "chaoshi_zhanbi", align: "center", minWidth: 100 },
        {title: "应标数量", key: "yingbiao", align: "center", minWidth: 100,},
        {title: "应标率", key: "yingbiaolv", align: "center", minWidth: 100,},
        { title: "中标数量", key: "zhongbiao", align: "center", minWidth: 100 },
        { title: "中标率", key: "zhongbiaolv", align: "center", minWidth: 100 },
        { title: "应标中标率", key: "ybzbl", align: "center", minWidth: 120 },
        { title: "中标异常数", key: "zhongbiao_exception", align: "center", minWidth: 120 },
        { title: "中标异常率", key: "exceptionlv", align: "center", minWidth: 120 },
        { title: "修复成功率(中标值)", key: "zbz", align: "center", minWidth: 120 },
        { title: "修复成功率(应标值)", key: "ybz", align: "center", minWidth: 120 },
        { title: "修复成功率(整体值)", key: "ztz", align: "center", minWidth: 120 },
        { title: "修复成功率(实际值)", key: "sjz", align: "center", minWidth: 120 },
        { title: "弃标率", key: "qbl", align: "center", minWidth: 120 },
      ],
      dataDingSun: [],
      exportLoading: false,
      timeshijian: [],
      dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
    };
  },
  methods: {
    exportExcel () {
      this.exportLoading = true

      let params = JSON.parse(JSON.stringify(this.search));
      params.t = 20000;//导出最大条数
      getDingsunList(params).then((res) => {
        if (res.errCode == 0) {

          if (res.data.list.length) {
            const params = {
              title: this.columsDingSun.map(x=>x.title),
              key: this.columsDingSun.map(x=>x.key),
              data: res.data.list,
              autoWidth: true,
              filename: '定损外修报表'
            }
            excel.export_array_to_excel(params)
            this.exportLoading = false
          } else {
            this.$Message.info('表格数据不能为空！')
             this.exportLoading = false
          }

        }
      });
    },
    tableSelectionChange(data2) {
      if (data2[0] != undefined) {
        this.checkedId = data2[0].id;
      }
      let ids = [];
      data2.map(function(item, key) {
        ids.push(item.id);
      });
      this.checkedIds = ids;
    },
    exportAction() {
      console.log("导出");
    },
    //分类下拉
    getItemType() {
      getItemType().then((res) => {
        this.itemType = res.data;
      });
    },
    getSparetypetwo() {
      getSparetypetwo().then((res) => {
        this.spareType = res.data;
      });
    },
    //选择机构输入框
    repair_org() {
      this.chooseorgInitOption.title = "选择外修商";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.repair_business;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    //选择定损员输入框
    insurance_ding() {
      // console.log(11111111);
      this.chooseorgInitOptionDing.title = "选择定损员";
      this.chooseorgInitOptionDing.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOptionDing.showModal = true;
      this.$refs.insurance_ding.handleSearch();
    },
    clearBaoxian() {
      this.search.createogid = "";
      this.search.createogid_name = "";
    },
    //选择机构
    getChooseGY(item) {
      // console.log(item);
      this.chooseorgInitOption.showModal = false;

      if (item.ogtype == 2) {
        this.search.ogid = item.id;
        this.search.createogid_name = item.name;
      } else if (item.ogtype == 3) {
        this.search.createogid = item.id;
        this.search.createogid_name = item.name;
      }
    },
    clear() {
      this.search.createuser = "";
      this.search.createuser_name = "";
    },
    //选择定损员
    getChooseGYDing(item) {
      // console.log(item);
      this.chooseorgInitOptionDing.showModal = false;

      this.search.createuser = item.id;
      this.search.createuser_name = item.real_name;
    },
    // 分页
    handlePage(value) {
      this.search.p = value;
      this.handleSearch();
    },
    handlePageSize(value) {
      this.search.t = value;
      this.handleSearch();
    },
    // 列表
    handleSearch() {
      let params = this.search;
      getDingsunList(params).then((res) => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.dataQingdan = res.data.list;
          // this.button = res.data.button
        }
      });
    },
    // 查询
    handleSearchAction() {
      this.search.p = 1;
      this.search.is_export = 0;
      this.handleSearch();
    },
    // 日期
		checkDate(date) {
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
  },
  mounted() {
    this.handleSearch();
    this.getSparetypetwo(); //配件分类
    this.getItemType(); //项目分类  1外修 2回收
  },
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.dizhiselecls {
  width: 342px;
  margin-right: 3px;
}

.map {
  width: 100%;
  height: 400px;
}

.map .search {
  margin-bottom: 65px;
}

.userfnpt {
  width: 300px;
  margin-right: 3%;
  display: inline-block;
}
</style>

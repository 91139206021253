<template>
  <div class="content">
    <div class="rowone">
      <div class="oneChart">
          <div class="onetitle">保险公司减损</div>
          <div class="pieChart">
            <stack :datatype="datatype"></stack>
          </div>
      </div>
      <div class="oneChart">
          <div class="onetitle">区域减损</div>
           <div class="pieChart">
             <regional :datatype="quyu_jiansun_zoushi"></regional>
           </div>
      </div>
      <div class="oneChart">
          <div class="onetitle">分类减损</div>
           <div class="pieChart">
             <classification :datatype="fenlei_jiansun_zoushi"></classification>
           </div>
      </div>
    </div>
    <div class="rowtwo">
      <div class="twoChart">
          <div class="onetitle">配件有效单数</div>
           <div class="pieCharttwo">
             <effective :datatype="peijian_youxiao_zoushi"></effective>
           </div>
      </div>
      <div class="twoChart">
          <div class="onetitle">配件减损</div>
           <div class="pieCharttwo">
             <derogation :datatype="peijian_jiansun_zoushi"></derogation>
           </div>
      </div>
      <div class="twoChart">
          <div class="onetitle">车辆品牌减损</div>
           <div class="pieCharttwo">
             <impairment :datatype="brand_jiansun_zoushi"></impairment>
           </div>
      </div>
    </div>
     <div class="rowthree">
      <div class="threeChart">
          <div class="onetitle">配件维修价格对比</div>
           <div class="pieChartthree">
             <parts :datatype="parts_price_zoushi"></parts>
           </div>
      </div>
      <div class="threeChartyi">
          <div class="onetitle">物损修复金额</div>
           <div class="pieChartthree">
             <damage :datatype="susun_zoushi"></damage>
           </div>
      </div>
      <div class="threeCharter">
          <div class="onetitle">车辆品牌频次分析</div>
           <div class="pieChartthree">
             <brand :datatype="brand_pinci_zoushi"></brand>
           </div>
      </div>
    </div>
  </div>
</template>
<script>
import stack from "../../components/charts/stack.vue"
import regional from "../../components/charts/regional.vue"
import parts from "../../components/charts/parts.vue"
import brand from "../../components/charts/brand.vue"
import damage from "../../components/charts/damage.vue"
import classification from "../../components/charts/classification.vue"
import effective from "../../components/charts/effective.vue"
import derogation from "../../components/charts/derogation.vue"
import impairment from "../../components/charts/impairment.vue"
import {waixiujiansunfenxi} from "@api/admin";
export default {
  components:{
    stack,regional,parts,brand,damage,classification,effective,derogation,impairment
  },
  data() {
    return {
      datatype:{},
      quyu_jiansun_zoushi:{},
      fenlei_jiansun_zoushi:{},
      peijian_youxiao_zoushi:{},
      peijian_jiansun_zoushi:{},
      brand_jiansun_zoushi:{},
      parts_price_zoushi:{},
      brand_pinci_zoushi:{},
      susun_zoushi:{},
    };
  },
  methods: {
    handleSearch() {
      waixiujiansunfenxi().then(res => {
        if (res.errCode == 0) {
          this.datatype = res.data.baoxian_jiansun_zoushi;
          this.quyu_jiansun_zoushi = res.data.quyu_jiansun_zoushi;
          this.fenlei_jiansun_zoushi = res.data.fenlei_jiansun_zoushi;
          this.peijian_youxiao_zoushi = res.data.peijian_youxiao_zoushi;
          this.peijian_jiansun_zoushi = res.data.peijian_jiansun_zoushi;
          this.brand_jiansun_zoushi = res.data.brand_jiansun_zoushi;
          this.parts_price_zoushi = res.data.parts_price_zoushi;
          this.brand_pinci_zoushi = res.data.brand_pinci_zoushi;
          this.susun_zoushi = res.data.susun_zoushi;
        }
      });
    },
  },
  mounted() {
    this.handleSearch();
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  padding:0 20px;
}
.pieChart {
  width: 100%;
    height: 154px;
}
.pieCharttwo {
  width: 100%;
  height: 155px;
}
.pieChartthree {
  width: 100%;
height: 139px;
}
.rowone {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  display: flex;
  color: #333333;
  justify-content: space-between;
  align-content: center;
}
.rowtwo {
  width: 100%;
   height: 200px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-content: center;
}
.rowthree {
  width: 100%;
    height: 183px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-content: center;
}
.oneChart {
  width:32.5%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.twoChart {
  width: 32.5%;
height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.threeChart{
width: 32.5%;height: 183px;
background: #FFFFFF;
box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
border-radius: 6px;
}
.threeChartyi{
width: 45%;
height: 183px;
background: #FFFFFF;
box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
border-radius: 6px;
}
.threeCharter{
 width: 20%;
height: 183px;
background: #FFFFFF;
box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
border-radius: 6px;
}
.onetitle {
  width: 263px;
    height: 41px;
  padding-left: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 50px;
font-size: 14px;

}

</style>

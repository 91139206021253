<style lang="less">
  @import './login.less';
</style>

<template>
  <div class="login">
    <!-- <h2>山东远东保险公估有限公司</h2> -->
    <div class="login-con" >
		<img src='@/assets/images/sy_logo.png' style="margin-bottom: 20px;"></img>
      <Card icon="log-in" title="密码安全" :bordered="false" style="height:450px;">
        <div class="form-con">
          <LoginForm @on-success-valid="handleSubmit"></LoginForm>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
 // import zhongzhuan from '@/libs/zhongzhuan'
import zhongz from '../../components/main/components/tags-nav/zhongz.js'
import LoginForm from '@cmp/editpwd-aq'
import { mapActions } from 'vuex'
import { editpwdaq } from '@/api/user.js'
export default {
  components: {
    LoginForm
  },
  methods: {
    ...mapActions([]),
    handleSubmit ({ account, oldpwd, pwd, pwd2 }) {
      editpwdaq(  { account, oldpwd, pwd, pwd2 }).then(res => {
        if (res.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '密码修改成功，请重新登陆'
          })
          this.$router.push({
            name: 'login'
          })
        } else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      })
    },
    guanbifuns(){
      zhongz.$emit('guanbi','guo');
    },
  }
}
</script>

<style>
</style>

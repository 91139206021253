<template>
    <div class="main" id="brand">

    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        num:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getbrand()
    },
    getbrand(){

      var chartDom = document.getElementById('brand');
      var myChart = echarts.init(chartDom);
            myChart.resize()
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          position:'left'
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        color: ["#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE","#3BA272","#FC8452",'#9A60B4','#EA7CCC','#75BE9C'],
        series: [
          {
            labelLine: {
              smooth: 0.2,
              length:5,
              length2: 5,
               length3: 5,
                length4: 5,
                length5: 5,
            },
            label: {
              align: "left",
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },
                textStyle: {
                  fontSize: 9,
                  //显示文字的大小，
                  color: "#333333",
                },
              },
            },
            name: '',
            type: 'pie',
            radius: [20,60],
            center: ['44%', '45%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 10
            },
            // data: [
            //   { value: 40, name: '奥迪' },
            //   { value: 38, name: '别克' },
            //   { value: 32, name: '雪佛兰' },
            //   { value: 30, name: '现代' },
            //   { value: 28, name: '奔驰' },
            //   { value: 26, name: '丰田' },
            //   { value: 22, name: '宝马' },
            //   { value: 18, name: '福特' },
            //   { value: 18, name: '大众' },
            //   { value: 18, name: '本田' },
            // ]
            data: this.data1.num
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getbrand()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

import  * as axios from '@/libs/api.request'

export const getTableData = () => {
  return axios.get('get_table_data',''
  )
}

export const getDragList = () => {
	return axios.get('get_drag_list','')
  
}

export const errorReq = () => {
	
	
  return axios.post('error_url','')
}

export const saveErrorLogger = info => {
	return axios.post('save_error_logger',info)
 
}

export const uploadImg = formData => {
  return axios.request({
  
    data: formData,
	
	  url: 'image/upload'
  })
}

export const getOrgData = () => {
  return axios.get(
     'get_org_data',
   )
}

export const getTreeSelectData = () => {
  return axios.get(
    'get_tree_select_data'
  
  )
}

<template>
    <div class="main" id="mmp">

    </div>
</template>
<script>
import * as echarts from 'echarts';
 import 'echarts/map/js/china.js'
export default {
  props: {
    datatype: {
      type: Array
    },
  },
  data(){
    return{
      dataList:[
          {name: '南海诸岛', value: 0},
          {name: '北京', value: 2170.7},
          {name: '天津', value: 1559.6},
          {name: '上海', value: 2423.78},
          {name: '重庆', value: 3048.43},
          {name: '河北', value: 7556.3},
          {name: '河南', value: 9605},
          {name: '云南', value: 4800.5},
          {name: '辽宁', value: 4359.3},
          {name: '黑龙江', value: 3788.7},
          {name: '湖南', value: 6860.2},
          {name: '安徽', value: 6323.6},
          {name: '山东', value: 10047.2},
          {name: '新疆', value: 2444.67},
          {name: '江苏', value: 8029.3},
          {name: '浙江', value: 5737},
          {name: '江西', value: 4622.1},
          {name: '湖北', value: 5917},
          {name: '广西', value: 4885},
          {name: '甘肃', value: 2625.71},
          {name: '山西', value: 3702.35},
          {name: '内蒙古', value: 2534},
          {name: '陕西', value: 3835.44},
          {name: '吉林', value: 2717.43},
          {name: '福建', value: 3941},
          {name: '贵州', value: 3580},
          {name: '广东', value: 11346},
          {name: '青海', value: 3983.8},
          {name: '西藏', value: 3371.5},
          {name: '四川', value: 8341},
          {name: '宁夏', value: 681.79},
          {name: '海南', value: 925.76},
          {name: '台湾', value: 2369},
          {name: '香港', value: 748.25},
          {name: '澳门', value: 63.2}
      ],
      data1:[]
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getbidWinning()
    },
    getbidWinning(){
      var chartDom = document.getElementById("mmp");
      var myChart = echarts.init(chartDom, null, { width: 380, height: 120 });
         myChart.resize();
      var option;

      option = {
        tooltip: {
          formatter:function(params,ticket, callback){
            return params.seriesName+'<br />'+params.name+':'+(params.value?params.value:0)
          }//数据格式化
        },

        layoutCenter: ['60%', '49%'],//距离左右，上下距离的百分比
        layoutSize:'100%',//map百分比大小
        visualMap: {
          //地图图例
          show: true,
          showLabel: true,
          min: 0,
          max: 99999,
          left: 'left',
          top: 'bottom',
          //图例大小
          itemHeight: 10,
          itemWidth: 15,
          itemGap: 5, textStyle: {
            fontSize:8,
          },


          padding:[0, 0, 10 ,20],
          inRange: {
            color: ['#fff4e6', '#dd2002',"#1f307b"]//取值范围的颜色
          },
          pieces: [
            //根据数据大小，各省显示不同颜色
            {
              gte: 10000,
              label: ">= 10000",
              color: "#1f307b",
            },
            {
              gte: 5000,
              lt: 9999,
              label: "5000 - 9999",
              color: "#3c57ce",
            },
            {
              gte: 1000,
              lt: 4999,
              label: "1000 - 4999",
              color: "#6f83db",
            },
            {
              gte: 100,
              lt: 999,
              label: "100 - 999",
              color: "#9face7",
            },
            {
              lt: 100,
              label: "<100",
              color: "#bcc5ee",
            },
          ],
        },
        geo: {
          map: 'china',
          roam: false,//不开启缩放和平移
          zoom:1.28,//视角缩放比例

          label: {
            normal: {
              show: true,
              fontSize:'5',//注意：地图省份名字字体如果过大会导致字体重叠
              color: 'rgba(0,0,0,0.7)'
            }
          },
          itemStyle: {
            normal:{
              borderColor: 'rgba(0, 0, 0, 0.2)'
            },
            emphasis:{
              areaColor: '#F2D5AD',//鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: '#EEEEEE'
            }
          }
        },
        series : [
          {
            name: '外修商区域统计',
            type: 'map',
            geoIndex: 0,
            // data:this.dataList,
            data:this.data1,

          }
        ]

      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getbidWinning()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

<template>
	<Card class="col-right-card min-height">
		<p slot="title">{{usertype==1?"外修商配件匹配新增编辑":"外修商配件外修范围查看"}}	
		</p>
		<Form  ref="ogdata" :model="ogdata" :rules="dataRules">
			<Row class='og_row'>
				<FormItem class="FromItemnpt  org-width" label="外修商" prop="name" >
					<div v-if='isEdit'>
						<Input :readonly=true v-model="ogdata.name" />
					</div>
					<div v-else>
						<Input v-model="ogdata.name " :readonly=true />
            <Icon type="ios-search" slot="suffix" @click="chooseGy_org"/>
					</div>
				</FormItem>
				<Button type="primary" @click="save"  v-show="usertype==1">保存</Button>
			</Row>
		</Form>
	<Modal v-model="slemodal" title="选择外修商" width="900">
			<Form :label-width="80">
				<FormItem class="FromItemnpt" label="名称">
					<Input v-model="search.kw" clearable />
					</FormItem>
				<Button type="primary" @click="handleSearch">搜索</Button>
			</Form>
      <Table height="500" border stripe :columns="canshu" :data="canshudata"></Table>
      <div class="my-page">
        <Page  show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
        <div style="height:30px;"></div>
      </div>
			<div slot="footer"></div>
		</Modal>
		<div class="tree_view">
			<div v-for="pitem in partsList" class="p_item">
				<Card>
					<div slot="title" class="title_p">
					<Button type="warning">{{pitem.name}}</Button>
					</div>
					<div class="tree_select">
						<Tree :data="pitem.ptreedata" show-checkbox multiple @on-check-change='checkchang'></Tree>
						</div>
				</Card>
			</div>
		</div>
		<div></div>
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';
import { getorglist, getpartsorglist ,addorgcat} from '@/api/admin';

export default {
	data() {
		return {
			usertype:'2',
		
			ogdata: {},
			partsList: [],
			canshudata:[],
			slemodal:false,
			search: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.repair_business,
				kw: null,
				status: null,
				stime: null,
				etime: null
			},
			canshu: [
				
				{
					title: '外修商编号',
					key: 'code',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
				{
					title: '外修商名称',
					key: 'name',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				},
			
				{
					title: '主营',
					key: 'main_camp',
					align: 'center',
					minWidth: 100
				},
				{
					title: '所在省市区',
					key: 'area_name',
					align: 'center',
					minWidth: 150
				},
				{
					title: '所在地址',
					key: 'address',
					align: 'center',
					minWidth: 100
				},
			
				{
					title: '添加时间',
					key: 'createtime',
					align: 'center',
					minWidth: 170
				},
				{
					title: '状态',
					key: 'status_str',
					align: 'center',
					minWidth: 100
				},
				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
									
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.handleUpdateModal(params.row);
										}
									}
								},
								'选择'
							)
						]);
					}
				}
			],
			dataRules: {
				name: [
					{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}
				]
			},
			ptreedata: [],
			isEdit:false,
			
			matchmodal: true
		};
	},
	methods: {
		save(){
			var that = this;
			
			this.$refs.ogdata.validate(valid => {
				if (valid) {
					let add_acts=[];
					that.partsList.forEach(pitem => {
						let tdata=pitem.ptreedata[0].children;
						tdata.forEach(titem => {
							if(titem.checked){
								let pare={
									base_spare_type:titem.csptyoe,
									base_spare:titem.id,
								}
								add_acts.push(pare)
							}
						})
					})
					let params = {
						add_acts:add_acts,
						ogid:that.ogdata.id,
		
					};
					addorgcat(params).then(res => {
						if (res.errCode == 0) {
							// if(){
							// 	that.$route.push('')
							// }
							
							that.$Modal.success({
								title: '提示',
								content: '保存成功'
							});
						} else {
							that.$Modal.error({
								title: '提示',
								content: res.errMsg
							});
						}
					});
				}
			});
		},
		checkchang(arr,val){
			console.log('111',arr,val);
		},
		
	    // 分页
	    handlePage(value) {
	    	this.search.p = value;
	    	this.handleSearch();
	    },
	    handlePageSize(value) {
	    	this.search.t = value;
	    	this.handleSearch();
	    },
	    
	    handleSearch(){
	    	let params = this.search;
	    getorglist(params).then(res => {
	    	if (res.errCode == 0) {
	    		this.totalCount = parseInt(res.data.total);
	    		this.canshudata = res.data.list;
	    	}
	    });	
	    },
		chooseGy_org(){
			this.slemodal=true
			
		    this.handleSearch()
		},
		handleUpdateModal(val){
			this.slemodal=false;
			this.ogdata=val;
			this.matchparts(val.id)
		},
		matchparts(id) {
			this.matchmodal = true;
			const msg = this.$Message.loading({
				content: '加载中...',
				dutration: 0
			});

			getpartsorglist({
				ogid: id.id
			}).then(res => {
				console.log('getpartsorglist', res);
				if (res.errCode === 0) {
					this.partsList = res.data.list;
					this.partsList.forEach(pitem => {
						// debugger
						pitem.ptreedata=[];
						let p={
							title:'全部',
							expand: true,
							children:[],
						};
						pitem.data.forEach(item1 => {
						
							
							let ch = false;
							if (item1.status == 0) {
								ch = false;
							} else {
								ch = true;
							}
							let cl = {
								checked: ch,
								id: item1.id,
								title: item1.cspname,
								csptyoe: item1.csptyoe
							};

							p.children.push(cl);
						});
						pitem.ptreedata.push(p)
					});
					
					setTimeout(msg, 0);
				}
			});
		}
	
	},
	mounted() {
		this.usertype=util.getUserType();
		if(this.$route.query.type=='edit'){
			this.isEdit=true;
			this.ogdata = this.$route.query.ogdata;
			this.matchparts(this.ogdata);
		}else{
			this.isEdit=false;
		}
		
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}
.og_row{
	button{
		margin-left: 100px;
	}
}
.map .search {
	margin-bottom: 65px;
}
.org-width {
	width: 480px !important;
}
.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
.right_title {
	line-height: 20px;
	font-size: 16px;
	color: #17233d;
}
.tree_view {
	width: 100%;
	display: flex;
	.p_item {
		width: 20%;
		.title_p{
			color: #f57d22;
		}
		
		.tree_select{
			overflow: scroll;
			height: 600px;
		}
	}
}
</style>

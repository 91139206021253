<style lang="less">
  @import './login.less';
</style>

<template>
  <div class="login">
    <!-- <h2>山东远东保险公估有限公司</h2> -->
    <div class="login-con" >
		<img src='@/assets/images/sy_logo.png' style="margin-bottom: 20px;"></img>
      <Card icon="log-in" title="欢迎登录" :bordered="false" style="height:250px;">
        <div class="form-con">
          <login-form @on-success-valid="handleSubmit"></login-form>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
 // import zhongzhuan from '@/libs/zhongzhuan'
import zhongz from '../../components/main/components/tags-nav/zhongz.js'
import LoginForm from '@cmp/login-form'
import { mapActions } from 'vuex'
import { setToken, setUserType, setTokenUser } from '@/libs/util'
import { login } from '@/api/user.js'
export default {
  components: {
    LoginForm
  },
  methods: {
    ...mapActions([
      // 'handleLogin',
      // 'getUserInfo'
    ]),
    handleSubmit ({ userName, password }) {
      login({ userName, password }).then(res => {
        if (res.errCode === 0) {
          setTokenUser(res.data)
            this.$store.commit('setAccess',res.data.access)
          this.guanbifuns()
          this.$router.push({
            name: this.$config.homeName
          })
        } else if(res.errCode == 1881){
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
          this.$router.push({
            name: 'editpwd'
          })
        }else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      })
    },
    guanbifuns(){
      zhongz.$emit('guanbi','guo');
    },
  },
  mounted() {
    var userName = this.$route.query.userName;
    var password = this.$route.query.password;
    if(userName == 'yddbzy'){ 
      this.handleSubmit({userName,password});
    }
  },
}
</script>

<style>
</style>

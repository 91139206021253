<template>
    <div class="main" id="parts">

    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        bidmoney:[],
        checkmoney:[],
        piancha:[],
        offermoney:[],
        baojia:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getparts()
    },
    getparts(){
      var app = {};
      var chartDom = document.getElementById('parts');
      var myChart = echarts.init(chartDom,{width:380,height:110});
            myChart.resize()
      var option;

      app.configParameters = {
        rotate: {
          min: -90,
          max: 200
        },

      };
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: false,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      };
      option = {
        grid: {
          top:26,
          left: "2%",
          right: "0%",
          bottom: "17%",
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['配件平均价', '配件平均报价','平均中标价','平均审核价','偏差价格'],
          padding:[2, 0, 0 ,0],
          itemHeight:9,
          itemWidth:11,
// 间距
          itemGap:5,
          textStyle: {

            fontSize: 8,
            // padding: [0, 0, 0, 10]
          },

        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'left',
          top: 'center',

        },
        xAxis: [
          {
            type: 'category',
            //   隐藏刻度线
            axisTick: { show: false },
            // data: ['前保险杠','大灯框架','倒车灯','外门把手','后备箱盖','后侧尾板','前桥','后桥'],
            data: this.data1.date,
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:9,
                fontFamily:"MicrosoftYaHei"

              },
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            //   隐藏刻度线
            axisTick: { show: false },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:9,
                fontFamily:"MicrosoftYaHei"

              },
            }
          }
        ],
        series: [
          {
            name: '配件平均价',
            type: 'line',
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            // data: [520, 532,532, 661,441, 634, 390,320],
            data: this.data1.offermoney,
            color:["#4A62D3"]
          },
          {
            name: '配件平均报价',
            type: 'line',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#84CD7A"],
            // data: [1500, 1432,1632, 1322,1441, 1034, 1390,1200],
            data: this.data1.baojia,
          },
          {
            name: '平均中标价',
            type: 'line',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#FAC858"],
            // data: [770, 882,532, 661,941, 1034, 1200,1600],
            data: this.data1.bidmoney,
          },
          {
            name: '平均审核价',
            type: 'line',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#EE6666"],
            // data: [880, 532,532, 1261,1331, 1534, 1090,1500],
            data: this.data1.checkmoney,
          },
          {
            name: '偏差价格',
            type: 'line',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            barCategoryGap: '15%',
            color:["#73C0DE"],
            // data: [620, 632,732, 861,841, 774, 990,1020],
            data: this.data1.piancha,
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getparts()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

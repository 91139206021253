<template>
  <Card class="col-right-card min-height">
    <p slot="title">支付方式</p>
    <Table height="500" border stripe :columns="shangpincoumm" :data="canshudatas"></Table>
  </Card>
</template>

<script>
  import { getPaymentMethodView, getPaymentMethodAdd } from '@/api/backend'

  export default {
    data () {
      return {
        shangpincoumm: [
          {
            title: '支付方式',
            key: 'pic_url',
            render: (h, params) => {
              return h('div', [
                h('img', {
                  attrs: {
                    src: params.row.pic_url, style: 'width: 50px;height: 50px;float: left;margin: 10px 10px;',
                  },
                }),
                h('b', {
                  style: {
                    display: 'block', lineHeight: '40px',
                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                  },
                }, params.row.name),
                h('span', {
                  style: {
                    color: '#999',
                  },
                }, params.row.content),
              ])
            }
          },
          {
            title: '操作',
            align: 'center',
            width: '130',
            render: (h, params) => {
              let vm = this
              return h('div', [
                h('i-switch', {
                    props: {
                      type: 'primary',
                      value: params.row.value,
                    },
                    on: {
                      'on-change': (event) => {
                        vm.canshudatas[params.index].value = event
                        vm.editData()
                      }
                    }
                  },
                  [h('span', { slot: 'open', domProps: { innerHTML: '开' } }),
                    h('span', { slot: 'close', domProps: { innerHTML: '关' } })
                  ])
              ])
            }
          },
        ],
        canshudatas: [],
      }
    },
    methods: {
      viewData () {
        getPaymentMethodView().then(res => {
          if (res.errCode == 0) {
            this.canshudatas = []
            let vm = this
            for (let i in res.data) {
              vm.canshudatas.push(res.data[i])
            }
          }
        })
      },
      editData () {
        let params = {}
        let vm = this
        for (let i = 0; i < this.canshudatas.length; i++) {
          params[vm.canshudatas[i].type] = vm.canshudatas[i]
        }
        getPaymentMethodAdd(params).then(res => {
          if (res.errCode == 0) {
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
            this.viewData()
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      }
    },
    mounted () {
      this.viewData()
    },
  }
</script>

<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
</style>

<template>
  <Form ref="loginForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit">
    <FormItem >
      <p>密码安全强度弱，不符合安全规范，请修改后登陆；若有疑问可联系客服：15589957820</p>
    </FormItem>
    <FormItem prop="account">
      <Input v-model="form.account" placeholder="请输入用户名">
        <span slot="prepend">
          <Icon :size="16" type="ios-person"></Icon>
        </span>
      </Input>
    </FormItem>
    <FormItem prop="oldpwd">
      <Input type="password" v-model="form.oldpwd" placeholder="请输入原密码">
        <span slot="prepend">
          <Icon :size="14" type="md-lock"></Icon>
        </span>
      </Input>
    </FormItem>
    <FormItem prop="pwd">
      <Input type="password" v-model="form.pwd" placeholder="请输入新密码,6-15位包含字母数字的组合">
        <span slot="prepend">
          <Icon :size="14" type="md-lock"></Icon>
        </span>
      </Input>
    </FormItem>
    <FormItem prop="pwd2">
      <Input type="password" v-model="form.pwd2" placeholder="请重复输入新密码">
        <span slot="prepend">
          <Icon :size="14" type="md-lock"></Icon>
        </span>
      </Input>
    </FormItem>
    <FormItem class='login_btn'>
      <Button @click="handleSubmit" type="primary" long>修改</Button>
    </FormItem>
  </Form>
</template>
<script>
export default {
  name: 'LoginForm',
  props: {
    accountRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ]
      }
    },
    oldpwdRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      }
    },
    pwdRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      }
    },
    pwd2Rules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      }
    },
  },
  data () {
    return {
      form: {
        userName: '',
        password: ''
      }
    }
  },
  computed: {
    rules () {
      return {
        account: this.accountRules,
        oldpwd: this.oldpwdRules,
        pwd: this.pwdRules,
        pwd2: this.pwd2Rules,

      }
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$emit('on-success-valid', {
            account: this.form.account,
            oldpwd: this.form.oldpwd,
            pwd: this.form.pwd,
            pwd2: this.form.pwd2
          })
        }
      })
    }
  }
}
</script>
<style scoped="">
	.login_btn{
		margin-top: 30px;
	}
</style>

<template>
  <Card class="col-right-card min-height">
    <p slot="title">文章管理</p>
    <Form :label-width="120">
      <FormItem class="FromItemnpt " label="标题" >
        <Input clearable placeholder="文章标题" v-model="search.kw" clearable/>
      </FormItem>

        <FormItem class="FromItemnpt" label="类型" >
          <Select v-model="search.type" clearable>
            <Option v-for="(item,index) in hotNewsType" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>


        <FormItem class="FromItemnpt" label="状态" >
          <Select v-model="search.status" clearable>
            <Option v-for="(item,index) in closetatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <FormItem class="FromItemnpt" label="添加时间：">
          <DatePicker
            style="width:190px;"
            type="daterange"
            :options="dateRange"
            @on-change="checkDate"
          ></DatePicker>
        </FormItem>

      
      <Button type="primary" @click="handleSearchAction">查询</Button>
    </Form>
    <div class="gray-nona"></div>
    <div class="tablebnt">
      <Button type="primary" v-show="button.enable" @click="handleEdit('2')">启用(显示)</Button>
      <Button type="primary" v-show="button.disabled" @click="handleEdit('3')">禁用(隐藏)</Button>
      <Button type="primary" v-show="button.delete" @click="handleEdit('1')">删除</Button>
    </div>
    <Table stripe border
           height="500"
           :columns="canshu"
           :data="canshudata"
           @on-select="handleCheckedOrDeleteTable"
           @on-select-all="handleCheckedOrDeleteTable"
           @on-select-cancel="handleCheckedOrDeleteTable"
           @on-select-all-cancel="handleCheckedOrDeleteTable"
    ></Table>
    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height:30px;"></div>
    </div>
  </Card>
</template>

<script>
  import { getHotNews, getHotNewsEdit} from '@/api/backend'
  import {getClosetatus,getHotNewsType } from '@/api/dictionary'
  export default {
    data () {
      return {
        closetatus:[],
        hotNewsType:[],
        checkedIds: [],
        checkedId: '',
        button:{},
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          action_id:'242'
        },

        totalCount: 10,
        canshu: [
          { type: 'selection', align: 'center', width: 60 },
         { title: 'id', key: 'id', align: 'center' },
          {
            title: '图片', align: 'center', key: 'path_url', width: 130,
            render: (h, params) => {
              return h('viewer', [h('img',
                {
                  attrs: {
                    src: this.canshudata[params.index].path_url,
                    onerror: this.canshudata[params.index].path_url
                  },
                  style: {
                    width: '',
                    height: '60px',
                    marginTop: '5px'
                  },
                }
                )]
              )
            },
          },
          {
            title: '标题',
            align: 'center',
            minWidth: 130,
            key: 'title',
            render: (h, params) => {
              let vm = this
              return h('span', {
                domProps: {
                  innerHTML: params.row.title
                },
                style: {
                  color: '#2d8cf0 ',
                  cursor: 'pointer'
                },
                on: {
                  click () {
                    let query = { id: params.row.id }
                    vm.$router.push({
                      name: 'essar_new_detail',
                      query: query
                    })
                  }
                }
              })
            }
          },
          { title: '阅读量', key: 'read_num', align: 'center'  ,minWidth: 130},
          { title: '点赞量', key: 'star_num', align: 'center'  ,minWidth: 130},
          { title: '类型', key: 'type_str', align: 'center'  ,minWidth: 130},
          { title: '排序', key: 'sortcode', align: 'center'  ,minWidth: 130},
          { title: '状态', key: 'status_str', align: 'center'  ,minWidth: 130},
          { title: '发布时间', key: 'createtime', align: 'center'  ,minWidth: 130},
          // {
          //   title: '操作',
          //   width: '180',
          //   align: 'center',
          //   render: (h, params) => {
          //     let vm = this
          //     return h('div', [
          //       h('Button', {
          //         props: {
          //           type: 'primary',
          //           size: 'small',
          //           disabled:!this.button.delete
          //         },
          //         style: {
          //           marginRight: '5px'
          //         },
          //         on: {
          //           click: () => {
          //             this.$router.push({
          //               name:'essar_new_detail'
          //             })
          //           }
          //         }
          //       }, '编辑')
          //     ])
          //   }
          // }

        ],
        dateRange: {
          shortcuts: [
            {
              text: "今日",
              value() {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: "本周",
              value() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: "本月",
              value() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: "本季度",
              value() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        },
        canshudata: [],
      }
    },
    methods: {
      getClosetatus () {
        getClosetatus().then(res => {
          this.closetatus = res.data
        })
      },
      getHotNewsType () {
        getHotNewsType().then(res => {
          this.hotNewsType = res.data
        })
      },
      checkDate(date) {
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      handleCheckedOrDeleteTable (data1) {
        if (data1[0]) {
          this.checkedId = data1[0].id
        }
        let ids = []
        data1.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },

      // 查询
      handleSearchAction () {
        this.search.p = 1
        this.search.is_export = 0
        this.handleSearch()
      },
      // 分页
      handlePage (value) {
        this.search.p = value
        this.handleSearch()
      },
      handlePageSize (value) {
        this.search.t = value
        this.handleSearch()
      },
      // 列表
      handleSearch () {
        let params = this.search
        getHotNews(params).then(res => {
          this.totalCount = parseInt(res.data.total)
          this.canshudata = res.data.list
          this.button = res.data.button
        })
      },

      // 删除
      handleEdit (type) {
        if (this.checkedIds.length > 0) {
          let txt = ''
          if (type == '1') {
            txt = '删除'
          } else if (type == '2') {
            txt = '启用'
          } else {
            txt = '禁用'
          }
          let params = {}
          params.id = this.checkedIds
          params.type = type
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为'+txt+'状态么？?</p>',
            onOk: () => {
              this.getDeleteWrite(params)
            },
            onCancel: () => {
              this.$Message.info('您取消了删除操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },

      getDeleteWrite (params) {
        getHotNewsEdit(params).then(res => {
          if (res.errCode == 0) {
            this.handleSearch()
            this.checkedIds = []
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      },
    },
    mounted () {
      this.getClosetatus()
      this.getHotNewsType()
      this.handleSearch()
    }
  }
</script>


<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
</style>

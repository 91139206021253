<template>
  <Submenu :name="`${parentName}`">
    <template slot="title">
      <!--菜单的头-->
      <common-icon :type="parentItem.icon || ''" />
      <!-- 159 -->
      <span>{{ showTitle(parentItem) }}</span>
    </template>

    <template v-for="item in children">
      <template v-if="item.children && item.children.length === 1">
        <SideMenuItem
          v-if="showChildren(item)"
          :key="`menu-${item.name}`"
          :parent-item="item"
        ></SideMenuItem>
        <MenuItem
          v-else
          :name="getNameOrHref(item, true)"
          :key="`menu-${item.children[0].name}`"
        >
          <common-icon :type="item.children[0].icon || ''" />
          <span>{{showTitle(item.children[0]) }}</span>
        </MenuItem>
      </template>

      <template v-else>
        <SideMenuItem
          v-if="showChildren(item)"
          :key="`menu-${item.name}`"
          :parent-item="item"
        ></SideMenuItem>

        <MenuItem
          id="spanlihover"
          v-else
          :name="getNameOrHref(item)"
          :key="`menu-${item.name}`"
        >
          <common-icon id="spanhovericon" :type="item.icon || ''" />
          <span id="spanhover">{{ showTitle(item) }}</span>
        </MenuItem>
      </template>
    </template>
  </Submenu>
</template>
<script>
import mixin from "./mixin";
import itemMixin from "./item-mixin";
export default {
  name: "SideMenuItem",
  mixins: [mixin, itemMixin],
  mounted() {},
};
</script>
<style scoped>
#spanlihover:hover #spanhover {
  color: #16d6cc !important;
}
#spanlihover:hover #spanhovericon {
  color: #16d6cc !important;
}


.ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active, .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item-active:hover {
  border-right: none;
  color: #fff;
  background: #f4701f !important;
}

 .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu .ivu-menu-item:hover {
  border-right: none;
  color: #fff;
  background: #f4701f !important;
}
/* #spanhover:hover{,
    color: #fff !important;
} */
</style>
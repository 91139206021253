import { get,post } from '@/libs/api.request'
import { getUserType } from '@/libs/util'

/**
 * 车主类型
 */
export const getOwnerType = (params) => {
  return get('dictionary/get-owner-type', params)

}

/**
 * 代理商级别
 */
export const getOrgAgentLevel = (params) => {
  return get('dictionary/get-org-agent-level', params)
}


// 门店级别
export const getMerchantLevel = (params) => {
  return get('dictionary/get-merchant-level', params)
}




// 门店类型
export const getMerchantType = (params) => {
  return get('dictionary/get-merchant-type', params)
}


// 门店资质
export const getMerchantQuality = (params) => {
  return get('dictionary/get-merchant-quality', params)
}



/**
 * 机构状态
 */
export const getGetOrgStatus = (params) => {
  return get('dictionary/get-org-status', params)
}


/**
 * 启用 、 冻结
 */
export const getStatus = () => {

  return get('dictionary/get-status')
}
/**
 * 启用 、 冻结
 */
export const getorgstatus = () => {

  return get('dictionary/get-org-status')
}

export const getorglevel = () => {

  return get('dictionary/get-org-level')
}

/**
 * 地理区划
 */
export const getRegion = () => {
  const params = {
  }
  return get('dictionary/get-region', params)
}

/**
 * 会员类型
 */
export const getMemberType = () => {
  return get('dictionary/get-member-type')
}

/**
 * 按钮类型
 * @returns {*}
 */
export const getButtonType = () => {
  return post('dictionary/get-button-type')
}

/**
 * 按钮类型
 * @returns {*}
 */
export const getSeeStatus = () => {
  return post('dictionary/get-see-status')
}

// 用户状态
export const getUserStatus = () => {
  return post('dictionary/get-user-status')
}

// 物损指派类型
export const getWsModeType = () => {
  return post('dictionary/get-ws-mode-type')
}


// 用户状态
export const getInputType = () => {
  return post('dictionary/get-input-type')
}

// 商品标签
export const getGoodsTag = () => {
  return post('dictionary/get-goods-tag')
}


// 商品标签
export const getExpectedDeliveryDate = () => {
  return post('dictionary/get-expected-delivery-date')
}
// 单位
export const getGoodsUnit = () => {
  return post('dictionary/get-goods-unit')
}
// 优惠券 适用范围
export const getCouponRangeType = () => {
  return post('dictionary/get-coupon-range-type')
}

// 优惠券类型
export const getCouponType = () => {
  return post('dictionary/get-coupon-type')
}
// 优惠券 - 参加活动商品类型
export const getCouponGoodsType = () => {
  return post('dictionary/get-coupon-goods-type')
}

// 后台订单状态
export const getOrderStatus = () => {
  return post('dictionary/get-order-status')
}


// 付款方式
export const getPayMethod = () => {
  return post('dictionary/get-pay-method')
}

//促销活动状态
export const getGoodsActivityStatus = () => {
  return post('dictionary/get-goods-activity-status')
}

//折扣类型
export const getActivityRatioType = () => {
  return post('dictionary/get-activity-ratio-type')
}

//自动收货时间
export const getAutoReceiptTime = () => {
  return post('dictionary/get-auto-receipt-time')
}
//交易设置 - 购物返积分 - 积分计算方式
export const getBackIntegralMethod = () => {
  return post('dictionary/get-back-integral-method')
}

//交易设置 - 购物返积分 - 购物返积分节点
export const getBackScoreNode = () => {
  return post('dictionary/get-back-score-node')
}

//交易设置 - 积分抵扣 - 抵扣计算方式
export const getDeductIntegralMethod = () => {
  return post('dictionary/get-deduct-integral-method')
}
//提现设置 - 提现方式
export const getCashWithdrawalMethod = () => {
  return post('dictionary/get-cash-withdrawal-method')
}
//短信通知 - 模板类型
export const getNoticeTemplateType = () => {
  return post('dictionary/get-notice-template-type')
}

//短信通知 - 模板类型
export const getNotifierType = () => {
  return post('dictionary/get-notifier-type')
}
export const getOrgLogisticsStatus = () => {
  return post('dictionary/get-org-logistics-status')
}

export const getGoodsStar = () => {
  return post('dictionary/get-goods-star')
}

export const getMemberGroupTagType = () => {
  return post('dictionary/get-member-group-tag-type')
}

//余额提现状态
export const getCashWithdrawalStatus = () => {
  return post('dictionary/get-cash-withdrawal-status')
}

//积分流水/余额变动 积分变动类型
export const getIntegralFlowType = () => {
  return post('dictionary/get-integral-flow-type')
}
//佣金流水 变动类型
export const getCommissionFlowType = () => {
  return post('dictionary/get-commission-flow-type')
}

// 会员消费TOP排名
export const getTopRank = () => {
  return post('dictionary/get-top-rank')
}

export const getValid = () => {
  return post('dictionary/get-valid')
}


export const getMenuAscription = () => {
  return post('dictionary/get-menu-ascription')
}


// 广告相关开始
export const getAdPosition = () => {
  return post('dictionary/get-ad-position')
}
export const getAdDataType = () => {
  return post('dictionary/get-ad-data-type')
}
export const getAdMediaType = () => {
  return post('dictionary/get-ad-media-type')
}
// 广告相关结束

export const getUserBackStatus = () => {
  return post('dictionary/get-user-back-status')
}

export const getApplyStatus = () => {
  return post('dictionary/get-apply-status')
}

export const getApplyCashWithdrawalMethod = () => {
  return post('dictionary/get-apply-cash-withdrawal-method')
}

export const getBackUType = () => {
  return post('dictionary/get-back-u-type')
}

export const getDictionaryEditOrderStatus = () => {
  return post('dictionary/get-edit-order-status')
}


export const getEditOrderStoreStatus = (params) => {
  return post('dictionary/get-edit-order-store-status', params)
}


export const getAllAddUserType = (params) => {
  return post('dictionary/get-all-add-user-type', params)
}


export const getAddUserType = (params) => {
  return post('dictionary/get-add-user-type', params)
}

export const getHotNewsType = (params) => {
  return post('dictionary/get-hot-news-type', params)
}

export const getClosetatus = (params) => {
  return post('dictionary/get-closetatus', params)
}


export const getReceiptCateType = (params) => {
  return post('dictionary/get-receipt-cate-type', params)
}

export const getReceiptType = (params) => {
  return post('dictionary/get-receipt-type', params)
}


export const getEvaluateStarType = (params) => {
  return post('dictionary/get-evaluate-star-type', params)
}

export const getSmsQueueStatus = (params) => {
  return post('dictionary/get-sms-queue-status', params)
}

export const getShortProvince = (params) => {
  return post('dictionary/get-short-province', params)
}


export const getScoreType = (params) => {
  return post('dictionary/get-score-type', params)
}
export const getOrderZzType = (params) => {
    return post('dictionary/get-zou-zhang-type', params)
}

export const getGoodsServe = (params) => {
  return post('dictionary/get-goods-serve', params)
}

export const getGoodsStatus = (params) => {
  return post('dictionary/get-goods-status', params)
}

export const getMerchantServe = (params) => {
  return post('dictionary/get-merchant-serve', params)
}



export const getCarType = (params) => { // 门店类型
  return post('dictionary/get-car-type', params)
}



export const getWithdrawUserType = (params) => {
  return post('dictionary/get-withdraw-user-type', params)
}

export const getBalanceType = (params) => {
  return post('dictionary/get-balance-type', params)
}


export const getTransactionType = (params) => {
  return post('dictionary/get-transaction-type', params)
}


export const getWhether = (params) => { // 是否竞价
  return post('dictionary/get-whether', params)
}

export const getWhetherstatus = (params) => { // 维保推送状态
  return post('dictionary/get-status', params)
}

export const GetWhether = (params) => {
  return post('dictionary/get-whether', params)
}

export const getBackUtype = (params) => {
  return post('dictionary/get-back-u-type', params)
}
//经销商订货 是否出入库
export const getaddWarehouse = (params) => {
  return post('dictionary/get-warehouse-type', params)
}

//1 外修   2 物损
export const getOrgAreaType = (params) => {
    return post('dictionary/org-area-type', params)
}

//中标规则
export const getOrgbidType = (params) => {
    return post('dictionary/orgbid-type', params)
}
//统计中心-分类报表-配件类型
export const getItemType = (params) => {
    return post('dictionary/get-item-type',params)
}
//统计中心-分类报表-外修配件分类
export const getSparetypetwo = (params) => {
    return post('dictionary/get-sparetypetwo',params)
}
//物损分类
export const getWusunType = (params) => {
  return post('dictionary/get-wusun-type2',params)
}
//
export const getRegoption = (params) => {
    return post('common/regoption', params)
}

export const getBusinessstatus = () => {
  return get('dictionary/get-business-status')
}

export const getRecoverstatus = () => {
  return get('dictionary/get-recover-status')
}

















 


























<template>
  <div style="white-space: nowrap;">
    <Select :disabled="isEdit" v-model="viewdata.daareaid" v-if="isShowArea" class="search-col" style="width:100px;margin-right: 10px;" clearable>
      <Option v-for="(item,key) in regions" :value="key" :key="item">{{ item }}</Option>
    </Select>
    <Select :disabled="isEdit" v-model="viewdata.provinceModel" class="search-col" style="width:105px;" clearable>
      <Option v-for="item in provinceList" :value="item.id" :key="item.id" >{{ item.name }}</Option>
    </Select>
    <Select :disabled="isEdit" v-model="viewdata.cityModel" class="search-col" style="width:105px;margin-left: 10px;" clearable>
      <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
    <Select :disabled="isEdit" v-model="viewdata.districtModel" class="search-col" style="width:105px;margin-left: 10px;" clearable>
      <Option v-for="item in districtList" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
  </div>
</template>
<script>
  import { getCity, getRegion } from '@/api/common'

  export default {
    name: 'cityview',
    props: {
      cityitems: Object,
      isShowArea: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        viewdata: {
          daareaid: 0,
          provinceModel: '',
          cityModel: '',
          districtModel: '',
          edit_view:true,
        },

        regions: [],
        provinceList: [],
        cityList: [],
        districtList: [],
        isEdit: false
      }
    },
    methods: {
      resetValue () {
        this.viewdata.provinceModel = ''
        this.viewdata.cityModel = ''
        this.viewdata.districtModel = ''
        this.viewdata.edit_view = true
      },
      returnCity () {
        this.$emit('selectFunc', this.viewdata)
      },
      getprovince () {
        getCity().then(res => {
          if (res.errCode === 0) {
            this.provinceList = res.data
          }
        })
      },
      getcity () {
        if (this.viewdata.provinceModel) {
          getCity(this.viewdata.provinceModel).then(res => {
            if (res.errCode === 0) {
              this.cityList = res.data
            }
          })
        } else {
          this.cityList = []
          this.districtList = []
          this.viewdata.cityModel = ''
          this.viewdata.districtModel = ''
        }
      },
      getdistrict () {
        if (this.viewdata.cityModel) {
          getCity(this.viewdata.cityModel).then(res => {
            if (res.errCode === 0) {
              this.districtList = res.data
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.districtList = []
          this.viewdata.districtModel = ''
        }
      },
      getRegion () {
        getRegion().then(res => {
          if (res.errCode === 0) {
            this.regions = res.data
            // console.log('regions', this.regions)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    mounted () {
      this.getprovince()
      if (this.isShowArea) {
        this.getRegion()
      }
    },
    watch: {
      'viewdata.edit_view' (newValue, oldValue) {
        if (newValue == true) {
          this.isEdit = false
        }  else {
          this.isEdit = true
        }
      },

      'viewdata.provinceModel' (newValue, oldValue) {
        if (oldValue && oldValue !== '0') {
          this.viewdata.cityModel = ''
          this.viewdata.districtModel = ''
          this.cityList = []
          this.districtList = []
        }
        this.getcity()
        this.returnCity()
      },
      'viewdata.cityModel' (newValue, oldValue) {
        if (oldValue && (oldValue !== '0' || oldValue !== '' ||oldValue !== undefined )) {
          this.viewdata.districtModel = ''
          this.districtList = []
        }
        this.getdistrict()
        this.returnCity()
      },
      'viewdata.districtModel' () {
        this.returnCity()
      }
    }
  }
</script>

<template>
  <div class="myApp Mivu-card-bodys">
    <Row class="my-row">
      <div class="cls1o">
        <div class="col-left MTree">
          <Card>
            <p slot="title">导航结构树</p>
            <div style="overflow:auto;padding-bottom: 20px;">
              <Tree :data="tree" @on-select-change="handleGetDataByTree" empty-text="加载中···"></Tree>
            </div>
          </Card>
        </div>
        <div class="col-right">
          <Card class="col-right-card">
            <p slot="title">导航栏目</p>
            <Form :label-width="100">
              <div>
                <FormItem class="FromItemnpt" label="导航名称：">
                  <Input v-model="search.name" clearable/>
                </FormItem>
                <FormItem class="FromItemnpt" label="状态：">
                  <Select v-model="search.status" clearable>
                    <Option v-for="(item,key) in rolestatus" :value="key" :key="item"> {{ item }}</Option>
                  </Select>
                </FormItem>
                <Button type="primary" @click="handleSearchAction">查询</Button>
              </div>
            </Form>
            <div class="gray-nona"></div>
            <div class="table-bnt">
              <Button type="primary" @click="handleCreateModel" v-show="button.add">新增</Button>
              <Button type="primary" @click="handleUpdateModal" v-show="button.edit">修改</Button>
              <Button type="primary" @click="handleStatusSuccess" v-show="button.enable">启用</Button>
              <Button type="primary" @click="handleStatusFail" v-show="button.disabled">禁用</Button>
              <Button type="primary" @click="handleDelete" v-show="button.delete">删除</Button>


              <!-- 新增  / 修改导航 -->
              <Modal v-model="newModel" :title="titleData" width="600" draggable>
                <div>
                  <Form :label-width="100" ref="subData" :model="subData" :rules='rules'>
                    <FormItem label="上级分类" required>
                      <Input disabled v-model="subData.pid_name"/>
                    </FormItem>
                    <FormItem label="类型" prop="m_type">
                      <RadioGroup v-model="subData.m_type">
                        <Radio key="1" label="1" :disabled="disabled">菜单</Radio>
                        <Radio key="2" label="2" :disabled="disabled">按钮</Radio>
                      </RadioGroup>
                    </FormItem>
                    <FormItem label="按钮类型：" v-show="subData.m_type=='2' && subData.id==''" prop="button_arr" required>
                      <Checkbox-group v-model="subData.button_arr">
                        <Checkbox v-for="(item,key) in buttonlists" :label="key" :key="item">{{item}}</Checkbox>
                      </Checkbox-group>
                    </FormItem>
                    <FormItem label="栏目名称" prop="name" v-show="subData.id || (subData.id =='' && subData.m_type == 1) ">
                      <Input v-model="subData.name"/>
                    </FormItem>
                    <FormItem label="菜单图标">
                      <Input v-model="subData.icon"/>
                    </FormItem>
                    <FormItem label="路径显示" v-show="subData.m_type =='1'" prop="action_name" :disabled="disabled">
                      <Input v-model="subData.action_name" :disabled="actiondisabled"/>
                    </FormItem>
                    <FormItem label="排序">
                      <Input v-model="subData.mysort"/>
                    </FormItem>
                    <FormItem label="状态" prop="status">
                      <RadioGroup v-model="subData.status">
                        <Radio :key="item" v-for="(item,index) in rolestatus" :label="index">
                          {{item}}
                        </Radio>
                      </RadioGroup>
                    </FormItem>
                  </Form>
                </div>
                <div slot="footer">
                  <Button @click="newModel = false">取消</Button>
                  <Button type="primary" @click="getAdminRoleAdd">保存</Button>
                </div>
              </Modal>
            </div>
            <Table stripe border height="420" :columns="columns" :data="data1" @on-select="handleCheckedOrDeleteTable"
                   @on-select-all="handleCheckedOrDeleteTable"
                   @on-select-cancel="handleCheckedOrDeleteTable"></Table>
            <div class="my-page">
              <Page :total="totalCount" :current="search.p" @on-change="handleChangePage"
                    :page-size="parseInt(search.t)"></Page>
              <span class="pageSelect">每页显示
                  <Select v-model="search.t" @on-change="handleChangePageLimit" :label="search.t">
                      <Option value='10'>10</Option>
                      <Option value='20' v-show="totalCount > 10">20</Option>
                      <Option value='30' v-show="totalCount > 20">30</Option>
                      <Option value='40' v-show="totalCount > 30">40</Option>
                      <Option value='50' v-show="totalCount > 40">50</Option>
                  </Select>
              </span>
            </div>
          </Card>
        </div>
      </div>
    </Row>
  </div>
</template>
<script>
  import { getMenuList, getSysMenu, getSysMenuAdd, getSysMenuView, getSysMenuEdit } from '@/api/backend'
  import { getStatus, getButtonType } from '@/api/dictionary'

  export default {
    name: 'nav_column',
    data () {
      const validatabutton = (rule, value, callback) => {
        if (this.subData.id) {
          callback()
        } else if (this.subData.m_type == '1' && this.subData.id == '') {
          callback()
        } else if (this.subData.id == '' && this.subData.m_type == '2' && (this.subData.button_arr == undefined || this.subData.button_arr.length == 0)) {
          callback(new Error('必填'))
        } else {
          callback()
        }
      }
      const validataaction_name = (rule, value, callback) => {
        if (this.subData.m_type == '1' && this.subData.action_name == '') {
          callback(new Error('必填'))
        } else {
          callback()
        }
      }
      const validataame = (rule, value, callback) => {
        if (this.subData.id == '' && this.subData.m_type == '1' && this.subData.name == '') {
          callback(new Error('必填'))
        } else if (this.subData.id && this.subData.name == '') {
          callback(new Error('必填'))
        } else {
          callback()
        }
      }
      return {
        rules: {
          name: [
            { required: true, validator: validataame, trigger: 'blur' }
          ],
          status: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          m_type: [
            { required: true, message: '必选', trigger: 'change' },
          ],
          button_arr: [
            { validator: validatabutton, trigger: 'blur' }
          ],
          action_name: [
            { required: true, validator: validataaction_name, trigger: 'blur' }
          ],
        },
   
        actiondisabled: false,
        disabled: false,
        titleData: '',
        newModel: false, // 新增模态框显示状态
        editModel: false,  // 编辑模态框显示状态
        totalCount: 10, // 总条数
        tree: [], // 组织结构树
        search: {
          name: '',
          status: 0,
          menu_type:'',
          pid: null,
          p: 1, // 当前页码
          t: 10, // 每页显示条数
        },
        button: {},
        subData: {
          name: '',
          icon: '',
          id: '',
          pid_name: '',
          pid: '',
          mysort: 100,
          status: 1,
          m_type: '1',
          button_arr: [],
          action_name: '',
        }, // 新增数据
        data1: [],  // 列表数据
        buttonlists: [], // 按钮类型
        rolestatus: [], // 是否启用   1.启用。2 禁用
        checkedIds: [],
        columns: [ // 表头
          { type: 'selection', align: 'center', width: 60 },
          { title: '导航名称', align: 'center', key: 'name', minWidth: 100 },
          { title: '类型', align: 'center', key: 'type_str', minWidth: 90 },
          { title: '路径显示', align: 'center', key: 'action_name', minWidth: 100 },
          { title: '上级名称', align: 'center', key: 'pid_menu_name', minWidth: 100 },
          { title: '状态', align: 'center', key: 'status_str', minWidth: 100 },
          { title: '添加时间', align: 'center', key: 'createtime', minWidth: 150 },
        ],
      }
    },
    methods: {
 
      // 是否已启用
      getStatus () {
        getStatus().then(res => {
          this.rolestatus = res.data

        })
      },
      // 获取结构树
      handleGetTree () {
        getMenuList({expand:true}).then(res => {
          this.tree = res.data
          this.subData.pid = res.data[0].id
          this.subData.pid_name = res.data[0].title
        })
      },

      // 选择上级
      handleGetDataByTree (data3) {
        if (data3.length > 0) {
          this.search.pid = data3[0].id
          this.handleSearchAction()
          this.subData.pid = data3[0].id
          this.subData.pid_name = data3[0].title
        } else {
          this.subData.pid = ''
          this.subData.pid_name = ''
        }
      },
      // 查询
      handleSearchAction () {
        this.search.p = 1
        this.handleSearch()
      },
      handleChangePage (data1) { // 切换页码
        this.search.p = data1
        this.handleSearch()
      },
      handleChangePageLimit () { // 切换每页显示条数
        this.search.p = 1
        this.handleSearch()
      },

      // 导航列表
      handleSearch () {
        this.search.action_id = '35'
        let params = this.search
        let permiose = getSysMenu(params)
        permiose.then(res => {
          this.totalCount = parseInt(res.data.total)
          this.data1 = res.data.list
          this.button = res.data.button
        }).catch(err => {
          console.log(err)
        })
      },

      // checkbox 选中触发
      handleCheckedOrDeleteTable (data2) {
        if (data2[0] != undefined) {
          this.checkedId = data2[0].id
        }
        let ids = []
        data2.map(function (item, key) {
          ids.push(item.id)
        })

        this.checkedIds = ids

      },

      changeButton () {// 按钮类型
        getButtonType().then(res => {
          this.buttonlists = res.data
        })
      },
      init () {
        this.disabled = false
        this.titleData = '新增导航信息'
        this.subData.id = ''
        this.subData.mysort = 100
        this.subData.status = '1'
        this.subData.name = ''
        this.subData.icon = ''
        if (this.subData.pid_name == '顶级菜单') {
          this.subData.action_name = '顶级菜单'
        } else {
          this.subData.action_name = ''
        }
        this.subData.m_type = '1'
        this.subData.button_arr = []
      },
      handleCreateModel () {
        // this.handleGetTree()
        if (this.subData.pid_name) {
          this.reset()
          this.init()
          this.newModel = true
        } else {
          this.$Modal.info({ title: '提示', content: '请选择左侧组织结构树' })
          return
        }
      },

      handleUpdateModal () {
        this.titleData = '修改导航信息'
        if (this.checkedIds.length == 1) {
          this.reset()
          this.handlemenuview()
          this.newModel = true
        } else {
          this.$Modal.info({ title: '提示', content: '请选择一项' })
        }
      },
      handlemenuview () {
        let params = { id: this.checkedId }
        getSysMenuView(params).then(res => {
          this.subData = res.data
          if (this.subData.pid_name == '顶级菜单') {
            this.subData.action_name = '顶级菜单'
          }
          this.disabled = true
        })
      },
      handleStatusSuccess () {
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为启用状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '2'
              let permiose = getSysMenuEdit(params)
              permiose.then(res => {
                if (res.errCode == 0) {
                  this.handleSearch()
                  this.checkedIds = []
                  this.handleGetTree()
                }
                this.$Message.info(res.errMsg)
              })
            },
            onCancel: () => {
              this.$Message.info('您取消了启用操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },
      handleStatusFail () {
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为停用状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '3'
              let permiose = getSysMenuEdit(params)
              permiose.then(res => {
                if (res.errCode == 0) {
                  this.checkedIds = []
                  this.handleSearch()
                  this.handleGetTree()
                }
                this.$Message.info(res.errMsg)
              })
            },
            onCancel: () => {
              this.$Message.info('您取消了启用操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },
      handleDelete () {
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '1'
              let permiose = getSysMenuEdit(params)
              permiose.then(res => {
                if (res.errCode == 0) {
                  this.checkedIds = []
                  this.handleSearch()
                  this.handleGetTree()
                }
                this.$Message.info(res.errMsg)
              })
            },
            onCancel: () => {
              this.$Message.info('您取消了删除操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },

     
      getAdminRoleAdd () {
        this.$refs.subData.validate((valid) => {
          if (valid) {
            let params = this.subData
            getSysMenuAdd(params).then(res => {
              if (res.errCode == 0) {
                if (this.subData.id) {
                  this.checkedIds = []
                }
                this.newModel = false
                this.handleGetTree()
                this.handleSearch()
                this.$Modal.success({ title: '提示', content: res.errMsg })
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            }).catch(err => {
              console.log(err)
            })
          }
        })
      },
      reset () {
        this.$refs.subData.resetFields()
      }
    },

    mounted () {
      this.handleGetTree()
      this.getStatus()
      this.handleSearch()
      this.changeButton()
    },
    watch: {
      'subData.pid_name' () {
        if (this.subData.pid_name == '顶级菜单') {
          this.actiondisabled = true
        } else {
          this.actiondisabled = false
        }
      }
    }
  }

</script>

<style lang="less">
  @import '../style2.less';
  //排版
  // .pai_01{width:20%;float: left;display:inline-block;}
  // .pai_02{width:80%;display:inline-block;}
 
 .Mivu-card-bodys .ivu-card-body {
    height: 658px;
    overflow: auto;
}
</style>

<template>
  <div class="content">
    <div class="rowone">
      <div class="oneChartone">
        <div class="onetitle">配件修复率</div>
        <div class="pieChart" id="fail"></div>
      </div>
      <div class="oneCharttwo">
        <div class="onetitle">外修月度分析走势图</div>
        <div class="pieChart">
          <external :datatype="waixiuyuedu_zoushi"></external>
        </div>
      </div>
      <div class="oneCharttwo">
        <div class="onetitle">外修商修复率</div>
        <div class="pieChart" >
          <businessman :datatype="waixiuxiufu_zoushi"></businessman>
        </div>
      </div>
    </div>
    <div class="rowtwo">
      <div class="twoChart">
        <div class="onetitle">保险公司修复率</div>
        <div class="otherchart">
          <repair :datatype="baoxianxiufu_zoushi"></repair>
        </div>
      </div>
      <div class="twoChart">
        <div class="onetitle">区域修复率</div>
        <div class="otherchart">
          <region :datatype="qvyuxiufu_zoushi"></region>
        </div>
      </div>
      <div class="twoChart">
        <div class="onetitle">物损修复率</div>
        <div class="otherchart" id="itemRepair"></div>
      </div>
    </div>
    <div class="rowthree">
      <div class="threeChart">
        <div class="twotitle">物损单数月度走势</div>
        <div class="chartSize" id="monthly"></div>
      </div>
      <div class="threeChart2">
        <div class="twotitle">
          <p>外修商</p>
          <p>修复成功数</p>
        </div>
        <div class="chartSize" style="background: #f5f7f9 !important;padding:10px;">
          <ol style="width: 100%; list-style: none">
            <li
              :class="index % 2 == 0 ? 'core' : 'corewhite'"
              v-for="(item, index) in core"
              :key="index"
            >
              <div class="corelist" style="width: 230px;">
                <p>{{ item.name }}</p>
                <p>{{ item.value }}</p>
              </div>
            </li>
            <li class="core" style="position: absolute;
  bottom: 0px;">
              <div class="corelist" style="width: 210px;">
                <p>总计</p>
                <p>{{ core_num }}</p>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div class="threeChart2">
        <div class="twotitle">
          <p>物损商</p>
          <p>修复成功数</p>
        </div>
        <div class="chartSize" style="background: #f5f7f9 !important;padding:10px;">
          <ol style="width: 100%; list-style: none">
            <li
              :class="index % 2 == 0 ? 'core' : 'corewhite'"
              v-for="(item, index) in core2"
              :key="index"
            >
              <div class="corelist" style="width: 230px;">
                <p>{{ item.name }}</p>
                <p>{{ item.value }}</p>
              </div>
            </li>
            <li class="core" style="position: absolute;
  bottom: 0px;">
              <div class="corelist" style="width: 210px;">
                <p>总计</p>
                <p>{{ core_num2 }}</p>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div class="threeChart">
        <div class="twotitle">
          <p>定损员</p>
          <p>修复成功数</p>
        </div>
        <div class="chartSize" style="background: #f5f7f9 !important;">
           <ol style="width: 100%; list-style: none">
            <li
              :class="index % 2 == 0 ? 'core' : 'corewhite'"
              v-for="(item, index) in loss"
              :key="index"
            >
              <div class="corelist">
                <p>{{ item.createuser_str }}</p>
                <p>{{ item.succeed }}</p>
              </div>
            </li>
            <li class="core" style="position: absolute;
  bottom: 0px;">
              <div class="corelist">
                <p>总计</p>
                <p>{{ loss_num }}</p>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import repair from "../../components/charts/repair.vue"
import region from "../../components/charts/region.vue"
import businessman from "../../components/charts/businessman.vue"
import external from "../../components/charts/external.vue"
import {waixiuchenggongfenxi} from "@api/admin";

export default {
  components:{
    repair,region,businessman,external
  },
  data() {
    return {
      peijianxiufulv:{},
      waixiuyuedu_zoushi:{},
      waixiuxiufu_zoushi:{},
      baoxianxiufu_zoushi:{},
      qvyuxiufu_zoushi:{},
      wusunxiufulv_zoushi:{},
      wusundanyuedu_zoushi:{},
      core: [],
      core2: [],
      // core: [
      //   {name: "镇江车太贤维修服务中心",value: 5,},
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心",value: 5,},
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      // ],
      loss:[],
      // loss:[
      //   {name: "镇江车太贤维修服务中心",value: 5,},
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心",value: 5,},
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      //   { name: "镇江车太贤维修服务中心", value: 5 },
      // ]
      core_num:0,
      core_num2:0,
      loss_num:0,

    };
  },
  methods: {
    handleSearch() {
      waixiuchenggongfenxi().then(res => {
        if (res.errCode == 0) {
          this.peijianxiufulv = res.data.peijianxiufulv;
          this.waixiuyuedu_zoushi = res.data.waixiuyuedu_zoushi;
          this.waixiuxiufu_zoushi = res.data.waixiuxiufu_zoushi;
          this.baoxianxiufu_zoushi = res.data.baoxianxiufu_zoushi;
          this.qvyuxiufu_zoushi = res.data.qvyuxiufu_zoushi;
          this.wusunxiufulv_zoushi = res.data.wusunxiufulv_zoushi;
          this.wusundanyuedu_zoushi = res.data.wusundanyuedu_zoushi;
          this.core = res.data.waixiushang_list.list;
          this.core2 = res.data.waixiushang_list.list2;
          this.core_num = res.data.waixiushang_list.zongshu;
          this.core_num2 = res.data.waixiushang_list.zongshu2;
          this.loss = res.data.dingsunyuan_list.list;
          this.loss_num = res.data.dingsunyuan_list.zongshu;
          this.getfail();
          this.getitemRepair();
          this.getmonthly();
        }
      });
    },
    getfail() {
      var chartDom = document.getElementById("fail");
      var myChart = echarts.init(chartDom, null, { width: 298, height: 140 });
      var option;
      option = {
        tooltip: {
          trigger: 'item'
        },
        global: false,
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}%',
          position:'right'
        },
        series: [
          {     radius: '100%',
            center: ["53%", "50%"],
            label: {
              align: "left",
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },
                textStyle: {
                  fontSize: 8,
                  //显示文字的大小，
                  color: "#000",
                  
                },
              },
            },
            labelLine: {

              smooth: 0.2,
              length:10,
              length2: 5
            },
            name: "",
            type: "pie",
            radius: "65%",
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            // data: [
            //   { value: 1048, name: " 超时未报价" },
            //   { value: 735, name: "报价未修复" },
            //   { value: 580, name: "取件后无法修" },
            //   { value: 484, name: "取件无法修" },
            //   { value: 300, name: "修复成功" },
            // ],
            data: this.peijianxiufulv,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            legend: {
              orient: "horizontal",
              x: "right",
            },
            color: ["#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE"],
          },
        ],
      };
      option && myChart.setOption(option);
    },
    getitemRepair() {
      var itemR = document.getElementById("itemRepair");
      var itemRe = echarts.init(itemR, null,  { width: 394, height: 135 });
      window.addEventListener("resize",function () {
    myChart.resize()
});
      var optionsix;

      optionsix = {
        grid: {
          left: "1.5%",
          right: "0%",
          bottom: "0%",
          width: "380",
          height: "115",
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          axisLine: {
            //x轴坐标轴，false为隐藏，true为显示
            show: false,
          },


          //   隐藏刻度线
          axisTick: { show: false },
          axisLabel: {
            interval: 0,//使x轴文字显示全
            textStyle: {
              color: "#333333",//Y轴内容文字颜色
              fontSize:8
            },
          },
          type: "category",
          // data: [
          //   "土建施工",
          //   "护栏施工",
          //   "限高杆施工",
          //   " 绿化施工",
          //   "灯杆施工",
          //   "伸缩门施工",
          //   "通信光缆施工",
          //   "道闸施工",
          // ],
          data: this.wusunxiufulv_zoushi.date,
        },
        yAxis: {
          min:0,
          max:100,
          type: "value",
          axisLine: {
            //y轴坐标轴，false为隐藏，true为显示
            show: false,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %' ,
            interval: 0,//使x轴文字显示全
            textStyle: {
              color: "#333333",//Y轴内容文字颜色
              fontSize:9
            },
          },
          //   隐藏刻度线
          axisTick: { show: false },

        },
        series: [
          {
            center: ["50%", "23%"],
            // data: [20, 20, 15, 80, 70, 10, 30, 20],
            data: this.wusunxiufulv_zoushi.num,
            type: "bar",
            label: {
              formatter: "{d}",
              textStyle: {
                fontSize: 13,
                //显示文字的大小，
                color: "#000",
              },
            },
            color: ["#84CD7A"],
            barWidth: 22, //柱图宽度
            barGap: "70%" /*多个并排柱子设置柱子之间的间距*/,
          },
        ],
      };

      optionsix && itemRe.setOption(optionsix);
    },
    getmonthly() {
      var itemM = document.getElementById("monthly");
      var itemMo = echarts.init(itemM,  { width: 384, height: 135 });
      var optionsix;

      optionsix = {
        grid: {
          top:20,
          left: "1.5%",
          right: "0%",
          bottom: "17%",
          width: "390",
          height: "120",
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          axisLine: {
            //x轴坐标轴，false为隐藏，true为显示
            show: false,
          },
          //   隐藏刻度线
          axisTick: { show: false },
          axisLabel: {
            interval: 0,//使x轴文字显示全
            textStyle: {
              color: "#333333",//Y轴内容文字颜色
              fontSize:9
            },
          },
          type: "category",
          // data: [
          //   "1",
          //   "2",
          //   "3",
          //   " 4",
          //   "5",
          //   "6",
          //   "7",
          //   "8",
          // ],
          data: this.wusundanyuedu_zoushi.date,
        },
        yAxis: {
          type: "value",
          axisLine: {
            //y轴坐标轴，false为隐藏，true为显示
            show: false,
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value}' ,
            interval: 0,//使x轴文字显示全
            textStyle: {
              color: "#333333",//Y轴内容文字颜色
              fontSize:9
            },
          },
          //   隐藏刻度线
          axisTick: { show: false },

        },
        series: [
          {
            center: ["50%", "23%"],
            // data: [20, 20, 15, 80, 70, 10, 30, 20],
            data: this.wusundanyuedu_zoushi.num,
            type: "bar",
            label: {
              formatter: "{d}",
              textStyle: {
                fontSize: 13,
                padding:[0,20,0,0],
                //显示文字的大小，
                color: "#000",
              },
            },
            color: ["#FAC858"],
            barWidth: 22, //柱图宽度
            barGap: "70%" /*多个并排柱子设置柱子之间的间距*/,
          },
        ],
      };

      optionsix && itemMo.setOption(optionsix);
    },
  },
  mounted() {
    this.getfail();
    this.getitemRepair();
    this.getmonthly();
    this.handleSearch();
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  /* padding: 20px; */
}
.pieChart {
  width: 100%;
height: 145px;
}
.otherchart {
  width: 100%;
  height: 140px;
}
.chartSize {
  width: 100%;
  position: relative;
      height: 153px;
  background-color: white;
}
.rowone {
  width: 100%;
  height: 195px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;

}
.rowtwo {
  width: 100%;
  height: 190px;
  margin-bottom: 10px;
  display: flex;
  color: #333333;
  justify-content: space-between;
  align-content: center;

}
.rowthree {
  width: 100%;
  height: 195px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-content: center;

}
.oneChart {
  width: 32.6%;
  height: 100%;
  border-radius: 5px;
  padding: 12px 0px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
}
.oneCharttwo {
width: 37.6%;
  height: 100%;
  border-radius: 5px;
  padding: 12px 0px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
}
.oneChartone {
  width: 299px;
  height: 100%;
  border-radius: 5px;
  padding: 12px 0px 0px 0px;
  background: #ffffff;
  
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
}
.onetitle {
  width: 263px;
  height: 35px;
  padding-left: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
  font-size: 14px;
}
.twotitle {
height: 31px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
      font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.twoChart {
 width: 33%;
  height: 100%;
  background: #ffffff;
  padding: 12px 0px 0px 0px;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.threeChart {
width: 33%;
  padding: 12px 0px 0px 0px;
    height: 195px;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.threeChart2 {
width:16%;
  padding: 12px 0px 0px 0px;
    height: 195px;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.twocontent {
  width: 100%;
  height: calc(100vh-40px);
  background-color: aquamarine;
}
.core {
  width: 100%;
     height:16px;
  display: flex;
  justify-content: center;
  background: #f5f7f9;
}
.corewhite {
  width: 100%;
    height: 16px;
  display: flex;
  justify-content: center;
  background: white;
}
.corelist {
  width: 500px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.corelist p {
  font-size: 9px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
         -webkit-transform-origin-x: 0; 
            -webkit-transform: scale(0.8);
  color: #333333;
}

</style>

<template>
  <Modal
    v-model="initOption.showModal"
    width="1000px"
    :mask-closable="false">
    <p slot="header" style="color:#f60;text-align:center;">
      <Icon type="information-circled"></Icon>
      <span>{{initOption.title}}</span>
    </p>
    <Form
      ref="roleForm"
      :model="subForm"
      :rules="formRules"
      :label-width="90">
      <div>
        <FormItem prop="id" v-if="initOption.action === 'edit'">
          <input type="hidden" :model="subForm.id">
        </FormItem>

        <FormItem class="userfnpt" label="头像" style="height: 60px;">
          <viewer style="display: inline-block;" :image="subForm.url" v-if="subForm.url">
            <div class="yingimgs">
              <img :src="subForm.url" style="width: 60px;height: 60px;"
                   class="upload-image-view"/>
            </div>
          </viewer>
          <Upload
            ref="upload"
            :default-file-list="defaultList"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :format="['jpg','jpeg','png','gif']"
            :max-size="1024"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :headers="{Authorization: 'Bearer ' + this.access_token}"
            :data="{type: 'avatar',
								agent:4,
								loginIdent:1,loginIdent:'1'}"
            type="drag"
            :action=this.uploadHttp
            style="display: inline-block;width:58px;">
            <div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
        </FormItem>
      </div>
      <div>
        <FormItem class="userfnpt" label="用户账号" prop="username">
          <Input type="text" v-if="this.initOption.id" disabled v-model="subForm.username"
                 placeholder="请输入用户账号"></Input>
          <Input type="text" v-else v-model="subForm.username" placeholder="请输入用户账号"></Input>

        </FormItem>
        <FormItem class="userfnpt" label="手机号" prop="mobile">
          <Input type="text" v-model="subForm.mobile" placeholder="请输入手机号"></Input>
        </FormItem>

        <FormItem class="userfnpt" label="真实姓名" prop="real_name">
          <Input type="text" v-model="subForm.real_name" placeholder="请输入真实姓名"></Input>
        </FormItem>
        <FormItem class="userfnpt" label="登录密码" prop="password">
          <Input type="password" v-model="subForm.password" placeholder="默认手机号后六位"></Input>
        </FormItem>
        <FormItem class="userfnpt" label="确认密码" prop="confirmpwd">
          <Input type="password" v-model="subForm.confirmpwd" placeholder="默认手机号后六位"></Input>
        </FormItem>
        <FormItem class="userfnpt" label="生日">
          <DatePicker style="width:210px;" type="date" :value="subForm.birthday" placeholder="请选择生日"
                      @on-change="setBirthday"></DatePicker>
        </FormItem>
        <FormItem class="userfnpt" label="QQ">
          <Input v-model="subForm.qq" placeholder="请输入QQ号码"></Input>
        </FormItem>
        <FormItem class="userfnpt" label="邮箱">
          <Input v-model="subForm.email" placeholder="请输入邮箱"></Input>
        </FormItem>

        <FormItem class="userfnpt" label="所属角色"  prop="role_id" v-show="!initOption.is_agent">
          <Select v-model="subForm.role_id" class="search-col">
            <Option v-for="item in roles" :value="item.id" :key="item.id">{{ item.role_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="userfnpt" label="关联代理商" prop="ogname" v-show="!initOption.is_agent && subForm.role_id == '11'">
           <Input v-model="subForm.ogname" :disabled=true >
            <Icon type="ios-search" slot="suffix" @click="chooseDLS_org"/>
          </Input>
        </FormItem>




        <FormItem class="userfnpt" style="width:100%;white-space: nowrap;" label="所属区域"  prop="cityCheck">
          <cityView style="height: 40px;    display: inline-block;" class="search-col"  ref="cityView"
                    @selectFunc="selectFunc"></cityView>
          <Input style="width:468px;margin-left: 10px;" v-model="subForm.address" placeholder="详细地址"></Input>
        </FormItem>
       

        <FormItem class="userfnpt" label="用户性别" prop="sex">
          <RadioGroup v-model="subForm.sex">
            <Radio label="0">不详</Radio>
            <Radio label="1">男</Radio>
            <Radio label="2">女</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem class="userfnpt" label="状态" prop="status">
          <RadioGroup v-model="subForm.status">
            <Radio label="1">启用</Radio>
            <Radio label="2">禁用</Radio>
            <Radio label="3">驳回</Radio>
          </RadioGroup>
        </FormItem>
      </div>
      <chooseDLS_org ref="chooseDLS_org" :initOption="chooseorgInitOption" @my-event="getChooseDLS"></chooseDLS_org>
      <FormItem style="width:92%" label="描述">
        <Input type="textarea" :autosize="{minRows: 2,maxRows: 5}" v-model="subForm.describe"
               placeholder="请输入描述"></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="primary" @click="handleSubmit">保存</Button>
      <Button type="error" @click="reset" v-if="initOption.action === 'add'">重置</Button>
    </div>
  </Modal>
</template>
<script>
  import { getRoleDropDown, addUserAccount, getUserView } from '@/api/backend'
  import { getToken,getUser } from '@/libs/util'
  import chooseDLS_org from '@/view/my-components/org/chooseDLS_org'
  import config from '@/config'
  import cityView from '../mycomponents/cityView.vue'

  export default {
    components: {
      cityView,
      chooseDLS_org
    },
    name: 'sysRoleFormDialog',
    props: {
      initOption: {
        title: {
          type: String,
          default: '新增管理员'
        },
        action: {
          type: String,
          default: 'add'
        },
        showModal: {
          type: Boolean
        },
        id: {
          type: String
        },
        is_agent:{
          type: Boolean,
          default:false  // user: 系统设置用户管理入口    dealer    代理商入口
        }
      }
    },
    data () {
      const validatePwd = (rule, value, callback) => {
        // if (value === '' && this.initOption.action === 'add') {
        //   callback(new Error('请输入登录密码'))
        // } else {
        //   callback()
        // }
        callback()
      }
      const validatePassCheck = (rule, value, callback) => {
        // if (this.initOption.action === 'add' || this.subForm.password !== '') {
        if (this.subForm.password !== '') {
          if (value == '') {
            callback(new Error('请再次输入密码'))
          } else if (value == this.subForm.password) {
            callback()
          } else {
            callback(new Error('两个输入密码不匹配！'))
          }
        } else {
          callback()
        }
      }
      const validateCityCheck = (rule, value, callback) => {
        if (!this.subForm.province || !this.subForm.city || !this.subForm.district) {
          callback(new Error('请选择省市区'))
        } else {
          callback()
        }
      }
      const validateRoleCheck = (rule, value, callback) => {
        if (this.subForm.role_id.length === 0) {
          callback(new Error('请选择所属角色'))
        } else {
          callback()
        }
      }
      const validateOgName = (rule, value, callback) => {
        if (!this.initOption.is_agent  &&  this.subForm.role_id == '11' && !value) {
          callback(new Error('请选择代理商'))
        } else {
          callback()
        }
      }
      
      return {
        roleData: [], // 当前登录人信息
        chooseRole: '',
        roles: [],
        provinces: [],
        citys: [],
        districts: [],
        formRules: {
          username: [
            { required: true, message: '用户账号不能为空', trigger: 'blur' }
          ],
          password: [
            { validator: validatePwd, trigger: 'blur' }
          ],
          confirmpwd: [
            { validator: validatePassCheck, trigger: 'blur' }
          ],
          role_id: [
            { required: true, validator: validateRoleCheck, trigger: 'change' }
          ],
          sex: [
            { required: true, message: '必选', trigger: 'blur' }
          ],
          real_name: [
            { required: true, message: '请输入真实姓名', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
          ],

          birthday: [
            { required: true, message: '请选择生日', trigger: 'change' }
          ],
          email: [
            { required: true, message: '邮箱不能为空', trigger: 'blur' },
            { type: 'email', message: '电子邮件格式不正确', trigger: 'blur' }
          ],
          cityCheck: [
            { validator: validateCityCheck,required: true, trigger: 'blur' }
          ],
          status: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ],
          ogname: [
            { required: true, validator: validateOgName, trigger: 'change' }
          ],
         
        },



        subForm: {
          id: this.initOption.id,
          mobile: '',
          username: '',
          role_id: [],
          ogname:'',
          ogid:'',
          email: '',
          qq: '',
          user_type: '6',
          real_name: '',
          sex: '1',
          birthday: '',
          describe: '',
          province: '',
          city: '',
          district: '',
          address:'',
          status: '1',
          password: '',
          confirmpwd: '',
          avatar: '',
          url: '',
          daareaid: 0,
        },
        chooseorgInitOption: {
          title: '',
          showModal: false,
        },
        uploadHttp: '',
        access_token: '',
        defaultList: []
      }
    },
    methods: {
      chooseDLS_org () {
        this.chooseorgInitOption.title = '选择门店'
        this.chooseorgInitOption.showModal = true
        this.$refs.chooseDLS_org.handleSearch()
      },
      getChooseDLS (item) {
        this.chooseorgInitOption.showModal = false
        this.chooseorgInitOption.topForm = {}
        this.subForm.ogid = item.id
        this.subForm.ogname = item.name
        this.$forceUpdate()
      },
      handleError (data1, data2, data3) { // error file fileList
        this.$Modal.error({ title: '提示', content: '上传失败' })
      },
      handleFormatError (data1, data2, data3) { // file fileList
        this.$Modal.error({ title: '提示', content: '文件格式不正确' })
      },
      handleMaxSize (data1, data2) { // file fileList
        this.$Modal.error({ title: '提示', content: '文件太大' })
      },
      handleSuccess (data1, data2, data3) { // response file fileList    图标
        if (data1.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '上传成功'
          })
          this.defaultList = []
          this.subForm.avatar = data1.data[0].pic // 图片上传地址
          this.subForm.url = data1.data[0].url // 图片域名地址
        }else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      },

      setBirthday (date) {
        this.subForm.birthday = date
      },

      cloneObjectFn (obj) { // 对象复制
        return JSON.parse(JSON.stringify(obj))
      },
      getInfo () {
        if (this.initOption.id == '') {
          this.reset()
        }
        getUserView(this.initOption.id).then(res => {
          if (res.errCode === 0) {
            this.subForm = this.cloneObjectFn(res.data)
            // this.$refs.cityView.isEdit = true
            this.$refs.cityView.viewdata.provinceModel = res.data.province
            this.$refs.cityView.viewdata.cityModel = res.data.city
            this.$refs.cityView.viewdata.districtModel = res.data.district
          } else {
            this.$Modal.error({
              title: '提示',
              content: res.errMsg
            })
          }
        })
      },
      getRoles () {
        let permiose = getRoleDropDown()
        permiose.then(res => {
          if (res.errCode === 0) {
            this.roles = res.data
          }
        })
      },
      handleSubmit () {
        this.subForm.user_type = '6'

        this.$refs.roleForm.validate((valid) => {
          if (valid) {
            addUserAccount(this.subForm).then(response => {
              this.handleResult(response)
            })
          }
        })
      },
      handleResult (obj) {
        if (obj.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '操作成功'
          })
          // 父类回调，刷新列表
          this.$emit('resultMethod', '')
          this.reset()
        } else {
          this.$Notice.error({
            title: '提示',
            desc: obj.errMsg
          })
        }
      },



/*
   管理员  - 新增编辑
   @param uid=>用户id || mobile=>手机号 || username=>用户账号 || ogid=>机构id
   @param role_id=>角色id（字符串形式） || email=>邮箱 || qq=>qq || user_type=>用户类型管理员固定传6
   @param real_name=>真实姓名 || sex=>性别 || birthday=>生日
   @param describe=>描述简介 || province=>省id || city=>市id
   @param district=>区域id || address=>地址 || status=>状态
   @param password=>密码 || confirmpwd=>确认密码 || avatar=>头像
 */
      reset () {
        this.$refs.roleForm.resetFields()
        this.$refs.cityView.isEdit = false
        this.$refs.cityView.resetValue()
        if(this.initOption.is_agent == true) {
          this.subForm.role_id = '11' 
        } else {
         this.subForm.role_id = ''
        }
        this.subForm.id = ''
        this.subForm.mobile = ''
        this.subForm.username = ''
        this.subForm.email = ''
        this.subForm.qq = ''
        this.subForm.user_type = '6'
        this.subForm.real_name = ''
        this.subForm.sex = '0'
        this.subForm.ogid = ''
        this.subForm.ogname = ''

        this.subForm.birthday = ''
        this.subForm.describe = ''
        this.subForm.province = ''
        this.subForm.city = ''
        this.subForm.district = ''
        this.subForm.address = ''
        this.subForm.status = '1'
        this.subForm.password = ''
        this.subForm.confirmpwd = ''
        this.subForm.avatar = '' // 图片上传地址
        this.subForm.url = '' // 图片域名地址
      },


      selectFunc (cityObj) {
        this.subForm.daareaid = cityObj.daareaid
        this.subForm.province = cityObj.provinceModel
        this.subForm.city = cityObj.cityModel
        this.subForm.district = cityObj.districtModel
      }
    },
    mounted () {
      this.getRoles()
      this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro
      this.access_token = getToken()
    }
  }
</script>
<style scoped>
  .userfnpt {
    width: 300px;
    display: inline-block;
  }
</style>

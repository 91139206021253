<template>
  <div class="myApp">
    <Row class="my-row">
      <div class="col-rightN">
        <Card class="col-right-card">
          <p slot="title">查询角色</p>
          <Form :label-width="80">
            <div>
              <FormItem class="FromItemnpt" label="角色名称：" prop="username">
                <Input clearable type="text" v-model="search.role_name" placeholder="请输入角色名称"></Input>
              </FormItem>
              <FormItem class="FromItemnpt" label="状态：" prop="username">
                <Select v-model="search.status" clearable>
                  <Option v-for="(item,key) in statuslist" :value="key" :key="item">{{ item }}</Option>
                </Select>
              </FormItem>
              <FormItem class="FromItemnpt" label="添加时间：" prop="username">
                <DatePicker
                  style="width:180px;"
                  type="daterange"
                  :options="dateRange"
                  @on-change="checkDate"
                ></DatePicker>
              </FormItem>
              <Button type="primary" @click="handleSearchAction">查询</Button>
            </div>
          </Form>
          <div class="gray-nona"></div>
          <div class="tablebnt">
            <Button type="primary" @click="handleCreateModel" v-show="button.add">新增</Button>
            <Button type="primary" @click="handleUpdateModal" v-show="button.edit">修改</Button>
            <Button type="primary" @click="handleStatusSuccess" v-show="button.enable">启用</Button>
            <Button type="primary" @click="handleStatusFail" v-show="button.disabled">禁用</Button>
            <Button type="primary" @click="handleDelete" v-show="button.delete">删除</Button>
            
            <Modal v-model="newModel" :title="titleData" width="600" draggable>
              <div>
                <Form :label-width="100" ref="subData" :model="subData" :rules="rules">
                  <FormItem label="角色名称" prop="role_name">
                    <Input v-model="subData.role_name"/>
                  </FormItem>
                  <FormItem label="状态" prop="status">
                    <RadioGroup v-model="subData.status">
                      <Radio :key="item" v-for="(item,index) in statuslist" :label="index">
                        {{item}}
                      </Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem label="角色描述">
                    <Input type="textarea" v-model="subData.remark"/>
                  </FormItem>
                </Form>
              </div>
              <div slot="footer">
                <Button @click="newModel = false">取消</Button>
                <Button type="primary" @click="getOrgRoleAdd">保存</Button>
              </div>
            </Modal>
          </div>
          <Table stripe border
                 height="520"
                 :columns="columns"
                 :data="data1"
                 @on-select="handleCheckedOrDeleteTable"
                 @on-select-all="handleCheckedOrDeleteTable"
                 @on-select-cancel="handleCheckedOrDeleteTable"
          ></Table>

          <div class="my-page">
            <Page
              :total="totalCount"
              :current="search.p"
              @on-change="handleChangePage"
              :page-size="parseInt(search.t)"
            ></Page>
            <span class="pageSelect">
              每页显示
              <Select
                class="ivu-select-selection-1"
                v-model="search.t"
                @on-change="handleChangePageLimit"
                :label="search.t"
              >
                <Option value="10">10</Option>
                <Option value="20" v-show="totalCount > 10">20</Option>
                <Option value="30" v-show="totalCount > 20">30</Option>
                <Option value="40" v-show="totalCount > 30">40</Option>
                <Option value="50" v-show="totalCount > 40">50</Option>
              </Select>
            </span>
          </div>
        </Card>
      </div>
    </Row>
  </div>
</template>


<script>
  import { getStatus } from '@/api/dictionary'
  import {
    getOrgRole, getOrgRoleAdd, getOrgRoleEdit,
    getOrgRoleView
  } from '@/api/backend'

  export default {
    data () {
      return {
        titleData: '',
        rules: {
          role_name: [{ required: true, message: '必填', trigger: 'blur' }],

          status: [{ required: true, message: '必填', trigger: 'change' }]
        },

        newModel: false, // 新增模态框显示状态
        totalCount: 10, // 总条数
        button: {},
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          role_name: '', // 角色名称
          status: 0,
          action_id: '453'
        },
        rolelist: [], // 角色名称
        data1: [],
        statuslist: [], // 是否启用   1.启用。2 禁用
        subData: {
          status: '1',
          id: '',
          role_name: '',
          remark: ''
        },
        columns: [
          // 表头
          { type: 'selection', align: 'center', width: 60 },
          { title: '角色名称', align: 'center', key: 'role_name', minWidth: 100 },
          { title: '说明描述', align: 'center', key: 'remark', minWidth: 130 },
          { title: '创建人', align: 'center', key: 'createname', minWidth: 100 },
          { title: '修改人', align: 'center', key: 'updatename', minWidth: 100 },
          { title: '状态', align: 'center', key: 'status_str', minWidth: 100 },
          { title: '添加时间', align: 'center', key: 'createtime', minWidth: 150 },
          { title: '更新时间', align: 'center', key: 'updatetime', minWidth: 150 }
        ],
        checkedIds: [],
        checkedId: '',
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        }
      }
    },
    methods: {
      getStatus () {
        getStatus().then(res => {
          this.statuslist = res.data
        })
      },
      checkDate (date) {
        // 操作时间
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      // 角色列表
      handleSearch () {
        let params = this.search
        getOrgRole(params).then(res => {
          this.totalCount = parseInt(res.data.total)
          this.data1 = res.data.list
          this.button = res.data.button
        })
          .catch(err => {
            console.log(err)
          })
      },

      getOrgRoleAdd () {
        this.$refs.subData.validate(valid => {
          if (valid) {
            let params = this.subData
            getOrgRoleAdd(params).then(res => {
              if (res.errCode == 0) {
                if (this.subData.id) {
                  this.checkedIds = []
                }
                this.newModel = false
                this.handleSearch()
                this.$Modal.success({ title: '提示', content: res.errMsg })
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },

      handleSearchAction () {
        this.search.p = 1
        this.handleSearch()
      },
      handleCreateModel () {
        this.reset()
        this.subData.id = ''
        this.subData.role_name = ''
        this.subData.remark = ''
        this.subData.status = '1'
        this.titleData = '新增角色'
        this.newModel = true
      },
      handleUpdateModal () {
        this.reset()
        if (this.checkedIds.length == 1) {
          this.titleData = '修改角色'
          this.handleorgview()
          this.newModel = true
        } else {
          this.$Modal.info({ title: '提示', content: '请选择一项' })
        }
      },
      handleorgview () {
        let params = {}
        params.id = this.checkedId
        getOrgRoleView(params).then(res => {
          this.subData = res.data
        })
      },
      handleCheckedOrDeleteTable (data1) {
        if (data1[0]) {
          this.checkedId = data1[0].id
        }
        let ids = []
        data1.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },

      handleChangePage (data1) {
        // 切换页码
        this.search.p = data1
        this.handleSearch()
      },
      handleChangePageLimit () {
        // 切换每页显示条数
        this.search.p = 1
        this.handleSearch()
      },

      handleStatusSuccess () {
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为启用状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '2'
              this.getOrgRoleEdit(params)
            },
            onCancel: () => {
              this.$Message.info('您取消了启用操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },

      getOrgRoleEdit (params) {
        getOrgRoleEdit(params).then(res => {
          if (res.errCode == 0) {
            this.handleSearch()
            this.checkedIds = []
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      },

      handleStatusFail () {
        // 停用
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为禁用状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '3'
              this.getOrgRoleEdit(params)
            },
            onCancel: () => {
              this.$Message.info('您取消了启用操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },

      handleDelete () {
        if (this.checkedIds.length > 0) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
            onOk: () => {
              let params = {}
              params.id = this.checkedIds
              params.type = '1'
              this.getOrgRoleEdit(params)
            },
            onCancel: () => {
              // this.$Message.info("您取消了删除操作");
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },
      reset () {
        this.$refs.subData.resetFields()
      },
    },
    mounted () {
      this.handleSearch()
      this.getStatus()
    },
    watch: {}
  }
</script>


<style lang="less">
  @import "../style2.less";
</style>


<template>
  <Card class="col-right-card min-height">
    <p slot="title">订单统计</p>
    <Form :label-width="120">
      <FormItem class="FromItemnpt" label="第三方单位名称：">
        <Input v-model="search.repair_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="repair_org" />
        </Input>
        <a style="margin-left: 5px" @click="clear">清除</a>
      </FormItem>
      <FormItem v-show="usertype == 1" class="FromItemnpt" label="定损机构：">
        <Input v-model="search.in_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="insurance_org" />
        </Input>
        <a style="margin-left: 5px" @click="cleartwo">清除</a>
      </FormItem>

      <Button type="primary" @click="handleSearchAction">查询</Button>
      <Button type="primary" :loading="exportLoading" @click="exportExcel" >导出</Button>
    </Form>
    <div class="gray-nona"></div>

    <Table
      height="500"
      border
      stripe
      :columns="canshu"
      :data="canshudata"
      @on-select="tableSelectionChange"
      @on-select-all="tableSelectionChange"
      @on-select-cancel="tableSelectionChange"
    ></Table>

    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height: 30px"></div>
    </div>

    <insurance_org
      ref="insurance_org"
      :initOption="chooseorgInitOption"
      @getorgdata="getChooseGY"
    >
    </insurance_org>
  </Card>
</template>
<script>
import excel from '@/libs/excel'
import insurance_org from "@/view/my-components/ditanorg/insurance_org";
import * as Enum from "@/libs/enum";
import aaa from "@/config/index.js";
import * as util from "@/libs/util";
import * as tools from "@/libs/tools";
import config from "@/config";
import cityView from "../mycomponents/cityView.vue";

import {getOrderStatistics, getQingdanList} from "@/api/admin";

// import { getCustomer, getCustomerView, getCustomerAdd, getCustomerEdit, getCustomerDetail } from '@/api/backend';
import { getMemberLevelDrop } from "@/api/common";
import { getUserStatus, getWhether } from "@/api/dictionary";

export default {
  components: {
    insurance_org,
  },
  // computed: {
  //   ...mapGetters([
  //     'userStatus'
  //   ]),

  //   },
  data() {
    const validateCityCheck = (rule, value, callback) => {
      if (
        this.subData.province == undefined ||
        this.subData.city == undefined ||
        this.subData.district == undefined
      ) {
        callback(new Error("请选择省市区"));
      } else {
        callback();
      }
    };
    const validatePwd = (rule, value, callback) => {
      callback();
    };
    const validatePassCheck = (rule, value, callback) => {
      if (this.subData.password !== "") {
        if (value == "") {
          callback(new Error("请再次输入密码"));
        } else if (value == this.subData.password) {
          callback();
        } else {
          // console.log(this.subData.password);
          callback(new Error("两个输入密码不匹配！"));
        }
      } else {
        callback();
      }
    };
    return {
      usertype: "2",
      handleData: {
        type: "",
        data: "",
      },
      button1: "全部",

      map: {
        keyword: "",
        center: {
          lng: 117.1195,
          lat: 36.655164,
        },
        zoom: 15,
        show: true,
        dragging: true,
      },
      markmodel: false,
      lat: 0,
      lng: 0,

      dataRules: {
        username: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: validatePwd,
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "必选",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "必选",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          {
            message: "格式有误",
            trigger: "blur",
            pattern: /^1[34578]\d{9}$/,
          },
        ],

        cityCheck: [
          {
            validator: validateCityCheck,
            required: true,
            trigger: "blur",
          },
        ],
      },
      checkedIds: [],

      uploadHttp: "",
      access_token: "",
      defaultList: [],
      userInfoType: [],
      detailData: {},
      subData: {
        id: "",
        username: "", //姓名
        email: "",
        mobile: "",
        referralCode: "", //推荐码
        workNumber: "", //工号
        organization: "", //所属机构
        qq: "",
        sex: "0",
        status: "1",
        password: "",
        remark: "", //用户备注
        province: "",
        city: "",
        district: "",
      },
      hyse: false,
      types: "",
      chooseorgInitOption: {
        title: "",
        showModal: false,
      },
      search: {
        p: 1, // 当前页码
        t: 10, // 每页显示条数
        repair_name: "",
        ogid: "",
        in_name: "",
        createogid: "",
      },
      timeshijian: [],
      button: {},
      userStatus: [],
      editTitle: "回收单新增",
      showEdit: false,
      showDetail: false,
      organizationList: [],

      memberLevelDrop: [], // 会员等级
      totalCount: 10,
      dateRange: {
        shortcuts: [
          {
            text: "今日",
            value() {
              const end = new Date();
              const start = new Date();
              return [start, end];
            },
          },
          {
            text: "本周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "本月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "本季度",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
      canshu: [
        // { title: '序号', key: 'caseno', align: 'center', minWidth: 100, fixed: 'left' },
        {
          title: "定损机构",
          key: "insurance_company",
          align: "center",
          minWidth: 130,
        },
        {
          title: "第三方名称",
          key: "external_repairman",
          align: "center",
          minWidth: 100,
        },
        { title: "配件总数", key: "total", align: "center", minWidth: 80 },
        { title: "待报价", key: "dbj_total", align: "center", minWidth: 100 },
        { title: "待取件", key: "dqj_total", align: "center", minWidth: 100 },
        { title: "待还件", key: "dhj_total", align: "center", minWidth: 100 },
        { title: "待核价", key: "dsh_total", align: "center", minWidth: 100 },
        { title: "待评价", key: "dpj_total", align: "center", minWidth: 100 },
      ],
      canshudata: [],
      exportLoading: false,
    };
  },
  methods: {
    exportExcel () {
      this.exportLoading = true

      let params = JSON.parse(JSON.stringify(this.search));
      params.t = 20000;//导出最大条数
      getOrderStatistics(params).then((res) => {
        if (res.errCode == 0) {

          if (res.data.list.length) {
            const params = {
              title: ['定损机构', '第三方名称', '配件总数', '待报价', '待取件', '待还件', '待核价', '待评价'],
              key: ['insurance_company', 'external_repairman', 'total', 'dbj_total', 'dqj_total', 'dhj_total', 'dsh_total', 'dpj_total'],
              data: res.data.list,
              autoWidth: true,
              filename: '订单统计'
            }
            excel.export_array_to_excel(params)
            this.exportLoading = false
          } else {
            this.$Message.info('表格数据不能为空！')
             this.exportLoading = false

          }



        }
      });
    },

    clear() {
      this.search.ogid = "";
      this.search.repair_name = "";
    },
    cleartwo() {
      this.search.createogid = "";
      this.search.in_name = "";
    },
    repair_org() {
      this.chooseorgInitOption.title = "选择外修商";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.repair_business;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    getChooseGY(item) {
      // console.log(item);
      this.chooseorgInitOption.showModal = false;

      if (item.ogtype == 2) {
        this.search.ogid = item.id;
        this.search.repair_name = item.name;
      } else if (item.ogtype == 3) {
        this.search.createogid = item.id;
        this.search.in_name = item.name;
      }
    },

    getUserInfoType() {
      //
      getWhether().then((res) => {
        this.userInfoType = res.data;
      });
    },
    tableSelectionChange(data2) {
      if (data2[0] != undefined) {
        this.checkedId = data2[0].id;
      }
      let ids = [];
      data2.map(function (item, key) {
        ids.push(item.id);
      });
      this.checkedIds = ids;
    },
    markmap() {
      //标注
      this.markmodel = true;
      if (this.subData.lng) {
        this.map.center.lng = this.subData.lng;
        this.map.center.lat = this.subData.lat;
      }
    },
    handleEdit(type) {
      if (this.checkedIds.length > 0) {
        let params = {};
        params.uid = this.checkedIds;
        params.type = type;
        if (type == 1) {
          this.$Modal.confirm({
            title: "确认信息",
            content: "<p>你确定要将选中的信息设置为删除状态么？?</p>",
            onOk: () => {
              this.sysEdit(params);
            },
            onCancel: () => {
              this.$Message.info("您取消了删除操作");
            },
          });
        } else {
          this.sysEdit(params);
        }
      } else {
        this.$Modal.info({
          title: "提示",
          content: "请至少选择一项",
        });
      }
    },

    handleGrade() {
      // 批量设置等级
      if (this.checkedIds.length > 0) {
        this.hyse = true;
      } else {
        this.$Modal.info({
          title: "提示",
          content: "请至少选择一项",
        });
      }
    },
    clickdel() {
      this.showDetail = true;

      // let query = { id: params.row.id }
      this.$router.push({
        name: "order_details",
      });
    },
    handleSubmit(type) {
      let params = {};
      params.id = this.checkedIds;
      params.type = type;
      params.data = this.handleData.levelData;
      this.sysEdit(params);
    },

    selectFunc(cityObj) {
      this.subData.province = cityObj.provinceModel;
      this.subData.city = cityObj.cityModel;
      this.subData.district = cityObj.districtModel;
    },
    selectFunc1(cityObj) {
      this.search.province = cityObj.provinceModel;
      this.search.city = cityObj.cityModel;
      this.search.district = cityObj.districtModel;
    },

    getUserStatus() {
      // console.log('result',this.userStatus );
      getUserStatus().then((res) => {
        this.userStatus = res.data;
      });
    },
    confirmlnglat() {
      this.subData.lng = this.lng;
      this.subData.lat = this.lat;
      this.$Notice.success({
        title: "提示",
        desc: "选择成功",
      });
      this.markmodel = false;
    },

    getMemberLevelDrop() {
      let params = {
        type: "1",
      };
      getMemberLevelDrop(params).then((res) => {
        this.memberLevelDrop = res.data;
      });
    },

    // 日期
    checkDate(date) {
      this.search.stime = date[0];
      this.search.etime = date[1];
    },
    // 查询
    handleSearchAction() {
      this.search.p = 1;
      this.search.t = 10;
      this.search.is_export = 0;
      this.handleSearch();
    },
    handler({ BMap, map }) {
      let me = this;
      // console.log(BMap, map);
      // 鼠标缩放

      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener("click", function (e) {
        me.subData.lng = e.point.lng;
        me.subData.lat = e.point.lat;
        me.lng = e.point.lng;
        me.lat = e.point.lat;
        // console.log(e.point.lng, e.point.lat);
      });
    },
    // 分页
    handlePage(value) {
      this.search.p = value;
      this.handleSearch();
    },
    handlePageSize(value) {
      this.search.t = value;
      this.handleSearch();
    },
    // 列表
    handleSearch() {
      let params = this.search;

      getOrderStatistics(params).then((res) => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.canshudata = res.data.list;
          // this.button = res.data.button
        }
      });
    },
    exportAction() {
      window.location.href =
        aaa.baseUrl.pro +
        "backend/export-order-statistics?loginIdent=1&agent=4&specific_date=" +
        this.search.specific_date +
        "&createogid=" +
        this.search.createogid +
        "&ogid=" +
        this.search.ogid +
        "&p=1&t=5000";
    },
    reset() {
      this.$refs.subData.resetFields();
    },
    // 新增
    handleCreateModel() {
      this.reset();
      this.subData = {};
      this.$refs.cityView.viewdata.provinceModel = "";
      this.$refs.cityView.viewdata.cityModel = "";
      this.$refs.cityView.viewdata.districtModel = "";
      this.showEdit = true;
      this.editTitle = "回收单新增";
    },

    handleUpdateModal(data) {
      this.viewData(data);
      this.editTitle = "回收单修改";
      this.showEdit = true;
    },

    handleDetailModal(data) {
      getCustomerDetail({
        uid: data,
      }).then((res) => {
        this.detailData = res.data;
        this.showDetail = true;
      });
    },

    viewData(uid) {
      getCustomerView({
        uid: uid,
      }).then((res) => {
        this.subData = res.data;
        let that = this;
        setTimeout(function () {
          that.$refs.cityView.viewdata.provinceModel = res.data.province;
          that.$refs.cityView.viewdata.cityModel = res.data.city;
          that.$refs.cityView.viewdata.districtModel = res.data.district;
        }, 150);
      });
    },

    submitForm() {
      // 保存
      this.$refs.subData.validate((valid) => {
        if (valid) {
          let params = this.subData;
          getCustomerAdd(params).then((res) => {
            if (res.errCode == 0) {
              this.showEdit = false;
              this.handleSearch();
              this.reset();
              this.$Modal.success({
                title: "提示",
                content: "提交成功",
              });
            } else {
              this.$Modal.error({
                title: "提示",
                content: res.errMsg,
              });
            }
          });
        }
      });
    },
    handleError(data1, data2, data3) {
      // error file fileList
      this.$Modal.error({
        title: "提示",
        content: "上传失败",
      });
    },
    handleFormatError(data1, data2, data3) {
      // file fileList
      this.$Modal.error({
        title: "提示",
        content: "文件格式不正确",
      });
    },
    handleMaxSize(data1, data2) {
      // file fileList
      this.$Modal.error({
        title: "提示",
        content: "文件太大",
      });
    },
    handleSuccess(data1, data2, data3) {
      // response file fileList    图标
      if (data1.errCode === 0) {
        this.$Notice.success({
          title: "提示",
          desc: "上传成功",
        });
        this.defaultList = [];
        this.subData.avatar = data1.data[0].pic; // 图片上传地址
        this.subData.url = data1.data[0].url; // 图片域名地址
      } else {
        this.$Notice.error({
          title: "提示",
          desc: res.errMsg,
        });
      }
    },
    getDuration(date) {
      // how many days of this month
      let dt = new Date();
      var month = dt.getMonth();
      dt.setMonth(dt.getMonth() + 1);
      dt.setDate(0);
      return dt.getDate();
    },
    GetDate(is) {
      var now = new Date();
      var year = now.getFullYear(); //年
      var month = now.getMonth() + 1; //月
      // var day = now.getDate();            　　//日
      if (is) {
        return year + "-" + month + "-" + is;
      } else {
        return year + "-" + month + "-1";
      }
    },
  },
  mounted() {
    switch (this.$route.query.type) {
      case 1:
        var mun = this.getDuration();
        this.search.status = "1";
        this.search.stime = this.GetDate(false);
        this.search.etime = this.GetDate(false);
        this.timeshijian = [this.GetDate(false), this.GetDate(false)];
        break;
      case 2:
        break;
    }
    // this.getUserStatus();
    // this.getMemberLevelDrop();
    // this.getUserInfoType();
    this.handleSearch();
    this.uploadHttp =
      process.env.NODE_ENV === "development"
        ? config.uploadImgUrl.dev
        : config.uploadImgUrl.pro;
    this.access_token = util.getToken();
    this.usertype = util.getUserType();

    //console.log('getUserType',util.getUserType());
  },
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.dizhiselecls {
  width: 342px;
  margin-right: 3px;
}

.map {
  width: 100%;
  height: 400px;
}

.map .search {
  margin-bottom: 65px;
}

.userfnpt {
  width: 300px;
  margin-right: 3%;
  display: inline-block;
}
</style>

<template>
    <div class="main" id="total">

    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: {
    datatype: {
      type: Array
    },
  },
  data(){
    return{
      data1:[],
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.gettotal()
    },
    gettotal(){
      var chartDom = document.getElementById("total");
      var myChart = echarts.init(chartDom, null,{});
          myChart.resize();
      var option;
      option = {
        title: {
          text: '外修商总数',
          left:"center",
          top:'11%',
          textStyle:{
            color:'#66FFFf',
            fontStyle:'normal',//主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight:"400",//可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily:" Microsoft YaHei",//主题文字字体，默认微软雅黑
            fontSize:12//主题文字字体大小，默认为18px

          },
        },
        global: false,
        tooltip: {
          trigger: 'item',
          position:'right'
        },

        series: [
          {
            center: ["50%", "60%"],
            label: {
              align: "left",
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },

                textStyle: {
                  fontSize: 8,
                  //显示文字的大小，
                  color: "#66FFFf",
                },
              },

            },
            labelLine: {

              smooth: 0.2,
              length:10,
              length2: 5
            },
            name: "外修商总数",
            type: "pie",
            radius: "55%",
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            // data: [
            //   { value: 1048, name: " 电器件" },
            //   { value: 735, name: "三者物损" },
            //   { value: 580, name: "总成件" },
            //   { value: 484, name: "外观件" },
            // ],
            data: this.data1,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            legend: {
              orient: "horizontal",
              x: "right",
            },
            color: ["#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE"],
          },
        ],
      };
      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.gettotal()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

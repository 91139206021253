<template>
  <div class="main" id="external"></div>
</template>
<script>
import * as echarts from "echarts";

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data() {
    return {
      data1:{
        date:[],
        banjin:[],
        jixiu:[],
        dianqi:[],
        boli:[],
        zongcheng:[],
        average:[],
      },
    };
  },
  methods: {
    init(){
      this.data1=this.datatype;
      this.getExternal()
    },
    getExternal() {
      var app = {};
      var chartDom = document.getElementById("external");
      var myChart = echarts.init(chartDom,  { width: 450, height: 120 });
      var option;

      app.configParameters = {
        rotate: {
          min: -90,
          max: 200,
        },
      };
      app.config = {
        rotate: 60,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottom",
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance,
          };
          myChart.setOption({
            series: [
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
            ],
          });
        },
      };
      const labelOption = {
        show: false,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: "{c}  {name|{a}}",
        fontSize: 16,
        rich: {
          name: {},
        },
      };
      option = {
        grid: {
          top: 16,
          left: "1.5%",
          right: "0%",
          // bottom: "17%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          width: "100%",
          data: [
            "钣金件",
            "机修件",
            "电器件",
            "玻璃件",
            "总成件",
            "每月平均维修成功率",
          ],

          padding: [0, 0, 0, 0],
          itemHeight: 6,
          itemWidth: 6,
          // 间距
          itemGap: 5,
          textStyle: {
            fontSize: 8,
          },
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
        },
        xAxis: [
          {
            type: "category",
            //   隐藏刻度线
            axisTick: { show: false },
            // data: ["1", "2", "3", "4", "5", "6", "7", "8"],
            data: this.data1.date,
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
             axisLabel: {

                    interval: 0,//使x轴文字显示全
             textStyle: {
                     color: "#333333",//Y轴内容文字颜色
                     fontSize:8
                },
                },
          },
        ],
        yAxis: [
          {
            type: "value",
            name:"单数",
            position: 'left',
             axisLabel: {
                  show: true,
                  interval: 'auto',
                  formatter: '{value} 单'  ,
                      textStyle: {
                     color: "#333333",//Y轴内容文字颜色
                     fontSize:9
                },
                },
                 axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
          },
          {min:0,
          max:100,
            type: "value",
            name:"维修成功率",
            position: 'right',
             axisLabel: {
                  show: true,
                  interval: 'auto',
                  formatter: '{value} %'  ,
                      textStyle: {
                     color: "#333333",//Y轴内容文字颜色
                     fontSize:9
                },
                },
                 axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
          },
        ],
        series: [
          {
            name: "钣金件",
            type: "bar",
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            // data: [20, 32, 33, 30, 30, 34, 39, 32],
            data: this.data1.banjin,
            color: ["#4A62D3"],
          },
          {
            name: "机修件",
            type: "bar",
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            color: ["#ADDDA6"],
            // data: [70, 32, 53, 40, 60, 34, 39, 32],
            data: this.data1.jixiu,
          },
          {
            name: "电器件",
            type: "bar",
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            color: ["#FAC858"],
         // data:[ 60, 42, 63, 40, 55, 34, 69, 32],
         data:this.data1.dianqi,
          },
          {
            name: "玻璃件",
            type: "bar",
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            color: ["#EE6666"],
         // data: [20, 32, 33, 30, 50, 34, 39, 32],
         data: this.data1.boli,
          },
          {
            name: "总成件",
            type: "bar",
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            color: ["#73C0DE"],
             // data: [20, 42, 33, 60, 30, 34, 39, 44],
             data: this.data1.zongcheng,
          },
          {
            name: "维修成功率",
            type: "line",
            yAxisIndex: 1,
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            color: ["#3DA373"],
            // data: [20, 52, 33, 30, 30, 34, 39, 32],
            data: this.data1.average,
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.getExternal();
  },
  watch: {
    datatype() {
      this.init();
    },
  }
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
}
</style>

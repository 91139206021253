import { render, staticRenderFns } from "./effectiveTrend.vue?vue&type=template&id=30b35c67&scoped=true&"
import script from "./effectiveTrend.vue?vue&type=script&lang=js&"
export * from "./effectiveTrend.vue?vue&type=script&lang=js&"
import style0 from "./effectiveTrend.vue?vue&type=style&index=0&id=30b35c67&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b35c67",
  null
  
)

export default component.exports
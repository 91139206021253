<template>
	<div class="myApp">
		<Row class="my-row">
			<div class="col-rightN">
				<Card class="col-right-card">
					<p slot="title">部门管理</p>
					<Form :label-width="90">
						<div>
							<FormItem class="FromItemnpt" label="机构名称：" prop="username"><Input v-model="search.ogname" clearable /></FormItem>

							<FormItem class="FromItemnpt" label="添加时间：" prop="username">
								<DatePicker type="daterange" :options="dateRange" @on-change="checkDate" style="width: 210px;"></DatePicker>
							</FormItem>
							<Button type="primary" @click="handleSearchAction">查询</Button>
						</div>
					</Form>

					<div class="gray-nona"></div>
					<div class="tablebnt">
						<Button type="primary" @click="handleCreateModel">新增</Button>
						<!--<Button type="primary" @click="handleUpdateModal">修改</Button>-->
						<!--<Button type="primary" @click="handleStatusSuccess">启用</Button>-->
						<!--<Button type="primary" @click="handleStatusFail">禁用</Button>-->
						<Button type="primary" @click="handleDelete">删除</Button>
						
					</div>

					<Table
						stripe
						height="500"
						style="margin-bottom: 7px;"
						border
						:columns="columns"
						:data="data1"
						@on-select="tableSelectionChange"
						@on-select-all="tableSelectionChange"
						@on-select-cancel="tableSelectionChange"
					></Table>

					<div class="my-page"><Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" /></div>
                    <Modal v-model="newModel" :title="titleData" width="600">
							<div>
								<Form :label-width="100" ref="subData" :model="subData" :rules="addrules"  >
									<FormItem v-show="usertype == 1"  label="所属机构"   required>
											<Input placeholder="请点击右边选择所属机构" disabled v-model="subData.ogname">
											  <Icon type="ios-search" slot="suffix" @click="chooseGy_org"/>
											</Input>
									</FormItem>

									<FormItem label="上级部门" prop="parentname">
										<Cascader :data="deparr" v-model="subData.parent_arr" change-on-select @on-change="changeparent"></Cascader>
									</FormItem>
									<FormItem label="部门名称" prop="name">
										<Input v-model="subData.name"  clearable />
									</FormItem>
									<FormItem class="FromItemnpt" label="系统部门">
										 <i-switch v-model="kaiguan" @on-change="systemchange">
										        <span slot="open">是</span>
										        <span slot="close">否</span>
										    </i-switch>
									
									</FormItem>

									<FormItem style="width:92%" label="描述">
										<Input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="subData.description" placeholder="请输入描述" />
									</FormItem>
								</Form>
							</div>
							<div slot="footer">
								<Button @click="newModel = false">取消</Button>
								<Button type="primary" @click="adddep">保存</Button>
							</div>
						</Modal>
					<Modal v-model="orgmodal" title="选择保险公司" width="900">
						<Form :label-width="80">
							<FormItem class="FromItemnpt" label="名称"><Input v-model="orgsearch.kw" clearable /></FormItem>
							<FormItem class="FromItemnpt dizhi_width" label="地区" style="margin-right: 6px;">
								<cityView ref="cityView" style="display: inline-block;" @selectFunc="selectFunc1"></cityView>
							</FormItem>
							<Button type="primary" @click="searchorg">搜索</Button>
						</Form>

						<Table height="500" border stripe :columns="orgcanshu" :data="orgcanshudata"></Table>

						<div class="my-page">
							<Page :total="orgtotalCount" show-elevator show-sizer show-total @on-change="handleorgPage" @on-page-size-change="handleorgPageSize" />
							<div style="height:30px;"></div>
						</div>

						<div slot="footer"></div>
					</Modal>
				</Card>
			</div>
		</Row>
	</div>
</template>

<script>
import config from '@/config';

import * as util from '@/libs/util';
import { getOrgView, getOrgEdit, getInsideAdd, getInsideList } from '@/api/user';
// import { getOryStatus } from '@/api/common';
import { getDepartmentList, getorglist, addDepartment, getDepartmentDrop,departmentview,getDepartmentEdit } from '@/api/admin';
import cityView from '../mycomponents/cityView.vue';
import * as Enum from '@/libs/enum';
export default {
	components: {
		cityView
	},
	name: 'account',
	data() {
		const validateParent = (rule, value, callback) => {
			if (this.subData.parent_arr.length > 0) {
				callback();
			} else {
				callback(new Error('请选择上级部门'));
			}
		};
		const validatename = (rule, value, callback) => {
			if (this.subData.name) {
				callback();
			} else {
				callback(new Error('请输入部门名称'));
			}
		};
		// const validateogname = (rule, value, callback) => {
		// 	if (this.subData.ogname) {
		// 		callback();
		// 	} else {
		// 		callback(new Error('请选择机构'));
		// 	}
		// };
		return {
            usertype:'2',// 1管理员  2保险公司 3外修商 4回收商 5物损
            curogid:'0',
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			addrules: {
				// ogname: [{
				// 		validator: validateogname,
				// 		required: true,
				// 		trigger: 'blur'
				// 	}],
				name: [{
						validator: validatename,
						required: true,
						trigger: 'blur'
					}],
			
				parentname: [
					{
						validator: validateParent,
						required: true,
						trigger: 'change'
					}
				],
			},
			orgtotalCount: 0,
			orgsearch: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			},
			button: {},
			checkedIds: [],
			titleData: '', // 标题
			newModel: false,
			
			search: {
				// 筛选条件
				status: 0,
				p: 1,
				t: 10
			},
			subData: {
				ogname: '',
				ogid: '',
				parent_id: '',
				parent_arr: null,
				parent_name: null,
				department_id:null,
				issystem:0,//是否系统部门   1是 0否
				
			},
			kaiguan:false,
			pageTotal: 10,
			modal1: false,
			orgcanshudata: [],
			orgcanshu: [
				{
					title: '保险公司编号',
					key: 'code',
					align: 'center',
					minWidth: 200,
					fixed: 'left'
				},
				{
					title: '保险公司名称',
					key: 'name',
					align: 'center',
					minWidth: 200,
					fixed: 'left'
				},
				{
					title: '联系人',
					key: 'manager',
					align: 'center',
					minWidth: 130
				},
				{
					title: '联系电话',
					key: 'managerphone',
					align: 'center',
					minWidth: 100
				},
				/*{
					title: '主营',
					key: 'main_camp',
					align: 'center',
					minWidth: 80
				},*/
				{
					title: '所在省市区',
					key: 'area_name',
					align: 'center',
					minWidth: 200
				},
				{
					title: '所在地址',
					key: 'address',
					align: 'center',
					minWidth: 100
				},

				{
					title: '添加时间',
					key: 'createtime',
					align: 'center',
					minWidth: 170
				},
				{
					title: '状态',
					key: 'status_str',
					align: 'center',
					minWidth: 100
				},
				{
					title: '备注',
					key: 'remark',
					align: 'center',
					minWidth: 100
				},

				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
										disabled: !this.button.edit
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.selectorg(params.row);
										}
									}
								},
								'选择'
							)
							// h(
							// 	'Button',
							// 	{
							// 		props: {
							// 			type: 'primary',
							// 			size: 'small'
							// 		},
							// 		style: {
							// 			marginRight: '5px'
							// 		},
							// 		on: {
							// 			click: () => {
							// 				this.handleDetailModal(params.row.id);
							// 			}
							// 		}
							// 	},
							// 	'查看'
							// )
						]);
					}
				}
			],

			columns: [
				// 表头
				{ type: 'selection', align: 'center', width: 60 },
				{ title: '部门ID', align: 'center', key: 'department_id', minWidth: 120 },
				{ title: '部门名称', align: 'center', key: 'name', minWidth: 140 },
				{ title: '所属机构', align: 'center', key: 'ogname', minWidth: 100 },
				{ title: '上级部门', align: 'center', key: 'parent_name', minWidth: 140 },
				{ title: '系统部门', align: 'center', key: 'issystem', minWidth: 140 },
				{ title: '添加时间', align: 'center', key: 'createtime', minWidth: 150 },
				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
										disabled: !this.button.edit
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.updatestation(params.row);
										}
									}
								},
								'编辑'
							)
						
						]);
					}
				}
			],
			data1: [],
			orgmodal: false,
			deparr: []
		};
	},
	methods: {
		vailpor(prop,a,b){
			// console.log('onvalidata',prop);
		},
		selectFunc1(cityObj) {
			this.orgsearch.province = cityObj.provinceModel;
			this.orgsearch.city = cityObj.cityModel;
			this.orgsearch.district = cityObj.districtModel;
		},
		searchorg() {
			let params = this.orgsearch;
			getorglist(params).then(res => {
				if (res.errCode == 0) {
					this.orgtotalCount = parseInt(res.data.total);
					this.orgcanshudata = res.data.list;
				}
			});
		},
		changeparent(value, selectedData) {
			// console.log(selectedData, value);
			if (selectedData.length > 0) {
				this.subData.parent_name = selectedData[selectedData.length - 1].label;
				this.subData.parent_arr = value;
				this.subData.parent_id = selectedData[selectedData.length - 1].value;
			} else {
				this.subData.parent_id = null;
				this.subData.parent_name = null;
				this.subData.parent_arr = [];
			}
		},
		selectorg(val) {
			// console.log('selectorg', val);
			this.orgmodal = false;
			this.subData.ogname = val.name;
			this.subData.ogid = val.id;
			getDepartmentDrop({ ogid: val.id }).then(res => {
				if (res.errCode == 0) {
					let resdata = res.data;
					resdata.unshift({
						value: 0,
						label: '顶级部门',
						children: []
					});
					this.deparr = resdata;
				}
			});
		},
		systemchange(status){
			// console.log('systemchange',status);
			if(status){
				this.subData.issystem=1;
			}
			else{
				this.subData.issystem=0;
			}
			// console.log('this.subData.issystem',this.subData.issystem);
		},
		adddep(){
			var that=this;
			  this.$refs.subData.validate((valid) => {
			                    if (valid) {
									let params=that.subData;
			                       addDepartment(params).then(res => {
			                       	if (res.errCode == 0) {
										
										this.handleSearch()
			                       		that.$Modal.success({ title: '提示', content: '成功' });
										that.newModel=false
			                       	}else{
										that.$Modal.error({ title: '提示', content: res.errMsg });
									}
			                       });
			                    } else {
			                        that.$Message.error('表单校验错误');
			                    }
			                })
		},
		chooseGy_org() {
			this.orgmodal = true;
			let params = {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			};
			getorglist(params).then(res => {
				if (res.errCode == 0) {
					this.orgtotalCount = parseInt(res.data.total);
					this.orgcanshudata = res.data.list;
				}
			});
		},
		checkDate(date) {
			// 操作时间
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		handleCreateModel() {
            this.reset();
			this.subData = {};
			this.subData.ogid = this.curogid;
			this.subData.parent_id = '';
			this.subData.name = '';
			this.subData.description = '';
			this.titleData = '新增部门';
			this.newModel = true;
			this.deparr=[];
            getDepartmentDrop({ ogid: this.curogid }).then(res => {
                if (res.errCode == 0) {
                    let resdata = res.data;
                    resdata.unshift({
                        value: 0,
                        label: '顶级部门',
                        children: []
                    });
                    this.deparr = resdata;
                }
            });
		},
        updatestation(val) {
            this.reset();
            this.titleData = '修改部门信息';
            this.newModel = true;


            getDepartmentDrop({ ogid: val.ogid }).then(res => {
                if (res.errCode == 0) {
                    this.deparr =res.data;
                }
            });

            this.handledel(val.department_id);
        },

        handledel(id) {
            departmentview({department_id:id}).then(res => {
                    // console.log('positionview',res);
                    // let datare=res.data

                    this.subData=res.data;
                    // this.subData.dep_arr=[];
                    // this.subData.position_arr=[];
                    this.subData.ogname=res.data.og_name;

                    this.subData.issystem=res.data.issystem;

                    if (res.data.issystem == 0){
                        this.kaiguan=false;

					}else {
                        this.kaiguan=true;
					}
                    // console.log('positionarr',this.positionarr,this.deparr);
                    // this.subData.position_arr.push(datare.parent_id);
                    // this.subData.dep_arr.push(datare.department_id);

                }).catch(err => {
                    // console.log(err);
                });
        },

		tableSelectionChange(data1) {
            if (data1[0]) {
                this.checkedId = data1[0].department_id
                this.subData.department_id = data1[0].department_id
            }
            let ids = []
            data1.map(function (item, key) {
                ids.push(item.department_id)
            })
            this.checkedIds = ids
		},

		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		handleorgPage(value) {
			this.orgsearch.p = value;
			this.searchorg();
		},
		handleorgPageSize(value) {
			this.orgsearch.t = value;
			this.searchorg();
		},

		// 机构列表
		handleSearch() {
			let params = this.search;

			  params.action_id='457';
			getDepartmentList(params).then(res => {
				if (res.errCode === 0) {
					this.data1 = res.data.list;
					this.pageTotal = res.data.total;
					this.button = res.data.button;
				}
			});
		},
	
		handleSearchAction() {
			this.search.p = 1;
			this.handleSearch();
		},

		handleUpdateModal() {
			this.reset();
			this.titleData = '修改部门信息';
			if (this.checkedIds.length == 1) {
				this.handleorgview();
				this.newModel = true;
			} else {
				this.$Modal.info({ title: '提示', content: '请选择一项' });
			}
		},

		// 机构详情
		handleorgview() {
			let id = this.checkedId;
			getOrgView(id)
				.then(res => {
					this.subData = res.data;
					this.subData.org_type = String(res.data.org_type);
					this.chooseProvince();
					this.chooseCity();
					this.imgurl = res.data.img;
					this.imgurlUrl = res.data.img_url;
				})
				.catch(err => {
					// console.log(err);
				});
		},


		sysAdminEdit(type) {
			let params = {};
            params.department_id = this.checkedIds;
             params.type = type;
            getDepartmentEdit(params).then(res => {
				if (res.errCode === 0) {
					this.checkedIds = [];
					this.$Notice.success({
						title: '提示',
						desc: res.errMsg
					});
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},
		// 删除
		handleDelete() {
			if (this.checkedIds.length > 0) {
				this.$Modal.confirm({
					title: '确认信息',
					content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
					onOk: () => {
						this.sysAdminEdit(1);
					},
					onCancel: () => {
						this.$Message.info('您取消了删除操作');
					}
				});
			} else {
				this.$Modal.info({ title: '提示', content: '请至少选择一项' });
			}
		},
		reset() {
			this.$refs.subData.resetFields();
		}
	},

	mounted() {
		this.handleSearch();
        this.usertype=util.getUserType();
        var user=util.getUser();
        this.curogid=user.ogid;
	}
};
</script>
<style lang="less">
@import '../style2.less';

.bxnpt {
	width: 25%;
	margin-left: 2%;
	float: left;
}
</style>

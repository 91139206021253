<template>
    <div class="main" id="effectiveTrend">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        banjin:[],
        jixiu:[],
        dianqi:[],
        boli:[],
        zongcheng:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.geteffectiveTrend()
    },
    geteffectiveTrend(){
      let that = this
      var chartDom = document.getElementById("effectiveTrend");
      var myChart = echarts.init(chartDom, null, { 
        // width:300,//大屏显示

        width: 375,//笔记本显示
        height: 120});
            myChart.resize();
      var option;

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          padding:[10, 0, 0 ,0],
          itemHeight:9,
          itemWidth:11,
// 间距
          itemGap:5,
          textStyle: {

            fontSize: 8,
            // padding: [0, 0, 0, 10]
          },
          data: ['钣金件', '机修件', '电器件', '玻璃件', '总成件']
        },
        grid: {
          top:'34',
          left: '3%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月'],
            data: this.data1.date,
            axisTick: { show: false,
              alignWithLabel: true

            },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return that.data1.date2[that.data1.date.indexOf(params.value)]
                }
              }
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:8
              },
            }

          }
        ],
        yAxis: [
          {
            type: 'value',
            name:"(单)",
            min: 0,
            // max: 500,
            axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {//y轴文字的配置
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:8
              },
            },
          }
        ],
        series: [
          {
            name: '钣金件',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            color:["#1DD0CB"],
            emphasis: {
              focus: 'series'
            },
            // data: [60, 32, 101, 264, 200, 150, 250, 164, 290, 140, 232, 101]
            data: this.data1.banjian
          },
          {
            name: '机修件',
            type: 'line',
            stack: 'Total2',
            smooth: true,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            color:["#DE87EA"],
            emphasis: {
              focus: 'series'
            },
            // data: [80, 52, 121, 284, 220, 170, 270, 194, 320, 160, 252, 121]
            data: this.data1.jixiu
          },
          {
            name: '电器件',
            type: 'line',
            stack: 'Total3',
            smooth: true,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            color:["#FF85AD"],
            emphasis: {
              focus: 'series'
            },
            // data: [120, 132, 201, 234, 190, 130, 220, 132, 201, 334, 190, 130, 220]
            data: this.data1.dianqi
          },
          {
            name: '玻璃件',
            type: 'line',
            stack: 'Total4',
            smooth: true,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            color:["#FAB27B"],
            emphasis: {
              focus: 'series'
            },
            // data: [220, 102, 131, 134, 190, 230, 120, 402, 231, 134, 190, 230, 120]
            data: this.data1.boli
          },
          {
            name: '总成件',
            type: 'line',
            stack: 'Total5',
            smooth: true,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            label: {
              show: true,
              position: 'top'
            },
            color:["#3BF3B9"],
            emphasis: {
              focus: 'series'
            },
            // data: [220, 302, 181, 234, 210, 290, 150, 302, 181, 234, 210, 290, 150]
            data: this.data1.zongcheng
          },
          // {
          //   name: '物损件',
          //   type: 'line',
          //   stack: 'Total',
          //   smooth: true,
          //   lineStyle: {
          //     width: 2
          //   },
          //   showSymbol: false,
          //   label: {
          //     show: true,
          //     position: 'top'
          //   },
          //   color:["#17B4F9"],
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   data: [220, 302, 181, 234, 510, 290, 150, 302, 181, 234, 210, 290, 150]
          // }

        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.geteffectiveTrend()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

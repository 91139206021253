<template>
  <div class="myApp MTreess">
    <Row class="my-row">
      <div class="cls1o">
        <div class="col-left col_left_page">
          <Card>
            <p slot="title">角色选择</p>
            <div>
              <div class="col-left-tree-itemxinde">
                <Tree :data="data1" @on-select-change="handleChangePost" empty-text="加载中···"></Tree>
              </div>
            </div>
          </Card>
        </div>

        <div class="col-right col_right_page">
          <Card class="col-right-card">
            <p slot="title">后台管理权限管理</p>
            <div class="left-side-33">
              <Button type="primary" @click="handleUpdateFront" v-show="button.add">保存</Button>
              <div class="col-right-tree-itemxinde">
                <Tree :data="tree1" show-checkbox @on-check-change="handleChangeMenuData"
                      empty-text="加载中···"></Tree>
              </div>
            </div>

          </Card>

        </div>
      </div>

    </Row>

  </div>
</template>


<script>

  import { getSysRoleMenuAdd, getRoleTree, getRolesMenu } from '@/api/backend'

  export default {
    data () {
      return {
        button: {},
        type: '1',
        id: '',
        ischange: 0,
        data1: [],
        tree1: [],
        checkMenuData: [],
        rolechange: 0,
      }
    },
    methods: {
      // 角色列表
      getRoleTree () {
        getRoleTree().then(res => {
          this.data1 = res.data
        })
      },
      // 选择角色后触发
      handleChangePost (data1) {
        if (data1.length > 0) {

          if (data1[0].id == '') {
            this.type = '1'
            this.id = ''
            this.getRoleList()
            this.rolechange = 0
          } else {
            this.rolechange = 1
            this.id = data1[0].id
            this.type = data1[0].type
            this.getRoleList()
          }
        } else {
          this.rolechange = 0
        }
      },

      getRoleList () {
        let params = {}
        params.id = this.id
        params.type = this.type
        params.action_id = '454'
        getRolesMenu(params).then(res => {
          this.tree1 = res.data.list
          this.button = res.data.button
        })

      },
      // 选择菜单
      handleChangeMenuData (data1) {
        this.ischange = 1
        this.checkMenuData = data1
      },

      // 保存选中参数
      handleUpdateFront () {
        if (this.rolechange == 0) {
          this.$Modal.info({ title: '提示', content: '请先选择角色' })
          return
        }
        if (this.ischange == 0) {
          this.$Modal.info({ title: '提示', content: '权限无变化,无需保存' })
          return
        }
        let params = {}
        params.type = this.type
        params.id = this.id
        params.save_menu = this.checkMenuData
        getSysRoleMenuAdd(params).then(res => {
          if (res.errCode == 0) {
            this.ischange = 0
            this.$Modal.success({ title: '提示', content: res.errMsg })
          } else {
            this.$Modal.error({ title: '提示', content: res.errMsg })
          }
        })
      },
    },
    mounted () {
      this.getRoleTree()
      this.getRoleList()
    },

  }
</script>


<style lang="less">
  @import '../style2.less';
    .cls1o{width:100%}
  .cls1o .left-side-33 {
    width: 100%;
    margin-left: 15px;
    background: #f5f5f6;
  }

  .MTreess .ivu-card-body {
    overflow: auto;
  }

  .col-right-tree-itemxinde {
    overflow: auto;
    height: 590px;
  }

  .col-left-tree-itemxinde {
    overflow: auto;
    height: 642px;
  }
  .col_left_page{width:29%}
  .col_right_page{float:left;width:71%}
</style>

<template>
  <Card class="col-right-card min-height">
    <p slot="title">广告管理清单 </p>
    <Form :label-width="120">
      <FormItem class="FromItemnpt " label="关键词">
        <Input clearable placeholder="广告名称" v-model="search.kw"/>
      </FormItem>

      <FormItem class="FromItemnpt" label="广告位置">
          <Select v-model="search.position_id">
            <Option v-for="(item,index) in adPosition" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>



      <FormItem class="FromItemnpt" label="添加时间：">
        <DatePicker
          style="width:190px;"
          type="daterange"
          :options="dateRange"
          @on-change="checkDate"
        ></DatePicker>
      </FormItem>

      <FormItem class="FromItemnpt" label="有效时间：">
        <DatePicker
          style="width:190px;"
          type="daterange"
          :options="dateRange"
          @on-change="checkDate1"
        ></DatePicker>
      </FormItem>
      
      <Button type="primary" @click="handleSearchAction">查询</Button>
    </Form>
    <div class="gray-nona"></div>
    <div class="tablebnt">
      <Button type="primary" v-show="button.delete" @click="handleEdit('1')">删除</Button>
      <Button type="primary" v-show="button.enable" @click="handleEdit('2')">启用</Button>
      <Button type="primary" v-show="button.disabled" @click="handleEdit('3')">禁用</Button>
    </div>
    <Table stripe border
           height="500"
           :columns="canshu"
           :data="canshudata"
           @on-select="handleCheckedOrDeleteTable"
           @on-select-all="handleCheckedOrDeleteTable"
           @on-select-cancel="handleCheckedOrDeleteTable"
           @on-select-all-cancel="handleCheckedOrDeleteTable"
    ></Table>
    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height:30px;"></div>
    </div>
  </Card>
</template>

<script>
  import cityView from '../mycomponents/cityView.vue'
  import { getAd, getAdEdit } from '@/api/backend'
  import {getStatus,getAdPosition } from '@/api/dictionary'

  export default {
    data () {
      return {
        statusList:[],// 广告位媒体类型
        adPosition:[],
        checkedIds: [],
        checkedId: '',
        button:{},
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          action_id:'206'
        },
        totalCount: 10,
        canshu: [
          { type: 'selection', align: 'center', width: 60 },
          {
            title: '广告名称',
            align: 'center',
            minWidth: 130,
            key: 'name',
            render: (h, params) => {
              let vm = this
              return h('span', {
                domProps: {
                  innerHTML: params.row.name
                },
                style: {
                  color: '#2d8cf0 ',
                  cursor: 'pointer'
                },
                on: {
                  click () {
                    let query = { id: params.row.id }
                    vm.$router.push({
                      name: 'ad_detail',
                      query: query
                    })
                  }
                }
              })
            }
          },
          {
            title: '广告图片', align: 'center', key: 'icon', width: 100,
            render: (h, params) => {
              return h('viewer', [h('img',
                {
                  attrs: {
                    src: params.row.path_url,
                    onerror: params.row.path_url,
                  },
                  style: {
                    width: '60px',
                    height: '60px',
                    marginTop: '5px'
                  },
                }
                )]
              ) 
            },
          },
          { title: '广告位置', key: 'position_str', align: 'center'  ,minWidth: 130},
          { title: '是否显示', key: 'status_str', align: 'center'  ,minWidth: 130},
          { title: '有效时间', key: 'time_str', align: 'center'  ,minWidth: 130},
          { title: '添加时间', key: 'createtime', align: 'center'  ,minWidth: 130},
          {
            title: '操作',
            width: '180',
            align: 'center',
            width: 120,
            render: (h, params) => {
              let vm = this
              return h('div', [
                h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small',
                    disabled:!this.button.delete
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      vm.handleDeleteRow(params.row.id)
                    }
                  }
                }, '删除')
              ])
            }
          }

        ],
        canshudata: [],
        dateRange: {
          shortcuts: [
            {
              text: "今日",
              value() {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: "本周",
              value() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: "本月",
              value() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: "本季度",
              value() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]
        },
      }
    },
    methods: {
      getStatus() {
        getStatus().then(res => {
          this.statusList = res.data
        })
      },

      getAdPosition() {
        getAdPosition().then(res => {
          this.adPosition = res.data
        })
      },

      checkDate(date) {
        this.search.stime = date[0]
        this.search.etime = date[1]
      },

      checkDate1(date) {
        this.search.v_stime = date[0]
        this.search.v_etime = date[1]
      },

      handleCheckedOrDeleteTable (data1) {
        if (data1[0]) {
          this.checkedId = data1[0].id
        }
        let ids = []
        data1.map(function (item, key) {
          ids.push(item.id)
        })
        this.checkedIds = ids
      },

      // 查询
      handleSearchAction () {
        this.search.p = 1
        this.search.is_export = 0
        this.handleSearch()
      },
      // 分页
      handlePage (value) {
        this.search.p = value
        this.handleSearch()
      },
      handlePageSize (value) {
        this.search.t = value
        this.handleSearch()
      },
      // 列表
      handleSearch () {
        let params = this.search
        getAd(params).then(res => {
          this.totalCount = parseInt(res.data.total)
          this.canshudata = res.data.list
          this.button = res.data.button
        })
      },

      // 删除
      handleEdit (type) {
        if (this.checkedIds.length > 0) {
          let txt = ''
          if (type == '1') {
            txt = '删除'
          } else if (type == '2') {
            txt = '启用'
          } else {
            txt = '禁用'
          }
          let params = {}
          params.id = this.checkedIds
          params.type = type
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为'+txt+'状态么？?</p>',
            onOk: () => {
              this.getDeleteWrite(params)
            },
            onCancel: () => {
              this.$Message.info('您取消了删除操作')
            }
          })
        } else {
          this.$Modal.info({ title: '提示', content: '请至少选择一项' })
        }
      },
      handleDeleteRow (data) {
        if (data) {
          this.$Modal.confirm({
            title: '确认信息',
            content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
            onOk: () => {
              let params = {}
              params.type = 1
              params.org_type = '2'
              params.id = data.split()
              this.getDeleteWrite(params)
            },
            onCancel: () => {
              this.$Message.info('您取消了删除操作')
            }
          })
        }
      },

      getDeleteWrite (params) {
        getAdEdit(params).then(res => {
          if (res.errCode == 0) {
            this.handleSearch()
            this.checkedIds = []
            this.$Notice.success({
              title: '提示',
              desc: '操作成功'
            })
          } else {
            this.$Notice.error({
              title: '提示',
              desc: res.errMsg
            })
          }
        })
      },
    },
    mounted () {
      this.getAdPosition()
      this.getStatus()
      this.handleSearch()
    }
  }
</script>


<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
</style>

<template>
  <div class="myApp">
    <Row class="my-row">
      <div class="col-rightN">
        <Card class="col-right-card">
          <p slot="title">短信发送记录</p>
          <Form :label-width="80">
            <div>
              <FormItem class="FromItemnpt" label="手机号">
                <Input v-model="search.kw" clearable placeholder="请输入手机号" />
              </FormItem>
              <Form-item class="FromItemnpt" label="状态">
                <i-select :model.sync="status_arr" style="width:200px" @on-change="selectStatus">
                    <i-option v-for="item in status_arr" :value="item.id">{{ item.name }}</i-option>
                </i-select>
              </Form-item>
              <FormItem class="FromItemnpt" label="发送时间：" prop="username">
                <DatePicker
                  style="width:180px;"
                  type="daterange"
                  :options="dateRange"
                  @on-change="checkDate"
                ></DatePicker>
              </FormItem>
              <Button type="primary" @click="handleSearchAction">查询</Button>
            </div>
          </Form>
          <div class="gray-nona"></div>
          <Table stripe border height="520" :columns="columns" :data="data1"></Table>
          <div class="my-page">
            <Page
              :total="totalCount"
              :current="search.p"
              @on-change="handleChangePage"
              :page-size="parseInt(search.t)"
            ></Page>
            <span class="pageSelect">
              每页显示
              <Select
                class="ivu-select-selection-1"
                v-model="search.t"
                @on-change="handleChangePageLimit"
                :label="search.t"
              >
                <Option value="10">10</Option>
                <Option value="20" v-show="totalCount > 10">20</Option>
                <Option value="30" v-show="totalCount > 20">30</Option>
                <Option value="40" v-show="totalCount > 30">40</Option>
                <Option value="50" v-show="totalCount > 40">50</Option>
              </Select>
            </span>
          </div>
        </Card>
      </div>
    </Row>
  </div>
</template>


<script> 
  import {getSmsQueue} from '@/api/backend'
  import {getSmsQueueStatus} from '@/api/dictionary'
  export default {
    data () {
      return {
        totalCount: 10, // 总条数
        search: {
          p: 1, // 当前页码
          t: 10, // 每页显示条数
          action_id: '218',
          status: 0,
          kw: '',
        },
        data1: [],
        status_arr: [],
        columns: [
          // 表头
          { title: '手机号', align: 'center', key: 'mobile', minWidth: 130},
          { title: '短信内容', align: 'center', key: 'msg', minWidth: 130 },
          { title: '发送类型', align: 'center', key: 'type', minWidth: 130},
          { title: '状态', align: 'center', key: 'status_str', minWidth: 130},
          { 
            title: '运营商返回', 
            align: 'center', 
            minWidth: 120,
            render (row, column, index ) {
              var data = JSON.parse(column.row.returnstr);
              return row('div', {
                  domProps: {
                      innerHTML: data.errorMsg
                  }
              });
            }
          },
          { title: '发送时间', align: 'center', key: 'sendtime', minWidth: 130},
          { title: '创建时间', align: 'center', key: 'createtime', minWidth: 130},
        ],
        dateRange: {
          shortcuts: [
            {
              text: '今日',
              value () {
                const end = new Date()
                const start = new Date()
                return [start, end]
              }
            },
            {
              text: '本周',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
              }
            },
            {
              text: '本月',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
              }
            },
            {
              text: '本季度',
              value () {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
              }
            }
          ]},
          
    }
  },
  methods: {
    selectStatus(data) {
      this.search.status = data;
    },
    checkDate(date) {
      // 操作时间
      this.search.stime = date[0];
      this.search.etime = date[1];
    },
    getSmsQueueStatus() {
      getSmsQueueStatus().then(res => {
        this.status_arr = res.data;
      });
    },
    handleSearch() {
      let params = this.search;
      getSmsQueue(params).then(res => {
        this.totalCount = parseInt(res.data.total);
        this.data1 = res.data.list;
      });
    },

    handleSearchAction() {
      this.search.p = 1;
      this.handleSearch();
    },
    handleChangePage(data1) {
      // 切换页码
      this.search.p = data1;
      this.handleSearch();
    },
    handleChangePageLimit() {
      // 切换每页显示条数
      this.search.p = 1;
      this.handleSearch();
    }
  },
  mounted() {
    this.handleSearch();
    this.getSmsQueueStatus();
  },
  watch: {}
};
</script>


<style lang="less">
@import "../style2.less";
</style>


<template>
	<Card class="col-right-card min-height">
		<p slot="title">外修商管理</p>
		<Form :label-width="150">
			<FormItem class="FromItemnpt" label="关键词"><Input clearable placeholder="名称|编号|联系人|联系电话|主修" v-model="search.kw" /></FormItem>

			<FormItem class="FromItemnpt dizhi_width" label="地区"><cityView ref="cityView" style="display: inline-block;" @selectFunc="selectFunc1"></cityView></FormItem>

			<FormItem class="FromItemnpt" label="状态">
				<Select v-model="search.status" clearable>
					<Option v-for="(item, index) in orgstatus" :value="index" :key="index">{{ item }}</Option>
				</Select>
			</FormItem>

			<FormItem class="FromItemnpt" label="添加时间">
				<DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" v-model="timeshijian"></DatePicker>
			</FormItem>
			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>
		<Button class="marginbnt" @click="handleCreateModel" type="primary" v-show="button.add">新增</Button>

		<!-- <Button class="marginbnt" @click="handleEdit(1)" type="primary" v-show="button.delete">删除</Button> -->
		<Button class="marginbnt" @click="handleEdit(2)" type="primary" v-show="button.enable">启用</Button>
		<Button class="marginbnt" @click="handleEdit(3)" type="primary" v-show="button.disabled">禁用</Button>
		<!--
    <Button class="marginbnt"   v-show="button.edit" @click="handleGrade" type="primary">批量设置等级</Button> -->

		<Table
			height="500"
			border
			stripe
			:columns="canshu"
			:data="canshudata"
			@on-select="tableSelectionChange"
			@on-select-all="tableSelectionChange"
			@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
			<div style="height:30px;"></div>
		</div>

		<Modal v-model="showEdit" width="55%" :title="editTitle">
			<Form :label-width="120" ref="subData" :model="subData" :rules="dataRules">

				<!--<FormItem class="FromItemnpt  org-width" label="上级机构" prop="parentname">-->
				<!--<Cascader :data="orgdata" v-model="subData.parent_arr" change-on-select @on-change='changeparent'></Cascader>-->
				<!--</FormItem>-->

				<FormItem  class="FromItemnpt org-width" label="上级机构" prop="parentname">
					<Input v-model="subData.parent_name" :disabled=true >
					<Icon type="ios-search" slot="suffix" @click="insurance_org"/>
					</Input>
					<a style="margin-left:5px;" @click="clear">恢复默认</a>
				</FormItem>


				<FormItem class="FromItemnpt" label="公司名称" prop="name"><Input v-model="subData.name"  :disabled=isview /></FormItem>
				<FormItem class="FromItemnpt" label="公司简称" prop="shortname"><Input  :disabled=isview v-model="subData.shortname" /></FormItem>
				<FormItem class="FromItemnpt" label="联系人" prop="manager"><Input  :disabled=isview v-model="subData.manager" /></FormItem>

				<FormItem class="FromItemnpt" label="联系电话" prop="managerphone"><Input  :disabled=isview v-model="subData.managerphone" /></FormItem>
				<!--<FormItem class="FromItemnpt" label="外修商类型" prop="status">-->
					<!--<Select v-model="subData.level">-->
						<!--<Option v-for="(item, index) in leveltype" :value="item.id" :key="index">{{ item.name }}</Option>-->
					<!--</Select>-->
				<!--</FormItem>-->


				<FormItem class="FromItemnpt" label="登录账号" prop="user_name" v-if='!subData.id'><Input v-model="subData.user_name"  :disabled=isview /></FormItem>

				<FormItem class="FromItemnpt" label="密码" prop="password" v-if='!subData.id'><Input type="password" v-model="subData.password" placeholder="默认手机号后六位"   :disabled=isview /></FormItem>


				<FormItem class="FromItemnpt" label="法人姓名" prop="legalName"><Input :disabled=isview v-model="subData.legalName" /></FormItem>

				<FormItem class="FromItemnpt" label="法人身份证号" prop="legalIds" ><Input v-model="subData.legalIds" :disabled=isview /></FormItem>
				<FormItem class="FromItemnpt" label="主营" prop="main_camp"><Input :disabled=isview v-model="subData.main_camp" /></FormItem>

				<FormItem class="FromItemnpt" label="营业执照号" prop="license_no"><Input v-model="subData.license_no" :disabled=isview /></FormItem>




				<FormItem class="FromItemnpt" label="营业执照照片" prop="license_pic" >
					<viewer style="display: inline-block;" :image="subData.license_pic_url" v-if="subData.license_pic_url">
						<div class="yingimgs" >
							<img :src="subData.license_pic_url" style="width: 60px;height: 60px;" class="upload-image-view" />
						</div>
					</viewer>
					<Upload  v-show=!isview
							ref="upload"
							:default-file-list="defaultList"
							:show-upload-list="false"
							:on-success="licentseupload"
							:on-error="handleError"
							:format="['jpg', 'jpeg', 'png', 'gif']"
							:max-size="1024"
							:on-format-error="handleFormatError"
							:on-exceeded-size="handleMaxSize"
							:headers="{ Authorization: 'Bearer ' + this.access_token }"
							:data="{ type: 'license', agent: 4, loginIdent: 1 }"
							type="drag"
							:action="this.uploadHttp"
							style="display: inline-block;width:58px;"
					>
						<div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;"><Icon type="ios-camera" size="20"></Icon></div>
					</Upload>
				</FormItem>

				<FormItem class="FromItemnpt" label="机构logo" prop="id_card_front" >
					<viewer style="display: inline-block;" :image="subData.logo_url" v-if="subData.logo_url">
						<div class="yingimgs" >
							<img :src="subData.logo_url" style="width: 60px;height: 60px;" class="upload-image-view" />
						</div>
					</viewer>
					<Upload v-show=!isview
							ref="upload"
							:default-file-list="defaultList"
							:show-upload-list="false"
							:on-success="handleSuccess"
							:on-error="handleError"
							:format="['jpg', 'jpeg', 'png', 'gif']"
							:max-size="1024"
							:on-format-error="handleFormatError"
							:on-exceeded-size="handleMaxSize"
							:headers="{ Authorization: 'Bearer ' + this.access_token }"
							:data="{ type: 'avatar', agent: 4, loginIdent: 1 }"
							type="drag"
							:action="this.uploadHttp"
							style="display: inline-block;width:58px;"
					>
						<div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;"><Icon type="ios-camera" size="20"></Icon></div>
					</Upload>
				</FormItem>


				<FormItem class="FromItemnpt" label="状态" prop="status">
					<Select v-model="subData.status" :disabled=isview>
						<Option v-for="(item, index) in orgstatus" :value="index" :key="index">{{ item }}</Option>
					</Select>
				</FormItem>

				<FormItem class="FromItemnpt" label="公司地址" prop="address"><Input v-model="subData.address" :disabled=isview  /></FormItem>


				<FormItem class="FromItemnpt dizhi_width" label="地区" prop="cityarr">
					<cityView ref="cityView" style="display: inline-block;" @selectFunc="selectFunc"></cityView>
				</FormItem>

				<Row>
					<FormItem class="FromItemnpt " label="经度">
						<InputNumber v-model="subData.lng" placeholder="点击事件获取经纬度"  :disabled=isview />
						<Button type="primary" @click="markmap" v-show=!isview >确定经纬度</Button>
					</FormItem>
					<FormItem class="FromItemnpt " label="纬度"><InputNumber v-model="subData.lat" placeholder="点击事件获取经纬度"  :disabled=isview /></FormItem>

					<FormItem label="公司简介" prop="remark"
					><Input type="textarea" v-model="subData.remark"  :disabled=isview /></FormItem>
				</Row>

				<FormItem class="FromItemnpt" label="注册地址" prop="reg_add"><Input v-model="subData.reg_add" :disabled=isview /></FormItem>
				<FormItem class="FromItemnpt" label="注册电话" prop="reg_phone"><Input v-model="subData.reg_phone" :disabled=isview /></FormItem>
				<FormItem class="FromItemnpt" label="厂房地址" prop="extfield1"><Input v-model="subData.extfield1" :disabled=isview /></FormItem>
				<!--<FormItem class="FromItemnpt" label="承修项目" prop="extfield2"><Input v-model="subData.extfield2" /></FormItem>-->

				<FormItem class="FromItemnpt dizhi_width" label="承修项目" >
					<CheckboxGroup v-model="subData.extfield2">
						<Checkbox v-for="item in merchantServe" :label="item.id">{{item.name}}</Checkbox>
					</CheckboxGroup>
				</FormItem>

				<FormItem class="FromItemnpt" label="经营场所照片" prop="extfield3" >
					<viewer style="display: inline-block;" :image="subData.extfield3_url" v-if="subData.extfield3">
						<div class="yingimgs" >
							<img :src="subData.extfield3_url" style="width: 60px;height: 60px;" class="upload-image-view" />
						</div>
					</viewer>
					<Upload v-show=!isview
							ref="upload"
							:default-file-list="defaultList"
							:show-upload-list="false"
							:on-success="extfield3load"
							:on-error="handleError"
							:format="['jpg', 'jpeg', 'png', 'gif']"
							:max-size="1024"
							:on-format-error="handleFormatError"
							:on-exceeded-size="handleMaxSize"
							:headers="{ Authorization: 'Bearer ' + this.access_token }"
							:data="{ type: 'license', agent: 4, loginIdent: 1 }"
							type="drag"
							:action="this.uploadHttp"
							style="display: inline-block;width:58px;"
					>
						<div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;"><Icon type="ios-camera" size="20"></Icon></div>
					</Upload>
				</FormItem>


				<FormItem class="FromItemnpt" label="车间照片" prop="extfield4" >
					<viewer style="display: inline-block;" :image="subData.extfield4_url" v-if="subData.extfield4">
						<div class="yingimgs" >
							<img :src="subData.extfield4_url" style="width: 60px;height: 60px;" class="upload-image-view" />
						</div>
					</viewer>
					<Upload  v-show=!isview
							ref="upload"
							:default-file-list="defaultList"
							:show-upload-list="false"
							:on-success="extfield4load"
							:on-error="handleError"
							:format="['jpg', 'jpeg', 'png', 'gif']"
							:max-size="1024"
							:on-format-error="handleFormatError"
							:on-exceeded-size="handleMaxSize"
							:headers="{ Authorization: 'Bearer ' + this.access_token }"
							:data="{ type: 'license', agent: 4, loginIdent: 1 }"
							type="drag"
							:action="this.uploadHttp"
							style="display: inline-block;width:58px;"
					>
						<div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;"><Icon type="ios-camera" size="20"></Icon></div>
					</Upload>
				</FormItem>

				<FormItem class="FromItemnpt" label="维修设备照片" prop="extfield5" >
					<viewer style="display: inline-block;" :image="subData.extfield5_url" v-if="subData.extfield5">
						<div class="yingimgs" >
							<img :src="subData.extfield5_url" style="width: 60px;height: 60px;" class="upload-image-view" />
						</div>
					</viewer>
					<Upload  v-show=!isview
							ref="upload"
							:default-file-list="defaultList"
							:show-upload-list="false"
							:on-success="extfield5load"
							:on-error="handleError"
							:format="['jpg', 'jpeg', 'png', 'gif']"
							:max-size="1024"
							:on-format-error="handleFormatError"
							:on-exceeded-size="handleMaxSize"
							:headers="{ Authorization: 'Bearer ' + this.access_token }"
							:data="{ type: 'license', agent: 4, loginIdent: 1 }"
							type="drag"
							:action="this.uploadHttp"
							style="display: inline-block;width:58px;"
					>
						<div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;"><Icon type="ios-camera" size="20"></Icon></div>
					</Upload>
				</FormItem>

				<FormItem class="FromItemnpt" label="品牌授权证明" prop="extfield6" >
					<viewer style="display: inline-block;" :image="subData.extfield6_url" v-if="subData.extfield6">
						<div class="yingimgs" >
							<img :src="subData.extfield6_url" style="width: 60px;height: 60px;" class="upload-image-view" />
						</div>
					</viewer>
					<Upload v-show=!isview
							ref="upload"
							:default-file-list="defaultList"
							:show-upload-list="false"
							:on-success="extfield6load"
							:on-error="handleError"
							:format="['jpg', 'jpeg', 'png', 'gif']"
							:max-size="1024"
							:on-format-error="handleFormatError"
							:on-exceeded-size="handleMaxSize"
							:headers="{ Authorization: 'Bearer ' + this.access_token }"
							:data="{ type: 'license', agent: 4, loginIdent: 1 }"
							type="drag"
							:action="this.uploadHttp"
							style="display: inline-block;width:58px;"
					>
						<div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;"><Icon type="ios-camera" size="20"></Icon></div>
					</Upload>
				</FormItem>

			</Form>
			<div slot="footer">
				<Button @click="showEdit = false">取消</Button>
				<Button type="primary" @click="submitForm"  v-show=!isview >保存</Button>
			</div>
		</Modal>
		
		<Modal v-model="markmodel" title="标注" width="900">
			<Form :label-width="80">
				<FormItem class="FromItemnpt" label="当前经度"><Input v-model="lng" clearable /></FormItem>
				<FormItem class="FromItemnpt" label="当前纬度"><Input v-model="lat" clearable /></FormItem>
				<Button type="primary" @click="confirmlnglat">确定经纬度</Button>
				<FormItem class="FromItemnpt" label="关键词"><Input v-model="map.keyword" clearable /></FormItem>
			</Form>
			<baidu-map class="map" :center="map.center" id="mapID" :zoom="map.zoom" @ready="handler">
				<!--缩放-->
				<bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
				<!--定位-->
				<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
				<!--点-->
				<bm-marker :position="map.center" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP">
					<!--提示信息-->
					<bm-info-window :show="map.show">标注位置</bm-info-window>
				</bm-marker>
				<bm-local-search class="search" :keyword="map.keyword" :auto-viewport="true"></bm-local-search>
			</baidu-map>
			<div slot="footer"></div>
		</Modal>


		<insurance_org
				ref="insurance_org"
				:initOption="chooseorgInitOption"
				@getorgdata="getChooseGY">
		</insurance_org>


	</Card>
</template>
<script>
    import insurance_org from '@/view/my-components/ditanorg/insurance_org'
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
import config from '@/config';
import cityView from '../mycomponents/cityView.vue';
import { getorglist, getorhDrop, getOrgEdit, getorgdetail, addorg, getpartsorglist } from '@/api/admin';
import { getorgstatus, getWhether,getRegoption } from '@/api/dictionary';
export default {
	components: {
		cityView,
        insurance_org
	},

	/*if(	this.isVisible(val.provinceModel)&&this.isVisible(val.cityModel)&&this.isVisible(val.districtModel))
	*/
	data() {
		const validateCityCheck = (rule, value, callback) => {
			//if ( typeof this.subData.cityCheck === Object&&this.subData.cityCheck.provinceModel&&this.subData.cityCheck.cityModel&&this.subData.cityCheck.districtModel ) {
			if(this.subData.province!=''&&this.subData.city!=''&&this.subData.district!=''){		
			    callback();
	
			} else {
				callback(new Error('请选择省市区'));
			}
		};
		const validateParent = (rule, value, callback) => {
			if (this.subData.parent_id !== '') {
				callback();
			} else {
				callback(new Error('请选择上级部门'));
			}
		};
		const validatePwd = (rule, value, callback) => {
			callback();
		};
		return {

			usertype:'2',
             chotype:1,//查询赋值，新增赋值
			handleData: {
				type: '',
				data: ''
			},
			matchloading: true,
			map: {
				keyword: '',
				center: {
					lng: 117.1195,
					lat: 36.655164
				},
				zoom: 15,
				show: true,
				dragging: true
			},
			partsdata: [],
			leveltype:[
				{
					id:0,
					name:'请选择'
				},
				{
					id:1,
					name:'服务站'
				},
				{
					id:2,
					name:'维修厂'
				}
			],
			partscanshu: [
				{
					type: 'selection',
					width: 60,
					align: 'center',
					fixed: 'left'
				},
				{
					title: 'ID',
					key: 'id',
					align: 'center',
					minWidth: 60,
					fixed: 'left'
				},
				{
					title: '名称',
					key: 'cspname',
					align: 'center',
					minWidth: 100,
					fixed: 'left'
				}
			],

			orgdata: [],
			value1: null,
			markmodel: false,
			goodsname: null,
			lat: 0,
			lng: 0,
			isview:false,
			dataRules: {
				
				name: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					}
				],
				shortname: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					}
				],

				manager: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					}
				],

				managerphone: [
					{
						required: true,
						message: '必填',
						trigger: 'blur'
					},
					{
						message: '格式有误',
						trigger: 'blur',
						pattern: /^1[3456789]\d{9}$/
					}
				],
                cityarr: [
					{
						validator: validateCityCheck,
					
						trigger: 'change'
					}
				],
				parentname: [
					{
						validator: validateParent,
						
						trigger: 'change'
					}
				],
				password: [
					{
						validator: validatePwd,
						trigger: 'blur'
					}
				],
			},
			checkedIds: [],

			uploadHttp: '',
			access_token: '',
			defaultList: [],
			legalIDCardList: [],
			userInfoType: [],
			detailData: {},
            subData: {
                id: '',
                ogtype: Enum.ogtypeEnum.repair_business,
                name: '', 			//名称
                shortname: '', 		//简称
                manager: '', 		//联系人
                managerphone: '', 	//联系电话
                user_name: '', 		//登录账号
                password: '', 		//登录密码
                address: '', 		//公司地址
                legalName: '', 		//法人姓名
                legalIds: '', 		//法人身份证号码
                main_camp: '', 		//主营
                license_no: '', 	//营业执照号
                status: "2", 		//状态 1待审核 2正常 3禁用 4驳回
                remark: '', 		//公司简介
                logo:'',
                logo_url:'',
                license_pic: '',
                license_pic_url: '',
                cityObj:[],
				cityCheck:[],
                province:'',
                city:'',
                district:'',
                // lng:0,//经度
                // lat:0,//维度
                // type:1,
                parent_id: '',//顶级机构为0
                parent_name: '',//顶级机构为0
                parent_arr: [], //父级串
                cityCheck:{
                	provinceModel:'',
                	cityModel:'',
                	districtModel:'',
                },
                reg_add:'',
                reg_phone:'',
                extfield1:'',
                extfield2:[],
                extfield3:'',
                extfield4:'',
                extfield5:'',
                extfield6:'',
                extfield3_url: '',
                extfield4_url: '',
                extfield5_url: '',
                extfield6_url: '',
            },
			hyse: false,
			types: '',
            chooseorgInitOption: {
                title: '',
                showModal: false,
            },
			search: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.repair_business,
				kw: null,
				status: null,
				stime: null,
				etime: null,
				action_id:361,
				  ogname:'',
                    ogid:'',

			},
			timeshijian: [],
			button: {},
			orgstatus: [],
            merchantServe:[],
			editTitle: '外修商新增',
			showEdit: false,
			showDetail: false,

			memberLevelDrop: [], // 会员等级
			totalCount: 10,
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			canshu: [
				{
					type: 'selection',
					width: 60,
					align: 'center',
					fixed: 'left'
				},
				{
					title: 'ID',
					key: 'id',
					align: 'center',
					width: 60,
					fixed: 'left'
				},
				{
					title: '外修商编号',
					key: 'code',
					align: 'center',
					minWidth: 160,
					fixed: 'left'
				},
				
				{
	            title: '外修商名称',
	            align: 'center',
	            key: 'name',
	            minWidth: 100,
	            render: (h, params) => {
	              let vm = this
	              return h('span', {
	                domProps: {
	                  innerHTML: params.row.name
	                },
	                style: {
	                  color: '#2d8cf0',
	                  cursor: 'pointer'
	                },
	                on: {
	                  click () {
	                    vm.handleUpdateModal(params.row.id,true);
	                  }
	                }
	              })
	            }
	          },
				{
					title: '联系人',
					key: 'manager',
					align: 'center',
					minWidth: 130
				},
				{
					title: '联系电话',
					key: 'managerphone',
					align: 'center',
					minWidth: 100
				},
				{
					title: '主营',
					key: 'main_camp',
					align: 'center',
					minWidth: 160
				},
				{
					title: '所在省市区',
					key: 'area_name',
					align: 'center',
					minWidth: 150
				},
				{
					title: '所在地址',
					key: 'address',
					align: 'center',
					minWidth: 100
				},

				{
					title: '添加时间',
					key: 'createtime',
					align: 'center',
					minWidth: 170
				},
				{
					title: '状态',
					key: 'status_str',
					align: 'center',
					minWidth: 100
				},
				{
					title: '操作',
					key: 'action',
					width: 350,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
										disabled: !this.button.edit
									},
									style: {
										marginRight: '5px',
										display:!this.button.edit?'none':''
									},
									on: {
										click: () => {
											this.handleUpdateModal(params.row.id,false);
										}
									}
								},
								'编辑'
							),
							h(
								'Button',
								{
									props: {
										type: 'success',
										size: 'small'
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.matchparts(params.row);
										}
									}
								},
								'维修分类'
							)
							,
							// h(
							// 	'Button',
							// 	{
							// 		props: {
							// 			type: 'warning',
							// 			size: 'small'
							// 		},
							// 		style: {
							// 			marginRight: '5px',
							// 			display:this.usertype==1?"":"none"
							// 		},
							// 		on: {
							// 			click: () => {
							// 				this.matcharea(params.row);
							// 			}
							// 		}
							// 	},
							// 	'保险合作区域'
							// ),
							h(
								'Button',
								{
									props: {
										type: 'warning',
										size: 'small'
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.coverarea(params.row);
										}
									}
								},
								'覆盖区域'
							)
						]);
					}
				}
			],
			canshudata: [],
			partsList: []
		};
	},
	methods: {
        clear() {
            this.subData.parent_id= '0';
            this.subData.parent_name= '顶级机构';
        },
  clearogid(){

            	console.log('------clearogid--------');
            	this.search.ogid = 0;
                	this.search.ogname ='';
            },
        insurance_org (ctype) {
        	this.chotype=ctype;
            this.chooseorgInitOption.title = '选择保险公司';
            this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.repair_business;
            this.chooseorgInitOption.showModal = true;
            this.$refs.insurance_org.handleSearch()
        },
        getChooseGY (item) {
            // console.log(item);

            this.chooseorgInitOption.showModal = false
             if (this.chotype==1) {
                	this.search.ogid = item.id;
                	this.search.ogname = item.name;
                }else{
            this.subData.parent_id = item.id;
            this.subData.parent_name = item.name;
        }
        },
		getUserInfoType() {
			//
			getWhether().then(res => {
				this.userInfoType = res.data;
			});
		},
		getinsuranceorg() {
			let params = {
				ogtype: Enum.ogtypeEnum.repair_business
			};
			getorhDrop(params).then(res => {
				let resdata = res.data;
				resdata.unshift({
					value: 0,
					label: '顶级机构',
					children: []
				});
				this.orgdata = resdata;
			});
		},
		tableSelectionChange(data2) {
			if (data2[0] != undefined) {
				this._checkedId = data2[0].id;
			}
			let ids = [];
			data2.map(function(item, key) {
				ids.push(item.id);
			});
			this.checkedIds = ids;
		},
		handlepartsSelectAll(val) {
			this.$refs.partsselection.selectAll(val);
		},

		markmap() {
			//标注
			this.markmodel = true;
			if (this.subData.lng) {
				this.map.center.lng = this.subData.lng;
				this.map.center.lat = this.subData.lat;
			}
		},
		handleEdit(type) {
			if (this.checkedIds.length > 0) {
				if (type == 1) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的机构设置为删除状态么？?</p>',
						onOk: () => {
							this.sysEdit(type);
						},
						onCancel: () => {
							this.$Message.info('您取消了操作');
						}
					});
				} else if (type == 2) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的机构与机构下的用户设置为启用状态么？?</p>',
						onOk: () => {
							this.sysEdit(type);
						},
						onCancel: () => {
							this.$Message.info('您取消了操作');
						}
					});
				} else if (type == 3) {
					this.$Modal.confirm({
						title: '确认信息',
						content: '<p>你确定要将选中的机构与机构下的用户设置为禁用状态么？?</p>',
						onOk: () => {
							this.sysEdit(type);
						},
						onCancel: () => {
							this.$Message.info('您取消了操作');
						}
					});
				}
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},
		handleGrade() {
			// 批量设置等级
			if (this.checkedIds.length > 0) {
				this.hyse = true;
			} else {
				this.$Modal.info({
					title: '提示',
					content: '请至少选择一项'
				});
			}
		},

		handleSubmit(type) {
			let params = {};
			params.id = this.checkedIds;
			params.type = type;
			params.data = this.handleData.levelData;
			this.sysEdit(params);
		},
		sysEdit(type) {
			const id = this.checkedIds;

			let parms = {
				id: id,
				type: type
			};
			getOrgEdit(parms).then(res => {
				if (res.errCode === 0) {
					this.$Notice.success({
						title: '提示',
						desc: '操作成功'
					});
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},
		selectFunc(cityObj) {
			// console.log('-----cityObj------',cityObj);
			var curobj={provinceModel:'',cityModel:'',districtModel:''};
			this.subData.cityCheck = typeof cityObj === Object?cityObj:curobj;
			this.subData.province = cityObj.provinceModel;
			this.subData.city = cityObj.cityModel;
			this.subData.district = cityObj.districtModel;
		},
		selectFunc1(cityObj) {
			this.search.province = cityObj.provinceModel;
			this.search.city = cityObj.cityModel;
			this.search.district = cityObj.districtModel;
		},

		getorgstatus() {
			getorgstatus().then(res => {
				this.orgstatus = res.data;
			});
		},
        getMerchantServe() {
            getRegoption().then(res => {
                // console.log(res.data.csp)
                this.merchantServe = res.data.csp
            })
        },
		matchparts(val) {
            // alert(11111);
			// console.log('result', val);
			let query = { ogdata: val, type: 'edit',fromtop:1 };
			this.$router.push({
				name: 'extra_bussiness_parts',
				query: query
			});
		},
		matcharea(val) {
			// console.log('result', val);
            // alert(222222);
			let query = { ogdata: val, type: 'extra',title:'外修商' };
			this.$router.push({
				name: 'more_bussiness_area',
				query: query
			});
		},

		coverarea(val) {
			// console.log('result', val);
            // alert(222222);
			let query = { ogdata: val, type: 'extra',title:'外修商' };
			this.$router.push({
				name: 'cover_bussiness_area',
				query: query
			});
		},

		confirmlnglat() {
			this.subData.lng = this.lng;
			this.subData.lat = this.lat;
			this.$Notice.success({
				title: '提示',
				desc: '选择成功'
			});
			this.markmodel = false;
		},

		// 日期
		checkDate(date) {
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		changeparent(value, selectedData) {
			// console.log(selectedData,value);return;

			this.subData.parent_name = selectedData[selectedData.length - 1].label;
			this.subData.parent_arr = value;
			this.subData.parent_id = selectedData[selectedData.length - 1].value;
		},
		// 查询
		handleSearchAction() {
			this.search.p = 1;
			this.search.is_export = 0;
			this.handleSearch();
		},
		handler({ BMap, map }) {
			let me = this;
			// 鼠标缩放

			map.enableScrollWheelZoom(true);
			// 点击事件获取经纬度
			map.addEventListener('click', function(e) {
				me.subData.lng = e.point.lng;
				me.subData.lat = e.point.lat;
				me.lng = e.point.lng;
				me.lat = e.point.lat;
			});
		},
		// 分页
		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		// 列表
		handleSearch() {
			let params = this.search;
			// getMember(params).then(res => {
			getorglist(params).then(res => {
				if (res.errCode == 0) {
					this.totalCount = parseInt(res.data.total);
					this.canshudata = res.data.list;
					this.button = res.data.button;
				}
			});
		},
		reset() {
			this.$refs.subData.resetFields();
		},
		// 新增
		handleCreateModel() {
			this.reset();
            this.subData.id= '';
            this.subData.parent_id= '0';
            this.subData.parent_name= '顶级机构';
			this.subData.status = '2';
			this.subData.lat = 0;
			this.subData.lng = 0;
			this.$refs.cityView.viewdata.provinceModel = '';
			this.$refs.cityView.viewdata.cityModel = '';
			this.$refs.cityView.viewdata.districtModel = '';
			this.showEdit = true;
			this.editTitle = '外修商新增';
		},

		handleUpdateModal(data,edit) {
			this.viewData(data);
			this.editTitle = '外修商修改';
			this.isview=edit;
			this.showEdit = true;
		},

		viewData(uid) {
			getorgdetail({ id: uid }).then(res => {
				this.subData = res.data;
				// console.log('this.subData ',this.subData )
				let that = this;
				setTimeout(function() {
					that.$refs.cityView.viewdata.provinceModel = res.data.province;
					that.$refs.cityView.viewdata.cityModel = res.data.city;
					that.$refs.cityView.viewdata.districtModel = res.data.district;
				}, 150);
			});
		},

		submitForm() {
			// 保存
		  // console.log('submitForm',11);
			var that = this;
			// console.log('this.$refs.subData',this.$refs.subData)
			try{
				this.$refs.subData.validate(valid => {
						console.log('valid',valid)
					if (valid) {
				
						that.subData.ogtype = 2;
						let params = that.subData;
						addorg(params).then(res => {
							if (res.errCode == 0) {
								that.showEdit = false;
								that.handleSearch();
								that.reset();
								that.$Modal.success({
									title: '提示',
									content: '提交成功'
								});
							} else {
								that.$Modal.error({
									title: '提示',
									content: res.errMsg
								});
							}
						});
					}else{
						that.$Modal.error({
							title: '提示',
							content: '表单检验错误'
						});
					}
				});
			}catch(e){
				//TODO handle the exception
				// console.log('catch',e);
			}
			
			
		},
		handleError(data1, data2, data3) {
			// error file fileList
			this.$Modal.error({
				title: '提示',
				content: '上传失败'
			});
		},
		handleFormatError(data1, data2, data3) {
			// file fileList
			this.$Modal.error({
				title: '提示',
				content: '文件格式不正确'
			});
		},
		handleMaxSize(data1, data2) {
			// file fileList
			this.$Modal.error({
				title: '提示',
				content: '文件太大'
			});
		},
		licentseupload(data1, data2, data3) {
			//上传法人照片
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.license_pic = data1.data[0].pic; // 图片上传地址
				this.subData.license_pic_url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: data1.errMsg
				});
			}
		},
		idfrontupload(data1, data2, data3) {
			//上传法人照片
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.id_card_front = data1.data[0].pic; // 图片上传地址
				this.subData.id_card_front_url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: data1.errMsg
				});
			}
		},
		idbackupload(data1, data2, data3) {
			//上传法人照片
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.id_card_back = data1.data[0].pic; // 图片上传地址
				this.subData.id_card_back_url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: data1.errMsg
				});
			}
		},
		handleSuccess(data1, data2, data3) {
			//上传门口照
			// response file fileList    图标
			if (data1.errCode === 0) {
				this.$Notice.success({
					title: '提示',
					desc: '上传成功'
				});
				this.defaultList = [];
				this.subData.logo = data1.data[0].pic; // 图片上传地址
				this.subData.logo_url = data1.data[0].url; // 图片域名地址
			} else {
				this.$Notice.error({
					title: '提示',
					desc: data1.errMsg
				});
			}
		},
		//经营场所照片
        extfield3load(data1, data2, data3) {
            if (data1.errCode === 0) {
                this.$Notice.success({
                    title: '提示',
                    desc: '上传成功'
                });
                this.defaultList = [];
                this.subData.extfield3 = data1.data[0].pic; // 图片上传地址
                this.subData.extfield3_url = data1.data[0].url; // 图片域名地址
            } else {
                this.$Notice.error({
                    title: '提示',
                    desc: data1.errMsg
                });
            }
        },
        //车间照片
        extfield4load(data1, data2, data3) {
            if (data1.errCode === 0) {
                this.$Notice.success({
                    title: '提示',
                    desc: '上传成功'
                });
                this.defaultList = [];
                this.subData.extfield4 = data1.data[0].pic; // 图片上传地址
                this.subData.extfield4_url = data1.data[0].url; // 图片域名地址
            } else {
                this.$Notice.error({
                    title: '提示',
                    desc: data1.errMsg
                });
            }
        },
        //维修设备照片
        extfield5load(data1, data2, data3) {
            if (data1.errCode === 0) {
                this.$Notice.success({
                    title: '提示',
                    desc: '上传成功'
                });
                this.defaultList = [];
                this.subData.extfield5 = data1.data[0].pic; // 图片上传地址
                this.subData.extfield5_url = data1.data[0].url; // 图片域名地址
            } else {
                this.$Notice.error({
                    title: '提示',
                    desc: data1.errMsg
                });
            }
        },
        //品牌授权证明
        extfield6load(data1, data2, data3) {
            if (data1.errCode === 0) {
                this.$Notice.success({
                    title: '提示',
                    desc: '上传成功'
                });
                this.defaultList = [];
                this.subData.extfield6 = data1.data[0].pic; // 图片上传地址
                this.subData.extfield6_url = data1.data[0].url; // 图片域名地址
            } else {
                this.$Notice.error({
                    title: '提示',
                    desc: data1.errMsg
                });
            }
        },
		getDuration(date) {
			// how many days of this month
			let dt = new Date();
			var month = dt.getMonth();
			dt.setMonth(dt.getMonth() + 1);
			dt.setDate(0);
			return dt.getDate();
		},
		GetDate(is) {
			var now = new Date();
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			// var day = now.getDate();            　　//日
			if (is) {
				return year + '-' + month + '-' + is;
			} else {
				return year + '-' + month + '-1';
			}
		}
	},
	mounted() {
		switch (this.$route.query.type) {
			case 1:
				var mun = this.getDuration();
				this.search.status = '1';
				this.search.stime = this.GetDate(false);
				this.search.etime = this.GetDate(false);
				this.timeshijian = [this.GetDate(false), this.GetDate(false)];
				break;
			case 2:
				break;
		}
		this.getMerchantServe();
		this.getorgstatus();
		//获取保险公司机构下拉
		this.getinsuranceorg();
		this.getUserInfoType();
		this.handleSearch();
		this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro;
		this.access_token = util.getToken();
		this.usertype=util.getUserType();
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}

.map .search {
	margin-bottom: 65px;
}
.org-width {
	width: 480px !important;
}
.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
</style>

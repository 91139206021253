<template>
  <div class="content">
    <div class="rowone">

      <div class="externalRepairone" >
        <div class="externalRepair-left">
          <td class="title">本月外修单数</td>
          <td class="valueflex">
            <div class="value"  style="display: flex;">{{ waixiu_singular.total.value }}
            <div class="lineendone">单</div>
            </div>
           
          </td>
          <td class="as">与上月同比</td>
          <td class="count">
            <p>{{ waixiu_singular.total.trend }}  </p>
            <p>{{ waixiu_singular.total.ratio }}</p>
          </td>
        </div>
        <div class="externalRepair-right">
          <div class="device" v-for="(item, index) in waixiu_singular.list" :key="index">
            <div class="rowDevice" style="font-size:15px;">
              <p style="width: 45px;text-align: right;">{{ item.name }}</p>
              <p style="width: 74px;text-align: right;">
              {{ item.value }}
              {{ item.company}}</p>
              <div style="display:flex;width: 49px;justify-content: flex-end;">
                <p style="text-align: right;">{{ item.trend }} </p>
                <p style="text-align: right;">
                {{ item.ratio }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="externalRepairtwo" >
        <div class="externalRepair-left">
          <td class="title">本月外修金额</td>
          <td class="valueflex">
            <div class="value" style="display: flex;">{{ waixiu_money.total.value }}
             <div class="lineendone">万</div></div>
          
          </td>
          <td class="as">与上月同比</td>
          <td class="count">
            <span>{{ waixiu_money.total.trend }}</span>
            <span>{{ waixiu_money.total.ratio }}</span>
          </td>
        </div>
         <div class="externalRepair-right">
          <div class="device" v-for="(item, index) in waixiu_money.list" :key="index">
            <div class="rowDevice" style="font-size:15px;">
              <p style="width: 45px;text-align: right;">{{ item.name }}</p>
              <p style="width: 78px;text-align: right;">
              {{ item.value }}
              {{ item.company}}</p>
              <div style="display:flex;width: 49px;justify-content: flex-end;">
                <p style="text-align: right;">{{ item.trend }} </p>
                <p style="text-align: right;">
                {{ item.ratio }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="materialDamageone">
        <div class="externalRepair-left">
          <td class="title">本月物损单数</td>
          <td class="valueflex">
          
 <div class="value"  style="display: flex;">{{ wusun_singular.total.value }}
            <div class="lineendone">单</div>
            </div>
          </td>
          <td class="as">与上月同比</td>
          <td class="count">
            <span>{{wusun_singular.total.trend}}</span>
            <span>{{wusun_singular.total.ratio}}</span>
          </td>
        </div>
      </div>

      <div class="materialDamagetwo">
        <div class="externalRepair-left">
          <td class="title">本月物损金额</td>
          <td class="valueflex">
 <div class="value"  style="display: flex;">{{ wusun_money.total.value  }}
            <div class="lineendone">万</div>
            </div>

          </td>
          <td class="as">与上月同比</td>
          <td class="count">
            <span>{{ wusun_money.total.trend }}</span>
            <span>{{ wusun_money.total.ratio }}</span>
          </td>
        </div>
      </div>


    </div>


    <div class="rowtwo">
      <div class="twoBox">
        <div class="twoTitle">
          <td class="twotit">新增外修商</td>
          <td class="valueflex">
            <div class="twpvalue">
              {{ add_waixiu.total }}
              </div>
            <span class="lineend">家</span>
          </td>
        </div>
        <div class="flexbw">
          <div class="lefeColumn">
            <ol class="twodevice" >
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>钣金件</span>
                <span>{{ add_waixiu.banjin }}家</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>机修件</span>
                <span>{{ add_waixiu.jixiu }}家</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>电器件</span>
                <span>{{ add_waixiu.dianqi }}家</span>
              </li>
            </ol>
          </div>
          <div class="rightColumn">
            <ol class="twodevice" >
              <li class="rowDevice" style="margin-left: 21px !important;">
                <span>玻璃件</span>
                <span>{{ add_waixiu.boli }}家</span>
              </li>
              <li class="rowDevice" style="margin-left: 21px !important;">
                <span>总成件</span>
                <span>{{ add_waixiu.zongcheng }}家</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="twoBox">
        <div class="twoTitle">
          <td class="twotit">本月有效单数</td>
          <td class="valueflex">
            <div class="twpvalue">{{ youxiao.total }}</div>
            <span class="lineend">单</span>
          </td>
        </div>
        <div class="flexbw">
          <div class="lefeColumn">
            <ol class="twodevice" >
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>钣金件</span>
                <span>{{ youxiao.banjin }}单</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>机修件</span>
                <span>{{ youxiao.jixiu }}单</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>电器件</span>
                <span>{{ youxiao.dianqi }}单</span>
              </li>
            </ol>
          </div>
          <div class="rightColumn">
            <ol class="twodevice">
              <li class="rowDevice" style="margin-left: 21px !important;">
                <span>玻璃件</span>
                <span>{{ youxiao.boli }}单</span>
              </li>
              <li class="rowDevice" style="margin-left: 21px !important;">
                <span>总成件</span>
                <span>{{ youxiao.zongcheng }}单</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="twoBox">
        <div class="twoTitle">
          <td class="twotit">减损金额</td>
          <td class="valueflex">
            <div class="twpvalue">{{ jiansun_money.total }}</div>
            <span class="lineend">万</span>
          </td>
        </div>
        <div class="flexbw">
          <div class="lefeColumn">
            <ol class="twodevice">
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>钣金件</span>
                <span>{{ jiansun_money.banjin }}万</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>机修件</span>
                <span>{{ jiansun_money.jixiu }}万</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>电器件</span>
                <span>{{ jiansun_money.dianqi }}万</span>
              </li>
            </ol>
          </div>
          <div class="rightColumn">
            <ol class="twodevice" >
              <li class="rowDevice" style="margin-left:21px !important;">
                <span>玻璃件</span>
                <span>{{ jiansun_money.boli }}万</span>
              </li>
              <li class="rowDevice" style="margin-left:21px !important;">
                <span>总成件</span>
                <span>{{ jiansun_money.zongcheng }}万</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="twoBox">
        <div class="twoTitle">
          <td class="twotit">减损率</td>
          <td class="valueflex">
            <div class="twpvalue">{{ jiansun_lv.total }}</div>
            <span class="lineend"></span>
          </td>
        </div>
        <div class="flexbw">
          <div class="lefeColumn">
            <ol class="twodevice" >
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>钣金件</span>
                <span>{{ jiansun_lv.banjin }}</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>机修件</span>
                <span>{{ jiansun_lv.jixiu }}</span>
              </li>
              <li class="rowDevice" style="margin-left: 0px !important;">
                <span>电器件</span>
                <span>{{ jiansun_lv.dianqi }}</span>
              </li>
            </ol>
          </div>
          <div class="rightColumn">
            <ol class="twodevice" >
              <li class="rowDevice" style="margin-left: 21px !important;">
                <span>玻璃件</span>
                <span>{{ jiansun_lv.boli }}</span>
              </li>
              <li class="rowDevice" style="margin-left: 21px !important;">
                <span>总成件</span>
                <span>{{ jiansun_lv.zongcheng }}</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="twoBox">
        <div class="efficiency">
          <td class="efficiency-title">订单有效率</td>
          <td class="valueflex">
            <div class="efficiencyValue">{{dingdan_youxiao_lv}}</div>
            <span class="lineend"></span>
          </td>
        </div>
      </div>
    </div>

    <div class="rowthree">
      <div class="threeChart">
        <div class="threetitle">未外修成功饼状图</div>
        <div class="pieChart" id="pie"></div>
      </div>

      <div class="threeChartmid">
        <div class="threetitle">外修商区域统计</div>
          <div class="pieChart" >
          <mmp :datatype="waixiushang_area"></mmp>
        </div>

      </div>
      <div class="threeChart">
        <div class="threetitle">有效外修单走势图</div>
        <div class="pieChart" >
          <effectiveTrend :datatype="youxiaowaixiudan"></effectiveTrend>
        </div>
      </div>
    </div>

    <div class="rowfour">
      <div class="fourChart">
         <div class="threetitle">外修金额走势图</div>
        <div class="pieCharttwo" >
        <amountTrend :datatype="waixiu_money_zoushi"></amountTrend>
        </div>

      </div>
      <div class="fourChart">
         <div class="threetitle">减损金额走势图</div>
        <div class="pieCharttwo" >
        <decreasing :datatype="jiansun_money_zoushi" ></decreasing>
        </div>

      </div>
      <div class="fourChart">
          <div class="threetitle">询价中标单走势图</div>
        <div class="pieCharttwo" >
          <bidWinning :datatype="xunjia_zhongbiao_zoushi"></bidWinning>
        </div>

      </div>
    </div>
    <div class="rowfive">

      <div class="fiveChart">
        <div class="fivetitle">
<!--          <div class="title-one"><p>外修商TOP5省份</p></div>-->
<!--          <div class="title-two"><p>保险公司TOP5省份</p></div>-->
<!--          <div class="title-two"><p>物损商TOP5省份</p></div>-->


          <div :class="org_show1==true?'title-one':'title-two'" @click="waixiushang_top(1)"><p>外修商TOP5省份</p></div>
          <div :class="org_show2==true?'title-one':'title-two'" @click="waixiushang_top(2)"><p>保险公司TOP5省份</p></div>
          <div :class="org_show3==true?'title-one':'title-two'" @click="waixiushang_top(3)"><p>物损商TOP5省份</p></div>
        </div>
        <div class="five-bottom"  v-show="org_show1">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in org_top.waixiushang_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>{{ item.province_name }}
                </span>
                <span>{{ item.value }} 家</span>
              </li>
            </ol>
          </div>
        </div>

        <div class="five-bottom" v-show="org_show2">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in org_top.baoxian_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>
                  {{ item.province_name }}
                </span>
                <span>
                  {{ item.value }}
                 家</span>
              </li>
            </ol>
          </div>
        </div>

        <div class="five-bottom" v-show="org_show3">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in org_top.wusun_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>{{ item.province_name }}</span>
                <span>{{ item.value }} 家</span>
              </li>
            </ol>
          </div>
        </div>
      </div>


      <div class="fiveChart">
        <div class="fivetitle">
          
          <div :class="dan_show1==true?'title-one':'title-two'" style="width:134px !important;" @click="danshu_tops(1)"><p>发单数TOP5保险公司</p></div>
          <div :class="dan_show2==true?'title-one':'title-two'" style="width:122px !important;" @click="danshu_tops(2)"><p>接单数TOP5外修商</p></div>
          <div :class="dan_show3==true?'title-one':'title-two'" @click="danshu_tops(3)"><p>接单数TOP5物损商</p></div>
        </div>
        <div class="five-bottom" v-show="dan_show1">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in danshu_top.fadan_baoxian_top" :key="index">
              <li class="rowfiveDevice"  style="width: 407px !important;">
                <span>{{ item.baoxianname }}</span>
                <span>
                  {{ item.counts }} 
                单</span>
              </li>
            </ol>
          </div>
        </div>

        <div class="five-bottom" v-show="dan_show2">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in danshu_top.jiedan_waixiushang_top" :key="index">
              <li class="rowfiveDevice"  style="width: 407px !important;">
                <span>{{ item.name }}</span>
                <span>{{ item.counts }} 单</span>
              </li>
            </ol>
          </div>
        </div>

        <div class="five-bottom" v-show="dan_show3">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in danshu_top.jiedan_wusun_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>{{ item.name }}</span>
                <span>{{ item.counts }} 单</span>
              </li>
            </ol>
          </div>
        </div>
      </div>



      <div class="fiveChart">
        <div class="fivetitle">
          
          <div :class="jiansun_show1==true?'title-one':'title-two'" @click="jiansun_tops(1)"><p>减损金额TOP5省份</p></div>
          <div :class="jiansun_show2==true?'title-one':'title-two'" style="width:117px !important;" @click="jiansun_tops(2)"><p>减损金额TOP5保险</p></div>
          <div :class="jiansun_show3==true?'title-one':'title-two'" style="width:134px !important;"  @click="jiansun_tops(3)"><p>减损金额TOP5外修商</p></div>
        </div>
        <div class="five-bottom" v-show="jiansun_show1">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in jiansun_top.jiansun_shengfeng_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>{{ item.bx_province_name }}</span>
                <span>{{ item.sums }} 万</span>
              </li>
            </ol>
          </div>
        </div>
        <div class="five-bottom" v-show="jiansun_show2">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in jiansun_top.jiansun_baoxian_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>{{ item.baoxianname }}</span>
                <span>{{ item.sums }} 万</span>
              </li>
            </ol>
          </div>
        </div>
        <div class="five-bottom" v-show="jiansun_show3">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in jiansun_top.jiansun_waixiushang_top" :key="index">
              <li class="rowfiveDevice" style="width: 407px !important;">
                <span>{{ item.name }}</span>
                <span>{{ item.sums }} 万</span>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class="fiveCharts">
        <div class="fivetitle">
          
          <div :class="jiansunLv_show1==true?'title-three':'title-four'" @click="jiansunLv_tops(1)"><p>减损率TOP5省份</p></div>
          <div :class="jiansunLv_show2==true?'title-three':'title-four'" @click="jiansunLv_tops(2)"><p>减损率TOP5保险公司</p></div>
        </div>
         <div class="five-bottom" v-show="jiansunLv_show1">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in jiansun_top.jiansun_shengfeng_top2" :key="index">
              <li class="rowfiveDevice">
                <span>{{ item.bx_province_name }}</span>
                <span>{{ item.jiansunlv }}</span>
              </li>
            </ol>
          </div>
        </div>

        <div class="five-bottom" v-show="jiansunLv_show2">
          <div class="fiveColom">
            <ol class="device" v-for="(item, index) in jiansun_top.jiansun_baoxian_top2" :key="index">
              <li class="rowfiveDevice">
                <span>{{ item.baoxianname }}</span>
                <span>{{ item.jiansunlv }}</span>
              </li>
            </ol>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import "echarts/map/js/china.js";
import effectiveTrend from "../../components/charts/effectiveTrend.vue"
import bidWinning from "../../components/charts/bidWinning.vue"
import amountTrend from "../../components/charts/amountTrend.vue"
import decreasing from "../../components/charts/decreasing.vue"
import mmp from "../../components/charts/mmp.vue"
import {zongheZoushi} from "@api/admin";
export default {
  components:{
    effectiveTrend,bidWinning,amountTrend,decreasing,mmp
  },
  data() {
    return {
      waixiu_singular:{
        total:{},
        list:{},
      },
      waixiu_money:{
        total:{},
        list:{},
      },
      wusun_singular:{
        total:{},
      },
      wusun_money:{
        total:{},
      },
      add_waixiu:{},
      youxiao:{},
      jiansun_money:{},
      jiansun_lv:{},
      dingdan_youxiao_lv:{},
      not_succeed:[],
      waixiushang_area:[],
      youxiaowaixiudan:{},
      waixiu_money_zoushi:{},
      jiansun_money_zoushi:{},
      xunjia_zhongbiao_zoushi:{},
      org_top:{
        waixiushang_top:[],
        baoxian_top:[],
        wusun_top:[],
      },
      danshu_top:{
        fadan_baoxian_top:[],
        jiedan_waixiushang_top:[],
        jiedan_wusun_top:[],
      },
      jiansun_top:{
        jiansun_shengfeng_top:[],
        jiansun_baoxian_top:[],
        jiansun_waixiushang_top:[],
      },

      org_show1:true,
      org_show2:false,
      org_show3:false,

      dan_show1:true,
      dan_show2:false,
      dan_show3:false,

      jiansun_show1:true,
      jiansun_show2:false,
      jiansun_show3:false,

      jiansunLv_show1:true,
      jiansunLv_show2:false,

      // tworightlist: [
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //   },
      // ],
      // twoleftlist: [
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //   },
      // ],
      // list: [
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //     icon: "↑",
      //     count: "+20%",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //     icon: "↑",
      //     count: "+20%",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //     icon: "↑",
      //     count: "+20%",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //     icon: "↑",
      //     count: "+20%",
      //   },
      //   {
      //     name: "钣金件",
      //     value: "100单",
      //     icon: "↑",
      //     count: "+20%",
      //   },
      // ],
    };
  },
  methods: {
    handleSearch() {
      zongheZoushi().then(res => {
        if (res.errCode == 0) {
          this.waixiu_singular = res.data.waixiu_singular;
          this.waixiu_money = res.data.waixiu_money;
          this.wusun_singular = res.data.wusun_singular;
          this.wusun_money = res.data.wusun_money;
          this.add_waixiu = res.data.add_waixiu;
          this.youxiao = res.data.youxiao;
          this.jiansun_money = res.data.jiansun_money;
          this.jiansun_lv = res.data.jiansun_lv;
          this.dingdan_youxiao_lv = res.data.dingdan_youxiao_lv;
          this.not_succeed = res.data.not_succeed;
          this.waixiushang_area = res.data.waixiushang_area;
          this.youxiaowaixiudan = res.data.youxiaowaixiudan;
          this.waixiu_money_zoushi = res.data.waixiu_money_zoushi;
          this.jiansun_money_zoushi = res.data.jiansun_money_zoushi;
          this.xunjia_zhongbiao_zoushi = res.data.xunjia_zhongbiao_zoushi;
          this.org_top = res.data.org_top;
          this.danshu_top = res.data.danshu_top;
          this.jiansun_top = res.data.jiansun_top;

          this.getpieChart();
        }
      });
    },
    getpieChart() {
      var chartDom = document.getElementById("pie");
      var myChart = echarts.init(chartDom, null, { width: 380, height: 120 });
      var option;
      option = {
        tooltip: {
          trigger: 'item',
          position:'right'
        },
        global: false,
        series: [
          {
            // 位置
            center: ["50%", "59%"],
            label: {
              align: "left",
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },
                textStyle: {
                  fontSize: 8,
                  //显示文字的大小，
                  color: "#333333",
                },
              },
            },
            labelLine: {

              smooth: 0.2,
              length:10,
              length2: 5
            },
            name: "",
            type: "pie",
            // 设置大小
            radius: "78%",
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            // data: [
            //   { value: 1048, name: " 超时未报价" },
            //   { value: 735, name: "报价未修复" },
            //   { value: 580, name: "取件后无法修" },
            //   { value: 484, name: "取件无法修" },
            //   { value: 300, name: "修复成功" },
            // ],
            data: this.not_succeed,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            legend: {
              orient: "horizontal",
              x: "right",
            },
            color: ["#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE"],
          },
        ],
      };
      option && myChart.setOption(option);
    },

    waixiushang_top(type) {
      if (type == 1) {
        this.org_show1 = true;
        this.org_show2 = false;
        this.org_show3 = false;
      } else if (type == 2) {
        this.org_show1 = false;
        this.org_show2 = true;
        this.org_show3 = false;
      } else if (type == 3) {
        this.org_show1 = false;
        this.org_show2 = false;
        this.org_show3 = true;
      }
    },
    danshu_tops(type) {
      if (type == 1) {
        this.dan_show1 = true;
        this.dan_show2 = false;
        this.dan_show3 = false;
      } else if (type == 2) {
        this.dan_show1 = false;
        this.dan_show2 = true;
        this.dan_show3 = false;
      } else if (type == 3) {
        this.dan_show1 = false;
        this.dan_show2 = false;
        this.dan_show3 = true;
      }
    },
    jiansun_tops(type) {
      if (type == 1) {
        this.jiansun_show1 = true;
        this.jiansun_show2 = false;
        this.jiansun_show3 = false;
      } else if (type == 2) {
        this.jiansun_show1 = false;
        this.jiansun_show2 = true;
        this.jiansun_show3 = false;
      } else if (type == 3) {
        this.jiansun_show1 = false;
        this.jiansun_show2 = false;
        this.jiansun_show3 = true;
      }
    },
    jiansunLv_tops(type) {
      if (type == 1) {
        this.jiansunLv_show1 = true;
        this.jiansunLv_show2 = false;
      } else if (type == 2) {
        this.jiansunLv_show1 = false;
        this.jiansunLv_show2 = true;
      }
    },
  },
  mounted() {
    this.handleSearch();
    this.getpieChart();
    // this.setup();
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  padding: 0px 10px;
}
.rowone {
  width: 100%;
     height: 97px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.rowtwo {
  width: 100%;
       height: 69px;
  margin-bottom: 8px;
  display: flex;
  color: #333333;
  justify-content: space-between;
  align-content: center;
}
.rowthree {
  width: 100%;
    height: 150px;
  margin-bottom: 8px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-content: center;
}
.rowfour {
  width: 100%;
 height: 158px;
  margin-bottom:8px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.rowfive {
  width: 100%;
     height: 102px;
  margin-bottom: 5px;
  display: flex ;
  justify-content: space-between;
  align-content: center;
}
.externalRepairone {
 width: 28.5%;
  height: 100%;
  display: flex;
  padding:9px 20px;
  background-image: url(../../img/four.png);
  background-position: center center;
  background-size: 100% 100%;
  box-shadow: 0px 2px 17px 0px rgba(97, 97, 97, 0.14);
  border-radius: 8px;
}
.externalRepairtwo {
width: 28.5%;
  height: 100%;
  display: flex;
   padding:9px 20px;
  background-image: url(../../img/two.png);
  background-position: center center;
  background-size: 100% 100%;
  box-shadow: 0px 2px 17px 0px rgba(97, 97, 97, 0.14);
  border-radius: 8px;
}
.materialDamageone {
 width: 20.5%;
  height: 100%;
  background-image: url(../../img/yi.png);
  background-position: center center;
  background-size: 100% 100%;

  border-radius: 8px;
   padding:9px 20px;
}
.materialDamagetwo {
width: 20.5%;
  height: 100%;
  background-image: url(../../img/three.png);
  background-position: center center;
  background-size: 100% 100%;

  border-radius: 8px;
   padding:9px 20px;
}
.twoBox {
        width: 19.6%;
  height: 100%;
  color: #333333;
padding: 6px 20px 4px 20px;
  box-shadow: 0px 2px 17px 0px rgba(97, 97, 97, 0.14);
  border-radius: 8px;
  background-color: white;
}
.threeChart {
  width: 30.5%;
  height: 100%;
  padding: 4px 0px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.threeChartmid {
  padding: 4px 0px 0px 0px;
    width: 38%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.fourChart {
  padding: 4px 0px 0px 0px;
      width: 32.9%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.fiveChart {
     width: 26.1%;
 height: 102px;
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.fiveCharts {
    width: 20%;
height: 102px;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.externalRepair-left {
  width: 150px;
    height: 98px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: white;
}
.efficiency {
  width: 150px;
  height: 140px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: #333333;
  font-family: Microsoft YaHei;
}
.title {
width: 100%;
    height: 14px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
}
.efficiency-title {

    width: 90px;
    height: 22px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 18px;

}
.twotit {
  width: 366px;
  height: 100%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
     line-height: 16px;

}
.value {
width: 57px;
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: white;
  

}
.efficiencyValue {
width: 40px;
    height: 20px;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 25px;
}
.twpvalue {
    width:80px;
    height: 16px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 19px;
    text-align: right;
}
.as {
    width: 79px;
    height: 26px;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 29px;
}
.count {
     width: 86px;
    height: 21px;
    font-size: 18px;
    display: flex;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 27px;
}
td {
  width: 100%;
}
.valueflex {
  display: flex;
 
}
.lineend {
  height: 20px;
  display: flex;
  align-items: flex-end;
}
.lineendone {
  font-size: 10px;
    /* height: 33px; */
    height: 20px;
    line-height: 6px;
    display: flex;
    align-items: flex-end;
}
  
.externalRepair-right {
    width: 175px;
     height: 75px;
      margin-top: 4px;
          box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.device {
   width: 223px;
  color: white;
}
.twodevice {
  list-style: none;
  width: 100%;
  height: 20px;
  color: #333333;
}
.rowDevice {
  width: 100%;
height: 11px;
    margin-left: 9px;
  display: flex;
  font-size: 10px;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.75);
  justify-content: space-between;
  align-items: center;
}
.rowfiveDevice {
width: 306px;
      height: 14px;
    -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.75);
  display: flex;
  color: #333333;
  line-height: 14px;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.twoTitle {
  width: 100%;
     height: 19px;
  display: flex;
  justify-content: space-between;
}
.flex {
  width: 100%;
  display: flex;
}
.flexbw {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lefeColumn {
  width: 43%;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
    margin-top: 8px;
  text-align: right;
  color: #333333;
  line-height: 20px;
    height: 39px;
}
.rightColumn {
  width: 43%;
      height: 39px;
      margin-top: 8px;
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
}
.threetitle {
  width: 263px;
  height: 23px;
  padding-left: 20px;
      font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 27px;
}
.fivetitle {
  width: 100%;
  font-size: 18px;
  display: flex;
          box-shadow: 0px 2px 11px 0px rgb(0 0 0 / 8%);
    border-radius: 6px 6px 0px 0px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
}
.pieChart {
  width: 100%;
      height: 129px;
}
.pieCharttwo {
  width: 100%;
      height: 129px;
}
.title-one {
    width: 37.4%;
display:flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
    height: 24px;
  background: #6194ff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px 6px 0px 0px;
}
.title-three {
width: 50%;
    height: 24px;
  background: #6194ff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px 6px 0px 0px;
}
.title-two {
    width: 118px;
     height: 24px;
  background: #e1ebff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px 6px 0px 0px;
}
.title-four {
  width: 50%;
    height: 24px;
  background: #e1ebff;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px 6px 0px 0px;
}
.title-one p {
    width: 100%;
  height: 100%;
  font-size: 10px;
  -webkit-transform: scale(0.75);
  display: flex;
  justify-content: center;
align-items: center;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.title-three p {
    width: 100%;
    height: 100%;
    font-size: 10px;
    -webkit-transform: scale(0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
}
.title-two p {
   width: 110%;
    height: 100%;
    font-size: 10px;
    -webkit-transform: scale(0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
  color: #5c5c5c;
}
.title-four p {
    width: 100%;
    height: 100%;
    font-size: 10px;
    -webkit-transform: scale(0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
  color: #5c5c5c;
}
.five-bottom {
  width: 100%;
  padding-left: 39px;
  height:75px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
}
.fiveColom {
  width: 100%;
      margin-left: -56px;
}
</style>

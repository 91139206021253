<template>
  <div class="myApp">
    <Row class="my-row">
      <Card class="col-right-card">
        <h4 slot="title">个人信息</h4>
        <div class="gerenxinxi">
          <Form :label-width="100">
            <div class="xinxi" style="margin-top:20px;">
              <FormItem style="display: inline-block;width:400px;" class="tebieshez" label="头像">
                <img class="imgs" :src="search.avatar">
               

              </FormItem>
            </div>
            <div class="xinxi">
              <FormItem class="namecls" label="用户名">
                <Input v-model="search.real_name" :disabled="real_nameButton"/>
              </FormItem>

              <FormItem class="namecls" label="联系电话">
                <Input v-model="search.mobile" :disabled="mobileButton"/>
              </FormItem>

              <Modal
                v-model="shoujh"
                title="修改手机号">
                <Form :label-width="100">
                  <FormItem label="手机号">
                    <Input style="width:180px" v-model="search.mobile"/>
                  </FormItem>
                  <FormItem label="验证码">
                    <Input style="width:130px;margin-right:3px;" v-model="search.sms_code"/>
                    <Button @click="checkCode" :disabled="jinyong">获取验证码<span v-model="shuzi">{{shuzi}}</span></Button>
                  </FormItem>
                </Form>
                <div slot="footer">
                  <Button @click="shoujh = false">取消</Button>
                  <Button type="primary" @click="subData('mobile')">保存</Button>
                </div>
              </Modal>
              <!-- <Button @click="shoujhs">更换</Button> -->


            </div>
            <div class="xinxi">
              <FormItem class="namecls" label="生日">
                <Input v-model="search.birthday" :disabled="id_cardButton"/>
              </FormItem>
              <FormItem class="namecls" label="职务">
                <Input v-model="search.departmental_posts" :disabled="id_cardButton"/>
              </FormItem>
            </div>

            <div class="jianjie">                                         
              <FormItem style="width:65%;display: inline-block;margin-right: 1%;" label="简介" required>
                <Input :autosize="{minRows: 2,maxRows: 5}" type="textarea" v-model="search.describe"
                       :disabled="describeButton"/>
              </FormItem>
              <!-- <Button v-show="describeButton" @click="buttonChoose('describe')">更换</Button> -->
              <Button v-show="!describeButton" @click="subData('describe')">完成</Button>
            </div>

            <div class="xinxi">
          <!--     <FormItem class="namecls" style="width:40%;display: inline-block;margin-right: 1%;" label="身份证号">
                <Input v-model="search.id_card" :disabled="id_cardButton"/>
              </FormItem> -->

              <!-- <Button v-show="id_cardButton" @click="buttonChoose('id_card')">更换</Button> -->
              <Button v-show="!id_cardButton" @click="subData('id_card')">完成</Button>
            </div>
            <!-- <div class="shenfenzhengzhaop">
              <span>身份证照片</span>
              <div style="clear: both;"></div>
              <div class="zhengm">
                <div class="demo-upload-list" v-for="item in uploadList">
                  <template v-if="item.status === 'finished'">
                    <img :src="item.url">
                    <div class="demo-upload-list-cover">
                      <Icon type="ios-eye-outline" @click.native="handleView(item.name)"></Icon>
                      <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                  </template>
                </div>
                <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :default-file-list="defaultList"
                  :on-success="handleSuccess"
                  :format="['jpg','jpeg','png']"
                  :max-size="2048"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize"
                  :before-upload="handleBeforeUpload"
                  multiple
                  type="drag"
                  action="//jsonplaceholder.typicode.com/posts/"
                  style="display: inline-block;width:88px;position: relative;
    top: 60px;
    left: 156px;">
                  <div style="width: 88px;height:58px;line-height: 58px;">
                    <Icon type="ios-camera" size="20"></Icon>
                    正面照片
                  </div>
                </Upload>
                <Modal title="View Image" v-model="visible">
                  <img :src="'https://o5wwk8baw.qnssl.com/' + imgName + '/large'" v-if="visible" style="width: 100%">
                </Modal>
              </div>
              <div class="fanm">
                <div class="demo-upload-list" v-for="item in uploadList">
                  <template v-if="item.status === 'finished'">
                    <img :src="item.url">
                    <div class="demo-upload-list-cover">
                      <Icon type="ios-eye-outline" @click.native="handleView(item.name)"></Icon>
                      <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                  </template>
                  <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                  </template>
                </div>
                <Upload
                  ref="upload"
                  :show-upload-list="false"
                  :default-file-list="defaultList"
                  :on-success="handleSuccess"
                  :format="['jpg','jpeg','png']"
                  :max-size="2048"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize"
                  :before-upload="handleBeforeUpload"
                  multiple
                  type="drag"
                  action="//jsonplaceholder.typicode.com/posts/"
                  style="display: inline-block;width:88px;position: relative;
    top: 60px;
    left: 156px;">
                  <div style="width: 88px;height:58px;line-height: 58px;">
                    <Icon type="ios-camera" size="20"></Icon>
                    反面照片
                  </div>
                </Upload>
                <Modal title="View Image" v-model="visible">
                  <img :src="'https://o5wwk8baw.qnssl.com/' + imgName + '/large'" v-if="visible" style="width: 100%">
                </Modal>
              </div>
            </div> -->
          </Form>
        </div>


      </Card>
    </Row>
  </div>
</template>
<script>

  import {getinfo} from '@/api/admin'
  import {getUser,getToken} from '@/libs/util'
  import config from '@/config'


  export default {
    data() {
      return {
        roleData:[],
        uploadHttp: '',
        access_token: '',
        defaultList: [],
        search: {
          sms_code: '',
        },
        real_nameButton: true,
        mobileButton: true,
        describeButton: true,
        id_cardButton: true,
        avatar_urlButton: true,


        shuzi: '',
        setinter: null,
        jinyong: false,

        id: '',
        shoujh: false,
        shenfz: false,
        imgName: '',
        visible: false,
        uploadList: [],
      }
    },
    methods: {

      viewData() {
		 console.log('result',getUser()); 
		  this.search = getUser();
     
      },



     

      //修改
      buttonChoose(type) {
        if (type == 'avatar_url') {
        } else if (type == 'mobile') {
          this.mobileButton = false
          this.real_nameButton = false
        } else if (type == "describe") {
          this.describeButton = false
        } else if (type == 'id_card') {
          this.id_cardButton = false
        }
      },
      checkCode() { // 验证码
        let params = {}
        params.mobile = this.search.mobile
        params.type = 1
        let vm = this
        getSmscode(params).then(res => {
          if (res.errCode == 0) {
            this.dianji();
            this.$Modal.success({title: "提示", content: res.errMsg});
          } else {
            this.$Modal.error({title: "提示", content: res.errMsg});
          }
        })
      },
      dianji() {
        this.shuzi = 60
        this.setinter = setInterval(() => {
          this.shuzi--

        }, 1000)
        this.jinyong = true;
      },
      qingchu() {
        clearInterval(this.setinter)
        this.shuzi = ''
        this.jinyong = false
      },

      subData(type) { // 提交
        let params = {}
        params.type = type
        if (type == 'avatar') {
          params.data = this.search.pic
          this.avatar_urlButton = true
        } else if (type == 'mobile') {
          params.sms_code = this.search.sms_code
          params.data = this.search.mobile
        } else if (type == 'describe') {
          params.data = this.search.describe
          this.describeButton = true
        } else if (type == 'id_card') {
          params.data = this.search.id_card
          this.id_cardButton = true
        }
        getMyProfileEdit(params).then(res => {
          if (res.errCode == 0) {
            this.$Modal.success({title: "提示", content: res.errMsg});
            this.shoujh = false
            this.handleSearch()
          } else {
            this.$Modal.error({title: "提示", content: res.errMsg});
          }
        })
      },
      handleSuccess1(data1) { // response file fileList    图标
        if (data1.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '上传成功'
          })
          this.avatar_urlButton = false
          this.defaultList = []
          this.search.avatar_url = data1.data[0].url // 图片上传地址
          this.search.pic = data1.data[0].pic // 图片域名地址
        }
      },
      handleError(data1, data2, data3) { // error file fileList
        this.$Modal.error({title: '提示', content: '上传失败'})
      },

      shoujhs() {
        this.shoujh = true
      },

      handleView(name) {
        this.imgName = name;
        this.visible = true;
      },
      handleRemove(file) {
        const fileList = this.$refs.upload.fileList;
        this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
      },
      handleSuccess(res, file) {
        file.url = 'https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar';
        file.name = '7eb99afb9d5f317c912f08b5212fd69a';
      },
      handleFormatError(file) {
        this.$Notice.warning({
          title: 'The file format is incorrect',
          desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
        });
      },
      handleMaxSize(file) {
        this.$Notice.warning({
          title: 'Exceeding file size limit',
          desc: 'File  ' + file.name + ' is too large, no more than 2M.'
        });
      },
      handleBeforeUpload() {
        const check = this.uploadList.length < 5;
        if (!check) {
          this.$Notice.warning({
            title: 'Up to five pictures can be uploaded.'
          });
        }
        return check;
      },

    },
    watch: {
      shuzi: function (res) {
        if (res == 0) {
          this.qingchu()
        }
      }
    },

    mounted() {
      this.roleData = getUser()
      this.viewData()

      this.access_token = getToken()
      this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro
      // this.handleSearch();
      // this.uploadList = this.$refs.upload.fileList;
    },




  };

</script>
<style lang="less">
  .xinxi .imgs {
    width: 70px;
    border-radius: 10px;
    margin-top: -20px;
  }

  .xinxi {
    padding: 10px 10px 10px 15%;
    width: 100%;
  }

  .jianjie {
    padding: 10px 10px 10px 15%;
    width: 100%;
  }

  .namecls {
    width: 30%;
    display: inline-block;
    margin-right: 1%;
  }

  .gerenxinxi Button {
    margin-left: 10px;
  }

  //身份证照片
  .shenfenzhengzhaop {
    margin-top: 20px;
    height: 320px;
    margin-left: 17%;
    width: 810px;
  }

  .zhengm {
    width: 400px;
    border: 1px solid rgb(182, 178, 178);
    height: 200px;
    border-radius: 3px;
    padding: 10px;
    display: inline-block;
    margin-right: 5px;
    margin-top: 8px;
    float: left;
    overflow: hidden;
  }

  .fanm {
    width: 400px;
    border: 1px solid rgb(182, 178, 178);
    height: 200px;
    border-radius: 3px;
    padding: 10px;
    display: inline-block;
    margin-top: 8px;
    overflow: hidden;
    float: right;
  }


  .demo-upload-list {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  }

  .demo-upload-list img {
    width: 100%;
    height: 100%;
  }

  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .6);
  }

  .demo-upload-list:hover .demo-upload-list-cover {
    display: block;
  }

  .demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }

  .ivu-upload-drag {
    border: none !important;
  }

  .toubntcls {
    width: 80px;
    display: inline-block;
    vertical-align: top;
  }

  .tebieshez .ivu-upload {
    width: 100px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }

  .wanchengwz {
    position: absolute;
    left: 87px;
    top: 1px;
  }
</style>

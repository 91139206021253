<template>
	<div class="myApp">
		<Row class="my-row">
			<div class="col-rightN">
				<Card class="col-right-card">
					<p slot="title">系统区域管理</p>
					<Form :label-width="90">
						<div>
							<FormItem class="FromItemnpt" label="区域编码：" prop="name"><Input v-model="search.code" clearable /></FormItem>
							<FormItem class="FromItemnpt" label="区域名称：" prop="name"><Input v-model="search.name" clearable /></FormItem>
							<FormItem class="FromItemnpt" label="添加时间">
								<DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" v-model="timeshijian"></DatePicker>
							</FormItem>
							<Button type="primary" @click="handleSearch">查询</Button>
						</div>
					</Form>

					<div class="gray-nona"></div>
					<div class="tablebnt">
						<Button type="primary" @click="handleCreateModel" v-show="button.add">新增</Button>
						<!--<Button type="primary" @click="handleUpdateModal" v-show="button.add">修改</Button>-->
						<!--<Button type="primary" @click="handleDelete" v-show="button.add">删除</Button>-->
						<Modal v-model="newModel" :title="titleData" width="600">
							<div>
								<Form :label-width="100" ref="subData" :model="subData" :rules="rules">
									<FormItem class="FromItemnpt" label="区域编码：" prop="name">
										<Input v-model="subData.code" clearable /></FormItem>
									<FormItem class="FromItemnpt" label="区域名称：" prop="name">
										<Input v-model="subData.name" clearable /></FormItem>
									<FormItem class="FromItemnpt" label="城市编码：" prop="name">
										<Input v-model="subData.citycode" clearable /></FormItem>
									<FormItem class="FromItemnpt" label="城市中心点：" prop="name">
										<Input v-model="subData.center" clearable /></FormItem>
									<FormItem class="FromItemnpt  org-width" label="上级名称" prop="parentname">
										<Cascader :data="orgdata" v-model="subData.parent_arr" change-on-select @on-change='changeparent'>
										</Cascader>
									</FormItem>
									<FormItem class="FromItemnpt" label="区域层级" prop="status">
										<Select v-model="subData.region_type">
											<Option v-for="(item, index) in orgstatus" :value="index" :key="index">{{ item }}</Option>
										</Select>
									</FormItem>
								</Form>
							</div>
							<div slot="footer">
								<Button @click="newModel = false">取消</Button>
								<Button type="primary" @click="add">保存</Button>
							</div>
						</Modal>
					</div>

					<Table
						stripe
						height="500"
						style="margin-bottom: 7px;"
						border
						:columns="columns"
						:data="data1"
						@on-select="tableSelectionChange"
						@on-select-all="tableSelectionChange"
						@on-select-cancel="tableSelectionChange"
					></Table>

					<div class="my-page"><Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" /></div>
				</Card>
			</div>
		</Row>
	</div>
</template>

<script>
import config from '@/config';
import { getToken } from '@/libs/util';

import { getOryStatus } from '@/api/common';
import { getUserStatus } from '@/api/dictionary';
		import * as Enum from '@/libs/enum';
		import {
		getorglist,
            getRegionDrop,
		getOrgEdit,
		getreglist,
		getreglistadd,
            getAreaLevel

	} from '@/api/admin';

export default {
	name: 'account',
	data() {
		return {
			rules: {},
			button: {},
			timeshijian: [],
			checkedIds: [],
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			titleData: '', // 标题
			newModel: false,
			orgstatus: [], // 机构状态
			userStatus: [],
			search: {
				// 筛选条件
				p: 1, // 当前页码
				t: 10, // 每页显示条数
                code:null,
                name:null,
				stime:null,
				etime:null,
			},
			subData: {
			    id:'',
				code: '',//地区编码
				name: '',//地区名
				citycode: '', //城市编码
				center: '', //城市中心点
				parent_id:'',//父级id
                region_type:'',//区域层级
                parent_arr:''
			},
			statuslist: [],
			pageTotal: 10,
			modal1: false,
			columns: [
				{ type: 'selection', align: 'center', width: 60 },
				{ title: '区域编码', align: 'center', key: 'code', minWidth: 120 },
				{ title: '区域名称', align: 'center', key: 'name', minWidth: 140 },
				{ title: '城市编码',  align: 'center',key: 'citycode', minWidth: 100 },
				{ title: '城市中心点', align: 'center', key: 'center', minWidth: 140 },
				{ title: '上级名称', align: 'center', key: 'parent_name', minWidth: 140 },
				{ title: '添加时间', align: 'center', key: 'createtime', minWidth: 150 }
			],
			data1: []
		};
	},
	methods: {
        getAreaLevel() {//层级
            getAreaLevel().then(res => {
                this.orgstatus = res.data;
            });
        },
        getinsuranceorg() {
            getRegionDrop().then(res => {

                this.orgdata = res.data;
            });
        },
        changeparent(value, selectedData){
            this.subData.parent_arr=value;
            this.subData.parent_id=selectedData[selectedData.length-1].value;
        },
		checkDate(date) {
			// 操作时间
			this.search.stime = date[0];
			this.search.etime = date[1];
		},

		handleCreateModel() {
			this.subData = {};
			this.titleData = '新增系统区域';
			this.newModel = true;
			this.reset();
		},

		tableSelectionChange(data2) {
			if (data2[0] != undefined) {
				this.checkedId = data2[0].ogid;
			}
			let ids = [];
			data2.map(function(item, key) {
				ids.push(item.ogid);
			});
			this.checkedIds = ids;
		},

		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},

		// 机构列表
		handleSearch() {
			this.search.action_id = 46;
			let params = this.search;
			let permiose = getreglist(params);
			permiose.then(res => {
				if (res.errCode === 0) {
					this.data1 = res.data.list;
					this.pageTotal = res.data.total;
					this.button = res.data.button;
				}
			});
		},

		// 机构状态
		getAddOrgStatusList() {
			getOryStatus().then(res => {
				this.orgstatus = res.data;
			});
		},
		getUserStatus() {
			getUserStatus().then(res => {
				this.userStatus = res.data;
			});
		},
		handleSearchAction() {
			this.search.p = 1;
			this.handleSearch();
		},

		handleUpdateModal() {
			this.reset();
			this.titleData = '修改系统区域';
			if (this.checkedIds.length == 1) {
				this.handleorgview();
				this.newModel = true;
			} else {
				this.$Modal.info({ title: '提示', content: '请选择一项' });
			}
		},

		// 机构详情
		handleorgview() {
			let id = this.checkedId;
            getreglist(id)
				.then(res => {
					this.subData = res.data;
					this.subData.org_type = String(res.data.org_type);
					this.chooseProvince();
				})
				.catch(err => {
					console.log(err);
				});
		},

		// 新增，编辑
		add() {
			this.$refs.subData.validate(valid => {
				if (valid) {
					let params = this.subData;
					let permiose = getreglistadd(params);
					permiose
						.then(res => {
							if (res.errCode == 0) {
								if (this.subData.ogid) {
									this.checkedIds = [];
								}
								this.newModel = false;
								this.handleSearch();
								this.reset();
								this.$Modal.success({ title: '提示', content: '提交成功' });
							} else {
								this.$Modal.error({ title: '提示', content: res.errMsg });
							}
						})
						.catch(err => {
							console.log(err);
						});
				}
			});
		},
		// 启用
		handleStatusSuccess() {
			if (this.checkedIds.length > 0) {
				this.$Modal.confirm({
					title: '确认信息',
					content: '<p>你确定要将选中的信息设置为启用状态么？?</p>',
					onOk: () => {
						this.sysAdminEdit(2);
					},
					onCancel: () => {
						this.$Message.info('您取消了启用操作');
					}
				});
			} else {
				this.$Modal.info({ title: '提示', content: '请至少选择一项' });
			}
		},

		sysAdminEdit(type) {
			let params = {};
			params.ogid = this.checkedIds;
			params.type = type;

			getOrgEdit(params).then(res => {
				if (res.errCode === 0) {
					this.checkedIds = [];
					this.$Notice.success({
						title: '提示',
						desc: res.errMsg
					});
					this.handleSearch();
				} else {
					this.$Notice.error({
						title: '提示',
						desc: res.errMsg
					});
				}
			});
		},
		// 禁用
		handleStatusFail() {
			if (this.checkedIds.length > 0) {
				this.$Modal.confirm({
					title: '确认信息',
					content: '<p>你确定要将选中的信息设置为禁用状态么？?</p>',
					onOk: () => {
						this.sysAdminEdit(3);
					},
					onCancel: () => {
						this.$Message.info('您取消了禁用操作');
					}
				});
			} else {
				this.$Modal.info({ title: '提示', content: '请至少选择一项' });
			}
		},
		// 删除
		handleDelete() {
			if (this.checkedIds.length > 0) {
				this.$Modal.confirm({
					title: '确认信息',
					content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
					onOk: () => {
						this.sysAdminEdit(1);
					},
					onCancel: () => {
						this.$Message.info('您取消了删除操作');
					}
				});
			} else {
				this.$Modal.info({ title: '提示', content: '请至少选择一项' });
			}
		},
		reset() {
			this.$refs.subData.resetFields();
		}
	},
	mounted() {
		switch (this.$route.query.type) {
			case 1:
				var mun = this.getDuration();
				this.search.status = '1';
				this.search.stime = this.GetDate(false);
				this.search.etime = this.GetDate(false);
				this.timeshijian = [this.GetDate(false), this.GetDate(false)];
				break;
			case 2:
				break;
		}
		this.getAreaLevel();
		this.handleSearch();
        this.getinsuranceorg();
		this.getUserStatus(); //状态
	}
};
</script>
<style lang="less">
@import '../style2.less';

.bxnpt {
	width: 25%;
	margin-left: 2%;
	float: left;
}
</style>

<template>
  <div>
    <Card class="box-card oder_dingcls">
      <p slot="title">物损配件详情
        ---归属区域{{ subData.belong_province_name }}-{{ subData.belong_city_name }}-{{ subData.belong_district_name }}</p>
      <Form :label-width="120">
        <Row>
          <Col span="8">
            <FormItem label="报案号">
              <Input disabled v-model="subData.reportno"/>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="配件单号">
              <Input disabled v-model="subData.ordersn+'-'+itemdata.itemno "/>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span="8">
            <FormItem label="定损员名称"><Input disabled v-model="subData.createname"/></FormItem>
          </Col>
          <Col span="8">
            <FormItem label="定损员电话"><Input disabled v-model="subData.createphone"/></FormItem>
          </Col>
          <Col span="8">
            <FormItem label="保险公司"><Input disabled v-model="subData.baoxianname"/></FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <FormItem label="所在地区"><Input disabled v-model="subData.carno_pro"/></FormItem>
          </Col>
          <Col span="8">
            <FormItem label="接待人"><Input disabled v-model="subData.repair_contact"/></FormItem>
          </Col>
          <Col span="8">
            <FormItem label="接待人电话"><Input disabled v-model="subData.repair_contact_phone"/></FormItem>
          </Col>
        </Row>
        <Row>

          <Col span="8">
            <FormItem label="物损地址"><Input disabled v-model="subData.repair_address"/></FormItem>
          </Col>
          <Col span="8">
            <FormItem label="配件名称"><Input disabled v-model="itemdata.sparename"/></FormItem>
          </Col>
          <Col span="8">
            <FormItem label="配件照片">
              <Button icon="md-image" @click='seepartimg(itemdata.pic)'>查看图片</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>


    <Card>
      <Form :label-width="120">
        <FormItem label="概述">
          <Input disabled v-model="subData.remark" type="textarea"/>
        </FormItem>
      </Form>
    </Card>

    <Card>
      <Table border stripe :columns="canshu" :data="bsdata"></Table>
      <Table border height="400" :columns="columns1" :data="allbusdata"></Table>
    </Card>


    <Card>
      <p slot="title">操作日志</p>
      <Timeline>
        <TimelineItem v-for="item in subData.log">
          <p class="time">{{ item.createname }}</p>
          <p class="content">{{ item.content }}</p>
          <p class="content">{{ item.createtime }}</p>
        </TimelineItem>
      </Timeline>
    </Card>
    <Card>
      <Row>
        <Col :span="8" offset="8">
          <Button class="marginbnt" type="primary" @click="routefun">返回</Button>
          <Button class="marginbnt" type="success" :disabled="showbid" @click="clickshowbid">中标</Button>
          <Button class="marginbnt" type="warning" :disabled="checkitme" @click="clickcheckitme">审核</Button>
          <Button class="marginbnt" :disabled="disableChoose" @click="chooseBusiness">重选外修商</Button>
        </Col>
      </Row>
    </Card>
    <div>
      <Modal v-model="showimg" width="40%" :title="editTitle" style='text-align: center;'>
        <viewer :images="showimages" style="display: inline-block;">

          <img
              v-for="(src,index) in showimages"
              :src="src"
              :key="index"
              style="width: 180px;height: 180px;"
          >
        </viewer>

        <div slot="footer">
          <Button @click="showimg = false">关闭</Button>
        </div>
      </Modal>

    </div>


    <Modal v-model="ischoosebid" width="55%" title="选择中标物损商">
      <Table height="500" border stripe :columns="bidcanshu" :data="bidbuscanshudata"></Table>
      <div slot="footer">
        <Button @click="ischoosebid = false">取消</Button>
      </div>
    </Modal>


    <Modal v-model="showcheckmodal" width="70%" title="核价">
      <Form :label-width="160" ref="checkdata" :model="checkdata" :rules="checkdataRules">
        <FormItem class="FromItemnpt" label="审核价格" prop="checkmoney" required>
          <Input type="number" v-model="checkdata.checkmoney" placeholder="请输入审核金额" style='width: 200px;'/>
        </FormItem>
        <Table height="200" :columns="columns1" :data="itemdata.biditem"></Table>
        <FormItem class="FromItemnpt" label="审核备注" style="margin-top:10px">
          <textarea style="width:400px" v-model="checkdata.reason" placeholder="执行退回定损员操作时，请输入审核备注"></textarea>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="showcheckmodal = false">取消</Button>
        <Button @click="backOrder">退回定损员</Button>
        <Button type="primary" @click="submitCheckdata">确定</Button>
      </div>
    </Modal>
    <Modal v-model="showEdit" width="40%" :title="yjTitle">
  			<Form :label-width="60" ref="subData">
  				<Row>
  					<FormItem class="FromItemnpt org-width" style="width:90%" label="备注">
  						<textarea style="width:100%" v-model="yjRemark"></textarea>
  					</FormItem>
  				</Row>
  			</Form>
  			<div slot="footer">
  				<Button @click="showEdit = false">取消</Button>
  				<Button type="primary" @click="dicker2">保存</Button>
  			</div>
  		</Modal>
    <ChooseBusiness :showchoosemodal="showChoose" :itemId="$route.query.id2" @son="son"></ChooseBusiness>
  </div>
</template>
<script>
import {wusunorderview,waixiutuihui, getitembusinesslist, bidorderitem, checkbid,orderbusinessdickerprocess} from "@/api/admin";
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
import ChooseBusiness from '@/components/business/ChooseBusiness.vue'

export default {
  components:{
    ChooseBusiness
  },
  data() {
    const validatecheckmoney = (rule, value, callback) => {
      if (this.checkdata.checkmoney < 0) {
        callback(new Error('请输入正整数'));
      } else {
        callback();
      }
    };
    return {
      //议价
      yjTitle:'',
      yjId: 0,
      yjRemark:'',
      yjType:1,

      showEdit: false,
      showbid: false,
      checkitme: false,
      disableChoose: false,
      showChoose: false,
      id: "",
      grade: false,
      showimg: false,
      editTitle: '',
      search: {},
      showimages: [],
      columns1: [
        {
          title: '序号',
          key: 'id'
        },
        {
          title: '名称',
          key: 'name'
        },
        {
          title: '单价(元)',
          key: 'offermoney'
        },
        {
          title: '数量',
          key: 'num'
        },
        {
          title: '备注',
          key: 'remark'
        },
        {
          title: '报价机构',
          key: 'ogname',
          filterMultiple: false,
          filterMethod (value, row) {
            return row.ogname==value
          }
        },
        {
          title: '报价人',
          key: 'createname'
        }
      ],
      biditem: [
        {
          id: '',
          name: '',
          offermoney: '',
          num: '',
          remark: '',
          ogname: '',
          createname: '',
        }],
      allbusdata: [
        {
          id: '',
          name: '',
          offermoney: '',
          num: '',
          remark: '',
          ogname: '',
          createname: '',
        }
      ],
      canshu: [
        {
          title: "ID",
          key: "id",
          align: "center",
          minWidth: 80,
        },
        {
          title: "物损机构名称",
          key: "bid_ogname",
          align: "center",
          minWidth: 100,
        },
        {
          title: "物损机构联系人",
          key: "bjname",
          align: "center",
          minWidth: 80,
        },
        {
          title: "联系电话",
          key: "bjphone",
          align: "center",
          minWidth: 100,
        },
        {
          title: "状态",
          key: "bstatus_str",
          align: "center",
          minWidth: 90,
        },
        {title: "报价金额", key: "offermoney", align: "center", minWidth: 130},
        {
          title: "核价金额",
          key: "checkmoney",
          align: "center",
          minWidth: 100,
        },
        {
          title: "对方要价",
          key: "dicker_price",
          align: "center",
          minWidth: 100,
        },
        {
          title: "议价金额",
          key: "dicker_money",
          align: "center",
          minWidth: 100,
        },
        {
          title: "议价备注",
          key: "dicker_remark",
          align: "center",
          minWidth: 100,
        },
        {
          title: "报价时间",
          key: "offertime",
          align: "center",
          minWidth: 190,
        },
        {
          title: "创建时间",
          key: "createtime",
          align: "center",
          minWidth: 180,
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          fixed: 'right',
          render: (h, params) => {
            // console.log(params.row);
            return h('div', [
              h(
                  'Button',
                  {
                    props: {
                      type: 'success',
                      size: 'small',
                      disabled: params.row.bstatus == Enum.repair_bussiness_status.dicker ? false : true
                    },
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        this.dicker(params.row.id,1);
                      }
                    }
                  },
                  '议价通过'
              ),
              h(
                  'Button',
                  {
                    props: {
                      type: 'warning',
                      size: 'small',
                      disabled: params.row.bstatus == Enum.repair_bussiness_status.dicker ? false : true
                    },
                    style: {
                      marginRight: '5px'
                    },
                    on: {
                      click: () => {
                        this.dicker(params.row.id,2);
                      }
                    }
                  },
                  '议价不通过'
              )
            ])
          }
        }

      ],
      itemdata: {
        sparecode: '',
        itemno: '',
      },
      bsdata: [],
      subData: {},
      order_item_id: '',
      bstatus: '',
      ischoosebid: false,
      bidcanshu: [//弹窗
        {title: 'ID', key: 'id', align: 'center', minWidth: 100, fixed: 'left'},
        {title: '物损商名称', key: 'ogname', align: 'center', minWidth: 130},
        {title: '状态', key: 'bstatus_str', align: 'center', minWidth: 100},
        {title: '报价金额', key: 'offermoney', align: 'center', minWidth: 80},
        {
					title: '操作',
					key: 'action',
					width: 200,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						// console.log(params.row);
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'success',
										size: 'small',
										disabled: this.showbid2(params.row.bstatus)
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.bidbusinessselect(params.row.id, params.row.order_item_id, params.row.bstatus);
										}
									}
								},
								'选择'
							)
						]);
					}
				}
      ],
      bidbuscanshudata: [],
      showcheckmodal: false,//审核弹窗
      checkdata: {},//审核弹窗
      checkdataRules: {
        checkmoney: [
          {validator: validatecheckmoney, trigger: 'blur'}
        ],
      },
      checkmoney: '',
    };
  },
  methods: {
    showbid2(bstatus) {
			if (bstatus == Enum.repair_bussiness_status.pending_bid) return false;
			else return true;
		},
    dicker(id,type){
      this.showEdit = true
      this.yjId = id
      this.yjType = type
      if(type == 1){
        this.yjTitle = '议价审核通过'
      }else{
        this.yjTitle = '议价审核不通过'
      }
    },
    dicker2(){
      let bstatus = '';
      if(this.yjType == 1){
        bstatus = Enum.repair_bussiness_status.dicker_success;
      }else{
        bstatus = Enum.repair_bussiness_status.undicker;
      }

      let pa = {
        id: this.yjId,
        bstatus: bstatus,
        remark:this.yjRemark
      };
      orderbusinessdickerprocess(pa).then(res => {
        if (res.errCode == 0) {
          this.$Message.info('审核完成');
          this.showEdit = false
          this.viewData()
        } else {
          this.$Message.error(res.errMsg);
        }
      });
    },
    //中标
    clickshowbid() {
      var that = this;
      let pa = {
        order_item_id: this.order_item_id,
        bstatus: this.bstatus
      };
      getitembusinesslist(pa).then(res => {
        // console.log('getitembusinesslist', res);
        that.ischoosebid = true;
        that.bidbuscanshudata = res.data;
      });
    },
    //弹窗-选择
    showbidselect(bstatus) {
      if (bstatus == Enum.repair_bussiness_status.pending_bid) return false;
      else return true;
    },
    bidbusinessselect(id, itemid, bstatus) {
      var that = this;
      let pa = {
        order_item_id: itemid,
        bstatus: Enum.repair_bussiness_status.uncheck_quote,
        id: id
      };
      bidorderitem(pa).then(res => {
        if (res.errCode == 0) {
          that.$Notice.success({
            title: '提示',
            desc: '操作成功'
          });
          that.ischoosebid = false;
          // that.handleSearch();
          this.viewData();
        }
      });
    },
    //审核按钮
    clickcheckitme() {
      this.showcheckmodal = true;
      this.checkdata.checkmoney = this.checkmoney
    },
    chooseBusiness(){
      this.showChoose = true
    },
    backOrder() {
      // 保存
      if (!this.checkdata.reason) {
        this.$Modal.error({ title: '提示', content: '请输入审核备注' });
        return
      }
      var that=this
      waixiutuihui({id:this.order_item_id,
          reason:this.checkdata.reason}).then(res => {
        if (res.errCode == 0) {
          that.showcheckmodal = false;
          that.handleSearch();
          this.viewData();
          that.$Modal.success({ title: '提示', content: '退回成功' });
        } else {
          that.$Modal.error({ title: '提示', content: res.errMsg });
        }
      });
    },
    //审核保存
    submitCheckdata() {
      // alert(this.order_item_id);

      var that = this
      this.$refs.checkdata.validate(valid => {
        // console.log('valid',valid);
        if (valid) {
          let params = {
            bstatus: Enum.repair_bussiness_status.pending_pick,
            id: this.order_item_id,
            checkmoney: that.checkdata.checkmoney,
            reason:this.checkdata.reason
          };
          checkbid(params).then(res => {
            // console.log('checkbid',res);
            if (res.errCode == 0) {
              that.showcheckmodal = false;
              // that.handleSearch();
              this.viewData();
              that.$Modal.success({title: '提示', content: '审核成功'});
            } else {
              that.$Modal.error({title: '提示', content: res.errMsg});
            }
          });
        }
      });
    },
    routefun() {
      this.$router.push({
        name: 'wusun_fitting_manage',
        query: this.search
      })
    },
    showimgtitle(val) {
      if (tools.isVisible(val)) {
        return '查看照片'
      } else {
        return '无'
      }
    },
    getSendOut() {
      let query = {id: this.id, type: "order"};
      this.$router.push({
        name: "order_goods_fahuo_details",
        query: query,
      });
    },

    seevin(val) {
      if (val) {
        this.showimg = true;
        this.editTitle = '铭牌照片';
        let arr1 = [];
        arr1[0] = this.subData.a_driver_license;
        this.showimages = arr1
      }
    },

    seepartimg(val) {
      this.editTitle = '配件照片';
      // console.log('seepartimg',val);

      if (tools.isVisible(val)) {
        this.showimg = true;
        let arr1 = [];
        val.forEach((u) => {
          arr1.push(u.pic);
        })
        arr1 = [...arr1,...this.subData.piclist10,...this.subData.piclist11,...this.subData.piclist12]

        this.showimages = arr1
      }
    },

    viewData: function () {
      // alert( this.id );
      let params = {id: this.id};
      wusunorderview(params).then((res) => {
        if (res.errCode == 0) {
          let resdata = res.data;
          if (resdata.item_data.bstatus == Enum.repair_bussiness_status.pending_bid) {
            this.showbid = false;
          } else {
            this.showbid = true;
          }
          if (resdata.item_data.bstatus == Enum.repair_bussiness_status.uncheck_quote) {
            this.checkitme = false;
          } else {
            this.checkitme = true;
          }
          this.disableChoose = (resdata.item_data.bstatus == Enum.repair_bussiness_status.unable_fix || resdata.item_data.bstatus == Enum.repair_bussiness_status.unable_pick || resdata.item_data.bstatus == Enum.repair_bussiness_status.timeout_quote || resdata.item_data.bstatus == Enum.repair_bussiness_status.pick_before_unrepair || resdata.item_data.bstatus == Enum.repair_bussiness_status.pick_after_unrepair) ? false : true
          this.subData = resdata;
          this.itemdata = resdata.item_data
          this.bsdata = [...this.subData.business_data]
          this.allbusdata = resdata.allbusdata;

          let arr = []
          if (this.allbusdata.length) {
            for (let ele of this.allbusdata) {
              if (arr.indexOf(ele.ogname) == -1) {
                arr.push(ele.ogname)
              }
            }
          }
          this.$set(this.columns1[5], 'filters', arr.map(x=>({
              label:x,
              value:x,
          })))     
          
          this.order_item_id = resdata.item_data.id;//中标按钮
          this.bstatus = resdata.business_data.bstatus;//中标按钮
          this.checkmoney = this.itemdata.bidmoney;//审核按钮
        }
      });
    },
    son(data) {
      this.showChoose = data
    }
  },
  mounted() {
    // console.log('mounted',this.$route.query);
    this.id = this.$route.query.id;
    this.search.p = this.$route.query.p;
    this.search.t = this.$route.query.t;
    this.search.sparename = this.$route.query.sparename;
    this.search.stime = this.$route.query.stime;
    this.search.etime = this.$route.query.etime;
    this.search.reportn = this.$route.query.reportn;
    this.search.ordersn = this.$route.query.ordersn;
    this.search.reportno2 = this.$route.query.reportno2;
    this.search.bsstatus = this.$route.query.bsstatus;
    this.search.recoverstatus = this.$route.query.recoverstatus;
    this.viewData();
  },
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.casenoItemnpt {
  width: 500px;
  display: inline-block;
  margin-right: 3%;
  white-space: nowrap;
}

// ------------------------
.wytoubu {
  margin: 10px 0;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  text-align: center;
}

.wytoubu th {
  padding: 10px 0;
  font-weight: 400;
  font-size: 12px;
}

/*th宽度*/
.widthdu1 {
  width: 21%;
  line-height: 16px;
  font-size: 12px;
}

.widthdu2 {
  width: 4%;
  line-height: 16px;
  font-size: 12px;
}

.widthdu3 {
  width: 15%;
  line-height: 16px;
  font-size: 12px;
}

.widthdu6 {
  width: 15%;
  line-height: 16px;
  font-size: 12px;
}

.widthdu4 {
  width: 9%;
  line-height: 16px;
  font-size: 12px;
}

.widthdu5 {
  width: 9%;
  line-height: 16px;
  font-size: 12px;
}

.widthdu7 {
  width: 4%;
  line-height: 16px;
  font-size: 12px;
}

.zhuneitongpad .widthdu1,
.widthdu2,
.widthdu3,
.widthdu4,
.widthdu5,
.widthdu6,
.widthdu7 {
  padding: 10px 5px 10px 5px;
}

.widthdu1 .lefts {
  width: 80px;
  display: inline-block;
}

.widthdu1 .lefts img {
  width: 80px;
  height: 80px;
}

.widthdu1 .rights {
  display: inline-block;
  width: 70%;
  vertical-align: top;
  margin: 6px 0 0 5px;
}

.widthdu1 .rights p {
  width: 80%;
  line-height: 16px;
  margin-bottom: 20px;
}

.hoverred:hover {
  color: red;
  cursor: pointer;
}

.widthdu1 .rights span {
  color: #9e9e9e;
}

.xianbor {
  border-left: 1px solid #daf3ff;
}

/* 公用 */
.bought-table-mod__table___3u4gN {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: #3c3c3c;
  table-layout: fixed;
}

/* 全选一行样式 */
.shaixuan {
  height: 30px;
  margin: 10px 0;
  margin-top: 60px;
}

.shaixuan span {
  margin-right: 30px;
}

.shaixuan .anniutz {
  padding: 4px;
}

.shaixuan .florigh {
  float: right;
}

.bordertbal {
  border: 1px solid #daf3ff;
  margin: 10px 0;
}

/* teconttbody */
.teconttbody {
  background: #eaf8ff;
}

.tecont td {
  border: none;
}

.tecont .padd {
  padding: 10px 0px;
}

.padd b {
  margin-right: 10px;
  margin-left: 3px;
}

.teconttbody .widthdu4 {
  text-align: center;
}

.centes {
  text-align: center;
}

.centes p {
  text-align: center;
  line-height: 20px;
}

.centes span {
  color: #9e9e9e;
  display: block;
  margin-top: 5px;
}

.centes .reds {
  color: red;
}

.nptss {
  padding: 5px;
  width: 237px;
}

.shctr2 .bnt {
  width: 60px;
  padding: 5px 2px;
  margin-left: -4px;
}

.shctr2 {
  float: right;
  font-size: 12px;
  width: 300px;
  color: #6b6464;
  position: relative;
  top: 8px;
  z-index: 3;
}

/* .shctl{position:relative;} */
.el-tabs__content {
  overflow: visible;
}

.tempspan {
  /*表格里面的字*/
  width: 240px;
  display: inline-block;
}

.fenyedi {
  height: 40px;
}

.widthdu2 p {
  text-align: center;
  height: 64px;
  line-height: 64px;
}

.widthdu7 p {
  text-align: center;
  height: 64px;
  line-height: 64px;
}

.yanse {
  color: rgb(37, 147, 252);
}

.widthdu_div_margin {
  //   margin-bottom: 10px;
}

.widthdu_div_margin span:nth-child(3) {
  color: #74bcff;
  margin-left: 5px;
}
</style>

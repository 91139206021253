<template>
    <div class="main" id="trend">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        waixiudan_num:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.gettrend()
    },
    gettrend(){
      let that = this
      var chartDom = document.getElementById('trend');
      var myChart = echarts.init(chartDom,{
       width:'100%',
        height:60
      });
        myChart.resize();
      var option;

      option = {
        color: ['#00ACBA'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        title: {
          left:"center",
          top:"10%",
          text: '订单数近一年走势图',
          textStyle: {
            color: "#66FFFF",//Y轴内容文字颜色
            fontSize:12,
            fontWeight:"400",
            fontFamily:"MicrosoftYaHei"

          },

        },
        grid: {
        top: 40,
          left: "-1%",
          // right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月'],
            data: this.data1.date,
            axisTick: {
              show: false,
              alignWithLabel: true

            },
            splitLine:{
              show:true,
              lineStyle: {
                color: '#08257D',
                width: 5
              }
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "white",//Y轴内容文字颜色
                fontSize:8,

              },
            },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: true,
              lineStyle: {
                color: '#113383',
                width: 2
              }
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return that.data1.date2[that.data1.date.indexOf(params.value)]
                }
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            splitLine:{
              show:false
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              margin: 20,//刻度标签与轴线之间的距离。
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "white",//Y轴内容文字颜色
                fontSize:8,
                align: 'left',

                margin:[0,20,0,0],

              },
            },
          }
        ],
        series: [
          {
            name: '外修单数',
            type: 'line',
            stack: 'Total',
            smooth: true,
            /* lineStyle: {
              width: 1
            },
            showSymbol: true,

            emphasis: {
              focus: 'series'
            }, */
            // data: [140, 232, 101, 204, 90, 240, 250,44,75,205,88,240]
            data: this.data1.waixiudan_num
          },
          {
            name: '物损单数',
            type: 'line',
            stack: 'Total2',
            smooth: true,
            /* lineStyle: {
              width: 1,
            },
            showSymbol: true,

            emphasis: {
              focus: 'series'
            }, */
            // data: [140, 232, 101, 204, 90, 240, 250,44,75,205,88,240]
            data: this.data1.wusun_num
          }

        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.gettrend()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

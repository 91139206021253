<template>
  <div>
    <Card class="col-right-card min-height">
      <p slot="title">文章详情</p>
      <Form :label-width="120" ref="subData" :model="subData" :rules='dataRules'>
      	<FormItem class="FromItemnpt dizhi_width" label="标题"  prop="title">
          <Input v-model="subData.title"/>
        </FormItem>
  		<br/>
        <FormItem class="FromItemnpt dizhi_width" label="分类" prop="type">
          <Select v-model="subData.type">
            <Option v-for="(item,index) in hotNewsType" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <br/>

        <FormItem class="storebrand_form_doal" label="图片信息"  prop="path_url">
          <viewer style="display: inline-block;" :image="subData.path_url" v-if="subData.path_url">
            <div class="yingimgs">
              <img :src="subData.path_url" style="width: 60px;height: 60px;"
                   class="upload-image-view"/>
            </div>
          </viewer>
          <Upload
            ref="upload"
            :default-file-list="defaultList"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :format="['jpg','jpeg','png','gif']"
            :max-size="1024"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :headers="{Authorization: 'Bearer ' + this.access_token}"
            :data="{type: 'news'}"
            type="drag"
            :action=this.uploadHttp
            style="display: inline-block;width:58px;">
            <div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
          <p class="colors">建议400*200，支持JPG/GIF/PNG，上传信息不能超过2M</p>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt dizhi_width" label="优先级排序">
          <Input v-model="subData.sortcode"/>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt" label="状态">
          <i-Switch v-model="subData.status">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-Switch>
          <p class="colors">开启后，该文章为显示。关闭则隐藏。</p>
        </FormItem>
         <br/>
      </Form>
      <Divider orientation="left">文章描述</Divider>
      <editor style="margin-top: 10px;" v-model="subData.details"  :uploadtype="uploadtype"/>
    </Card>
    <Card class="col-right-card">
      <div class="bnt_center">
        <Button type="primary" @click="submitForm" :loading="loading" >保存</Button>
      </div>
    </Card>
  </div>
</template>
<script>
  import {getHotNewsAdd ,getHotNewsView } from '@/api/backend'
  import {getHotNewsType } from '@/api/dictionary'
  import { getToken } from '@/libs/util'
  import config from '@/config'
  import editor from '@/components/editor/index.js'
  export default {
  	components: {
      editor,
    },
    data () {
      return {
        loading:false,
        hotNewsType:[],
        id:'',
        uploadtype:'news',
        dataRules: {
          type: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          title: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
          path_url: [
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
        },
        subData: {
          id: '',
          title: '',
          type: '',
          path: '',
          path_url: '', // 图片
          sortcode: '255', 
          status: true, 
          details:'',
        },

        uploadList: '',
        uploadHttp: '',
        access_token: '',
        defaultList: [],
      }
    },
    methods: {
      getHotNewsType() {
        getHotNewsType().then(res => {
          this.hotNewsType = res.data
        })
      },
   
      handleSuccess (res, file) {
        if (res.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '上传成功'
          })
          this.subData.path = res.data[0].pic // 图片上传地址
          this.subData.path_url = res.data[0].url // 图片域名地址
        }else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      },
      handleError (file) {
        this.$Modal.error({ title: '提示', content: '上传失败' })
      },
      handleFormatError (file) {
        this.$Modal.error({ title: '提示', content: '文件格式不正确' })
      },

      handleMaxSize (file) {
        this.$Modal.error({ title: '提示', content: '文件太大' })
      },

      handleBeforeUpload () {

        const check = this.uploadList.length < 2
        if (!check) {
          this.$Modal.error({ title: '提示', content: '最多可以上传一张照片。' })
        }
        return check
      },


      viewData () {
        this.subData.id = this.id = this.$route.query.id
        let params = { id: this.id }
        getHotNewsView(params).then(res => {
          if (res.errCode == 0) {
            this.subData = res.data
          }
        })
      },

      submitForm () { // 保存
        this.loading = true;


        if(!this.subData.details ) {
          this.$Modal.error({ title: '提示', content: '请填写文章内容' })
        }
        this.$refs.subData.validate(valid => {
          if (valid) {
            let params = this.subData
            let vm = this
            getHotNewsAdd(params).then(res => {
              if (res.errCode == 0) {
                this.$Modal.success({ title: '提示', content: res.errMsg })
                this.loading = false;

                setTimeout(function () {
                  vm.viewData()
                }, 1500) 
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },
    },
    mounted () {
      this.viewData()
      this.getHotNewsType()
      this.access_token = getToken()
      this.uploadList = this.$refs.upload.fileList
      this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro
    }
  }
</script>


<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
</style>

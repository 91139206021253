export default {
  home: '首頁',
  login: '登錄',
  components: '组件',
  count_to_page: '数字渐变',
  tables_page: '多功能表格',
  split_pane_page: '分割窗口',
  markdown_page: 'Markdown編輯器',
  editor_page: '富文本編輯器',
  icons_page: '自定義圖標',
  img_cropper_page: '圖片編輯器',
  update: '上傳數據',
  join_page: 'QQ群',
  doc: '文檔',
  update_table_page: '上傳CSV文件',
  update_paste_page: '粘貼表格數據',
  multilevel: '多级菜单',
  directive_page: '指令',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': '上傳excel',
  'export-excel': '導出excel',
  tools_methods_page: '工具函數',
  drag_list_page: '拖拽列表',
  i18n_page: '多語言',
  modalTitle: '模態框題目',
  content: '這是模態框內容',
  buttonText: '顯示模態框',
  'i18n-tip': '注：僅此頁做了多語言，其他頁面沒有在多語言包中添加語言內容',
  error_store_page: '錯誤收集',
  error_logger_page: '錯誤日誌',
  query: '帶參路由',
  params: '動態路由',
  cropper_page: '圖片裁剪',
  message_page: '消息中心',
  tree_table_page: '樹狀表格',
  org_tree_page: '組織結構樹',
  drag_drawer_page: '可拖動抽屜',
  tree_select_page: '樹狀下拉選擇器',
  admin_manage: '后台管理',
  role_manage: '角色管理',
  admin_info: '管理员信息',
  user_manage: '用户',
  user_list: '用户管理',
  org_list: '机构管理',
  department_list: '部门管理',
  job_list: '岗位管理',
  address_list: '地址管理',
  receipt_list: '发票管理',
  inquiry_list: '中华保询价列表',
  inquiry_business_list: '询价配件商列表',
  inquiry_item_list: '询价配件管理',
  inquiry_item_bus: '询价配件报价管理',
  inquiry_log: '询价日志管理',
  bx_business_rule_price_add: '报价时效设置',
  bx_business_rule_supply_add: '供货商规则设置',
  carmodel_add: '车型新增',
  fukan_detail: '复勘详情',
  supply_detail: '供货详情',
  inquiry_detail: '中华保询价详情',
  system_config: '系统设置',
  bx_business_rule_fukan_add: '复勘规则设置',
  carpart_list: '配件结构管理',
  spare_base_list: '基础配件管理',
  inquiry_attachment_list: '询价附件',
  supply_attachment_list: '供货附件',
  fukan_attachment_list: '复勘附件',
  third_app_list: '三方接口管理',
  insurance_supply_detail: '保险-订单详情',
  fukan_list: '复勘列表',
  fukan_list_dai: '待复勘',
  peijian_detail: '供应商-复勘详情',


  // 供应商
  gy_inquiry_manage:'供应商询价',
  gy_wait_inquiry_list:'供应商待询价',
  gy_inquiry_list:'供应商询价列表',
  gy_inquiry_detail:'供应商询价详情',
  Login_Free: '',

}

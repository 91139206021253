<template>
  <div>
    <Card class="box-card oder_dingcls">
      <p slot="title">详情----归属区域{{subData.belong_province_name}}-{{subData.belong_city_name}}-{{subData.belong_district_name}}</p>
      <Form :label-width="120">
        <Row>
          <Col span="8">
            <FormItem label="报案号">
              <Input disabled v-model="subData.reportno" />
            </FormItem>
          </Col>
          <Col span="8"
            ><FormItem label="车辆VIN"
              ><Input disabled v-model="subData.vin" />
            </FormItem>
          </Col>
      
          <Col span="8"
            ><FormItem label="车辆品牌"
              ><Input disabled v-model="subData.brand_name" /></FormItem
          ></Col>
          <Col span="8"
            ><FormItem label="车型"
              ><Input disabled v-model="subData.model_name" /></FormItem
          ></Col>
          <Col span="8"
            ><FormItem label="车牌号"
              ><Input disabled v-model="subData.carno" /></FormItem
          ></Col>

           <Col span="8"
            ><FormItem label="修理厂名称"
              ><Input disabled v-model="subData.repair_ogname" /></FormItem
          ></Col>

           <Col span="8"
            ><FormItem label="修理厂省市区">{{subData.repair_province_name}}-{{subData.repair_city_name}}-{{subData.repair_district_name}}</FormItem
          ></Col>

           <Col span="8"
            ><FormItem label="修理厂地址"
              ><Input disabled v-model="subData.repair_address" /></FormItem
          ></Col>

           <Col span="8"
            ><FormItem label="修理厂联系人"
              >{{subData.repair_contact}}-{{subData.repair_contact_phone}}</FormItem
          ></Col>

           <Col span="8"
            ><FormItem label="保险公司"
              ><Input disabled v-model="subData.baoxianname" /></FormItem
          ></Col>


       
          <Col span="8"
            ><FormItem label="定损员名称"
              ><Input disabled v-model="subData.createname" /></FormItem
          ></Col>
          <Col span="8"
            ><FormItem label="定损员电话"
              ><Input disabled v-model="subData.createphone" /></FormItem
          ></Col>
          <Col span="8"
            ><FormItem label="铭牌照片"
              ><Button icon="md-image" @click='seevin()' 
                >查看图片</Button
              ></FormItem
		
            ></Col
          >
		  <Col span="8"
		    ><FormItem label="行驶证"
		      ><Button icon="md-image" @click='seexingshiimg()' 
		        >查看图片</Button
		      ></FormItem
		  		
		    ></Col
		  >
        </Row>
      </Form>
    </Card>
    <Card>
      <Form :label-width="120">
        <FormItem label="损失概述">
          <Input disabled v-model="subData.remark" type="textarea" />
        </FormItem>
      </Form>
    </Card>
    <Card>
      <Table border stripe :columns="canshu" :data="subData.item_data"></Table>
    </Card>

    <!--<Card>-->
    <!--<Form :label-width="120">-->
    <!--<FormItem label="修复失败概述">-->
    <!---->
    <!--<Input disabled v-model="subData.remark" type='textarea' />-->
    <!---->
    <!---->
    <!--</FormItem>-->
    <!--</Form>-->
    <!--</Card>-->
    <Card>
      <p slot="title">操作日志</p>
      <Timeline>
        <TimelineItem v-for="item in subData.log">
          <p style="font-size: 18px" class="time">{{ item.createname }}</p>
          <p style="font-size: 18px" class="content">{{ item.content }}</p>
          <p style="font-size: 18px" class="content">{{ item.createtime }}</p>
        </TimelineItem>
      </Timeline>
    </Card>
    <Card>
      <Row>
        <Col :span="8" offset="8">
          <Button class="marginbnt" type="primary" @click="routefun">返回</Button>
        </Col>
      </Row>
    </Card>
<div>
   <Modal v-model="showimg" width="40%" :title="editTitle"  style='text-align: center;'>
   <viewer :images="showimages" style="display: inline-block;" >
    
               <img
                            v-for="(src,index) in showimages"
                            :src="src"
                            :key="index"
                             style="width: 80px;height: 80px;"
                          >
   </viewer>
   
   	<div slot="footer">
   		<Button @click="showimg = false">关闭</Button>
   	</div>
   </Modal>
 
</div>			
		
		
			
		
  
  </div>
</template>
<script>
import { getOrderView } from "@/api/admin";
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
export default {
  data() {
    return {
      id: "",
      grade: false,
	  showimg:false,
	  editTitle:'',
      showimages: {},
      canshu: [
		  {
		    title: "ID",
		    key: "id",
		    align: "center",
		    minWidth: 100,
		    fixed: "left",
		  },
        {
          title: "配件分类",
          key: "type_name",
          align: "center",
          minWidth: 100,
          fixed: "left",
        },
        { title: "配件名称", key: "sparename", align: "center", minWidth: 130 },
        {
          title: "状态",
          key: "status",
          align: "center",
          minWidth: 100,
        },
		{
			title: "工单类型",
			key: "type_str",
			align: "center",
			minWidth: 100,
		},
        {
          title: "原始照片",
          key: "action",
          align: "center",
          minWidth: 80,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
					icon:"md-image"
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.seeorgial(params.row.pic);
                    },
                  },
                },
                "查看照片"
              ),
            ]);
          },
        },
        {
          title: "取件照片",
          key: "action",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
					icon:"md-image"
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.seequjianimg(params.row.order_pic);
                    },
                  },
                },
               this.showqujianimgtxt(params.row.order_pic)
              ),
            ]);
          },
        },
        {
          title: "还件照片",
          key: "action",
          align: "center",
          minWidth: 100,

          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
					icon:"md-image"
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                     this.seehuanjianimg(params.row.order_pic);
                    },
                  },
                },
               this.showhuanjianimgtxt(params.row.order_pic)
              ),
            ]);
          },
        },
      ],
      subData: {
        id: "",
        caseno: "", //报案号
        vin: "", //vin
        brand_name: "", //车品牌
        model_name: "", //车型
        carno: "", //车牌号
        createname: "", //定损员
        createphone: "", //定损员电话
        remark: "", //损失概述
        a_driver_license: "", //铭牌照片地址
      
        item_data: [
          {
            id: "",
            type_str: "",
            sparename: "11111",
            status_recover_str: "",
            orgialPhotoUrl: "原始照片url",
            takePhotoUrl: "取件url",
            returnPhotoUrl: "还件rul",
          },
        ],
      },
    };
  },
  methods: {
      routefun () {
          if (this.type == 1) {
              this.$router.push({
                  name: 'external_order'
              })
          }else {
              this.$router.push({
                  name: 'recovery_order'
              })
          }

      },
    getSendOut() {
      let query = { id: this.id, type: "order" };
      this.$router.push({
        name: "order_goods_fahuo_details",
        query: query,
      });
    },
	showhuanjianimgtxt(val){
		if(tools.isVisible(val.huanjian_pic))
			return '查看照片'
		else
		    return '无'
	},
	showqujianimgtxt(val){
		if(tools.isVisible(val.qujian_pic))
			return '查看照片'
		else
		    return '无'
	},
   seeorgial(val){
	  this.showimg=true;
	  this.editTitle='原始照片',
	  this.showimages=val;
   },
   seevin(){
	   if(this.subData.a_driver_license){
	   		  this.showimg=true;
	   		  this.editTitle='铭牌照片';
			  let arr1=[];
			  arr1[0]=this.subData.a_driver_license; 
	   		  this.showimages=arr1
	   }
   },
   seexingshiimg(){
   	   if(this.subData.a_car_license){
   	   		  this.showimg=true;
   	   		  this.editTitle='行驶证';
   			  let arr1=[];
   			  arr1[0]=this.subData.a_car_license; 
   	   		  this.showimages=arr1
   	   }
   },
   seequjianimg(val){
	   if(val){
		   if(val.qujian_pic){
			this.showimg=true;
			this.editTitle='取件照片',
			this.showimages=val.qujian_pic.pic;    
		   }
		 
	   }
  
   },
   seehuanjianimg(val){
	 if(val){
	 		 if(val.huanjian_pic){
	 		 			this.showimg=true;
	 		 			this.editTitle='还件照片',
	 		 			this.showimages=val.huanjian_pic.pic;    
	 		 }
	 		 
	 }
   	
   },
    viewData() {
      // alert( this.type );
      let params = { id: this.id,type: this.type};
      getOrderView(params).then((res) => {
        if (res.errCode == 0) {
        let resdata= res.data;
		  resdata.item_data.forEach((u)=>{
			  if(u.type==Enum.itemstatus.extra){
				  u.status=u.bstatus_str;
			  }else if(u.type==Enum.itemstatus.recover){
				  u.status=u.status_recover_str;
			  }
		
		  })
		  this.subData=resdata
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.viewData();
  },
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";
.casenoItemnpt {
  width: 500px;
  display: inline-block;
  margin-right: 3%;
  white-space: nowrap;
}
// ------------------------
  .wytoubu {
    margin: 10px 0;
    background-color: #f5f5f5;
    border: 1px solid #e8e8e8;
    text-align: center;
  }

  .wytoubu th {
    padding: 10px 0;
    font-weight: 400;
    font-size: 12px;
  }

  /*th宽度*/
  .widthdu1 {
    width: 21%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu2 {
    width: 4%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu3 {
    width: 15%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu6 {
    width: 15%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu4 {
    width: 9%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu5 {
    width: 9%;
    line-height: 16px;
    font-size: 12px;
  }

  .widthdu7 {
    width: 4%;
    line-height: 16px;
    font-size: 12px;
  }

  .zhuneitongpad .widthdu1,
  .widthdu2,
  .widthdu3,
  .widthdu4,
  .widthdu5,
  .widthdu6,
  .widthdu7 {
    padding: 10px 5px 10px 5px;
  }

  .widthdu1 .lefts {
    width: 80px;
    display: inline-block;
  }

  .widthdu1 .lefts img {
    width: 80px;
    height: 80px;
  }

  .widthdu1 .rights {
    display: inline-block;
    width: 70%;
    vertical-align: top;
    margin: 6px 0 0 5px;
  }

  .widthdu1 .rights p {
    width: 80%;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .hoverred:hover {
    color: red;
    cursor: pointer;
  }

  .widthdu1 .rights span {
    color: #9e9e9e;
  }

  .xianbor {
    border-left: 1px solid #daf3ff;
  }

  /* 公用 */
  .bought-table-mod__table___3u4gN {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    color: #3c3c3c;
    table-layout: fixed;
  }

  /* 全选一行样式 */
  .shaixuan {
    height: 30px;
    margin: 10px 0;
    margin-top: 60px;
  }

  .shaixuan span {
    margin-right: 30px;
  }

  .shaixuan .anniutz {
    padding: 4px;
  }

  .shaixuan .florigh {
    float: right;
  }

  .bordertbal {
    border: 1px solid #daf3ff;
    margin: 10px 0;
  }

  /* teconttbody */
  .teconttbody {
    background: #eaf8ff;
  }

  .tecont td {
    border: none;
  }

  .tecont .padd {
    padding: 10px 0px;
  }

  .padd b {
    margin-right: 10px;
    margin-left: 3px;
  }

  .teconttbody .widthdu4 {
    text-align: center;
  }

  .centes {
    text-align: center;
  }

  .centes p {
    text-align: center;
    line-height: 20px;
  }

  .centes span {
    color: #9e9e9e;
    display: block;
    margin-top: 5px;
  }

  .centes .reds {
    color: red;
  }

  .nptss {
    padding: 5px;
    width: 237px;
  }

  .shctr2 .bnt {
    width: 60px;
    padding: 5px 2px;
    margin-left: -4px;
  }

  .shctr2 {
    float: right;
    font-size: 12px;
    width: 300px;
    color: #6b6464;
    position: relative;
    top: 8px;
    z-index: 3;
  }

  /* .shctl{position:relative;} */
  .el-tabs__content {
    overflow: visible;
  }

  .tempspan {
    /*表格里面的字*/
    width: 240px;
    display: inline-block;
  }

  .fenyedi {
    height: 40px;
  }

  .widthdu2 p {
    text-align: center;
    height: 64px;
    line-height: 64px;
  }

  .widthdu7 p {
    text-align: center;
    height: 64px;
    line-height: 64px;
  }

  .yanse {
    color: rgb(37, 147, 252);
  }

  .widthdu_div_margin {
    //   margin-bottom: 10px;
  }

  .widthdu_div_margin span:nth-child(3) {
    color: #74bcff;
    margin-left: 5px;
  }
</style>

<template>
  <div class="cc">
    <div class="head">
      <p  class="head-p">经营驾驶舱智能看板</p>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-top">
          <div class="topone">
            <total :datatype="left.org_waixiu"></total>
          </div>
          <div class="topone">
            <companies :datatype="left.org_baoxian"></companies>
          </div>
        </div>
        <div class="left-top">
          <div class="topone">
            <order  :datatype="left.order"></order>
          </div>
          <div class="topone">
            <money :datatype="left.money_waixiu"></money>
          </div>
        </div>
        <div class="left-top">
          <div class="topone">
            <derogationValue :datatype="left.jiansunshu"></derogationValue>
          </div>
          <div class="topone">
            <materia :datatype="left.money_wusun"></materia>
          </div>
        </div>
      </div>
      <div class="mid">
        <div class="mid-map">
<cover></cover>
        </div>
        <div class="mid-content">
          <div class="mid-top">
<!--            <div class="today" > 今日</div>-->
<!--            <div class="month" >本月</div>-->
<!--            <div class="year" >本年 </div>-->
 
            <div  :class="date_show1==true?'today':'month'" @click="handleEdit(1)"> 今日</div>
            <div  :class="date_show2==true?'today':'month'" @click="handleEdit(2)">本月</div>
            <div  :class="date_show3==true?'today':'month'" @click="handleEdit(3)">本年 </div>
          </div>


          <div class="mid-mid" v-show="date_show1">
            <div class="midmidValue" v-for="(item,index) in jinri" :key="index">
              <td class="title">{{item.title}}</td>
              <td class="valueflex">
                <div class="value">{{item.count}}</div>
                <span class="lineend">{{item.company}}</span>
              </td>
              <td class="count">
                <span :class="item.trend=='↑' ? 'red':'gre'">{{item.trend}}</span>
                <span style="color:white;">{{item.ratio}}</span>
              </td>
            </div>
          </div>

          <div class="mid-mid" v-show="date_show2">
            <div class="midmidValue" v-for="(item,index) in month" :key="index">
              <td class="title">{{item.title}}</td>
              <td class="valueflex">
                <div class="value">
                  {{item.count}}
                  </div>
                <span class="lineend">{{item.company}}</span>
              </td>
              <td class="count">
                <span :class="item.trend=='↑' ? 'red':'gre'">{{item.trend}}</span>
                <span style="color:white;">{{item.ratio}}</span>
              </td>
            </div>
          </div>

          <div class="mid-mid" v-show="date_show3">
            <div class="midmidValue" v-for="(item,index) in year" :key="index">
              <td class="title">{{item.title}}</td>
              <td class="valueflex">
                <div class="value">{{item.count}}</div>
                <span class="lineend">{{item.company}}</span>
              </td>
              <td class="count">
                <span :class="item.trend=='↑' ? 'red':'gre'">{{item.trend}}</span>
                <span style="color:white;">{{item.ratio}}</span>
              </td>
            </div>
          </div>









          <div class="mid-value">
            <div class="value-list">
              <div class="listTitle">
                <p  :class=" new_show1==true?'centerone':'centertwo'" @click="newqiehuan(1)" >外修单新增</p>
                <p :class=" new_show2==true?'centerone':'centertwo'" @click="newqiehuan(2)" >物损单新增</p>
              </div>
              <div class="listCount"  v-show="new_show1">
                <div class="onelist" v-for="(item2,index2) in waixiu_list" :key="index2">
                  <p >{{item2.sparename}}</p>
                  <p  >{{item2.createtime_str}}</p></div>
              </div>

              <div class="listCount"  v-show="new_show2">
                <div class="onelist" v-for="(item2,index2) in wusun_list" :key="index2">
                  <p>{{item2.sparename}}</p>
                  <p >{{item2.createtime_str}}</p></div>
              </div>

            </div>


            <div class="value-list">
              <div class="listTitle">
                 
                <p :class="money_show1==true?'centerthree':'centerfour'" @click="moneyqiehuan(1)">外修金额</p>
                <p :class="money_show2==true?'centerthree':'centerfour'" @click="moneyqiehuan(2)">物损金额</p>
                <p  :class="money_show3==true?'centerthree':'centerfour'" @click="moneyqiehuan(3)">减损金额</p>
              </div>

              <div class="listCount" v-show="money_show1">
                <div class="onelist" v-for="(item2,index2) in waixiu_list" :key="index2" >
                  <p >{{item2.sparename}}</p>
                  <p >{{item2.checkmoney}}</p></div>
              </div>

              <div class="listCount" v-show="money_show2">
                <div class="onelist" v-for="(item2,index2) in wusun_list" :key="index2" >
                  <p >{{item2.sparename}}</p>
                  <p  >{{item2.checkmoney}}</p></div>
              </div>

              <div class="listCount" v-show="money_show3">
                <div class="onelist" v-for="(item2,index2) in waixiu_list" :key="index2" >
                  <p >{{item2.sparename}}</p>
                  <p  >{{item2.jiansun_money_str}}</p></div>
              </div>

            </div>



            <div class="value-list">
              <div class="listTitle">
                <p style="width:100%;hieght:100%;background-color:#1B3DA6;" class="center">配件减损率滚动</p>
              </div>
              <div class="listCount" >
                <div class="onelist" v-for="(item2,index2) in waixiu_list" :key="index2">
                  <p>{{item2.sparename}}</p>
                  <p >{{item2.jiansunlv}}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="rightone">
          <div class="number">
            <img
                src="../../img/money.png"
                alt=""
                style="width: 20px; height: 20px; margin-right: 7px"
            />
            <p class="number-p">订单数</p> 
            <p class="number-pp" v-show="day_show1">{{ order_day.year_day }}</p>
             <p class="number-pp" v-show="day_show2">{{ order_day.jiushi_day }}</p>
              <p class="number-pp" v-show="day_show3">{{ order_day.liushi_day }}</p>
               <p class="number-pp" v-show="day_show4">{{ order_day.sanshi_day }}</p>
                <p class="number-pp" v-show="day_show5">{{ order_day.qi_day }}</p>
          </div>
          <div style="height: 31%; width: 53%; display: flex;margin-top:5px;justify-content: center;
    margin-left: 5px;">
          <div style="width:26%;height:100%;background: #1c07f4;" @click="oderDate(1)"> <p class="tips" style="background: #1c07f4;">365天</p></div>
            <div style="width:22%;height:100%;background: #2d44fa;" @click="oderDate(2)"><p class="tips" style="background: #2d44fa;">90天</p></div>
            <div style="width:22%;height:100%;background: #3589fd;" @click="oderDate(3)"><p class="tips" style="background: #3589fd;">60天</p></div>
            <div style="width:22%;height:100%;background: #19aff7;" @click="oderDate(4)"><p class="tips" style="background: #19aff7;">30天</p></div>
           <div style="width:20%;height:100%;background: #04ccf3;" @click="oderDate(5)"><p class="tips" style="background: #04ccf3;">7天</p></div>
          
          </div>
        </div>
        <div class="righttwo">
          <div class="twoleft">
            <p style="width: 100%;
height: 12px;
line-height: 25px;
font-size: 12px;
text-align:center;
font-family: Microsoft YaHei;
font-weight: 400;
color: #66FFFF;">外修单数年度目标</p>
            <div style="width:100%;height:100px">  <singularTarget v-if="year.waixiu_count" :num="year.waixiu_count.count"></singularTarget></div>

          </div>
          <div class="twoleft">
            <p style="width: 100%;
            line-height: 25px;
height: 12px;
font-size: 12px;
text-align:center;
font-family: Microsoft YaHei;
font-weight: 400;
color: #66FFFF;">外修金额年度目标</p>
            <div style="width:100%;height:80px;">    <annualTarget v-if="year.waixiu_money" :money="year.waixiu_money.count"></annualTarget></div>

          </div>
        </div>
        <div class="rightthree">
          <trend :datatype="zoushi"></trend>
        </div>
        <div class="rightfour">
          <increase :datatype="zoushi"></increase>
        </div>
        <div class="rightfive">
          <derogationTrend :datatype="zoushi"></derogationTrend>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import total from "../../components/charts/total.vue";
import order from "../../components/charts/order.vue";
import money from "../../components/charts/money.vue";
import derogationValue from "../../components/charts/derogationValue.vue";
import companies from "../../components/charts/companies.vue";
import materia from "../../components/charts/materia.vue";
import singularTarget from "../../components/charts/singularTarget.vue";
import annualTarget from "../../components/charts/annualTarget.vue";
import trend from "../../components/charts/trend.vue";
import derogationTrend from "../../components/charts/derogationTrend.vue";
import increase from "../../components/charts/increase.vue";

import cover from "../../components/charts/cover.vue";
import {jiashicang} from "@api/admin";
export default {
  components: {
    total,
    companies,
    order,
    money,
    derogationValue,
    materia,
    singularTarget,
    annualTarget,
    trend,
    derogationTrend,
    increase,
    cover
  },
  data() {
    return {
      left:{
        org_waixiu:[],
        org_baoxian:[],
        order:[],
        money_waixiu:[],
        jiansunshu:[],
        money_wusun:[],
      },
      waixiu_list:[],
      wusun_list:[],
      new_show1:true,
      new_show2:false,
      money_show1:true,
      money_show2:false,
      money_show3:false,
      order_day:{
        qi_day:0,
        sanshi_day:0,
        liushi_day:0,
        jiushi_day:0,
        year_day:0,
      },

      // listtwo:[{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // }, {
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // },{
      //   name:"奔驰前保险杠维修",
      //   time:"04-02 17:25"
      // }],
day_show1:true,
day_show2:false,
day_show3:false,
day_show4:false,
day_show5:false,
      date_show1:true,
      date_show2:false,
      date_show3:false,
      jinri:{},
      month:{},
      year:{},

      // list:[
      //   {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↑',
      //     count:'18%'
      //   }, {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↑',
      //     count:'18%'
      //   },    {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↓',
      //     count:'18%'
      //   }, {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↑',
      //     count:'18%'
      //   },   {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↓',
      //     count:'18%'
      //   }, {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↑',
      //     count:'18%'
      //   },    {
      //     title:"外修单数总数",
      //     value:500,
      //     company:"单",
      //     trend:'↑',
      //     count:'18%'
      //   }]

      zoushi:{},
    };
  },
  methods: {
    handleSearch() {
      jiashicang().then(res => {
        if (res.errCode == 0) {
          this.left = res.data.left;
          this.jinri = res.data.jinri;
          this.month = res.data.month;
          this.year = res.data.year;
          this.waixiu_list = res.data.list.waixiu_list;
          this.wusun_list = res.data.list.wusun_list;
          this.order_day = res.data.order_day;
          this.zoushi = res.data.zoushi;
        }
      });
    },
    oderDate(type){
      
      if (type == 1) {
        this.day_show1 = true;
        this.day_show2 = false;
        this.day_show3 = false;
        this.day_show4 = false;
        this.day_show5 = false;
      } else if (type == 2) {
        this.day_show1 = false;
        this.day_show2 = true;
        this.day_show3 = false;
        this.day_show4 = false;
        this.day_show5 = false;
      }else if (type == 3){
        this.day_show1 = false;
        this.day_show2 = false;
        this.day_show3 = true;
        this.day_show4 = false;
        this.day_show5 = false;
      }else if (type == 4){
        this.day_show1 = false;
        this.day_show2 = false;
        this.day_show3 = false;
         this.day_show4 = true;
        this.day_show5 = false;
      }else if (type == 5){
        this.day_show1 = false;
        this.day_show2 = false;
        this.day_show3 = false;
         this.day_show4 = false;
        this.day_show5 = true;
      }
   
    },
    handleEdit(type) {
      if (type == 1) {
        this.date_show1 = true;
        this.date_show2 = false;
        this.date_show3 = false;
      } else if (type == 2) {
        this.date_show1 = false;
        this.date_show2 = true;
        this.date_show3 = false;
      } else if (type == 3) {
        this.date_show1 = false;
        this.date_show2 = false;
        this.date_show3 = true;
      }
    },
    newqiehuan(type) {
      if (type == 1) {
        this.new_show1 = true;
        this.new_show2 = false;
      } else if (type == 2) {
        this.new_show1 = false;
        this.new_show2 = true;
      }
    },
    moneyqiehuan(type) {
      if (type == 1) {
        this.money_show1 = true;
        this.money_show2 = false;
        this.money_show3 = false;
      } else if (type == 2) {
        this.money_show1 = false;
        this.money_show2 = true;
        this.money_show3 = false;
      }else if (type == 3){
        this.money_show1 = false;
        this.money_show2 = false;
        this.money_show3 = true;
      }
    },
  },
  mounted() {
    this.handleSearch();
  }
}
</script>
<style scoped>
.cc {
  width: 100%;
  /* padding: 0px 0px 0px 10px; */
  position: relative;
  background-color: #02004d;
}
.listCount{
    width: 100%;
    height: 148px;
   padding-top: 5px;
     background-image: url(../../img/pages.png);
     background-size: 100% 100%;

}
.content {
  width: 100%;
  height: 510px;
    padding: 10px;
    box-sizing: border-box;
  display: flex;
}
.left {
width: 27.5%;
  height: 100%;
}
.mid {
 width: 49%;
  height: 100%;
  position: relative;
}
.head{
  width: 100%;
  height: 60px;
  display: flex;
  line-height: 60px;
  justify-content: center;
  align-items: center;
  background-image: url(../../img/head.png);
  background-size: 100% 100%;
  margin-bottom: 20px;
}
.head-p{
width: 401px;
height: 32px;
font-size: 33px;
letter-spacing: 5px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #FFFFFF;
text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.red{
  color: red;
  font-size: 15px;
   margin-right: 5px;
}
.gre{
  color:green;
  font-size: 15px;
  margin-right: 5px;

}
.right {
     width: 22%;
  height: 100%;
}
.left-top {
  width: 100%;
     height: 157px;
  display: flex;
  margin-bottom: 9px;
  justify-content: space-between;
}
.topone {
   width: 49%;
  height: 100%;
  background-image: url("../../img/boxbg.png");
  background-size: 100% 100%;
  background-color: #02004D;
}
.rightone {
  width: 100%;
  height: 42px;
    background-image: url("../../img/rightyi.png");
  background-size: 100% 100%;
  display: flex;
  margin-bottom: 10px;
  background-color: #02004d;
}
.righttwo {
  width:100%;
 
    height: 89px;

  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #02004d;
}
.onelist{
    width: 100%;
    height: 13px;
    font-size: 10px;
  -webkit-transform: scale(0.75);
font-family: Microsoft YaHei;
font-weight: 400;
    color: #5CE6EB;
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.twoleft {
       width: 134px;
  height: 90px;
  display: flex;
  background-image: url(../../img/righttwo.png);
  background-size: 100% 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.rightthree {
   width:100%;
  height: 105px;
  display: flex;
    background-image: url(../../img/rightthree.png);
  background-size: 100% 100%;
   background-color: #02004d;
  margin-bottom: 10px;
}
.rightfour {
 width:100%;
   height: 106px;
  display: flex;
    background-image: url(../../img/rightthree.png);
  background-size: 100% 100%;
   background-color: #02004d;
  margin-bottom: 10px;
}
.rightfive {
 width:100%;
  height: 106px;
  display: flex;
    background-image: url(../../img/rightthree.png);
  background-size: 100% 100%;
  margin-bottom: 10px;
  background-color: #02004d;
}
.number {
  height: 100%;
     width: 44%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.number-p {
    width: 46px;
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 15px;
    color: #66ffff;
}
.number-pp {
  width: 38px;
  height: 15px;
  line-height: 15px;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #66ffff;
}
 
.tips {
      line-height: 11px;
  height: 100%;
 width: 100%;
font-family: Microsoft YaHei;
  color: white;
  display: flex;
  -webkit-transform: scale(0.55);
  justify-content: center;
  font-size: 8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.value {
  height: 27px;
  /* font-size: 6px; */
      margin-right: 5px;
    -webkit-transform-origin-x: 0; 
     -webkit-transform: scale(0.90);
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #66FFFF;
  line-height: 27px;
}
.mid-content{
    width: 96%;
       height: 280px;

    position: absolute;
    bottom: 0px;
    left: 9px;
}
.mid-top{
   display: flex;
   width: 160px;
   height: 25px;
}
.mid-mid{
width: 100%;
height: 76px;
margin-bottom: 10px;
display: flex;
justify-content: space-between;
}
.count {
  width: 100%;
  height: 21px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
}
td {
  width: 100%;
}
.valueflex {
  display: flex;
  width: 100%;
  justify-content: center;
}
.as {
  width: 79px;
  height: 31px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 27px;
}
.lineend {
height: 27px;
    line-height: 27px;
  display: flex;
    color: #66FFFF;
  align-items: flex-end;
}
.title {
    width: 74px;
  height: 28px;
  font-size: 10px;
  display: flex;
      justify-content: center;
    align-items: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
    color: #66FFFF;
  line-height: 27px;
}
.mid-value{
width: 100%;
    height: 164px;
display: flex;
border-radius: 8px;
justify-content: space-between;
}
.value-list{
    width: 32%;
    height: 100%;
    background-color: #02004D;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
}
.centerthree{
  width:33%;height:100%;background-color:#1B3DA6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
}
.centerfour{
  width:33%;height:100%;background-color:#0F1F7A; color:#41A3C1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
}
.centertwo{
  width:50%;height:100%;background-color:#0F1F7A; color:#41A3C1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
}
.centerone{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
    width:50%;
    height:100%;
    background-color:#1B3DA6;
}
.today{
width: 50px;
border-radius: 4px;
height: 100%;
font-size: 13px;
margin-right: 10px;
border:1px solid #5FECF5;
background-color: #327AA3;
color: #92FCFD;
display: flex;
justify-content: center;
align-items: center;
}

.month{
font-size: 13px;
height: 100%;
width: 50px;
border-radius: 4px;
margin-right: 10px;
display: flex;
justify-content: center;
align-items: center;
background-color: #0B0D63;
color: #FFFFFF;
}
.year{
font-size: 13px;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
width: 50px;
border-radius: 4px;
background-color: #0B0D63;
color: #FFFFFF;
}
.midmidValue{
      width: 82px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    flex-direction: column;
}
.listTitle{
    width: 100%;
    margin-top: 3px;
   height: 17px;
    color:#5FECF5;
    justify-content: center;
    align-items: center;
    display: flex;

}
.mid-map{
    width: 80%;
        height: 251px;
    margin-top: 0px;
    margin-left: 10px;

}
</style>

<template>
	<Card class="col-right-card min-height">
		<p slot="title">全局设置</p>

		<Form :label-width="120" ref="subData" :model="subData" :rules="dataRules">
			<FormItem class="FromItemnpt" label="客服电话" prop='phone'>
				<Input clearable placeholder="客服电话" v-model="subData.details" /></FormItem>

			<Button type="primary" @click="save">保存</Button>
		</Form>
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
import config from '@/config';

import {getServiceView,getServiceChange} from '@/api/admin';

export default {
	data() {
		return {
			dataRules: {
			
			
				// phone: [
				// 	{
				// 		required: true,
				// 		message: '必填',
				// 		trigger: 'blur'
				// 	},
				// 	{
				// 		message: '格式有误',
				// 		trigger: 'blur',
				// 		pattern: /^1[34578]\d{9}$/
				// 	}
				// ],
			
			},
			subData: {
                details:''
			}
		};
	},
	methods: {
        save() {
            let params = {};
            params.data = this.subData.details;
            getServiceChange(params).then(res => {
                if(res.errCode == 0) {
                    this.$Modal.success({ title: '提示', content: res.errMsg })
                    let vm = this
                    setTimeout(function () {
                        vm.viewData()
                    }, 1500)
                } else {
                    this.$Modal.error({ title: '提示', content: res.errMsg })
                }
            })
        },
        viewData() {
            getServiceView().then(res => {
                if (res.errCode == 0) {
                    this.subData.details = res.data
                }
            })
        }
	},
	mounted() {
        this.viewData()
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}

.map .search {
	margin-bottom: 65px;
}
.org-width {
	width: 480px !important;
}
.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
</style>

<template>
    <div class="main" id="target">
       
    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
    props:['num'],
    data(){
        return{

        }
    },
    methods:{
gettarget(){
  var chartDom = document.getElementById("target");
      var myChart = echarts.init(chartDom, null, { width: 134, height: 80 });
      var option;
    option = {
      graphic: {
        type: 'text',
        left: '40%',
        top: '45%',
        style: {
          text: '已完成',
          textAlign: 'center',
          fill: '#6E76A6',
          fontSize: 8,
        },
      },
      series: [
        {
         title: { // 仪表盘标题。
            show: true, // 是否显示标题,默认 true。
            offsetCenter: [0,
                '19%'
            ],
            textStyle: {
                fontSize: 7, // 文字的字体大小,默认 15。
                fontWeight: 400,
            },
            color: "#66FFFF", // 文字的颜色,默认 #333。
         },
          type: 'gauge',
           radius:'50',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 1,
          center:['50%','75%'],
          axisLine: {
            lineStyle: {
              width: 2,
              color: [
                [0.25, '#FF6E76'],
                [0.5, '#FDDD60'],
                [0.75, '#58D9F9'],
                [1, '#7CFFB2']
              ]
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            offsetCenter: [0, '-55%'],
            itemStyle: {
              color: 'auto'
            },
              show: true,             // 是否显⽰指针,默认 true。
                        length: "20%",          // 指针长度，可以是绝对数值，也可以是相对于半径的百分⽐,默认 80%。
                        width: 5,               // 指针宽度,默认 8
          },
          axisTick: {
            length: 5,
              distance: 2,
            lineStyle: {
              color: 'auto',
              width: 1
            }
          },
          splitLine: {
            length: 7,
            distance: 2,
            lineStyle: {
              color: 'auto',
              width: 1
            }
          },
          axisLabel: {
            color: '#464646',
            fontSize: 10,
            distance: -60,
            formatter: function (value) {
              if (value === 0.875) {
                return 'A';
              } else if (value === 0.625) {
                return 'B';
              } else if (value === 0.375) {
                return 'C';
              } else if (value === 0.125) {
                return 'D';
              }
              return '';
            }
          },
          detail: {
            fontSize: 15,
            offsetCenter: [0, '-10%'],
            valueAnimation: true,
            formatter: function (value) {
              return Math.round(value * 100) + '%';
            },
            color: 'auto'
          },

          data: [
            {
              value: this.num/1000,
              color:'#66FFFF',
              name: '年度总目标1000单',
            },

          ]
        }
      ]
    };
      option && myChart.setOption(option);
    
}
    },
    mounted(){
this.gettarget()
    }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

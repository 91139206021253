<template>
    <div class="main" id="order">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Array
    },
  },
  data(){
    return{
      data1:[],
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getorder()
    },
    getorder(){

      var chartDom = document.getElementById('order');
      var myChart = echarts.init(chartDom,{});
          myChart.resize();
      var option;

      option = {
        title: {
          text: '当年区域订单数',
          left:"center",
          top:'11%',
          textStyle:{
            color:'#66FFFF',
            fontStyle:'normal',//主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight:"400",//可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontSize:12,//主题文字字体大小，默认为18px

          }
        },
//   legend: {
//     top: 'bottom'
//   },
  tooltip: {
          trigger: 'item',
          position:'right'
        },
        toolbox: {
          show: false,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        color: ["#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE","#3BA272","#FC8452",'#9A60B4','#EA7CCC','#75BE9C'],
        series: [
          {
            labelLine: {

              smooth: 0.2,
              length:2,
              length2:2,
              length3:2,
              length5:2,
            },
            name: '当年区域订单数',
            type: 'pie',
            radius: [20,58],
            center: ["49%", "60%"],
            roseType: 'area',
            itemStyle: {
              borderRadius: 3
            },
            // data: [
            //   { value: 40, name: '奥迪' },
            //   { value: 38, name: '别克' },
            //   { value: 32, name: '雪佛兰' },
            //   { value: 30, name: '现代' },
            //   { value: 28, name: '奔驰' },
            //   { value: 26, name: '丰田' },
            //   { value: 22, name: '宝马' },
            //   { value: 18, name: '福特' },
            //   { value: 18, name: '大众' },
            //   { value: 18, name: '本田' },
            // ],
            data: this.data1,
            label: {
              align: "left",
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },
                textStyle: {
                  fontSize: 8,
                  //显示文字的大小，
                  color: "#66FFFf",
                },
              },
            },
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getorder()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

import { render, staticRenderFns } from "./impairment.vue?vue&type=template&id=6a54242e&scoped=true&"
import script from "./impairment.vue?vue&type=script&lang=js&"
export * from "./impairment.vue?vue&type=script&lang=js&"
import style0 from "./impairment.vue?vue&type=style&index=0&id=6a54242e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a54242e",
  null
  
)

export default component.exports
<template>
  <div class="user-avatar-dropdown">
    <Dropdown @on-click="handleClick">
      <Badge :dot="!!messageUnreadCount">
        <!-- <Avatar :src="userAvatar"/> -->
      </Badge>

      <Icon :size="18" type="md-arrow-dropdown"></Icon>
      <DropdownMenu slot="list">
        <!-- <DropdownItem name="message">
          消息中心<Badge style="margin-left: 10px" :count="messageUnreadCount"></Badge>
        </DropdownItem> -->
        <DropdownItem name="logout">退出登录</DropdownItem>
        <DropdownItem name="updatePwd">修改密码</DropdownItem>
        <DropdownItem name="gerenxinxi">个人信息</DropdownItem>
        <!--<DropdownItem name="updateZhong">中华保险总部</DropdownItem>-->
      </DropdownMenu>
    </Dropdown>
      <password  ref="upPwd" :initOption="updateInitOption" @logout="logout"></password>
      <!-- <zhbxzongb ref="upName" :initOption="zhongHuaBao"></zhbxzongb> -->
  </div>

</template>

<script>
import './user.less'
import { mapActions } from 'vuex'
import password from '../pass_word/passWord'
// import zhbxzongb from '../pass_word/zhbxzongb'
export default {
  components: {
    password,
    // zhbxzongb
  },
  data () {
    return {
      updateInitOption: {
        showModal: false,
        title: '修改密码'
      },
      zhongHuaBao: {
        showModal: false,
        title: '个人信息'
      },
    }
  },
  name: 'User',
  props: {
    userAvatar: {
      type: String,
      default: ''
    },
    messageUnreadCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapActions([
      'handleLogOut'
    ]),
    updatePwd () {
      this.$refs.upPwd.reset()
      this.updateInitOption.showModal = true
    },
    updateZhong () {
      // this.$refs.
      this.zhongHuaBao.showModal = true
    },
    logout () {
      this.handleLogOut().then(() => {
        this.$router.push({
          name: 'login'
        })
      })
    },
    gerenxinxi(){
        this.$router.push({
          name: 'namex'
        })
    },
    message () {
      this.$router.push({
        name: 'notice_list'
      })
    },


    handleClick (name) {
      switch (name) {
        case 'logout': this.logout()
          break
        case 'gerenxinxi': this.gerenxinxi()
          break
        case 'message': this.message()
          break
        case 'updatePwd': this.updatePwd()
          break
        case 'updateZhong': this.updateZhong()
          break
      }
    },
  }
}
</script>



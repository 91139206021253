<template>
	<div class="myApp">
		<Row class="my-row">
			<div class="col-rightN">
				<Card class="col-right-card">
					<p slot="title">岗位管理</p>
					<Form :label-width="90">
						<div>
							<FormItem  v-show="usertype == 1"   class="FromItemnpt" label="所属机构" >
																	
								
									<Input placeholder="请点击右边选择所属机构" disabled v-model="search.ogname" >
									  <Icon type="ios-search" slot="suffix" @click="chooseGy_org('search')" />
									</Input>
							      <!--<Button type="primary" @click="clearogname">清除</Button>-->
								<a style="margin-left:5px;" @click="clearogname">清除</a>
							</FormItem>
							<FormItem class="FromItemnpt" label="所属部门" >
							 <Cascader :data="searchdeplist" v-model="search.search_dep_list" change-on-select @on-change="changesearchdep"></Cascader>
							</FormItem>
							<FormItem class="FromItemnpt" label="上级岗位" >
							<Cascader :data="searchpostionlist" v-model="search.search_position_list" change-on-select @on-change="changesearchparent"></Cascader>
							</FormItem>
							<FormItem class="FromItemnpt" label="岗位名称：" prop="name"><Input v-model="search.name" clearable /></FormItem>
							
							
							<Button type="primary" @click="handleSearchAction">查询</Button>
						</div>
					</Form>

					<div class="gray-nona"></div>
					<div class="tablebnt">
						<Button type="primary" @click="handleCreateModel" v-show='button.add'>新增</Button>
						<!--<Button type="primary" @click="handleStatusSuccess" v-show='button.enable'>启用</Button>-->
						<!--<Button type="primary" @click="handleStatusFail" v-show='button.disabled'>禁用</Button>-->
						<Button type="primary" @click="handleDelete" v-show='button.delete'>删除</Button>
						
					</div>

					<Table
						stripe
						height="500"
						style="margin-bottom: 7px;"
						border
						:columns="columns"
						:data="data1"
						@on-select="tableSelectionChange"
						@on-select-all="tableSelectionChange"
						@on-select-cancel="tableSelectionChange"
					></Table>

					<div class="my-page"><Page :total="pageTotal" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" /></div>
                    <Modal v-model="newModel" :title="titleData" width="600">
							<div>
								<Form :label-width="100" ref="subData" :model="subData" :rules="addrules"  >
									<FormItem v-show="usertype == 1" label="所属机构"  required>
										
									
										</Input>
											<Input placeholder="请点击右边选择所属机构" disabled v-model="subData.ogname" >
											  <Icon type="ios-search" slot="suffix" @click="chooseGy_org('add')" />
											</Input>
											
									</FormItem>

									<FormItem label="上级岗位" prop="position_arr">
										<Cascader :data="positionarr" v-model="subData.position_arr" change-on-select @on-change="changeparent"></Cascader>
									</FormItem>
									<FormItem label="所属部门" prop="dep_arr">
										<Cascader :data="deparr" v-model="subData.dep_arr" change-on-select @on-change="changedep"></Cascader>
									</FormItem>
									<FormItem label="岗位名称" prop="name">
										<Input v-model="subData.name"  clearable />
									</FormItem>
									<FormItem class="FromItemnpt" label="系统岗位">
									<!-- 	 <i-switch  @on-change="systemchange" true-value='1' false-value='0'>
										        <span slot="open">是</span>
										        <span slot="close">否</span>
										    </i-switch> -->
									   <RadioGroup v-model="subData.issystem" @on-change="systemchange">
									       <Radio v-for="item in systemarr" :label="item.value">{{item.name}}</Radio>
									      
									    </RadioGroup>
									</FormItem>

									<FormItem style="width:92%" label="描述">
										<Input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="subData.description" placeholder="请输入描述" />
									</FormItem>
								</Form>
							</div>
							<div slot="footer">
								<Button @click="newModel = false">取消</Button>
								<Button type="primary" @click="adddep">保存</Button>
							</div>
						</Modal>
					<Modal v-model="orgmodal" title="选择保险公司" width="900">
						<Form :label-width="80">
							<FormItem class="FromItemnpt" label="名称"><Input v-model="orgsearch.kw" clearable /></FormItem>
							<FormItem class="FromItemnpt dizhi_width" label="地区" style="margin-right: 6px;">
								<cityView ref="cityView" style="display: inline-block;" @selectFunc="selectFunc1"></cityView>
							</FormItem>
							<Button type="primary" @click="searchorg">搜索</Button>
						</Form>

						<Table height="500" border stripe :columns="orgcanshu" :data="orgcanshudata"></Table>

						<div class="my-page">
							<Page :total="orgtotalCount" show-elevator show-sizer show-total @on-change="handleorgPage" @on-page-size-change="handleorgPageSize" />
							<div style="height:30px;"></div>
						</div>

						<div slot="footer"></div>
					</Modal>
				</Card>
			</div>
		</Row>
	</div>
</template>

<script>
import config from '@/config';
import * as util from '@/libs/util';
import { getOrgView, getOrgEdit, getInsideAdd, getInsideList } from '@/api/user';
import { getOryStatus } from '@/api/common';
import { getstationlist, getorglist, addPosition, getDepartmentDrop ,getPositionDrop,positionview,getPositionEdit} from '@/api/admin';
import * as Enum from '@/libs/enum';
import cityView from '../mycomponents/cityView.vue';
export default {
	components: {
		cityView
	},
	name: 'account',
	data() {
		const validateParent = (rule, value, callback) => {
			if (this.subData.position_arr.length > 0) {
				callback();
			} else {
				callback(new Error(' 请选择上级岗位'));
			}
		};
		const validatedep= (rule, value, callback) => {
			if (this.subData.dep_arr.length > 0) {
				callback();
			} else {
				callback(new Error('请选择上级部门'));
			}
		};
		
		const validatename = (rule, value, callback) => {
			if (this.subData.name) {
				callback();
			} else {
				callback(new Error('请输入岗位名称'));
			}
		};
		// const validateogname = (rule, value, callback) => {
		// 	if (this.subData.ogname) {
		// 		callback();
		// 	} else {
		// 		callback(new Error('请选择机构'));
		// 	}
		// };
		return {
			systemarr:[
				{
					value:0,
					name:"否"
				},
				{
					value:1,
					name:"是"
				}
			],
				
			usertype:'2',
			curogid:'0',
			dateRange: {
				shortcuts: [
					{
						text: '今日',
						value() {
							const end = new Date();
							const start = new Date();
							return [start, end];
						}
					},
					{
						text: '本周',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						}
					},
					{
						text: '本月',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						}
					},
					{
						text: '本季度',
						value() {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						}
					}
				]
			},
			addrules: {
				// ogname: [{
				// 		validator: validateogname,
				// 		required: true,
				// 		trigger: 'blur'
				// 	}],
				name: [{
						validator: validatename,
						required: true,
						trigger: 'blur'
					}],
			   position_arr: [
					{
						validator: validateParent,
						required: true,
						trigger: 'change'
					}
				],
				dep_arr: [
					{
						validator: validatedep,
						required: true,
						trigger: 'change'
					}
				],
			},
			orgtotalCount: 0,
			orgsearch: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			},
			button: {},
			checkedIds: [],
			titleData: '', // 标题
			newModel: false,
			
			search: {
				// 筛选条件
				status: 0,
				p: 1,
				t: 10,
				ogname: '',
				search_dep_list:[],
				search_position_list:[],
				parent_id: '',
				department_name: '',
				parent_name: '',
				department_id:''
				
			},
			subData: {
				
				ogname: '',
				ogid: '',
				parent_id: '',
				dep_arr: null,
				parent_name: null,
				department_id:null,
				issystem:0,//是否系统岗位   1是 0否
				position_arr:[],
				defswitch:true,
				
			},
			pageTotal: 10,
			modal1: false,
			orgcanshudata: [],
			orgcanshu: [
				{
					title: '保险公司编号',
					key: 'code',
					align: 'center',
					minWidth: 200,
					fixed: 'left'
				},
				{
					title: '保险公司名称',
					key: 'name',
					align: 'center',
					minWidth: 200,
					fixed: 'left'
				},
				{
					title: '联系人',
					key: 'manager',
					align: 'center',
					minWidth: 130
				},
				{
					title: '联系电话',
					key: 'managerphone',
					align: 'center',
					minWidth: 100
				},
				/*{
					title: '主营',
					key: 'main_camp',
					align: 'center',
					minWidth: 80
				},*/
				{
					title: '所在省市区',
					key: 'area_name',
					align: 'center',
					minWidth: 200
				},
				{
					title: '所在地址',
					key: 'address',
					align: 'center',
					minWidth: 100
				},

				{
					title: '添加时间',
					key: 'createtime',
					align: 'center',
					minWidth: 170
				},
				{
					title: '状态',
					key: 'status_str',
					align: 'center',
					minWidth: 100
				},
				{
					title: '备注',
					key: 'remark',
					align: 'center',
					minWidth: 100
				},

				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
										disabled: !this.button.edit
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.selectorg(params.row);
										}
									}
								},
								'选择'
							)
						
						]);
					}
				}
			],

			columns: [
				// 表头
				{ type: 'selection', align: 'center', width: 60 },
				{ title: '岗位ID', align: 'center', key: 'position_id', minWidth: 120 },
				{ title: '岗位名称', align: 'center', key: 'name', minWidth: 140 },
				{ title: '所属机构', key: 'ogname', align: 'center', minWidth: 100 },
				{ title: '所属部门', key: 'department_name', align: 'center', minWidth: 100 },
				{ title: '上级岗位', align: 'center', key: 'parent_name', minWidth: 140 },
				{ title: '系统岗位', align: 'center', key: 'issystem', minWidth: 140 },
				{ title: '添加时间', align: 'center', key: 'createtime', minWidth: 150 },
				{
					title: '操作',
					key: 'action',
					width: 100,
					align: 'center',
					fixed: 'right',
					render: (h, params) => {
						return h('div', [
							h(
								'Button',
								{
									props: {
										type: 'primary',
										size: 'small',
										disabled: !this.button.edit
									},
									style: {
										marginRight: '5px'
									},
									on: {
										click: () => {
											this.updatestation(params.row);
										}
									}
								},
								'编辑'
							)
						
						]);
					}
				}
			],
			data1: [],
			orgmodal: false,
			deparr: [],
			positionarr: [],
			chooseType:'',
			searchdeplist:[],
			searchpostionlist:[],
			
		};
	},
	methods: {
		clearogname(){
			this.search.ogname=null;
			this.search.ogid=null;
		},
		vailpor(prop,a,b){
			// console.log('onvalidata',prop);
		},
		selectFunc1(cityObj) {
			this.orgsearch.province = cityObj.provinceModel;
			this.orgsearch.city = cityObj.cityModel;
			this.orgsearch.district = cityObj.districtModel;
		},
		searchorg() {
			let params = this.orgsearch;
			getorglist(params).then(res => {
				if (res.errCode == 0) {
					this.orgtotalCount = parseInt(res.data.total);
					this.orgcanshudata = res.data.list;
				}
			});
		},
		changesearchparent(value, selectedData) {//选择岗位
			// console.log('changesearchparent',selectedData, value);
			if (selectedData.length > 0) {
				this.search.parent_name = selectedData[selectedData.length - 1].label;	
				this.search.parent_id = selectedData[selectedData.length - 1].value;
				this.search.search_position_list = value;
			} else {
				this.search.parent_id = null;
				this.search.parent_name = null;
				this.search.search_position_list = [];
			}
		},
		changesearchdep(value, selectedData) {//选择部门
			// console.log('changesearchdep',selectedData, value);
			if (selectedData.length > 0) {
			this.search.department_name = selectedData[selectedData.length - 1].label;
			this.search.department_id = selectedData[selectedData.length - 1].value;
			this.search.search_dep_list = value;
			} else {
			this.search.department_id = null;
			this.search.department_name = null;
			this.search.search_dep_list = [];
			}
		},
		changeparent(value, selectedData) {//选择岗位
			// console.log(selectedData, value);
			if (selectedData.length > 0) {
				this.subData.parent_name = selectedData[selectedData.length - 1].label;	
				this.subData.parent_id = selectedData[selectedData.length - 1].value;
				this.subData.position_arr = value;
			} else {
				this.subData.parent_id = null;
				this.subData.parent_name = null;
				this.subData.position_arr = [];
			}
		},
		changedep(value, selectedData) {//选择部门
			// console.log('changedep',selectedData, value);
			if (selectedData.length > 0) {
				this.subData.department_name = selectedData[selectedData.length - 1].label;
				this.subData.dep_arr = value;
				this.subData.department_id = selectedData[selectedData.length - 1].value;
			} else {
				this.subData.department_id = null;
				this.subData.department_name = null;
				this.subData.dep_arr = [];
			}
		},
		selectorg(val) {
			// console.log('selectorg', val);
			this.orgmodal = false;
			if(this.chooseType=='add'){
			this.subData.ogname = val.name;
			this.subData.ogid = val.id;
			getDepartmentDrop({ ogid: val.id }).then(res => {
				if (res.errCode == 0) {
					this.deparr =res.data;
				}
			});
			getPositionDrop({ ogid: val.id }).then(res => {
				if (res.errCode == 0) {
					let resdata = res.data;
					resdata.unshift({
						value: 0,
						label: '顶级岗位',
						children: []
					});
					this.positionarr = resdata;
				}
			});
			}else{
				this.search.ogname= val.name;
				this.search.ogid= val.id;
			}

		},
		systemchange(e){
			// console.log('systemchange',e);
			
				this.subData.issystem=e;
			
		},
		adddep(){
			var that=this
			  this.$refs.subData.validate((valid) => {
			                    if (valid) {
									let params=that.subData;
			                       addPosition(params).then(res => {
			                       	if (res.errCode == 0) {
										
										this.handleSearch()
			                       		that.$Modal.success({ title: '提示', content: '新增成功' });
										that.newModel=false
			                       	}else{
										that.$Modal.error({ title: '提示', content: res.errMsg });
									}
			                       });
			                    } else {
			                        that.$Message.error('表单校验错误');
			                    }
			                })
		},
		chooseGy_org(val) {
			this.chooseType=val
			this.orgmodal = true;
			let params = {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			};
			getorglist(params).then(res => {
				if (res.errCode == 0) {
					this.orgtotalCount = parseInt(res.data.total);
					this.orgcanshudata = res.data.list;
				}
			});
		},
		checkDate(date) {
			// 操作时间
			this.search.stime = date[0];
			this.search.etime = date[1];
		},
		handleCreateModel() {
            this.reset();
			this.subData = {};
			this.subData.ogid = this.curogid;
			this.subData.parent_id = '';
			this.subData.name = '';
			this.subData.description = '';
			this.subData.issystem=0;
			this.titleData = '新增岗位';
			this.newModel = true;
			this.deparr=[];
			this.positionarr=[];

            getDepartmentDrop({ ogid: this.curogid }).then(res => {
                if (res.errCode == 0) {
                    this.deparr =res.data;
                }
            });
            getPositionDrop({ ogid: this.curogid }).then(res => {
                if (res.errCode == 0) {
                    let resdata = res.data;
                    resdata.unshift({
                        value: 0,
                        label: '顶级岗位',
                        children: []
                    });
                    this.positionarr = resdata;
                }
            });

		},

        tableSelectionChange(data1) {
            if (data1[0]) {
                this.checkedId = data1[0].position_id
                this.subData.position_id = data1[0].position_id
            }
            let ids = []
            data1.map(function (item, key) {
                ids.push(item.position_id)
            })
            this.checkedIds = ids
        },

		handlePage(value) {
			this.search.p = value;
			this.handleSearch();
		},
		handlePageSize(value) {
			this.search.t = value;
			this.handleSearch();
		},
		handleorgPage(value) {
			this.orgsearch.p = value;
			this.searchorg();
		},
		handleorgPageSize(value) {
			this.orgsearch.t = value;
			this.searchorg();
		},

		// 机构列表
		handleSearch() {
			let params = this.search;
			  params.action_id='458';
			getstationlist(params).then(res => {
				if (res.errCode === 0) {
					this.data1 = res.data.list;
					this.pageTotal = res.data.total;
					this.button = res.data.button;
				}
			});
		},
	
		handleSearchAction() {
			this.search.p = 1;
			this.handleSearch();
		},

		updatestation(val) {
			this.reset();
			this.titleData = '修改岗位信息';
			this.newModel = true;
			getDepartmentDrop({ ogid: val.ogid }).then(res => {
				if (res.errCode == 0) {
					this.deparr =res.data;
				}
			});
			getPositionDrop({ ogid: val.ogid }).then(res => {
				if (res.errCode == 0) {
					let resdata = res.data;
					resdata.unshift({
						value: '0',
						label: '顶级岗位',
						children: []
					});
					this.positionarr = resdata;

				}
			});
			this.handledel(val.position_id);
		},

		handledel(id) {
			
			positionview({position_id:id})
				.then(res => {
				    // console.log('positionview',res);
                    // let datare=res.data
					this.subData=res.data;
				
					// this.subData.dep_arr=[];
					// this.subData.position_arr=[];
					this.subData.ogname=res.data.og_name;
					// console.log('positionarr',this.positionarr,this.deparr);
                    // this.subData.position_arr.push(datare.parent_id);
					// this.subData.dep_arr.push(datare.department_id);
					
					
				}).catch(err => {
					// console.log(err);
				});
		},


        sysAdminEdit(type) {
            let params = {};
            params.position_id = this.checkedIds;
            params.type = type;
            getPositionEdit(params).then(res => {
                if (res.errCode === 0) {
                    this.checkedIds = [];
                    this.$Notice.success({
                        title: '提示',
                        desc: res.errMsg
                    });
                    this.handleSearch();
                } else {
                    this.$Notice.error({
                        title: '提示',
                        desc: res.errMsg
                    });
                }
            });
        },
        // 删除
        handleDelete() {
            if (this.checkedIds.length > 0) {
                this.$Modal.confirm({
                    title: '确认信息',
                    content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
                    onOk: () => {
                        this.sysAdminEdit(1);
                    },
                    onCancel: () => {
                        this.$Message.info('您取消了删除操作');
                    }
                });
            } else {
                this.$Modal.info({ title: '提示', content: '请至少选择一项' });
            }
        },
		reset() {
			this.$refs.subData.resetFields();
		},
		getsearcharr(){
			getDepartmentDrop( ).then(res => {
				if (res.errCode == 0) {			
					this.searchdeplist =res.data;
				}
			});
			getPositionDrop().then(res => {
				if (res.errCode == 0) {	
					let resdata = res.data;
				   resdata.unshift({
					value: 0,
					label: '顶级岗位',
					children: []
				    });
					this.searchpostionlist = resdata;
				}
			});	
		}
	},

	mounted() {
		this.handleSearch();
		this.getsearcharr()
		this.usertype=util.getUserType();
		var user=util.getUser();
		this.curogid=user.ogid;
		// console.log('this.curogid',this.curogid);

	}
};
</script>
<style lang="less">
@import '../style2.less';

.bxnpt {
	width: 25%;
	margin-left: 2%;
	float: left;
}
</style>

<template>
	<Card class="col-right-card min-height">
		<p slot="title">{{pagetitle}}{{usertype==1?"批量":""}}{{typestr}}{{usertype==1?"区域匹配":"区域分布"}}	
		</p>
		<Form  ref="ogdata" :model="ogdata" :rules="dataRules">
			<Row class='og_row'>
				<FormItem class="FromItemnpt  org-width" :label="pagetitle" prop="name" >	
					<Input disabled v-model="ogdata.name"   />	
				</FormItem>	
				
				<Button type="primary" @click="save"  v-show="usertype==1">保存</Button>
			</Row>
				
		
		</Form>
	


		<div class="tree_view" v-if="orgareadata.length>0">
		
			<Tree :data="orgareadata" show-checkbox  @on-check-change='checkchang'  multiple></Tree>
		</div>
		<div></div>
	</Card>
</template>
<script>
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';
import * as tools from '@/libs/tools';
import config from '@/config';
import { getorglist, getorhDrop, getOrgEdit, getorgdetail, addorg, getpartsorglist ,addorgcat,selectarea,addorgareas,coverarea,addcoverarea} from '@/api/admin';

export default {
	data() {
		return {
			usertype:'2',
			typestr:'',
			ogdata: {},
			partsList: [],
			canshudata:[],
			slemodal:false,
			totalCount:0,
			type:null,
			titlespec:null,
			search: {
				p: 1, // 当前页码
				t: 10, // 每页显示条数
				ogtype: Enum.ogtypeEnum.insurance_company,
				kw: null,
				status: null,
				stime: null,
				etime: null
			},
			pagetitle:'外修商',
			orgareadata:[],
			
			dataRules: {
				name: [
					{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}
				]
			},
			ptreedata: [],
			isEdit:false,
			insurancedata:[],
			insuranid:0,
			showorg:1,
			matchmodal: true
		};
	},
	methods: {
		
		
		save(){
			var that = this;
			
			this.$refs.ogdata.validate(valid => {

				if (valid) {
					let add_acts=[];
					let pmdata=that.orgareadata[0].children;

                    // console.log(pmdata);

					pmdata.forEach((pitem,$one) => {
						let tdata=pitem.children;

						tdata.forEach((titem,$two) => {

                            let three=titem.children;

                            three.forEach(threetitem => {


                                if(threetitem.checked){

                                    // alert(pmdata[$one].bx_district_name);

                                    let pare={


                                        bx_province_id:pmdata[$one].bx_district_id,
                                        bx_province_name:pmdata[$one].bx_district_name,

                                        bx_city_id:tdata[$two].bx_district_id,
                                        bx_city_name:tdata[$two].bx_district_name,

                                        bx_district_id:threetitem.bx_district_id,
                                        bx_district_name:threetitem.bx_district_name,

                                    }
                                    add_acts.push(pare)
                                }
							})

						})
					})


					let typefor=2;
					if(that.type=='extra'){
						typefor=2;
					}else if(that.type=='recover'){
						typefor=4;
					}else if(that.type=='wusun'){
						typefor=5;
					}
					let params = {
						add_acts:add_acts,
						bus_ogid:that.ogdata.id,
						type:typefor,//2外修  4回收  5物损
					};


					// console.log('addorgareas params',params);


					addcoverarea(params).then(res => {
						// console.log('addorgcat',res);
						if (res.errCode == 0) {

							that.$Modal.success({
								title: '提示',
								content: '保存成功'
							});
							this.$router.go(-1)
						} else {
							that.$Modal.error({
								title: '提示',
								content: res.errMsg
							});
						}
					});
				}
			});
		},
		checkchang(arr,val){
			// console.log('111',arr,val);
			if(val.checked){
				this.a
			}else{
				
			}
		},
	
	
		

		handmatcharea(){
		
		
			let params={
				bus_ogid:this.ogdata.id,
			}
			this.matcharea(params)
		},

		 calleArr(array){
            array.forEach((item, index) => {
                if (item.child) {


                    item.checked = item.status === 1 ? 1 : 0
                    item.title = item.name
                    item.children = item.child

					  item.bx_district_id  = item.id
					  item.bx_district_name = item.name
										 
                    this.calleArr(item.child) //自己调用自己



                } else {
					  item.bx_district_id  = item.id
					  item.bx_district_name = item.name
                    item.checked = item.status === 1 ? 1 : 0
                    item.title = item.name
                 }
           })
   			return array
        },


		matcharea(val) {
			this.matchmodal = true;
			const msg = this.$Message.loading({
				content: '加载中...',
				dutration: 0
			});

			coverarea(val).then(res => {
         if (res.errCode === 0) {
          let resdata = res.data;
          // resdata[0].child[0].child[0].status = 1
          // console.log(res.data)
           this.orgareadata = [{
            title:'全部',
            expand: true,
            children: this.calleArr(resdata),
           }]
          //  console.log(this.orgareadata)
          setTimeout(msg, 0);
         }
   });

		}
	
	},
	mounted() {
			let querydata=this.$route.query;
			this.isEdit=true;
			this.type=this.$route.query.type;
				if(this.type=='extra'){
						this.typestr='外修';
					}else if(this.type=='recover'){
							this.typestr='回收';
					}else if(this.type=='wusun'){
							this.typestr='物损';
					}
			this.ogdata = querydata.ogdata;
			this.pagetitle=querydata.title;
			this.usertype=util.getUserType();
			this.handmatcharea();
		
		
	}
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';
.insurancediv{
	margin-left: 60px;
}
.dizhiselecls {
	width: 342px;
	margin-right: 3px;
}

.map {
	width: 100%;
	height: 400px;
}
.og_row{
	button{
		margin-left: 100px;
	}
}
.map .search {
	margin-bottom: 65px;
}
.org-width {
	width: 320px !important;
}
.userfnpt {
	width: 300px;
	margin-right: 3%;
	display: inline-block;
}
.right_title {
	line-height: 20px;
	font-size: 16px;
	color: #17233d;
}
.tree_view {
	width: 100%;
	display: flex;
	.p_item {
		width: 20%;
		.title_p{
			color: #f57d22;
		}
		
		.tree_select{
			overflow: scroll;
			height: 600px;
		}
	}
}
</style>

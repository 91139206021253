<template>
	<Card class="col-right-card min-height">
		<p slot="title">回收商分配</p>
		<Form :label-width="120">
			<FormItem class="FromItemnpt" label="用户姓名"
			><Input clearable placeholder="请输用户姓名" v-model="search.real_name"
			/></FormItem>
			<FormItem class="FromItemnpt" label="手机号"
			><Input clearable placeholder="请输入手机号" v-model="search.mobile"
			/></FormItem>



	<FormItem class="FromItemnpt" label="关键词"  v-show="usertype == 1">
        <Input v-model="search.ogname" clearable placeholder="请选择机构">
            <Icon type="ios-search" slot="suffix" @click="insurance_org(1)"/>
        </Input>
       <a style="margin-left:5px;" @click="clearogid">清除选择</a>
      </FormItem> 

			<FormItem class="FromItemnpt" label="状态">
				<Select v-model="search.status" clearable>
					<Option
							v-for="(item, index) in userStatus"
							:value="index"
							:key="index"
					>{{ item }}</Option
					>
				</Select>
			</FormItem>
			<FormItem class="FromItemnpt" label="添加时间">
				<DatePicker
						style="width:180px;"
						type="daterange"
						:options="dateRange"
						@on-change="checkDate"
						v-model="timeshijian"
				></DatePicker>
			</FormItem>
			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>
		<Button
				class="marginbnt"
				@click="handleCreateModel"
				type="primary"
				v-show="button.add"
		>新增</Button
		>

		<!-- <Button
				class="marginbnt"
				@click="handleEdit(1)"
				type="primary"
				v-show="button.delete"
		>删除</Button> -->
		<Button
				class="marginbnt"
				@click="handleEdit(2)"
				type="primary"
				v-show="button.enable"
		>启用</Button
		>
		<Button
				class="marginbnt"
				@click="handleEdit(3)"
				type="primary"
				v-show="button.disabled"
		>禁用</Button
		>
		<!--
        <Button class="marginbnt"   v-show="button.edit" @click="handleGrade" type="primary">批量设置等级</Button> -->

		<Table
				height="500"
				border
				stripe
				:columns="canshu"
				:data="canshudata"
				@on-select="tableSelectionChange"
				@on-select-all="tableSelectionChange"
				@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page
					:total="totalCount"
					show-elevator
					show-sizer
					show-total
					@on-change="handlePage"
					@on-page-size-change="handlePageSize"
			/>
			<div style="height:30px;"></div>
		</div>
		<Modal v-model="showEdit" width="55%" :title="editTitle">
			<Form
					:label-width="120"
					ref="subData"
					:model="subData"
					:rules="dataRules"
			>
				<FormItem class="FromItemnpt" label="手机号" prop="mobile"
				><Input v-model="subData.mobile"
				/></FormItem>
                <FormItem class="FromItemnpt" label="登录账号" prop="username" ><Input v-model="subData.username"   /></FormItem>
				
			
				<FormItem class="FromItemnpt" label="密码" prop="password"
				><Input
						type="password"
						v-model="subData.password"
						placeholder="默认手机号后六位"
				/></FormItem>
                <FormItem class="FromItemnpt" label="姓名" prop="real_name"
                ><Input v-model="subData.real_name"
                /></FormItem>
				<FormItem class="FromItemnpt" label="用户邮箱" prop="email"
				><Input v-model="subData.email"
				/></FormItem>
				<FormItem class="FromItemnpt" label="QQ" prop="qq"
				><Input v-model="subData.qq"
				/></FormItem>
				<!--<FormItem class="FromItemnpt" label="用户推荐码" prop="referralCode"-->
				<!--&gt;<Input  placeholder="系统自动生成"  v-model="subData.referral_code"-->
				<!--/></FormItem>-->

				<FormItem class="FromItemnpt" label="工号" prop="job_no"
				><Input v-model="subData.job_no"
				/></FormItem>
				<FormItem class="FromItemnpt" label="性别" prop="sex">
					<RadioGroup v-model="subData.sex">
						<Radio label="0">不详</Radio>
						<Radio label="1">男</Radio>
						<Radio label="2">女</Radio>
					</RadioGroup>
				</FormItem>

				<FormItem class="FromItemnpt" label="状态" prop="status">
					<Select v-model="subData.status">
						<Option
								v-for="(item, index) in userStatus"
								:value="index"
								:key="index"
						>{{ item }}</Option
						>
					</Select>
				</FormItem>
				<Row>
					<FormItem  class="FromItemnpt org-width" label="所属机构" prop="ogname">
						<Input v-model="subData.ogname" :disabled=true >
						<Icon type="ios-search" slot="suffix" @click="insurance_org(2)"/>
						</Input>
						<a style="margin-left:5px;" @click="clear">清除</a>
					</FormItem>
				</Row>
				<FormItem class="FromItemnpt dizhi_width" label="地区" prop="cityCheck">
					<cityView
							ref="cityView"
							style="display: inline-block;"
							@selectFunc="selectFunc"
					></cityView>
				</FormItem>

				<FormItem label="用户备注" prop="remark"
				><Input type="textarea" v-model="subData.describe"
				/></FormItem>

			</Form>
			<div slot="footer">
				<Button @click="showEdit = false">取消</Button>
				<Button type="primary" @click="submitForm">保存</Button>
			</div>
		</Modal>

		<Modal v-model="showDetail" width="60%" title="详情">
			<ul class="listDetailUl_class">
				<!-- <li>
                            <span>头像</span>
                            <viewer style="display: inline-block;" :image="detailData.url" v-if="detailData.url">
                                <div class="yingimgs">
                                    <img :src="detailData.url" style="width: 60px;height: 60px;" class="upload-image-view" />
                                </div>
                            </viewer>
                        </li> -->

				<li>
					<span>用户名称</span>
					<span>{{ detailData.username }}</span>
				</li>
				<li>
					<span>工号</span>
					<span>{{ detailData.workNumber }}</span>
				</li>
				<li>
					<span>推荐码</span>
					<span>{{ detailData.referralCode }}</span>
				</li>

				<li>
					<span>QQ</span>
					<span>{{ detailData.qq }}</span>
				</li>
				<li>
					<span>邮箱</span>
					<span>{{ detailData.email }}</span>
				</li>

				<li>
					<span>状态</span>
					<span>{{ detailData.status_name }}</span>
				</li>
				<li class="width100">
					<span>联系地址</span>
					<span>{{ detailData.area_name }}-{{ detailData.address }}</span>
				</li>
				<li class="width100">
					<span>备注</span>
					<span>{{ detailData.remark }}</span>
				</li>

				<li>
					<span>手机号</span>
					<span>{{ detailData.mobile }}</span>
				</li>
				<li>
					<span>性别</span>
					<span>{{ detailData.sex_name }}</span>
				</li>
			</ul>
			<div slot="footer">
				<Button type="primary" @click="showDetail = false">确定</Button>
			</div>
		</Modal>



		<insurance_org
				ref="insurance_org"
				:initOption="chooseorgInitOption"
				@getorgdata="getChooseGY">
		</insurance_org>



	</Card>
</template>
<script>
    import insurance_org from '@/view/my-components/ditanorg/insurance_org';
    import * as util from "@/libs/util";
    import * as tools from "@/libs/tools";
    import config from "@/config";
    import cityView from "../mycomponents/cityView.vue";
    import * as Enum from "@/libs/enum";

    import { getMemberLevelDrop } from "@/api/common";
    import { getuserlist, getorhDrop,getUserAdd, getuserview,getUserEdit } from "@/api/admin";
    import { getUserStatus, getWhether } from "@/api/dictionary";
    export default {
        components: {
            cityView,
            insurance_org
        },
        // computed: {
        //   ...mapGetters([
        //     'userStatus'
        //   ]),

        //   },
        data() {
            const validateCityCheck = (rule, value, callback) => {
              //if (tools.isVisibleCity(this.subData.cityCheck)) {
            if(this.subData.province!=''&&this.subData.city!=''&&this.subData.district!=''){
                  callback();
              } else {
              	callback(new Error('请选择省市区'));
              }
            };
            const validatePwd = (rule, value, callback) => {
                callback();
            };

            return {
                handleData: {
                    type: "",
                    data: "",
                },
                 usertype:'1',// 1管理员  2保险公司 3外修商 4回收商 5物损


                chotype:1,//查询赋值，新增赋值
                map: {
                    keyword: "",
                    center: {
                        lng: 117.1195,
                        lat: 36.655164,
                    },
                    zoom: 15,
                    show: true,
                    dragging: true,
                },
                markmodel: false,
                lat: 0,
                lng: 0,

                dataRules: {
                    ogname: [
                        {
                            required: true,
                            message: "必填",
                            trigger: "blur",
                        },
                    ],
                    real_name: [
                        {
                            required: true,
                            message: "必填",
                            trigger: "blur",
                        },
                    ],
                    // username: [
                    //     {
                    //         required: true,
                    //         message: "必填",
                    //         trigger: "blur",
                    //     },
                    // ],
                    password: [
                        {
                            validator: validatePwd,
                            trigger: "blur",
                        },
                    ],
                   /* sex: [
                        {
                            required: true,
                            message: "必选",
                            trigger: "change",
                        },
                    ],*/
                    status: [
                        {
                            required: true,
                            message: "必选",
                            trigger: "change",
                        },
                    ],
                    mobile: [
                        {
                            required: true,
                            message: "必填",
                            trigger: "blur",
                        },
                        {
                            message: '格式有误',
                            trigger: 'blur',
                            pattern: /^1[3456789]\d{9}$/
                        }
                    ],

                    cityCheck: [
                        {
                            validator: validateCityCheck,
                            required: true,
                            trigger: "change",
                        },
                    ],
                },
                checkedIds: [],

                uploadHttp: "",
                access_token: "",
                defaultList: [],
                userInfoType: [],
                detailData: {},
                subData: {
                    type: Enum.ogtypeEnum.user_recycle, //
                    uid: '',        //传uid是编辑，不传是新增
                    mobile: '',     //手机号
                    username: '',  //用户账号
                    real_name: '',  //用户姓名
                    password:'',    //密码
                    ogid:'',        //机构id
                    ogname:'',      //机构名称
                    email:'',       //用户邮箱
                    qq:'',          //用户邮箱
                    job_no:'',      //工号
                    // referral_code:'',//用户推荐码
                    sex:'0',        //0保密 1男 2女
                    status: '1',    //状态
                    describe: '',   //用户备注
                    province:'',    //省
                    city:'',        //市
                    district: '' ,   //区
					cityCheck:[],
                },
                hyse: false,
                types: "",
                chooseorgInitOption: {
                    title: '',
                    showModal: false,
                },
                search: {
                    p: 1, // 当前页码
                    t: 10, // 每页显示条数
                    type: Enum.ogtypeEnum.user_recycle,

                    real_name: "",
                    status: "",
                    mobile: "",
                    stime: "",
                    etime: "",
                      ogname:'',
                    ogid:'',
                },
                timeshijian: [],
                button: {},
                userStatus: [],
                editTitle: "回收商用户新增",
                showEdit: false,
                showDetail: false,
                organizationList: [],

                memberLevelDrop: [], // 会员等级
                totalCount: 10,
                dateRange: {
                    shortcuts: [
                        {
                            text: "今日",
                            value() {
                                const end = new Date();
                                const start = new Date();
                                return [start, end];
                            },
                        },
                        {
                            text: "本周",
                            value() {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                return [start, end];
                            },
                        },
                        {
                            text: "本月",
                            value() {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                return [start, end];
                            },
                        },
                        {
                            text: "本季度",
                            value() {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                return [start, end];
                            },
                        },
                    ],
                },
                canshu: [
                    { type: "selection", width: 60, align: "center", fixed: "left" },
                    {
                        title: "姓名",
                        key: "real_name",
                        align: "center",
                        minWidth: 100,
                        fixed: "left",
                    },
                    { title: "手机号", key: "mobile", align: "center", minWidth: 130 },
                    { title: "账号", key: "user_name", align: "center", minWidth: 100 },
                  /*  { title: "E-mail", key: "email", align: "center", minWidth: 100 },*/
                    { title: "性别", key: "sex_str", align: "center", minWidth: 80 },
                    { title: "工号", key: "job_no", align: "center", minWidth: 100 },
                 
                    { title: "所属机构", key: "orgname", align: "center", minWidth: 100 },
                    { title: "区域", key: "area_name", align: "center", minWidth: 120 },
                    {
                        title: "添加时间",
                        key: "createtime",
                        align: "center",
                        minWidth: 100,
                    },
                    { title: "状态", key: "status_str", align: "center", minWidth: 100 },
                    {
                        title: "推荐码",
                        key: "referral_code",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "操作",
                        key: "action",
                        width: 100,
                        align: "center",
                        fixed: "right",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "primary",
                                            size: "small",
                                            disabled: !this.button.edit,
                                        },
                                        style: {
                                            marginRight: "5px",
                                             display:this.usertype==1?'':'none'
                                        },
                                        on: {
                                            click: () => {
                                                this.handleUpdateModal(params.row.id);
                                            },
                                        },
                                    },
                                    "编辑"
                                ),
                                h(
                                'Button',
                                {
                                    props: {
                                        type: 'warning',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            //this.coverarea(params.row);
                                            	this.matcharea(params.row);
                                        }
                                    }
                                },
                                '区域分配'
                            )
                                // h(
                                //   "Button",
                                //   {
                                //     props: {
                                //       type: "primary",
                                //       size: "small",
                                //     },
                                //     style: {
                                //       marginRight: "5px",
                                //     },
                                //     on: {
                                //       click: () => {
                                //         this.handleDetailModal(params.row.id);
                                //       },
                                //     },
                                //   },
                                //   "查看"
                                // ),
                            ]);
                        },
                    },
                ],
                canshudata: [],
            };
        },
        methods: {
            clear() {
                this.subData.ogid = '';
                this.subData.ogname = '';
            },
  clearogid(){

            	console.log('------clearogid--------');
            	this.search.ogid = 0;
                	this.search.ogname ='';
            },
            insurance_org (ctype) {
            	this.chotype=ctype;
                this.chooseorgInitOption.title = '选择回收商';
                this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.recovery_business;
                this.chooseorgInitOption.showModal = true;
                this.$refs.insurance_org.handleSearch()
            },
            getChooseGY (item) {
                // console.log(item);
                this.chooseorgInitOption.showModal = false
                 if (this.chotype==1) {
                	this.search.ogid = item.id;
                	this.search.ogname = item.name;
                }else{
                this.subData.ogid = item.id;
                this.subData.ogname = item.name;
            	}
            },
            //所属机构
            getinsuranceorg() {
                let params = {
                    ogtype: Enum.ogtypeEnum.recovery_business
                };
                getorhDrop(params).then(res => {
                    let resdata = res.data;
                    // resdata.unshift({
                    //     value: 0,
                    //     label: '顶级机构',
                    //     children: []
                    // });
                    this.orgdata = resdata;
                });
            },
            changeparent(value, selectedData){
                this.subData.ogname=selectedData[selectedData.length-1].label;
                this.subData.ogid=selectedData[selectedData.length-1].value;
                this.subData.ogid_arr=value;
            },
            getUserInfoType() {
                //
                getWhether().then((res) => {
                    this.userInfoType = res.data;
                });
            },
            tableSelectionChange(data2) {
                if (data2[0] != undefined) {
                    this.checkedId = data2[0].id;
                }
                let ids = [];
                data2.map(function(item, key) {
                    ids.push(item.id);
                });
                this.checkedIds = ids;
            },
            markmap() {
                //标注
                this.markmodel = true;
                if (this.subData.lng) {
                    this.map.center.lng = this.subData.lng;
                    this.map.center.lat = this.subData.lat;
                }
            },
            handleEdit(type) {
                if (this.checkedIds.length > 0) {
                    let params = {};
                    params.uid = this.checkedIds;
                    params.type = type;

                    if (type == 1) {
                        this.$Modal.confirm({
                            title: "确认信息",
                            content: "<p>你确定要将选中的信息设置为删除状态么？?</p>",
                            onOk: () => {
                                this.sysEdit(params);
                            },
                            onCancel: () => {
                                this.$Message.info("您取消了删除操作");
                            },
                        });
                    } else if (type == 2) {
                        this.$Modal.confirm({
                            title: '确认信息',
                            content: '<p>你确定要将选中的用户设置为启用状态么？?</p>',
                            onOk: () => {
                                this.sysEdit(params);
                            },
                            onCancel: () => {
                                this.$Message.info('您取消了操作');
                            }
                        });
                    } else if (type == 3) {
                        this.$Modal.confirm({
                            title: '确认信息',
                            content: '<p>你确定要将选中的用户设置为禁用状态么？?</p>',
                            onOk: () => {
                                this.sysEdit(params);
                            },
                            onCancel: () => {
                                this.$Message.info('您取消了操作');
                            }
                        });
                    }
                } else {
                    this.$Modal.info({
                        title: "提示",
                        content: "请至少选择一项",
                    });
                }
            },

            handleGrade() {
                // 批量设置等级
                if (this.checkedIds.length > 0) {
                    this.hyse = true;
                } else {
                    this.$Modal.info({
                        title: "提示",
                        content: "请至少选择一项",
                    });
                }
            },

            handleSubmit(type) {
                let params = {};
                params.id = this.checkedIds;
                params.type = type;
                params.data = this.handleData.levelData;
                this.sysEdit(params);
            },

            sysEdit(params) {
                console.log(params);
                getUserEdit(params).then((res) => {
                    if (res.errCode == 0) {
                        this.$Notice.success({
                            title: "提示",
                            desc: "操作成功",
                        });
                        this.fzse = false;
                        this.hyse = false;
                        this.checkedIds = [];
                        this.handleSearch();
                    } else {
                        this.$Notice.error({
                            title: "提示",
                            desc: res.errMsg,
                        });
                    }
                });
            },

            selectFunc(cityObj) {
				this.subData.cityCheck = cityObj;
                this.subData.province = cityObj.provinceModel;
                this.subData.city = cityObj.cityModel;
                this.subData.district = cityObj.districtModel;
            },

            getUserStatus() {
                getUserStatus().then((res) => {
                    this.userStatus = res.data;
                });
            },
            confirmlnglat() {
                this.subData.lng = this.lng;
                this.subData.lat = this.lat;
                this.$Notice.success({
                    title: "提示",
                    desc: "选择成功",
                });
                this.markmodel = false;
            },

            getMemberLevelDrop() {
                let params = {
                    type: "1",
                };
                getMemberLevelDrop(params).then((res) => {
                    this.memberLevelDrop = res.data;
                });
            },

            // 日期
            checkDate(date) {
                this.search.stime = date[0];
                this.search.etime = date[1];
            },
            // 查询
            handleSearchAction() {
                this.search.p = 1;
                this.search.is_export = 0;
                this.handleSearch();
            },

            
	        coverarea(val) {
	            // console.log('result', val);
	            // alert(222222);
	            let query = { ogdata: val, type: 'recover',title:'用户' };
	            this.$router.push({
	                name: 'cover_user_area',
	                query: query
	            });
	        },

            matcharea(val) {
				console.log('result', val);
				let query = { ogdata: val, type: 'recover',title:'回收商' };
				this.$router.push({
					name: 'rec_bussiness_area',
					query: query
				});
			},

            handler({ BMap, map }) {
                let me = this;
                console.log(BMap, map);
                // 鼠标缩放

                map.enableScrollWheelZoom(true);
                // 点击事件获取经纬度
                map.addEventListener("click", function(e) {
                    me.subData.lng = e.point.lng;
                    me.subData.lat = e.point.lat;
                    me.lng = e.point.lng;
                    me.lat = e.point.lat;
                    console.log(e.point.lng, e.point.lat);
                });
            },
            // 分页
            handlePage(value) {
                this.search.p = value;
                this.handleSearch();
            },
            handlePageSize(value) {
                this.search.t = value;
                this.handleSearch();
            },
            // 列表
            handleSearch() {
                let params = this.search;
                // getMember(params).then(res => {
                getuserlist(params).then((res) => {
                    console.log("getuserlist", res);
                    if (res.errCode == 0) {
                        this.totalCount = parseInt(res.data.total);
                        this.canshudata = res.data.list;
                        this.button = res.data.button;
                    }
                });
            },
            reset() {
                this.$refs.subData.resetFields();
            },
            // 新增
            handleCreateModel() {
                this.reset();
                this.subData.ogid = '';
                this.subData.ogname = '';
                this.$refs.cityView.viewdata.provinceModel = "";
                this.$refs.cityView.viewdata.cityModel = "";
                this.$refs.cityView.viewdata.districtModel = "";
                this.showEdit = true;
                this.editTitle = "回收商用户新增";
            },

            handleUpdateModal(data) {
                this.viewData(data);
                this.editTitle = "回收商用户修改";
                this.showEdit = true;
            },

            handleDetailModal(data) {
                getuserview({
                    uid: data,
                }).then((res) => {
                    this.detailData = res.data;
                    this.showDetail = true;
                });
            },

            viewData(uid) {
                getuserview({
                    uid: uid,
                }).then((res) => {
                    this.subData = res.data;
                    let that = this;
                    setTimeout(function() {
                        that.$refs.cityView.viewdata.provinceModel = res.data.province;
                        that.$refs.cityView.viewdata.cityModel = res.data.city;
                        that.$refs.cityView.viewdata.districtModel = res.data.district;
                    }, 150);
                });
            },

            submitForm() {
                // 保存
                this.$refs.subData.validate((valid) => {
                    var that=this
                    if (valid) {
                        that.subData.type=4;
                       // that.subData.username= that.subData.mobile;
                        let params = this.subData;
                        getUserAdd(params).then((res) => {
                            if (res.errCode == 0) {
                                this.showEdit = false;
                                this.handleSearch();
                                this.reset();
                                this.$Modal.success({
                                    title: "提示",
                                    content: "提交成功",
                                });
                            } else {
                                this.$Modal.error({
                                    title: "提示",
                                    content: res.errMsg,
                                });
                            }
                        });
                    }
                });
            },
            handleError(data1, data2, data3) {
                // error file fileList
                this.$Modal.error({
                    title: "提示",
                    content: "上传失败",
                });
            },
            handleFormatError(data1, data2, data3) {
                // file fileList
                this.$Modal.error({
                    title: "提示",
                    content: "文件格式不正确",
                });
            },
            handleMaxSize(data1, data2) {
                // file fileList
                this.$Modal.error({
                    title: "提示",
                    content: "文件太大",
                });
            },
            handleSuccess(data1, data2, data3) {
                // response file fileList    图标
                if (data1.errCode === 0) {
                    this.$Notice.success({
                        title: "提示",
                        desc: "上传成功",
                    });
                    this.defaultList = [];
                    this.subData.avatar = data1.data[0].pic; // 图片上传地址
                    this.subData.url = data1.data[0].url; // 图片域名地址
                } else {
                    this.$Notice.error({
                        title: "提示",
                        desc: res.errMsg,
                    });
                }
            },
            getDuration(date) {
                // how many days of this month
                let dt = new Date();
                var month = dt.getMonth();
                dt.setMonth(dt.getMonth() + 1);
                dt.setDate(0);
                return dt.getDate();
            },
            GetDate(is) {
                var now = new Date();
                var year = now.getFullYear(); //年
                var month = now.getMonth() + 1; //月
                // var day = now.getDate();            　　//日
                if (is) {
                    return year + "-" + month + "-" + is;
                } else {
                    return year + "-" + month + "-1";
                }
            },
        },
        mounted() {
            switch (this.$route.query.type) {
                case 1:
                    var mun = this.getDuration();
                    this.search.status = "1";
                    this.search.stime = this.GetDate(false);
                    this.search.etime = this.GetDate(false);
                    this.timeshijian = [this.GetDate(false), this.GetDate(false)];
                    break;
                case 2:
                    break;
            }

            this.getinsuranceorg();//获取保险公司机构下拉
            this.getUserStatus();
            this.getMemberLevelDrop();
            this.getUserInfoType();
            this.handleSearch();
            this.usertype=util.getUserType();
            this.uploadHttp =
                process.env.NODE_ENV === "development"
                    ? config.uploadImgUrl.dev
                    : config.uploadImgUrl.pro;
            this.access_token = util.getToken();
        },
    };
</script>

<style lang="less">
	@import "../style2.less";
	@import "../gongye.less";

	.dizhiselecls {
		width: 342px;
		margin-right: 3px;
	}

	.map {
		width: 100%;
		height: 400px;
	}

	.map .search {
		margin-bottom: 65px;
	}
	.org-width {
		width: 455px !important;
	}
	.userfnpt {
		width: 300px;
		margin-right: 3%;
		display: inline-block;
	}
</style>

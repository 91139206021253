<template>
    <div class="main" id="money">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Array
    },
  },
  data(){
    return{
      data1:[],
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getmoney()
    },
    getmoney(){

      var chartDom = document.getElementById('money');
      var myChart = echarts.init(chartDom,{});
          myChart.resize();
      var option;

      option = {
        title: {
          text: '外修金额',
          left:"center",
          top:"10%",
          textStyle:{//标题内容的样式
            color:'#66FFFf',
            fontStyle:'normal',//主标题文字字体风格，默认normal，有italic(斜体),oblique(斜体)
            fontWeight:"400",//可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
            fontFamily:" Microsoft YaHei",//主题文字字体，默认微软雅黑
            fontSize:12//主题文字字体大小，默认为18px

          },
        },
        tooltip: {
          trigger: 'item',
          position:'right'
        },


        color: ["#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE","#3BA272","#FC8452",'#9A60B4','#EA7CCC','#75BE9C'],
        series: [
          {
            name: '外修金额',
            type: 'pie',
            center:['50%','57%'],
            radius: ['30%', '55%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 3,
              borderColor: '#02004d',
              borderWidth: 1
            },
            label: {
              color:'#66FFFf',
              normal: {
                formatter(v) {
                  let text = v.name;
                  if (text.length <= 8) {
                    return text;
                  } else if (text.length > 8 && text.length <= 16) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(8)}`);
                  } else if (text.length > 16 && text.length <= 24) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16)}`);
                  } else if (text.length > 24 && text.length <= 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(24)}`);
                  } else if (text.length > 30) {
                    return (text = `${text.slice(0, 8)}\n${text.slice(
                        8,
                        16
                    )}\n${text.slice(16, 24)}\n${text.slice(
                        24,
                        30
                    )}\n${text.slice(30)}`);
                  }
                },
                textStyle: {
                  fontSize: 8,
                  //显示文字的大小，
                  color: "#66FFFf",
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '10',
                fontWeight: 'bold',
                color:'#66FFFf',

              }
            },
            labelLine: {
              lineStyle: {
                color:'#66FFFf',
              },
              smooth: 0.2,
              length:5,
              length2: 5
            },
            // data: [
            //   { value: 1048, name: '喷漆' },
            //   { value: 735, name: '前保险杠' },
            //   { value: 580, name: '发动机' },
            //   { value: 484, name: '轮胎' },
            //   { value: 300, name: '钣金' }
            // ],
            data: this.data1
          }
        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getmoney()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}
</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

<template>
  <Card class="col-right-card min-height">
    <!-- <div class="list"> -->
    <p slot="title">清单</p>
    <Form :label-width="120">

      <FormItem class="FromItemnpt" label="物损商">
        <Input v-model="search.appoint_org_id_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="search_wxs_org" />
        </Input>
      </FormItem>
      <FormItem class="FromItemnpt" label="保险公司">
        <Input v-model="search.bx_ogname" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="search_insurance_org" />
        </Input>
      </FormItem>
      <FormItem class="FromItemnpt" label="提交人">
        <Input v-model="search.createname" ></Input>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
<!--      <Button type="primary" >导出</Button>-->
      <!--      @click="exportAction"-->
    </Form>
    <div class="gray-nona"></div>
    <Button class="marginbnt"  type="primary" @click="handleCreateModel" v-show="button.add">新增</Button>
    <Button class="marginbnt"  type="primary" v-show="button.delete" @click="handleEdit('1')">删除</Button>
    <Table
        height="500"
        border
        stripe
        :columns="columsQingdan"
        :data="dataQingdan"
        @on-select="handleCheckedOrDeleteTable"
        @on-select-all="handleCheckedOrDeleteTable"
        @on-select-cancel="handleCheckedOrDeleteTable"
        @on-select-all-cancel="handleCheckedOrDeleteTable"
    ></Table>
    <!-- </div> -->

    <div class="my-page">
      <Page
          :total="totalCount"
          show-elevator
          show-sizer
          show-total
          @on-change="handlePage"
          @on-page-size-change="handlePageSize"
      />
      <div style="height: 30px"></div>
    </div>

    <insurance_org ref="insurance_org" :initOption="chooseorgInitOption" @getorgdata="getChooseGY"></insurance_org>

    <Modal v-model="showEdit" width="55%" :title="editTitle">
      <Form :label-width="120" ref="subData" :model="subData" :rules="dataRules">

        <FormItem  class="FromItemnpt org-width" label="保险机构" prop="bx_ogname">
          <Input v-model="subData.bx_ogname" :disabled=true >
            <Icon type="ios-search" slot="suffix" @click="insurance_org"/>
          </Input>
        </FormItem>
        <FormItem  class="FromItemnpt org-width" label="物损商" prop="appoint_org_id_name">
          <Input v-model="subData.appoint_org_id_name" :disabled=true >
            <Icon type="ios-search" slot="suffix" @click="appoint_org"/>
          </Input>
        </FormItem>

        <!-- <FormItem class="FromItemnpt" label="提交人姓名" prop="createname"><Input  disabled  v-model="subData.createname" /></FormItem> -->

      </Form>
      <div slot="footer">
        <Button @click="showEdit = false">取消</Button>
        <Button type="primary" @click="submitForm">保存</Button>
      </div>
    </Modal>
  </Card>
</template>

<script>
import insurance_org from "@/view/my-components/ditanorg/insurance_org";
import * as Enum from "@/libs/enum";
import { getOrgAppointWsAdd, getOrgAppointWsList ,getOrgAppointWsEdit} from '@/api/admin';

export default {
  name: "org_appoint_ws_list",
  components: {
    insurance_org,
  },
  data() {
    return {
      checkedIds: [],
      dataRules: {
        rep_name: [{required: true, message: '必填', trigger: 'blur'}],
        bx_ogname: [{required: true, message: '必填', trigger: 'blur'}],
        appoint_org_id_name: [{required: true, message: '必填', trigger: 'blur'}],
      },
      subData: {
        id: '',
        bx_ogid: '',
        bx_ogname: '',
        ogtype: '5',//2外修商  4回收商  5物损
        appoint_org_id: '',
        appoint_org_id_name: '',
        createname: '',
      },
      editTitle: '新增',
      showEdit: false,
      button: {},
      search: {
        p: 1,
        t: 10,
        ogtype: 5,//2外修商  4回收商  5物损
        rep_id: '',
        rep_name: '',
        bx_ogid: '',
        bx_ogname: '',
        appoint_org_id: '',
        appoint_org_id_name: '',
        createname: '',
      },
      totalCount: 10,
      dataQingdan: [],
      columsQingdan: [
        { type: 'selection', align: 'center', width: 60 },
        {title: "外修商", key: "appoint_org_id_str", align: "center", minWidth: 150,},
        {title: "保险公司", key: "bx_ogid_str", align: "center", minWidth: 100,},
        {title: "提交人姓名", key: "createname", align: "center", minWidth: 100,},
        {title: "创建时间", key: "createtime_str", align: "center", minWidth: 100,},
      ],
      chooseorgInitOption: {
        title: "",
        showModal: false,
      },
      dateRange: {
        shortcuts: [
          {
            text: '今日',
            value() {
              const end = new Date();
              const start = new Date();
              return [start, end];
            }
          },
          {
            text: '本周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '本月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '本季度',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
    };
  },
  methods: {
    // 删除
    handleEdit (type) {
      if (this.checkedIds.length > 0) {
        let txt = ''
        if (type == '1') {
          txt = '删除'
        } else if (type == '2') {
          txt = '启用'
        } else {
          txt = '禁用'
        }
        let params = {}
        params.id = this.checkedIds
        params.type = type
        this.$Modal.confirm({
          title: '确认信息',
          content: '<p>你确定要将选中的信息设置为'+txt+'状态么？?</p>',
          onOk: () => {
            this.getDeleteWrite(params)
          },
          onCancel: () => {
            this.$Message.info('您取消了删除操作')
          }
        })
      } else {
        this.$Modal.info({ title: '提示', content: '请至少选择一项' })
      }
    },
    getDeleteWrite (params) {
      getOrgAppointWsEdit(params).then(res => {
        if (res.errCode == 0) {
          this.handleSearch()
          this.checkedIds = []
          this.$Notice.success({
            title: '提示',
            desc: '操作成功'
          })
        } else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      })
    },
    //新增编辑 选择机构
    getChooseGY(item) {
      // console.log(item)
      this.chooseorgInitOption.showModal = false;
      if (this.showEdit) {
        if (item.ogtype == 1) {
          this.subData.rep_id = item.id;
          this.subData.rep_name = item.name;
        } else if (item.ogtype == 3) {
          this.subData.bx_ogid = item.id;
          this.subData.bx_ogname = item.name;
        }else if (item.ogtype == 5) {
          this.subData.appoint_org_id = item.id;
          this.subData.appoint_org_id_name = item.name;
        }
      }else {
        if (item.ogtype == 1) {
          this.search.rep_id = item.id;
          this.search.rep_name = item.name;
        } else if (item.ogtype == 3) {
          this.search.bx_ogid = item.id;
          this.search.bx_ogname = item.name;
        }else if (item.ogtype == 5) {
          this.search.appoint_org_id = item.id;
          this.search.appoint_org_id_name = item.name;
        }
      }
    },
    //选择机构输入框
    insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    appoint_org() {
      this.chooseorgInitOption.title = "选择物损商";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.user_thing;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    submitForm() {
      var that=this
      this.$refs.subData.validate(valid => {
        if (valid) {
          let params = that.subData;
          getOrgAppointWsAdd(params).then(res => {
            if (res.errCode == 0) {
              that.showEdit = false;
              that.handleSearch();
              that.reset();
              that.$Modal.success({
                title: '提示',
                content: '提交成功'
              });
            } else {
              that.$Modal.error({
                title: '提示',
                content: res.errMsg
              });
            }
          });
        }
      });
    },
    // 新增
    handleCreateModel() {
      this.subData.id= '';
      this.subData.bx_ogid= '';
      this.subData.bx_ogname= '';
      this.subData.appoint_org_id= '';
      this.subData.appoint_org_id_name= '';
      this.subData.createname= '';
      this.showEdit = true;
      this.editTitle = '新增';
    },
    //选择机构输入框
    search_insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    search_wxs_org() {
      this.chooseorgInitOption.title = "选择外修商";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.org_thing;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    // 日期
    checkDate(date) {
      this.search.stime = date[0];
      this.search.etime = date[1];
    },
    handleCheckedOrDeleteTable (data1) {
      if (data1[0]) {
        this.checkedId = data1[0].id
      }
      let ids = []
      data1.map(function (item, key) {
        ids.push(item.id)
      })
      this.checkedIds = ids
    },



    // 分页
    handlePage(value) {
      this.search.p = value;
      this.handleSearch();
    },
    handlePageSize(value) {
      this.search.t = value;
      this.handleSearch();
    },
    // 列表
    handleSearch() {
      let params = this.search;
      getOrgAppointWsList(params).then((res) => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.dataQingdan = res.data.list;
          this.button = res.data.button
        }
      });
    },
    // 查询
    handleSearchAction() {
      this.search.p = 1;
      this.search.is_export = 0;
      this.handleSearch();
    },
  },
  mounted() {
    this.handleSearch();
  }
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.dizhiselecls {
  width: 342px;
  margin-right: 3px;
}

.map {
  width: 100%;
  height: 400px;
}

.map .search {
  margin-bottom: 65px;
}

.userfnpt {
  width: 300px;
  margin-right: 3%;
  display: inline-block;
}
</style>

<template>
	<Card class="col-right-card min-height">
		<p slot="title">开标规则</p>
		<Form :label-width="120">
			<FormItem class="FromItemnpt" label="关键词"><Input clearable placeholder="保险公司编号|名称" v-model="search.kw" /></FormItem>
			<Button type="primary" @click="handleSearchAction">查询</Button>
		</Form>
		<div class="gray-nona"></div>
		<Button class="marginbnt" @click="handleCreateModel" type="primary" v-show="button.add">新增</Button>
		<Button class="marginbnt" @click="handleEdit(1)" type="primary" v-show="button.delete">删除</Button>

		<Table
				height="500"
				border
				stripe
				:columns="canshu"
				:data="canshudata"
				@on-select="tableSelectionChange"
				@on-select-all="tableSelectionChange"
				@on-select-cancel="tableSelectionChange"
		></Table>

		<div class="my-page">
			<Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage" @on-page-size-change="handlePageSize" />
			<div style="height:30px;"></div>
		</div>

		<Modal v-model="showEdit" width="40%" :title="editTitle">
			<Form :label-width="120" ref="subData" :model="subData" :rules="dataRules">
				<Row>
					<!--<FormItem class="FromItemnpt  org-width" label="保险机构" prop="parentname">-->
						<!--<Cascader :data="bxorgdata" v-model="subData.bxorgid_arr" change-on-select @on-change='bxchangeparent'></Cascader>-->
					<!--</FormItem>-->

					<FormItem v-show="usertype == 1" class="FromItemnpt org-width" label="上级机构" prop="bx_ogname">
						<Input v-model="subData.bx_ogname" :disabled=true >
						    <Icon type="ios-search" slot="suffix" @click="insurance_org"/>
                        </Input>
						<a style="margin-left:5px;" @click="clear">清除</a>
					</FormItem>
				</Row>
				<Row>
					<FormItem class="FromItemnpt" label="外修开标时间" prop="bid_value"><Input v-model="subData.bid_value" />（小时）</FormItem>
				</Row>
                  <Row><FormItem class="FromItemnpt" label="外修开标跳过"> 
                    <TimePicker type="time"  v-model="subData.stime"    placement="bottom-end" placeholder="开始时间" style="width: 60px"></TimePicker>
                    <TimePicker type="time"  v-model="subData.etime"    placement="bottom-end" placeholder="结束时间" style="width: 60px"></TimePicker>
                  </FormItem></Row>
                <Row>
                    <FormItem class="FromItemnpt" label="开启整单竞价">
                        <RadioGroup v-model="subData.zdjj">
                            <Radio :label="0">否</Radio>
                            <Radio :label="1">是</Radio>
                        </RadioGroup>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem class="FromItemnpt" label="整单竞价配件分类">
                        <CheckboxGroup v-model="subData.zdjj_part_type">
                            <Checkbox v-for="part in zdjjPartType" :label="part.id" :key="part.id">{{ part.name }}</Checkbox>
                        </CheckboxGroup>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem class="FromItemnpt" label="整单竞价开标时间">
                        <Input v-model="subData.zdjj_value" />（小时）
                    </FormItem>
                </Row>
                <Row>
                    <FormItem class="FromItemnpt" label="整单竞价开标跳过"> 
                        <TimePicker type="time"  v-model="subData.zdjj_stime"    placement="bottom-end" placeholder="开始时间" style="width: 60px"></TimePicker>
                        <TimePicker type="time"  v-model="subData.zdjj_etime"    placement="bottom-end" placeholder="结束时间" style="width: 60px"></TimePicker>
                    </FormItem>
                </Row>

                <Row>
                  <FormItem class="FromItemnpt" label="开启外修随机竞价">
                    <RadioGroup v-model="subData.yxjj">
                      <Radio :label="0">否</Radio>
                      <Radio :label="1">是</Radio>
                    </RadioGroup>
                  </FormItem>
                </Row>
                <Row>
                  <FormItem class="FromItemnpt" label="外修随机竞价配件分类">
                    <CheckboxGroup v-model="subData.yxjj_part_type" style="margin-left: 40px;">
                      <Checkbox v-for="part in zdjjPartType" :label="part.id" :key="part.id">{{ part.name }}</Checkbox>
                    </CheckboxGroup>
                  </FormItem>
                </Row>
                <Row>
                  <FormItem class="FromItemnpt" label="外修随机竞价一询时间">
                    <Input v-model="subData.yxjj_yx_value" style="margin-left: 40px;"/>（小时）
                  </FormItem>
                </Row>
                <Row>
                  <FormItem class="FromItemnpt" label="外修随机竞价抢单时间">
                    <Input v-model="subData.yxjj_qd_value" style="margin-left: 40px;"/>（小时）
                  </FormItem>
                </Row>
                <Row>
                  <FormItem class="FromItemnpt" label="外修随机竞价开标跳过">
                    <TimePicker type="time"  v-model="subData.yxjj_stime"    placement="bottom-end" placeholder="开始时间" style="margin-left: 40px;width: 60px"></TimePicker>
                    <TimePicker type="time"  v-model="subData.yxjj_etime"    placement="bottom-end" placeholder="结束时间" style="width: 60px"></TimePicker>
                  </FormItem>
                </Row>
                
				<Row>
					<FormItem class="FromItemnpt" label="中标规则" prop="type">
						<Select v-model="subData.type">
							<Option v-for="(item, index) in orgstatus" :value="index" :key="index">{{ item }}</Option>
						</Select>
					</FormItem>
				</Row>

              <Row><FormItem class="FromItemnpt" label="自动审核">
                    <RadioGroup v-model="subData.autoaudit">
                        <Radio label="2">否</Radio>
                        <Radio label="1">是</Radio>
                        <Radio label="3">协议审核</Radio>
                    </RadioGroup>
                    </FormItem>
              </Row>
                <Row v-if="subData.autoaudit==3">
					<FormItem class="FromItemnpt" label="审核依据">
						<Select v-model="subData.agree_type">
                            <Option :value="1">区间实际值</Option>
							<Option :value="2">区间上限值</Option>
						</Select>
					</FormItem>
				</Row>
                <Row v-if="subData.autoaudit==3 && agreements.length>0">
					<FormItem class="FromItemnpt" label="请选择协议">
						<Select v-model="subData.agree_id">
							<Option v-for="item in agreements" :value="parseInt(item.id,10)" :key="item.id">{{ item.name }}</Option>
						</Select>
					</FormItem>
				</Row>
                <Row>	<FormItem class="FromItemnpt" label="物损开标时间" ><Input v-model="subData.bid_value_ws" />（小时）</FormItem></Row>
                <Row>	<FormItem class="FromItemnpt" label="一询商开标时间" ><Input v-model="subData.bid_value_ws_first" />（小时）</FormItem></Row>
              
                <Row><FormItem class="FromItemnpt" label="物损开标跳过">
                    <TimePicker type="time"  v-model="subData.stime_ws"    placement="bottom-end" placeholder="开始时间" style="width: 60px"></TimePicker>
                    <TimePicker type="time"  v-model="subData.etime_ws"    placement="bottom-end" placeholder="结束时间" style="width: 60px"></TimePicker>
                    
                    </FormItem>
                    </Row>




			</Form>
			<div slot="footer">
				<Button @click="showEdit = false">取消</Button>
				<Button type="primary" @click="submitForm">保存</Button>
			</div>
		</Modal>



		<insurance_org
				ref="insurance_org"
				:initOption="chooseorgInitOption"
				@getorgdata="getChooseGY">
		</insurance_org>
	</Card>
</template>
<script>

    import insurance_org from '@/view/my-components/ditanorg/insurance_org';
    import * as util from '@/libs/util';
    import cityView from '../mycomponents/cityView.vue';
    import {getMemberLevelDrop} from '@/api/common';
    import {getWhether,getOrgbidType} from '@/api/dictionary';
    import * as Enum from '@/libs/enum';

    import {
        getZhongbiaoList,
        agreementAll,
        getZhongbiaoAdd, getZhongbiaoEdit,getZhongbiaoView
    } from '@/api/admin';

    export default {
        components: {
            cityView,
            insurance_org
        },
        data() {
            return {
                zdjjPartType: [{
                    'id': '1',
                    'name': '钣金件'
                },
                {
                    'id': '2',
                    'name': '机修件'
                },
                {
                    'id': '3',
                    'name': '电器件'
                },
                {
                    'id': '4',
                    'name': '玻璃件'
                },
                {
                    'id': '5',
                    'name': '总成件'
                }],
                usertype:'2',// 1管理员  2保险公司 3外修商 4回收商 5物损
                curogid:'0',
                handleData: {
                    type: '',
                    data: ''
                },

                dataRules: {
                    // bx_ogname: [
                    //     {
                    //         required: true,
                    //         message: '必填',
                    //         trigger: 'blur'
                    //     }
                    // ],
                    bid_value: [
                        {
                            required: true,
                            message: '必填',
                            trigger: 'blur'
                        }
                    ],
                    type: [
                        {
                            required: true,
                            message: '必填',
                            trigger: 'blur'
                        }
                    ],
                },
                checkedIds: [],

                uploadHttp: '',
                access_token: '',
                defaultList: [],
                userInfoType: [],
                detailData: {},
                subData: {
                    id:'',
                    bx_ogid:0,
                    bx_ogname:'',
                    type:0,
                    bid_value:'', //开标时间
                    stime_str:'',
                    autoaudit:'',
                    bid_value_ws:'',
                    bid_value_ws_first:'',
                    stime:'',
                    etime:'',
                    stime_ws:'',
                    etime_ws:'',
                    stime_ws_str:'',
                    zdjj:'',
                    zdjj_part_type:[],
                    zdjj_value:'',
                    zdjj_stime:'',
                    zdjj_etime:'',
                    yxjj:'',
                    yxjj_part_type:[],
                    yxjj_yx_value:'',
                    yxjj_qd_value:'',
                    yxjj_stime:'',
                    yxjj_etime:'',
                    agree_id:0,
                    agree_type:0,
                },
                orgstatus: [],
                hyse: false,
                types: '',
                chooseorgInitOption: {
                    title: '',
                    showModal: false,
                },
                search: {
                    p: 1, // 当前页码
                    t: 10, // 每页显示条数
                    kw:null,
                },
                timeshijian: [],
                button: {},
                userStatus: [],
                editTitle: '外修商机构区域新增',
                showEdit: false,
                showDetail: false,

                memberLevelDrop: [], // 会员等级
                totalCount: 10,
                agreements:[],
                dateRange: {
                    shortcuts: [
                        {
                            text: '今日',
                            value() {
                                const end = new Date();
                                const start = new Date();
                                return [start, end];
                            }
                        },
                        {
                            text: '本周',
                            value() {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                return [start, end];
                            }
                        },
                        {
                            text: '本月',
                            value() {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                return [start, end];
                            }
                        },
                        {
                            text: '本季度',
                            value() {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                return [start, end];
                            }
                        }
                    ]
                },
                canshu: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center',
                        fixed: 'left'
                    },
                    {
                        title: '保险公司编号',
                        key: 'code',
                        align: 'center',
                        minWidth: 60,
                        fixed: 'left'
                    },

                    {
                        title: '保险公司名称',
                        key: 'bxname',
                        align: 'center',
                        minWidth: 60,
                        fixed: 'left'
                    },
                    {
                        title: '外修开标时间(小时)',
                        key: 'bid_value',
                        align: 'center',
                        minWidth: 60
                    },
                     {
                        title: '外修自动审核',
                        key: 'autoaudit_str',
                        align: 'center',
                        minWidth: 60
                    },
                       {
                        title: '外修开标跳过',
                        key: 'stime_str',
                        align: 'center',
                        minWidth: 60
                    },
                    {
                        title: '中标规则',
                        key: 'type_str',
                        align: 'center',
                        minWidth: 60
                    },

                     {
                        title: '物损开标时间(小时)',
                        key: 'bid_value_ws',
                        align: 'center',
                        minWidth: 60
                    },
                     {
                        title: '一询商开标时间(小时)',
                        key: 'bid_value_ws_first',
                        align: 'center',
                        minWidth: 60
                    },
                  
                      {
                        title: '物损开标跳过',
                        key: 'stime_ws_str',
                        align: 'center',
                        minWidth: 60
                    },
                
                    {
                        title: '添加时间',
                        key: 'createtime',
                        align: 'center',
                        minWidth: 60
                    },
                    {
                        title: '添加人',
                        key: 'createuser',
                        align: 'center',
                        minWidth: 60
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 170,
                        align: 'center',
                        fixed: 'right',
                        render: (h, params) => {
                            return h('div', [
                                h(
                                    'Button',
                                    {
                                        props: {
                                            type: 'primary',
                                            size: 'small',
                                            disabled: !this.button.edit
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.handleUpdateModal(params.row.id);
                                            }
                                        }
                                    },
                                    '编辑'
                                )
                                // h(
                                //     'Button',
                                //     {
                                //         props: {
                                //             type: 'primary',
                                //             size: 'small'
                                //         },
                                //         style: {
                                //             marginRight: '5px'
                                //         },
                                //         on: {
                                //             click: () => {
                                //                 this.handleDetailModal(params.row.id);
                                //             }
                                //         }
                                //     },
                                //     '查看'
                                // )
                            ]);
                        }
                    }
                ],
                canshudata: []
            };
        },
        methods: {
            //获取能选择的协议
            getAgreement() {
                let params = {}
                if (this.usertype == 1) {
                    if (!this.subData.bx_ogid) {
                        return
                    }
                    params.ogid = this.subData.bx_ogid
                }
                
                agreementAll(params).then(res => {
                    if (res.errCode == 0) {
                        this.agreements = res.data.list;
                        this.agreements = [{id:0,name:'系统默认协议'},...this.agreements]
                    }
                });
            },
            clear() {
                this.subData.bx_ogid = 0;
                this.subData.bx_ogname = '';
            },

            insurance_org () {
                this.chooseorgInitOption.title = '选择保险公司';
                this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
                this.chooseorgInitOption.showModal = true;
                this.$refs.insurance_org.handleSearch()
            },
            getChooseGY (item) {
                // console.log(item);
                this.chooseorgInitOption.showModal = false
                this.subData.bx_ogid = item.id;
                this.subData.bx_ogname = item.name;
                this.getAgreement()
            },

            //机构下拉
            // getinsuranceorg() {
            //     let bxparams = {
            //         ogtype: Enum.ogtypeEnum.insurance_company
            //     };
            //     getorhDrop(bxparams).then(res => {
            //         this.bxorgdata = res.data;
            //     });
            // },

            bxchangeparent(value, selectedData){
                // console.log(selectedData,value);return;
                this.subData.bx_ogid=selectedData[selectedData.length-1].value;
            },
            getUserInfoType() {
                //
                getWhether().then(res => {
                    this.userInfoType = res.data;
                });
            },
            tableSelectionChange(data2) {
                if (data2[0] != undefined) {
                    this.checkedId = data2[0].id;
                }
                let ids = [];
                data2.map(function(item, key) {
                    ids.push(item.id);
                });
                this.checkedIds = ids;
            },
            markmap() {
                //标注
                this.markmodel = true;
                if (this.subData.lng) {
                    this.map.center.lng = this.subData.lng;
                    this.map.center.lat = this.subData.lat;
                }
            },
            handleEdit(type) {
                if (this.checkedIds.length > 0) {
                    let params = {};
                    params.id = this.checkedIds;
                    params.type = type;
                    if (type == 1) {
                        this.$Modal.confirm({
                            title: '确认信息',
                            content: '<p>你确定要将选中的信息设置为删除状态么？?</p>',
                            onOk: () => {
                                this.sysEdit(params);
                            },
                            onCancel: () => {
                                this.$Message.info('您取消了删除操作');
                            }
                        });
                    } else {
                        this.sysEdit(params);
                    }
                } else {
                    this.$Modal.info({
                        title: '提示',
                        content: '请至少选择一项'
                    });
                }
            },

            handleGrade() {
                // 批量设置等级
                if (this.checkedIds.length > 0) {
                    this.hyse = true;
                } else {
                    this.$Modal.info({
                        title: '提示',
                        content: '请至少选择一项'
                    });
                }
            },

            handleSubmit(type) {
                let params = {};
                params.id = this.checkedIds;
                params.type = type;
                params.data = this.handleData.levelData;
                this.sysEdit(params);
            },

            sysEdit(params) {
                getZhongbiaoEdit(params).then(res => {
                    if (res.errCode == 0) {
                        this.$Notice.success({
                            title: '提示',
                            desc: '操作成功'
                        });
                        this.fzse = false;
                        this.hyse = false;
                        this.checkedIds = [];
                        this.handleSearch();
                    } else {
                        this.$Notice.error({
                            title: '提示',
                            desc: res.errMsg
                        });
                    }
                });
            },

            selectFunc(cityObj) {
                this.subData.bx_province_id = cityObj.provinceModel;
                this.subData.bx_city_id = cityObj.cityModel;
            },

            getOrgbidType() {
                getOrgbidType().then(res => {
                    this.orgstatus = res.data;
                });
            },
            confirmlnglat() {
                this.subData.lng = this.lng;
                this.subData.lat = this.lat;
                this.$Notice.success({
                    title: '提示',
                    desc: '选择成功'
                });
                this.markmodel = false;
            },

            getMemberLevelDrop() {
                let params = {
                    type: '1'
                };
                getMemberLevelDrop(params).then(res => {
                    this.memberLevelDrop = res.data;
                });
            },

            // 日期
            checkDate(date) {
                this.search.stime = date[0];
                this.search.etime = date[1];
            },
            // 查询
            handleSearchAction() {
                this.search.p = 1;
                this.search.is_export = 0;
                this.handleSearch();
            },
            handler({ BMap, map }) {
                let me = this;
                // console.log(BMap, map);
                // 鼠标缩放

                map.enableScrollWheelZoom(true);
                // 点击事件获取经纬度
                map.addEventListener('click', function(e) {
                    me.subData.lng = e.point.lng;
                    me.subData.lat = e.point.lat;
                    me.lng = e.point.lng;
                    me.lat = e.point.lat;
                    // console.log(e.point.lng, e.point.lat);
                });
            },
            // 分页
            handlePage(value) {
                this.search.p = value;
                this.handleSearch();
            },
            handlePageSize(value) {
                this.search.t = value;
                this.handleSearch();
            },
            // 列表
            handleSearch() {
                let params = this.search;
                 params.action_id='420';
                // getMember(params).then(res => {
                getZhongbiaoList(params).then(res => {
                    if (res.errCode == 0) {
                        this.totalCount = parseInt(res.data.total);
                        this.canshudata = res.data.list;
                        this.button = res.data.button
                    }
                });
            },
            reset() {
                this.$refs.subData.resetFields();
            },
            // 新增
            handleCreateModel() {
                this.reset();
                this.subData.id= '';
                this.subData.bx_ogid= this.curogid;
                this.showEdit = true;
                this.editTitle = '新增';
            },

            handleUpdateModal(data) {
                this.viewData(data);
                this.editTitle = '修改';
                this.showEdit = true;
            },
            // handleDetailModal(data) {
            //     getorgarea({
            //         uid: data
            //     }).then(res => {
            //         this.detailData = res.data;
            //         this.showDetail = true;
            //     });
            // },

            viewData(id) {
                this.subData.id = id;

                getZhongbiaoView({
                    id: id
                }).then(res => {
                    this.subData = res.data;
                    this.getAgreement()
                });
            },

            submitForm() {
                // 保存

                if (null != this.subData.stime_str && "" != this.subData.stime_str) {
                    this.subData.stime = this.subData.stime_str[0];
                    this.subData.etime= this.subData.stime_str[1];
                } 

                if (null != this.subData.stime_ws_str && "" != this.subData.stime_ws_str) {
                    this.subData.stime_ws = this.subData.stime_ws_str[0];
                    this.subData.etime_ws= this.subData.stime_ws_str[1];
                } 



                this.$refs.subData.validate(valid => {
                    if (valid) {
                        let params = this.subData;
                        getZhongbiaoAdd(params).then(res => {
                            if (res.errCode == 0) {
                                this.showEdit = false;
                                this.handleSearch();
                                this.reset();
                                this.$Modal.success({
                                    title: '提示',
                                    content: '提交成功'
                                });
                            } else {
                                this.$Modal.error({
                                    title: '提示',
                                    content: res.errMsg
                                });
                            }
                        });
                    }
                });
            },
            handleError(data1, data2, data3) {
                // error file fileList
                this.$Modal.error({
                    title: '提示',
                    content: '上传失败'
                });
            },
            handleFormatError(data1, data2, data3) {
                // file fileList
                this.$Modal.error({
                    title: '提示',
                    content: '文件格式不正确'
                });
            },
            handleMaxSize(data1, data2) {
                // file fileList
                this.$Modal.error({
                    title: '提示',
                    content: '文件太大'
                });
            },
            handleSuccess(data1, data2, data3) {
                // response file fileList    图标
                if (data1.errCode === 0) {
                    this.$Notice.success({
                        title: '提示',
                        desc: '上传成功'
                    });
                    this.defaultList = [];
                    this.subData.avatar = data1.data[0].pic; // 图片上传地址
                    this.subData.url = data1.data[0].url; // 图片域名地址
                } else {
                    this.$Notice.error({
                        title: '提示',
                        desc: res.errMsg
                    });
                }
            },
            getDuration(date) {
                // how many days of this month
                let dt = new Date();
                var month = dt.getMonth();
                dt.setMonth(dt.getMonth() + 1);
                dt.setDate(0);
                return dt.getDate();
            },
            GetDate(is) {
                var now = new Date();
                var year = now.getFullYear(); //年
                var month = now.getMonth() + 1; //月
                if (is) {
                    return year + '-' + month + '-' + is;
                } else {
                    return year + '-' + month + '-1';
                }
            }
        },
        mounted() {
            switch (this.$route.query.type) {
                case 1:
                    var mun = this.getDuration();

                    this.search.stime = this.GetDate(false);
                    this.search.etime = this.GetDate(false);
                    this.timeshijian = [this.GetDate(false), this.GetDate(false)];
                    break;
                case 2:
                    break;
            }
            // this.getinsuranceorg();//获取机构下拉
            this.getOrgbidType();
            this.getMemberLevelDrop();
            this.getUserInfoType();
            this.handleSearch();
            this.access_token = util.getToken();
            this.usertype=util.getUserType();
            var user=util.getUser();
            this.curogid=user.ogid;
        }
    };
</script>

<style lang="less">
	@import '../style2.less';
	@import '../gongye.less';

	.dizhiselecls {
		width: 342px;
		margin-right: 3px;
	}

	.map {
		width: 100%;
		height: 400px;
	}

	.map .search {
		margin-bottom: 65px;
	}
	.org-width {
		width: 480px !important;
	}
	.userfnpt {
		width: 300px;
		margin-right: 3%;
		display: inline-block;
	}
	.FromItemnpt {
		width: 360px;
		display: inline-block;
		margin-right: 3%;
		white-space: nowrap;
	}
</style>

<template>
    <div class="main" id="effective">

    </div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  props: {
    datatype: {
      type: Object
    },
  },
  data(){
    return{
      data1:{
        date:[],
        youxiao:[],
        zong:[],
      },
    }
  },
  methods:{
    init(){
      this.data1=this.datatype;
      this.getclassification()
    },
    getclassification(){

      var chartDom = document.getElementById('effective');
      var myChart = echarts.init(chartDom,{width:380,height:125});
  myChart.resize();
   var option;

      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['有效单数', '配件总单数'],
          padding:[2, 0, 0 ,0],
          itemHeight:9,
          itemWidth:11,
// 间距
          itemGap:5,
          textStyle: {

            fontSize: 8,
            // padding: [0, 0, 0, 10]
          },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          top:26,
          left: '1.5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            // data: ['前保险杠','大灯框架','倒车镜','前后门','后侧尾板'],
            data: this.data1.date,
            //   隐藏刻度线
            axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:8
              },
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            //   隐藏刻度线
            axisTick: { show: false },
            axisLine: {
              //x轴坐标轴，false为隐藏，true为显示
              show: false,
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
              textStyle: {
                color: "#333333",//Y轴内容文字颜色
                fontSize:9,
                fontFamily:"MicrosoftYaHei"

              },
            }
          }
        ],
        series: [
          {
            name: '有效单数',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            symbolSize: 3, //折线点的大小
            color:["#7186C4"],
            emphasis: {
              focus: 'series'
            },
            // data: [700, 332, 801, 534, 300, 230, 210]
            data: this.data1.youxiao
          },
          {
            name: '配件总单数',
            type: 'line',
            stack: 'Total2',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            color:["#B2DB9E"],
            symbolSize: 3, //折线点的大小
            itemStyle : { normal: {label : {show: true,
                  fontSize:9,color:'#333333'},},
            },
            // data: [400, 332, 101, 534, 500, 230, 210]
            data: this.data1.zong
          },

        ]
      };

      option && myChart.setOption(option);

    }
  },
  mounted(){
    this.getclassification()
  },
  watch: {
    datatype() {
      this.init();
    },
  }
}

</script>
<style scoped>
.main{
    width: 100%;
    height: 100%;
}
</style>

<template>
  <div class="pass_W">
    <Modal
      v-model="initOption.showModal">
      <p slot="header" style="color:#f60;text-align:left;">
        <Icon type="information-circled"></Icon>
        <span>{{initOption.title}}</span>
      </p>
      <Form
        ref="pwdForm"
        :model="subForm"
        :rules="formRules"
        :label-width="150">
        <FormItem label="旧密码：" prop="oldpwd">
          <Input style="width:200px" type="password" v-model="subForm.oldpwd"/>
        </FormItem>
        <FormItem label="新密码：" prop="pwd">
          <Input style="width:200px" type="password" v-model="subForm.pwd"/>
        </FormItem>
        <FormItem label="再次输入新密码：" prop="pwd2">
          <Input style="width:200px" type="password" v-model="subForm.pwd2"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" @click="initOption.showModal = false">取消</Button>
        <Button type="primary" @click="handleSubmit">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { changepwd } from '@/api/admin'
import {getUser,getToken} from '@/libs/util'
export default {
  props: {
    initOption: {
      title: {
        type: String,
        default: '修改'
      },
      showModal: {
        type: Boolean
      }
    }
  },
  data () {
    const validatePassCheck = (rule, value, callback) => {
      if (value) {
        if (value === this.subForm.pwd) {
          callback()
        } else {
          callback(new Error('两个输入密码不匹配！'))
        }
      } else {
        callback(new Error('请再次输入新密码'))
      }
    }
    return {
      subForm: {
		user_id: '',
        oldpwd: '',
        pwd: '',
        pwd2: ''
      },
      formRules: {
        oldpwd: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        pwd2: [
          { validator: validatePassCheck, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleSubmit () {
		console.log('handleSubmit',this.subForm);
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
			this.subForm.user_id=getUser().id;
          changepwd(this.subForm).then(res => {
            if (res.errCode === 0) {
              this.$Notice.success({ title: '提示', desc: '操作成功' })
              this.initOption.showModal = false
              this.$emit('logout')
            } else {
              this.$Notice.error({ title: '提示', desc: res.errMsg })
            }
          })
        }
      })
    },
    reset () {
      this.$refs.pwdForm.resetFields()
    }
  }
}
</script>

<style>

</style>


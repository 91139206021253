<template>
  <div>
    <Card class="col-right-card min-height">
      <p slot="title">文章新增</p>
      <Form :label-width="120" ref="subData" :model="subData" :rules='dataRules'>
      	<FormItem class="FromItemnpt dizhi_width" label="标题"  prop="title">
          <Input v-model="subData.title"/>
        </FormItem>
  		<br/>
        <FormItem class="FromItemnpt dizhi_width" label="分类" prop="type">
          <Select v-model="subData.type">
            <Option v-for="(item,index) in hotNewsType" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <br/>

        <FormItem class="storebrand_form_doal" label="图片信息"  prop="path_url">
          <viewer style="display: inline-block;" :image="subData.path_url" v-if="subData.path_url">
            <div class="yingimgs">
              <img :src="subData.path_url" style="width: 60px;height: 60px;"
                   class="upload-image-view"/>
            </div>
          </viewer>
          <Upload
            ref="upload"
            :default-file-list="defaultList"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :format="['jpg','jpeg','png','gif']"
            :max-size="1024"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :headers="{Authorization: 'Bearer ' + this.access_token}"
            :data="{type: 'news'}"
            type="drag"
            :action=this.uploadHttp
            style="display: inline-block;width:58px;">
            <div style="width: 58px; float:left;height:58px;line-height: 58px;maigin-left:5px;">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
          <p class="colors">建议400*200，支持JPG/GIF/PNG，上传信息不能超过2M</p>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt dizhi_width" label="优先级排序">
          <Input v-model="subData.sortcode"/>
        </FormItem>
        <br/>
        <FormItem class="FromItemnpt" label="状态">
          <i-Switch v-model="subData.status">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-Switch>
          <p class="colors">开启后，该文章为显示。关闭则隐藏。</p>
        </FormItem>
         <br/>
      </Form>
      <Divider orientation="left">文章描述</Divider>
      <div style="position:relative;height:0;">
          <Button style="position: absolute;bottom: 13px;right: 20px;" @click="modalWen = true" type="primary" >拉取文章</Button>
      </div>
      <editor style="margin-top: 10px;" v-model="subData.details"  :uploadtype="uploadtype"/>
    </Card>
    <Card class="col-right-card">
      <div class="bnt_center">
        <Button type="primary" @click="submitForm"  :loading="loading">保存</Button>
      </div>
    </Card>
    <!-- 拉取文章弹窗 -->
    <Modal
      v-model="modalWen"
      width="400"
      title="拉取文章"
    >
      <Form :label-width="100" class="FromClass">
        <FormItem label="文章链接" class="fromcls from-inputNumber">
          <Input v-model="graburl"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="modalWen = false">取消</Button>
        <Button type="primary"  :loading="loading2" @click="submitFormUrl">提取</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
  import {getHotNewsAdd ,getNewsGrab} from '@/api/backend'
  import {getHotNewsType } from '@/api/dictionary'
  import { getToken } from '@/libs/util'
  import config from '@/config'
  import editor from '@/components/editor/index.js'
  export default {
  	components: {
      editor,
    },
    data () {
      return {
        loading2:false,     
        loading:false,     

        graburl:'',
        modalWen:false,
        http:'',
        hotNewsType:[],
        uploadtype:'news',
        dataRules: {
          type: [
            { required: true, message: '必填', trigger: 'change' },
          ],
          title: [
            { required: true, message: '必填', trigger: 'blur' },
          ],
          path_url: [
            { required: true, message: '请上传图片', trigger: 'blur' },
          ],
        },
        subData: {
          id: '',
          title: '',
          type: '',
          path: '',
          path_url: '', // 图片
          sortcode: '255', 
          status: true, 
          details:'',
        },
        uploadList: '',
        uploadHttp: '',
        access_token: '',
        defaultList: [],
      }
    },
    methods: {
      modalAddOk() {
        this.$Message.info("Clicked ok");
      },
      modalAddCancel() {
        this.$Message.info("Clicked cancel");
      },
      getHotNewsType() {
        getHotNewsType().then(res => {
          this.hotNewsType = res.data
        })
      },
   
      handleSuccess (res, file) {
        if (res.errCode === 0) {
          this.$Notice.success({
            title: '提示',
            desc: '上传成功'
          })
          this.subData.path = res.data[0].pic // 图片上传地址
          this.subData.path_url = res.data[0].url // 图片域名地址
        }else {
          this.$Notice.error({
            title: '提示',
            desc: res.errMsg
          })
        }
      },
      handleError (file) {
        this.$Modal.error({ title: '提示', content: '上传失败' })
      },
      handleFormatError (file) {
        this.$Modal.error({ title: '提示', content: '文件格式不正确' })
      },

      handleMaxSize (file) {
        this.$Modal.error({ title: '提示', content: '文件太大' })
      },

      handleBeforeUpload () {
        const check = this.uploadList.length < 2
        if (!check) {
          this.$Modal.error({ title: '提示', content: '最多可以上传一张照片。' })
        }
        return check
      },
      submitForm () { // 保存
        this.loading = true;

        if(!this.subData.details ) {
          this.$Modal.error({ title: '提示', content: '请填写文章内容' })
        }
        this.$refs.subData.validate(valid => {
          if (valid) {
            let params = this.subData
            let vm = this
            getHotNewsAdd(params).then(res => {
              if (res.errCode == 0) {
                this.loading = false;
                this.$Modal.success({ title: '提示', content: res.errMsg })
                setTimeout(function () {
                  let query = { id: res.data}
                  vm.$router.push({
                    name: 'essar_new_detail',
                    query: query
                  })
                }, 1000) 
                // this.viewData()
              } else {
                this.$Modal.error({ title: '提示', content: res.errMsg })
              }
            })
          }
        })
      },

      submitFormUrl() {
        this.loading2 = true;     

        if(!this.graburl ) {
          this.$Modal.error({ title: '提示', content: '请填写要拉取的链接' })
        }
        let params = {}
        params.graburl = this.graburl
        params.type = 1
        getNewsGrab(params).then(res => {
          if(res.errCode == 0) {
            this.loading2 = false;     
            this.$Modal.success({ title: '提示', content: res.errMsg })
            this.subData.title = res.data.title
            this.subData.details = res.data.newsbody
            this.subData.path = res.data.firstimgrel // 图片上传地址
            this.subData.path_url = res.data.firstimg // 图片域名地址
            this.modalWen = false
          } else {
            this.loading2 = false;     
            this.$Modal.error({ title: '提示', content: res.errMsg })
          }
        })
      }




    },
    mounted () {
      this.getHotNewsType()
      this.access_token = getToken()
      this.uploadList = this.$refs.upload.fileList
      this.uploadHttp = process.env.NODE_ENV === 'development' ? config.uploadImgUrl.dev : config.uploadImgUrl.pro

    }
  }
</script>


<style lang="less">
  @import "../style2.less";
  @import "../gongye.less";
</style>

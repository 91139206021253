<template>
  <Layout style="height: 100%" class="main">
    <Sider hide-trigger collapsible  :width="203" :collapsed-width="64" v-model="collapsed" class="left-sider sidercls"
           :style="{overflow: 'hidden'}">
      <side-menu accordion ref="sideMenu" :active-name="$route.name" :collapsed="collapsed" @on-select="turnToPage"
                 :menu-list="menuList">
        <div class="logo-con">
          <img v-show="!collapsed" src="@/assets/images/logo_ditan.png" key="max-logo"/>
          <!-- <img v-show="collapsed" src="@/assets/images/anjianxiaologo.png" key="min-logo"/> -->
        </div>
      </side-menu>
    </Sider>

    <Layout>
      <Header class="header-con">
        <header-bar :collapsed="collapsed" @on-coll-change="handleCollapsedChange">
          <user :message-unread-count="unreadCount" :user-avatar="userAvatar"/>
          <!-- <language v-if="$config.useI18n" @on-lang-change="setLocal" style="margin-right: 10px;" :lang="local"/> -->
          
          <span style="margin:0 15px;"><b>用户名：</b>{{user.real_name}}</span><span  v-show="user.ogname" ><b>机构名称：</b>{{user.ogname}}</span>
          <!--<error-store v-if="$config.plugin['error-store'] && $config.plugin['error-store'].showInHeader" :has-read="hasReadErrorPage" :count="errorCount"></error-store>-->
          
          <fullscreen v-model="isFullscreen" style="margin-right: 10px;"/>
          
        <!--   <Tooltip class="lingdang" content="未33333读消息"  placement="bottom">
            <Badge :count="messageCount">
              <Icon type="ios-notifications-outline" size="26"  @click="toNotice"></Icon>
          </Badge>
        </Tooltip> -->
        </header-bar>
      </Header>

      <Content class="main-content-con">
        <Layout class="main-layout-con">
          <div class="tag-nav-wrapper">
            <tags-nav :value="$route" @input="handleClick" :list="tagNavList" @on-close="handleCloseTag"/>
          </div>
          <Content class="content-wrapper">
            <keep-alive :include="cacheList">
              <router-view/>
            </keep-alive>
            <ABackTop :height="100" :bottom="80" :right="50" container=".content-wrapper"></ABackTop>
          </Content>
        </Layout>
      </Content>
    </Layout>
    <div v-for="items in shuiyin" v-model="shuiyin">
      <div class="shuiyin"
           :class="{hang_1_left:items.isboor_left,hang_1_top:items.isboor_top1,hang_2_top:items.isboor_top2,hang_3_top:items.isboor_top3,hang_4_top:items.isboor_top4}">
        {{items.content}}
      </div>
      <div class="shuiyin"
           :class="{hang_2_left:items.isboor_left,hang_1_top:items.isboor_top1,hang_2_top:items.isboor_top2,hang_3_top:items.isboor_top3,hang_4_top:items.isboor_top4}">
        {{items.content}}
      </div>
      <div class="shuiyin"
           :class="{hang_3_left:items.isboor_left,hang_1_top:items.isboor_top1,hang_2_top:items.isboor_top2,hang_3_top:items.isboor_top3,hang_4_top:items.isboor_top4}">
        {{items.content}}
      </div>
      <div class="shuiyin"
           :class="{hang_4_left:items.isboor_left,hang_1_top:items.isboor_top1,hang_2_top:items.isboor_top2,hang_3_top:items.isboor_top3,hang_4_top:items.isboor_top4}">
        {{items.content}}
      </div>
      <div class="shuiyin"
           :class="{hang_5_left:items.isboor_left,hang_1_top:items.isboor_top1,hang_2_top:items.isboor_top2,hang_3_top:items.isboor_top3,hang_4_top:items.isboor_top4}">
        {{items.content}}
      </div>
    </div>

  </Layout>
</template>
<script>
  import { getNoReadNotice} from '@/api/user'
  import SideMenu from './components/side-menu'
  import HeaderBar from './components/header-bar'
  import TagsNav from './components/tags-nav'
  import User from './components/user'
  import ABackTop from './components/a-back-top'
  import Fullscreen from './components/fullscreen'
  import ErrorStore from './components/error-store'
  import {mapMutations, mapActions, mapGetters} from 'vuex'
  import {getNewTagList, routeEqual, getAvatar, getUser} from '@/libs/util'
  import routers from '@/router/routers'
  import minLogo from '@/assets/images/anjianxiaologo.png'
  import maxLogo from '@/assets/images/anjianlogo.png'
  import './main.less'
  export default {
    name: 'Main',
    components: {
      SideMenu,
      HeaderBar,
      // Language,
      TagsNav,
      Fullscreen,
      ErrorStore,
      User,
      ABackTop
    },
    data() {
      return {
        // width_val:0,//导航栏宽度
        // widths:0,
        shuiyin: [
          {
            content: 'yuanjianpingtai001',
            isboor_left: true,
            isboor_top1: true,
            isboor_top2: false,
            isboor_top3: false,
            isboor_top4: false,
          },
          {
            content: 'yuanjianpingtai001',
            isboor_left: true,
            isboor_top1: false,
            isboor_top2: true,
            isboor_top3: false,
            isboor_top4: false,
          },
          {
            content: 'yuanjianpingtai001',
            isboor_left: true,
            isboor_top1: false,
            isboor_top2: false,
            isboor_top3: true,
            isboor_top4: false,
          },
          {
            content: 'yuanjianpingtai001',
            isboor_left: true,
            isboor_top1: false,
            isboor_top2: false,
            isboor_top3: false,
            isboor_top4: true,
          }],
        messageCount:0,

        collapsed: false,
        minLogo,
        maxLogo,
        isFullscreen: false,
        user: {}
      }
    },
    computed: {
      // 159
      ...mapGetters([
        'errorCount',
		'menuList',
		'local',
		'hasReadErrorPage',
		
		
      ]),
      
      tagNavList() {
        return this.$store.state.app.tagNavList
      },
      tagRouter() {
        return this.$store.state.app.tagRouter
      },
      userAvatar() {
        // return this.$store.state.user.avatarImgPath
        var avatar_url = getAvatar()
        if (avatar_url) return avatar_url
        else return ''
      },
      cacheList() {
        const list = ['ParentView', ...this.tagNavList.length ? this.tagNavList.filter(item => !(item.meta && item.meta.notCache)).map(item => item.name) : []]
        return list
      },
    
      unreadCount() {
        return this.$store.state.user.unreadCount
      }
    },
    methods: {
      ...mapMutations([
        'setBreadCrumb',
        'setTagNavList',
        'addTag',
        'setLocal',
        'setHomeRoute',
        'closeTag'
      ]),
      ...mapActions([
        'handleLogin',
        'getUnreadMessageCount'
      ]),
      turnToPage(route) {
        let {name, params, query} = {}
        if (typeof route === 'string') name = route
        else {
          name = route.name
          params = route.params
          query = route.query
        }
        if (name.indexOf('isTurnByHref_') > -1) {
          window.open(name.split('_')[1])
          return
        }
        this.$router.push({
          name,
          params,
          query
        })
      },
      handleCollapsedChange(state) {
        this.collapsed = state
      },
      handleCloseTag(res, type, route) {
        if (type !== 'others') {
          if (type === 'all') {
            this.turnToPage(this.$config.homeName)
          } else {
            if (routeEqual(this.$route, route)) {
              this.closeTag(route)
            }
          }
        }
        this.setTagNavList(res)
      },

      // created(){
      //   this.timer = setInterval(() => {//启动定时器发送请求
      //       this.getNoReadNotice(); 
      //   },60000)
      // },

    // getNoReadNotice(){
    //   getNoReadNotice().then( res => {
    //     if (res.errCode == 0) {
    //       this.messageCount = parseInt(res.data)
    //     }
    //   })
    // },

    toNotice() {
      this.$router.push({
          name: 'notice_list',
      });
    },


      handleClick(item) {
        this.turnToPage(item)
      },

      shuiyinData(){
        for (var i = 0;i <4;i++) {
          let vm = this
          vm.shuiyin[i]['content'] = vm.user.user_name
        }
      },

    },
    watch: {
      '$route'(newRoute) {
        const {name, query, params, meta} = newRoute
        this.addTag({
          route: {name, query, params, meta},
          type: 'push'
        })
        this.setBreadCrumb(newRoute)
        this.setTagNavList(getNewTagList(this.tagNavList, newRoute))
        this.$refs.sideMenu.updateOpenName(newRoute.name)
      }
    },

     


    mounted() {
      // this.created()
      // this.getNoReadNotice()

      /**
       * @description 初始化设置面包屑导航和标签导航
       */

      this.setTagNavList()
      this.setHomeRoute(routers)
      const {name, params, query, meta} = this.$route
      this.addTag({
        route: {name, params, query, meta}
      });
      this.setBreadCrumb(this.$route);
      // 设置初始语言
      this.setLocal(this.$i18n.locale);
      // 如果当前打开页面不在标签栏中，跳到homeName页
      if (!this.tagNavList.find(item => item.name === this.$route.name)) {
        this.$router.push({
          name: this.$config.homeName
        })
      };

      // 获取未读消息条数
      // this.getUnreadMessageCount()
      this.user = getUser();
      this.shuiyinData();


      //监听屏幕宽度
      // this.width_val = document.body.clientWidth
      // if(this.width_val>1600){
      //             this.widths = 256+'px'
      //           }else{
      //             this.widths = 200+'px'
      //           }
      // window.onresize = () => {
      //       return (() => {
      //           this.width_val = document.body.clientWidth
      //           // console.log('detail---'+this.width_val)
      //           if(this.width_val>1600){
      //             this.widths = 256+'px'
      //           }else{
      //             this.widths = 200+'px'
      //           }
                
      //       })()
      //     }

    }
  }
</script>

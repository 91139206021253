<template>
    <Card class="col-right-card min-height">
        <p slot="title">开标协议</p>
        <Form :label-width="120">
            <FormItem class="FromItemnpt" label="关键词"><Input clearable placeholder="保险公司编号|名称" v-model="search.kw" />
            </FormItem>
            <Button type="primary" @click="handleSearchAction">查询</Button>
        </Form>
        <div class="gray-nona"></div>
        <Button class="marginbnt" @click="handleCreateModel" type="primary">新增</Button>
        <Button class="marginbnt" @click="handleDel(1)" type="primary">删除</Button>

        <Table height="500" border stripe :columns="canshu" :data="canshudata" @on-select="tableSelectionChange"
            @on-select-all="tableSelectionChange" @on-select-cancel="tableSelectionChange"></Table>

        <div class="my-page">
            <Page :total="totalCount" show-elevator show-sizer show-total @on-change="handlePage"
                @on-page-size-change="handlePageSize" />
            <div style="height:30px;"></div>
        </div>

        <Modal v-model="showEdit" width="80%" :title="editTitle" @on-cancel="()=> {partsList=[]}">
            <Form :label-width="120" ref="subData" :model="subData">
                <Row>
					<FormItem v-show="usertype == 1" class="FromItemnpt org-width" label="上级机构" prop="bx_ogname">
						<Input v-model="subData.bx_ogname" :disabled=true >
						<Icon type="ios-search" slot="suffix" @click="insurance_org"/>
						</Input>
						<a style="margin-left:5px;" @click="clear">清除</a>
					</FormItem>
				</Row>
                <Row>
                    <FormItem class="FromItemnpt" label="协议名">
                        <Input v-model="subData.name" placeholder="便于后期选择协议" />
                    </FormItem>
                </Row>
                <Row v-for="(item,index) in subData.rule">
                    <FormItem class="FromItemnpt" :label="'配件价格范围'+(index+1)"> 
                        <Input type="number"  v-model="item.start"    placement="bottom-end" placeholder="起始金额(元)" style="width: 120px" />
                        <span style="margin:0 10px;">——</span>
                        <Input type="number"  v-model="item.end"    placement="bottom-end" placeholder="结束金额(元)" style="width: 120px" />
                        <Input type="number"  v-model="item.bili"    placement="bottom-end" placeholder="外修商上限比例" style="width: 150px;margin-left: 10px;" />%
                        <Icon v-if="index+1 == subData.rule.length" type="ios-add-circle-outline" @click="addRule" size="24" />
                        <Icon v-if="subData.rule.length>1" type="ios-remove-circle-outline" @click="removeRule(index)" size="24" />
                    </FormItem>
                </Row>
                请选择适用的配件
                <div class="tree_view">
                    <div v-for="pitem in partsList" class="p_item">
                        <Card>
                            <div slot="title" class="title_p">
                            <Button type="warning">{{pitem.name}}</Button>
                            </div>
                            <div class="tree_select">
                                <Tree :data="pitem.ptreedata" show-checkbox multiple></Tree>
                                </div>
                        </Card>
                    </div>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="showEdit = false;partsList=[]">取消</Button>
                <Button type="primary" @click="submitForm">保存</Button>
            </div>
        </Modal>
        <insurance_org
				ref="insurance_org"
				:initOption="chooseorgInitOption"
				@getorgdata="getChooseGY">
		</insurance_org>
    </Card>
</template>
<script>
import { getpartsorglist2,agreementList,agreementAdd,agreementView,agreementDel} from '@/api/admin';
import insurance_org from '@/view/my-components/ditanorg/insurance_org';
import * as util from '@/libs/util';
import * as Enum from '@/libs/enum';

export default {
    components: {
        insurance_org
    },
    data() {
        return {
            partsList: [],
            showEdit: false,
            subData: {
                bx_ogname:'',
                rule:[{}]
            },
            canshudata: [],
            checkedIds: [],
            editTitle: '外修商机构区域新增',
            totalCount: 10,
            usertype:'2',
            search: {
                p: 1, // 当前页码
                t: 10, // 每页显示条数
                kw:null,
            },
            chooseorgInitOption: {
                title: '',
                showModal: false,
            },
            canshu: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                    fixed: 'left'
                },
                {
                    title: '保险公司编号',
                    key: 'code',
                    align: 'center',
                    minWidth: 60,
                    fixed: 'left'
                },
                {
                    title: '保险公司名称',
                    key: 'bxname',
                    align: 'center',
                    minWidth: 60,
                    fixed: 'left'
                },
                {
                    title: '协议名称',
                    key: 'name',
                    align: 'center',
                    minWidth: 60
                },
                {
                    title: '添加时间',
                    key: 'createtime',
                    align: 'center',
                    minWidth: 60
                },
                {
                    title: '添加人',
                    key: 'real_name',
                    align: 'center',
                    minWidth: 60
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 170,
                    align: 'center',
                    fixed: 'right',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Button',
                                {
                                    props: {
                                        type: 'primary',
                                        size: 'small',
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.handleUpdateModal(params.row.id);
                                        }
                                    }
                                },
                                '编辑'
                            )
                        ]);
                    }
                }
            ],
        };
    },
    methods: {
        handleUpdateModal(data) {
            this.viewData(data);
            this.matchparts(data)
            this.editTitle = '修改';
            this.showEdit = true;
        },
        viewData(id) {
            this.subData.id = id;
            agreementView({
                id: id
            }).then(res => {
                this.subData = res.data;
            });
        },
        clear() {
            this.subData.ogid = 0;
            this.subData.bx_ogname = '';
        },
        sysEdit(params) {
            agreementDel(params).then(res => {
                if (res.errCode == 0) {
                    this.$Notice.success({
                        title: '提示',
                        desc: '操作成功'
                    });
                    this.checkedIds = [];
                    this.handleSearch();
                } else {
                    this.$Notice.error({
                        title: '提示',
                        desc: res.errMsg
                    });
                }
            });
        },
        handleDel(type) {
            if (this.checkedIds.length > 0) {
                let params = {};
                params.id = this.checkedIds;
                params.type = type;
                if (type == 1) {
                    this.$Modal.confirm({
                        title: '确认信息',
                        content: '<p>你确定要将选中的信息删除么?</p>',
                        onOk: () => {
                            this.sysEdit(params);
                        },
                        onCancel: () => {
                            this.$Message.info('您取消了删除操作');
                        }
                    });
                }
            } else {
                this.$Modal.info({
                    title: '提示',
                    content: '请至少选择一项'
                });
            }
        },
        insurance_org () {
            this.chooseorgInitOption.title = '选择保险公司';
            this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
            this.chooseorgInitOption.showModal = true;
            this.$refs.insurance_org.handleSearch()
        },
        getChooseGY (item) {
            this.chooseorgInitOption.showModal = false
            this.subData.ogid = item.id;
            this.subData.bx_ogname = item.name;
        },
        addRule(){
            this.subData.rule.push({})
        },
        removeRule(index){
            this.subData.rule.splice(index,1)
        },
        submitForm(){
            this.subData.base_spare = []
            this.partsList.forEach(pitem => {
                let tdata=pitem.ptreedata[0].children;
                tdata.forEach(titem => {
                    if(titem.checked){
                        this.subData.base_spare.push(titem.id)
                    }
                })
            })
            agreementAdd(this.subData).then(res => {
                if (res.errCode == 0) {
                    this.showEdit = false;
                    this.handleSearch();
                    this.reset();
                    this.$Modal.success({
                        title: '提示',
                        content: '提交成功'
                    });
                } else {
                    this.$Modal.error({
                        title: '提示',
                        content: res.errMsg
                    });
                }
            });
        },
        // 查询
        handleSearchAction() {
            this.search.p = 1;
            this.search.is_export = 0;
            this.handleSearch();
        },
        // 新增
        handleCreateModel() {
            this.matchparts()
            this.reset();
            this.subData = {
                bx_ogname:'',
                rule:[{}]
            }
            this.showEdit = true;
            this.editTitle = '新增';
        },
        // 分页
        handlePage(value) {
            this.search.p = value;
            this.handleSearch();
        },
        handlePageSize(value) {
            this.search.t = value;
            this.handleSearch();
        },
        tableSelectionChange(data2) {
            if (data2[0] != undefined) {
                this.checkedId = data2[0].id;
            }
            let ids = [];
            data2.map(function(item, key) {
                ids.push(item.id);
            });
            this.checkedIds = ids;
        },
        // 列表
        handleSearch() {
            agreementList(this.search).then(res => {
                if (res.errCode == 0) {
                    this.totalCount = parseInt(res.data.total);
                    this.canshudata = res.data.list;
                }
            });
        },
        reset() {
            this.partsList = []
            this.$refs.subData.resetFields();
        },
        matchparts(id = 0) {
			const msg = this.$Message.loading({
				content: '加载中...',
				dutration: 0
			});

			getpartsorglist2({
				id: id
			}).then(res => {
				if (res.errCode === 0) {
					this.partsList = res.data.list;
					this.partsList.forEach(pitem => {
						// debugger
						pitem.ptreedata=[];
						let p={
							title:'全部',
							expand: true,
							children:[],
						};
						pitem.data.forEach(item1 => {
							let ch = false;
							if (item1.status == 0) {
								ch = false;
							} else {
								ch = true;
							}
							let cl = {
								checked: ch,
								id: item1.id,
								title: item1.cspname,
								csptyoe: item1.csptyoe
							};

							p.children.push(cl);
						});
						pitem.ptreedata.push(p)
					});
					
					setTimeout(msg, 0);
				}
			});
		},
    },
    mounted() {
        this.handleSearch();
        this.usertype=util.getUserType();
    }
};
</script>

<style lang="less">
@import '../style2.less';
@import '../gongye.less';

.dizhiselecls {
    width: 342px;
    margin-right: 3px;
}

.map {
    width: 100%;
    height: 400px;
}

.map .search {
    margin-bottom: 65px;
}

.org-width {
    width: 480px !important;
}

.userfnpt {
    width: 300px;
    margin-right: 3%;
    display: inline-block;
}

.FromItemnpt {
    width: 360px;
    display: inline-block;
    margin-right: 3%;
    white-space: nowrap;
}
.tree_view {
	width: 100%;
	display: flex;
	.p_item {
		width: 20%;
		.title_p{
			color: #f57d22;
		}
		
		.tree_select{
			overflow: scroll;
			height: 600px;
		}
	}
}
</style>

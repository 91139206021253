import HttpRequest from '@/libs/axios'
import config from '@/config'
const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro

const axios = new HttpRequest(baseUrl)


export function request (params,url) {
	  return axios.request(params)
}

export function get (url, params) {
  var params = {
    url: url,
    params,
    method: 'get'
  }
  return request(params)
}


export function post (url, data) {
  var params = {
    url: url,
    data,
    method: 'post'
  }
  return request(params)

}

export default axios

<template>
  <Card class="col-right-card min-height">
    <!-- <div class="list"> -->
    <p slot="title">物损清单</p>
    <Form :label-width="120">
      <!--<FormItem class="FromItemnpt"><Input clearable value='2020-01-07'/></FormItem>-->
      <FormItem class="FromItemnpt" label="订单发起时间">
        <DatePicker style="width:180px;" type="daterange" :options="dateRange" @on-change="checkDate" ></DatePicker>
      </FormItem>

      <FormItem class="FromItemnpt" label="保险公司">
        <Input v-model="search.baoxianname" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="insurance_org" />
        </Input>
        <a style="margin-left: 5px" @click="clearBaoxian">清除</a>
      </FormItem>
      <FormItem class="FromItemnpt" label="报案号">
        <Input
          v-model="search.reportno"
          placeholder="请输入报案号"
        ></Input>
      </FormItem>
      <FormItem class="FromItemnpt" label="第三方单位名称：">
        <Input v-model="search.repair_name" :disabled="true">
          <Icon type="ios-search" slot="suffix" @click="repair_org" />
        </Input>
        <a style="margin-left: 5px" @click="clear">清除</a>
      </FormItem>
      <Button type="primary" @click="handleSearchAction">查询</Button>
      <Button type="primary" :loading="exportLoading" @click="exportExcel" >导出</Button>
    </Form>
    <Table
      height="500"
      border
      stripe
      :columns="columsQingdan"
      :data="dataQingdan"
      @on-select="tableSelectionChange"
      @on-select-all="tableSelectionChange"
      @on-select-cancel="tableSelectionChange"
    ></Table>
    <!-- </div> -->

    <div class="my-page">
      <Page
        :total="totalCount"
        show-elevator
        show-sizer
        show-total
        @on-change="handlePage"
        @on-page-size-change="handlePageSize"
      />
      <div style="height: 30px"></div>
    </div>

    <insurance_org
      ref="insurance_org"
      :initOption="chooseorgInitOption"
      @getorgdata="getChooseGY"
    >
    </insurance_org>
  </Card>
</template>

<script>
import excel from '@/libs/excel'
import insurance_org from "@/view/my-components/ditanorg/insurance_org";
import * as Enum from "@/libs/enum";
import { wusunList } from '@/api/admin';
// import config from "@/config";
// import * as util from "@/libs/util";
export default {
  name: "",
  components: {
    insurance_org,
  },
  data() {
    return {
      search: {
        p: 1,
        t: 10,
        bx_ogid: '',
        baoxianname: '',
        reportno: '',
        ogid: '',
        repair_name: '',
      },
      totalCount: 10,
      dataQingdan: [],
      columsQingdan: [
        {title: "订单发起时间", key: "createtime_str", align: "center", minWidth: 170,},
        {title: "保险公司", key: "baoxianname", align: "center", minWidth: 100,},
        { title: "归属地区", key: "belong_city_id_str", align: "center", minWidth: 100 },
        {title: "定损员名称", key: "createname", align: "center", minWidth: 110,},
        { title: "报案号", key: "reportno", align: "center", minWidth: 100 },
        {title: "配件单号", key: "ordersn", align: "center", minWidth: 150,},
        {title: "物损地址", key: "repair_address", align: "center", minWidth: 150,},
        { title: "接待人", key: "repair_contact", align: "center", minWidth: 80 },
        { title: "物损名称", key: "sparename", align: "center", minWidth: 130 },
        { title: "物损分类", key: "base_spare_type_str", align: "center", minWidth: 100 },
        { title: "物损原值", key: "offermoney", align: "center", minWidth: 100 },
        {title: "物损商机构名称", key: "ogname", align: "center", minWidth: 150,},
        {title: "处理状态", key: "bstatus_str", align: "center", minWidth: 100,},
        { title: "整单状态", key: "obstatus_str", align: "center", minWidth: 100 },
        { title: "报价金额", key: "bjmoney", align: "center", minWidth: 100 },
        { title: "核价金额", key: "checkmoney", align: "center", minWidth: 100 },
        { title: "中标状态", key: "zbstatus", align: "center", minWidth: 100 },
        { title: "开标时间", key: "bidtime_str", align: "center", minWidth: 170 },
        {title: "物损商报价时间", key: "offertime_str", align: "center", minWidth: 170,},
        {title: "物损商取件时间", key: "taketime_str", align: "center", minWidth: 170,},
        {title: "物损商还件时间", key: "returntime_str", align: "center", minWidth: 170,},
        {title: "修复成功时间", key: "successtime_str", align: "center", minWidth: 170,},
        {title: "对方要价", key: "dicker_price", align: "center", minWidth: 170,},
        {title: "议价金额", key: "dicker_money", align: "center", minWidth: 170,},
        {title: "审核信息", key: "content", align: "center", minWidth: 170,},
        {title: "报价时效", key: "baojia_str", align: "center", minWidth: 170,},
        {title: "开标时效", key: "kaibiao_str", align: "center", minWidth: 170,},
        {title: "维修时效", key: "weixiu_str", align: "center", minWidth: 170,}
      ],
      chooseorgInitOption: {
        title: "",
        showModal: false,
      },
      dateRange: {
        shortcuts: [
          {
            text: '今日',
            value() {
              const end = new Date();
              const start = new Date();
              return [start, end];
            }
          },
          {
            text: '本周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '本月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '本季度',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      exportLoading: false,
    };
  },
  methods: {
    clear() {
      this.search.ogid = "";
      this.search.repair_name = "";
    },
    clearBaoxian() {
      this.search.bx_ogid = "";
      this.search.baoxianname = "";
    },
    exportExcel () {
      this.exportLoading = true



      let params = JSON.parse(JSON.stringify(this.search));
      params.t = 20000;//导出最大条数
      wusunList(params).then((res) => {
        if (res.errCode == 0) {

          if (res.data.list.length) {
            const params = {
              title: this.columsQingdan.map(x=>x.title),
              key: this.columsQingdan.map(x=>x.key),
              data: res.data.list,
              autoWidth: true,
              filename: '物损清单列表'
            }
            excel.export_array_to_excel(params)
            this.exportLoading = false
          } else {
            this.$Message.info('表格数据不能为空！')
            this.exportLoading = false
          }



        }
      });

    },
    // 日期
    checkDate(date) {
      this.search.stime = date[0];
      this.search.etime = date[1];
    },
    tableSelectionChange(data2) {
      if (data2[0] != undefined) {
        this.checkedId = data2[0].id;
      }
      let ids = [];
      data2.map(function(item, key) {
        ids.push(item.id);
      });
      this.checkedIds = ids;
    },
    //选择机构输入框
    repair_org() {
      this.chooseorgInitOption.title = "选择物损商";
      this.chooseorgInitOption.ogtype = 5;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    insurance_org() {
      this.chooseorgInitOption.title = "选择保险公司";
      this.chooseorgInitOption.ogtype = Enum.ogtypeEnum.insurance_company;
      this.chooseorgInitOption.showModal = true;
      this.$refs.insurance_org.handleSearch();
    },
    //选择机构
    // getChooseGY(item) {
    //   // console.log(item);
    //   this.chooseorgInitOption.showModal = false;
    //
    //   this.search.bx_ogid = item.id;
    //   this.search.baoxianname = item.name;
    // },

    getChooseGY(item) {

      this.chooseorgInitOption.showModal = false;

      if (item.ogtype == 5) {
        this.search.ogid = item.id;
        this.search.repair_name = item.name;
      } else if (item.ogtype == 3) {
        this.search.bx_ogid = item.id;
        this.search.baoxianname = item.name;
      }
    },

    // 分页
    handlePage(value) {
      this.search.p = value;
      this.handleSearch();
    },
    handlePageSize(value) {
      this.search.t = value;
      this.handleSearch();
    },
    // 列表
    handleSearch() {
      let params = this.search;
      wusunList(params).then((res) => {
        if (res.errCode == 0) {
          this.totalCount = parseInt(res.data.total);
          this.dataQingdan = res.data.list;
          // this.button = res.data.button
        }
      });
    },
    // 查询
    handleSearchAction() {
      this.search.p = 1;
      this.search.is_export = 0;
      this.handleSearch();
    },
  },
  mounted() {
    this.handleSearch();
  }
};
</script>

<style lang="less">
@import "../style2.less";
@import "../gongye.less";

.dizhiselecls {
  width: 342px;
  margin-right: 3px;
}

.map {
  width: 100%;
  height: 400px;
}

.map .search {
  margin-bottom: 65px;
}

.userfnpt {
  width: 300px;
  margin-right: 3%;
  display: inline-block;
}
</style>
